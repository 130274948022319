import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FILEF_INSTR_POPUP = ({ instructionOpen, setInstructionOpen }) => {
  return (
    <>
      <Dialog
        open={instructionOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={() => setInstructionOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            fontFamily: "Calibri",
            fontWeight: "bold",
            color: "#1976d2",
            fontSize: 25,
          }}
        >
          ISTRUZIONI D'INSERIMENTO:
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              fontFamily: "Calibri",
              fontSize: 25,
            }}
          >
            <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
              1 - ESPORTARE IL FILE VUOTO DA COMPILARE PER L'INSERIMENTO
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInstructionOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// export default FILEF_INSTR_POPUP;
