import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { FABB_ERRORS_COLUMNS } from "../../../../../colums/FABB_COLUMNS";

const ErrorDisplay = ({ importErrors, handleRemoveFile }) => {
  return (
    <>
      <div
        style={{ textAlign: "left", display: "inline-block", width: "100%" }}
      >
        <Button
          className="Button"
          variant="contained"
          component="label"
          color="redtest"
          startIcon={<DeleteOutlinedIcon />}
          onClick={handleRemoveFile}
        >
          RIMUOVI IL FILE
        </Button>
      </div>
      <h2
        style={{
          textAlign: "center",
          color: "#f00",
          fontFamily: "Calibri",
          paddingTop: "1%",
        }}
      >
        INDIVIDUATE ALCUNE IMPERFEZIONI NEL FILE INSERITO:
      </h2>
      <div>
        <Box
          sx={{
            height: "500px",
            width: "100%",
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "1%",
            "& .theme_header": {
              backgroundColor: "#1976d2",
              color: "#fff",
              fontFamily: "Calibri",
              fontSize: "17px",
            },
            "& .theme_cell": {
              fontFamily: "Calibri",
              fontSize: "18px",
              border: "1px solid",
            },
            "& .theme_cell_error": {
              fontFamily: "Calibri",
              backgroundColor: "#f00",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#fff",
              border: "1px solid #000",
            },
          }}
        >
          <DataGridPro
            columns={FABB_ERRORS_COLUMNS}
            rows={importErrors}
            getRowId={(row) => row.id_temp}
            headerHeight={40}
            rowHeight={33}
            disableColumnMenu
            hideFooter
          />
        </Box>
      </div>
    </>
  );
};

export default ErrorDisplay;
