import axios from "axios";
import { TaskQueue } from "cwait";

const MAX_SIMULTANEOUS_DOWNLOADS = 6;

//se sviluppo
// const urlEquivalenze = "http://localhost:3001/api/equivalenze";
//se produzione
const urlEquivalenze = "/api/equivalenze";

const createEndPointsList = (AICList) => {
  let newEndPointList = [];
  AICList.map((Selected) => {
    let endPoint = urlEquivalenze + "/" + Selected;
    newEndPointList.push(endPoint);
    return AICList;
  });
  return newEndPointList;
};

const getAIC = async (AICList) => {
  let endPointsList = createEndPointsList(AICList);

  const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
  const res = await Promise.all(
    endPointsList.map(queue.wrap(async (url) => await axios.get(url)))
  );

  return res;
};

const equivalenzeService = {
  getAIC,
};

export default equivalenzeService;
