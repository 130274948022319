import equivalenzeService from "../../services/equivalenze";

const rimuoviFornitoriDuplicati = (array) => {
  const visti = {};
  return array.filter(
    (item) => !visti[item.cf_piva] && (visti[item.cf_piva] = true)
  );
};

const handleFirstConfirm = async (
  setLoadingFarmData,
  dataFile,
  supplierDB,
  setLoadingPercentage,
  setNoMatchFlag,
  setControlloFornitori,
  setFileValutation,
  setNotMatch,
  setConfirmedFile
) => {
  setLoadingFarmData(true);

  let dataFileTEMP = [...dataFile];
  let foundedAIC = [];
  let matchFarmadati = [];
  let fornitoriNONTrovati = [];
  let AICList = [];

  await dataFileTEMP.map((Selected, index) => {
    let flag_sup = false;
    let supp_TEMP = {};

    supplierDB.map((SEL) => {
      if (Selected.fornitore_pivaFILE === SEL.cf_piva) {
        flag_sup = true;
        supp_TEMP = SEL;
      }
      return supplierDB;
    });

    if (flag_sup) {
      Selected.fornitore = supp_TEMP;
    } else {
      Selected.fornitore = {
        ragione_sociale: "NON TROVATO",
        cf_piva: Selected.fornitore_pivaFILE,
      };

      fornitoriNONTrovati.push({
        ragione_sociale: Selected.fornitore_ragioneSociale_FILE,
        cf_piva: Selected.fornitore_pivaFILE,
      });
    }

    AICList.push(Selected.aic);

    // Update loading percentage
    setLoadingPercentage(((index + 1) / dataFileTEMP.length) * 30); // Update percentage for this stage

    return dataFileTEMP;
  });

  const arraySenzaDuplicati = rimuoviFornitoriDuplicati(fornitoriNONTrovati);

  equivalenzeService.getAIC(AICList).then((res) => {
    foundedAIC = res;

    let matchList = [];
    dataFileTEMP.map((Selected, index) => {
      let tempFound = [];
      foundedAIC.map((element) => {
        if (element.data.length > 0 && element.data[0].aic === Selected.aic) {
          tempFound = element.data[0];
        }
        return foundedAIC;
      });

      let matchListTemp = {
        inserted_excell: Selected,
        found_farmadati: tempFound,
      };

      matchList.push(matchListTemp);

      // Update loading percentage
      setLoadingPercentage(30 + ((index + 1) / dataFileTEMP.length) * 15); // Update percentage for this stage

      return dataFileTEMP;
    });

    let id_acq_temp = 0;

    matchList.map((Selected, index) => {
      let temp = [];
      if (
        Selected.found_farmadati.length === 0 ||
        Selected.inserted_excell.aic === undefined
      ) {
        temp = {
          id_acquistato_temp: id_acq_temp,
          fornitore: {
            ragione_sociale:
              Selected?.inserted_excell.fornitore.ragione_sociale,
            cf_piva: Selected?.inserted_excell.fornitore.cf_piva,
          },
          aic: Selected?.inserted_excell.aic.toString(),
          nome_commerciale: Selected?.inserted_excell.nome_commerciale,
          atc: Selected?.inserted_excell?.atc,
          atc_complementare: Selected?.inserted_excell.atc_complementare,
          prezzo_unitario:
            Math.round(Selected?.inserted_excell.prezzo_unitario * 100000) /
            100000,
          quantita: Selected?.inserted_excell.quantita,
          struttura: Selected?.inserted_excell.struttura,

          aic_farmadati: "NO MATCH",
          nome_commerciale_farmadati: "NO MATCH",
          atc_farmadati: "NO MATCH",
          atc_complementare_farmadati: "NO MATCH",
          codice_gruppo_ospedaliero: "NO MATCH",
          descrizione_gruppo_ospedaliero: "NO MATCH",
          descrizione_atc_farmadati: "NO MATCH",
          principio_attivo_farmadati: "NO MATCH",
          forma_farmaceutica: "NO MATCH",
          dosaggio: "NO MATCH",
          fascia: "NO MATCH",
          iva: 0,
        };
      } else {
        temp = {
          id_acquistato_temp: id_acq_temp,
          fornitore: {
            ragione_sociale:
              Selected?.inserted_excell.fornitore.ragione_sociale,
            cf_piva: Selected?.inserted_excell.fornitore.cf_piva,
          },
          aic: Selected?.inserted_excell.aic.toString(),
          nome_commerciale: Selected?.inserted_excell.nome_commerciale,
          atc: Selected?.inserted_excell?.atc,
          atc_complementare: Selected?.inserted_excell.atc_complementare,
          prezzo_unitario:
            Math.round(Selected?.inserted_excell.prezzo_unitario * 100000) /
            100000,
          quantita: Selected?.inserted_excell.quantita,
          struttura: Selected?.inserted_excell.struttura,

          aic_farmadati: Selected?.found_farmadati.aic,
          nome_commerciale_farmadati: Selected?.found_farmadati.descrizione_aic,
          atc_farmadati: Selected?.found_farmadati.atc,
          atc_complementare_farmadati:
            Selected?.found_farmadati.atc_complementare,
          codice_gruppo_ospedaliero:
            Selected?.found_farmadati.codice_gruppo_ospedaliero,
          descrizione_gruppo_ospedaliero:
            Selected?.found_farmadati.descrizione_gruppo_ospedaliero,
          descrizione_atc_farmadati: Selected?.found_farmadati.descrizione_atc,
          principio_attivo_farmadati:
            Selected?.found_farmadati.principio_attivo,
          forma_farmaceutica: Selected?.found_farmadati.forma_farmaceutica,
          dosaggio: Selected?.found_farmadati.dosaggio,
          fascia: Selected?.found_farmadati.fascia,
          iva: Selected?.found_farmadati.iva,
        };
      }
      matchFarmadati.push(temp);
      id_acq_temp = id_acq_temp + 1;

      // Update loading percentage
      setLoadingPercentage(45 + ((index + 1) / matchList.length) * 15); // Update percentage for this stage

      return matchList;
    });

    let noMatch = [];
    let completeOBJ = [];

    matchFarmadati.map((Selected, index) => {
      if (Selected.aic_farmadati === "NO MATCH") {
        noMatch.push(Selected);
      } else {
        completeOBJ.push(Selected);
      }

      // Update loading percentage
      setLoadingPercentage(60 + ((index + 1) / matchFarmadati.length) * 40); // Update percentage for this stage

      return matchFarmadati;
    });

    const finalOBJ = noMatch.concat(completeOBJ);

    if (noMatch.length > 0) {
      setNoMatchFlag(true);
    }

    setControlloFornitori(arraySenzaDuplicati);
    setFileValutation(finalOBJ);
    setNotMatch(noMatch);
    setConfirmedFile(true);
    setLoadingFarmData(false);
  });
};

const handleSecondConfirm = async (
  fileValutation,
  updateFileData,
  insertedFile
) => {
  let finalFile = [];
  await fileValutation.map((Selected) => {
    if (Selected.aic_farmadati !== "NO MATCH") {
      let temp = {
        id_acquistato_temp: Selected.id_acquistato_temp,
        fornitore: Selected.fornitore,
        aic: Selected.aic_farmadati,
        nome_commerciale: Selected.nome_commerciale_farmadati,
        principio_attivo: Selected.principio_attivo_farmadati || "",
        atc: Selected.atc_farmadati,
        atc_complementare: Selected.atc_complementare_farmadati || "",
        codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
        descrizione_gruppo_ospedaliero:
          Selected.descrizione_gruppo_ospedaliero || "",
        descrizione_atc: Selected.descrizione_atc_farmadati || "",
        forma_farmaceutica: Selected.forma_farmaceutica || "",
        dosaggio: Selected.dosaggio || "",
        fascia: Selected.fascia || "",
        iva: Selected.iva || "",
        prezzo_unitario: Math.round(Selected.prezzo_unitario * 100000) / 100000,
        quantita: Selected.quantita,
        struttura: Selected.struttura,

        filef_flag: false,
        is_acquistato: true,
      };
      finalFile.push(temp);
    }

    return fileValutation;
  });

  finalFile.sort((a, b) => {
    let x = a.principio_attivo;
    let y = b.principio_attivo;

    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    }
    return 0;
  });

  var finalFile_COD_OSP = finalFile.reduce((x, y) => {
    (x[y.descrizione_gruppo_ospedaliero] =
      x[y.descrizione_gruppo_ospedaliero] || []).push(y);
    return x;
  }, {});

  let finalOBJ = [];

  Object.keys(finalFile_COD_OSP).map((resultSelected) => {
    let COD_OSPListTemp = [];

    finalFile.map((SelectedBIS) => {
      if (resultSelected === SelectedBIS?.descrizione_gruppo_ospedaliero) {
        COD_OSPListTemp.push(SelectedBIS);
      }

      return finalFile;
    });

    COD_OSPListTemp.sort((a, b) => {
      let x = a.prezzo_unitario;
      let y = b.prezzo_unitario;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    finalOBJ.push(COD_OSPListTemp);

    return finalFile_COD_OSP;
  });

  let OBJtoExp = [];

  finalOBJ.map((Sel) => {
    Sel.map((SelBIS) => {
      OBJtoExp.push(SelBIS);

      return Sel;
    });

    return finalOBJ;
  });

  updateFileData(OBJtoExp);
  insertedFile(true);
};

export { handleFirstConfirm, handleSecondConfirm };
