import axios from "axios";
import { TaskQueue } from "cwait";

const MAX_SIMULTANEOUS_DOWNLOADS = 6;

//se sviluppo
// const urlGaraPub = "http://localhost:3001/api/garepubbliche";
//se produzione
const urlGaraPub = "/api/garepubbliche";

const createATCEndPointsList = (ATCCOMPList) => {
  let newEndPointList = [];
  ATCCOMPList.map((Selected) => {
    let endPoint = urlGaraPub + "/garapubatc/" + Selected;
    newEndPointList.push(endPoint);
    return ATCCOMPList;
  });
  // console.log("end", newEndPointList);
  return newEndPointList;
};

const getATCCOMP = async (ATCCOMPList) => {
  let endPointsList = createATCEndPointsList(ATCCOMPList);

  const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
  const res = await Promise.all(
    endPointsList.map(queue.wrap(async (url) => await axios.get(url)))
  );

  return res;
};

const createGPOSPEndPointsList = (GPOSPCOMPList) => {
  let newEndPointList = [];
  GPOSPCOMPList.map((Selected) => {
    let endPoint = urlGaraPub + "/garapubgposp/" + Selected;
    newEndPointList.push(endPoint);
    return GPOSPCOMPList;
  });
  // console.log("end", newEndPointList);
  return newEndPointList;
};

const getGPOSP = async (GPOSPCOMPList) => {
  let endPointsList = createGPOSPEndPointsList(GPOSPCOMPList);

  const queue = new TaskQueue(Promise, MAX_SIMULTANEOUS_DOWNLOADS);
  const res = await Promise.all(
    endPointsList.map(queue.wrap(async (url) => await axios.get(url)))
  );

  return res;
};

//Invio
// const salvaGaraPub = async (newGaraPub) => {
//   // const config = {
//   //   headers: { Authorization: token },
//   // };
//   const res = await axios.post(urlGaraPub + "/savegarepub", newGaraPub);

//   return res;
// };

const garepubService = {
  getATCCOMP,
  getGPOSP,
  // salvaGaraPub,
};

export default garepubService;
