import garepubService from "../../services/garapub";

import {
  calcoloDifferenzaPerc,
  calcoloDifferenzaCosto,
  calcoloPercentuale,
} from "../CALCULATION/ACQ_BENCH_CALC";

import { calcoloCosto } from "../FUN_CALCOLI";

const sortByPrice = (a, b) => a.prezzo_unitario - b.prezzo_unitario;

const handleCreaFileStruttreSelezionate = (
  ACQ_LIST_TEMP,
  struttureSelected
) => {
  let FILE_TO_FILTER = [...ACQ_LIST_TEMP];

  const nomiStrutture = new Set(
    struttureSelected.map((item) => item.nome_struttura)
  );

  const filteredFILE = FILE_TO_FILTER.filter((obj) =>
    nomiStrutture.has(obj.struttura)
  );

  return filteredFILE;
};

const handleGarePubCalcolo = async (Array) => {
  const ACQ_TEMP = [...Array];

  let SEL_GareSI = [];
  let SEL_GareNO = [];

  await ACQ_TEMP.map((Selected) => {
    if (Selected.garePubList?.length > 0) {
      SEL_GareSI.push(Selected);
    } else {
      SEL_GareNO.push(Selected);
    }

    return ACQ_TEMP;
  });

  let GP_MIN_array = [];
  let GP_MAG_array = [];
  let GP_EQ_array = [];

  let GP_MIN_count = 0;
  let GP_MAG_count = 0;
  let GP_EQ_count = 0;

  let GP_MIN_spesaGP = 0;
  let GP_MAG_spesaGP = 0;
  let GP_EQ_spesaGP = 0;

  let GP_MIN_spesaACQ = 0;
  let GP_MAG_spesaACQ = 0;
  let GP_EQ_spesaACQ = 0;

  await SEL_GareSI.map((SEL_SI) => {
    let spesaGP_TEMP = calcoloCosto(
      SEL_SI?.garePubList[0]?.prezzo_unitario,
      SEL_SI?.quantita
    );

    let spesaACQ_TEMP = calcoloCosto(SEL_SI?.prezzo_unitario, SEL_SI?.quantita);

    let diffSpesa_TEMP = calcoloDifferenzaCosto(spesaACQ_TEMP, spesaGP_TEMP);

    if (diffSpesa_TEMP > 0) {
      GP_MAG_count = GP_MAG_count + 1;
      GP_MAG_spesaGP = GP_MAG_spesaGP + spesaGP_TEMP;
      GP_MAG_spesaACQ = GP_MAG_spesaACQ + spesaACQ_TEMP;
      GP_MAG_array.push(SEL_SI);
    } else if (diffSpesa_TEMP === 0) {
      GP_EQ_count = GP_EQ_count + 1;
      GP_EQ_spesaGP = GP_EQ_spesaGP + spesaGP_TEMP;
      GP_EQ_spesaACQ = GP_EQ_spesaACQ + spesaACQ_TEMP;
      GP_EQ_array.push(SEL_SI);
    } else if (diffSpesa_TEMP < 0) {
      GP_MIN_count = GP_MIN_count + 1;
      GP_MIN_spesaGP = GP_MIN_spesaGP + spesaGP_TEMP;
      GP_MIN_spesaACQ = GP_MIN_spesaACQ + spesaACQ_TEMP;
      GP_MIN_array.push(SEL_SI);
    }

    return SEL_GareSI;
  });

  let spesaACQ_TEMP = 0;

  await SEL_GareNO.map((SEL_NO) => {
    spesaACQ_TEMP =
      spesaACQ_TEMP + calcoloCosto(SEL_NO?.prezzo_unitario, SEL_NO?.quantita);

    return SEL_GareNO;
  });

  const TOT_SPESA_GP = GP_MIN_spesaGP + GP_MAG_spesaGP + GP_EQ_spesaGP;
  const TOT_SPESA_ACQ = GP_MIN_spesaACQ + GP_MAG_spesaACQ + GP_EQ_spesaACQ;

  const VAL_OBJ_TEMP = [
    {
      title: "PREZZI PIU ALTI DELLE GARE PUBBLICHE",
      n_molecole: GP_MIN_count,

      spesaACQ: Math.round(GP_MIN_spesaACQ * 100) / 100,
      perc_spesaACQ: calcoloPercentuale(
        GP_MIN_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),

      spesaGarePub: Math.round(GP_MIN_spesaGP * 100) / 100,
      diff_spesa: Math.round((GP_MIN_spesaGP - GP_MIN_spesaACQ) * 100) / 100,
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MIN_spesaACQ,
        GP_MIN_spesaGP - GP_MIN_spesaACQ
      ),
      array_gare: GP_MIN_array,
    },
    {
      title: "PREZZI PIU BASSI DELLE GARE PUBBLICHE",
      n_molecole: GP_MAG_count,

      spesaACQ: Math.round(GP_MAG_spesaACQ * 100) / 100,
      perc_spesaACQ: calcoloPercentuale(
        GP_MAG_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),

      spesaGarePub: Math.round(GP_MAG_spesaGP * 100) / 100,
      diff_spesa: Math.round((GP_MAG_spesaGP - GP_MAG_spesaACQ) * 100) / 100,
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MAG_spesaACQ,
        GP_MAG_spesaGP - GP_MAG_spesaACQ
      ),

      array_gare: GP_MAG_array,
    },
    {
      title: "PREZZI UGUALI ALLE GARE PUBBLICHE",
      n_molecole: GP_EQ_count,

      spesaACQ: Math.round(GP_EQ_spesaACQ * 100) / 100,
      perc_spesaACQ: calcoloPercentuale(
        GP_EQ_spesaACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),

      spesaGarePub: Math.round(GP_EQ_spesaGP * 100) / 100,
      diff_spesa: 0,
      perc_diff_spesa: 0,
      array_gare: GP_EQ_array,
    },
    {
      title: "TOTALE MOLECOLE INCROCIATE",
      n_molecole: SEL_GareSI.length,

      spesaACQ: Math.round(TOT_SPESA_ACQ * 100) / 100,
      perc_spesaACQ: calcoloPercentuale(
        TOT_SPESA_ACQ,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),

      spesaGarePub: Math.round(TOT_SPESA_GP * 100) / 100,
      diff_spesa: Math.round((TOT_SPESA_GP - TOT_SPESA_ACQ) * 100) / 100,
      perc_diff_spesa: calcoloDifferenzaPerc(
        TOT_SPESA_ACQ,
        TOT_SPESA_GP - TOT_SPESA_ACQ
      ),

      array_gare: [],
    },
    {
      title: "MOLECOLE NON INCROCIATE",
      n_molecole: SEL_GareNO.length,

      spesaACQ: Math.round(spesaACQ_TEMP * 100) / 100,
      perc_spesaACQ: calcoloPercentuale(
        spesaACQ_TEMP,
        spesaACQ_TEMP + TOT_SPESA_ACQ
      ),

      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: [],
    },
    {
      title: "TOTALE MOLECOLE ANALIZZATE",
      n_molecole: SEL_GareSI.length + SEL_GareNO.length,

      spesaACQ: Math.round((spesaACQ_TEMP + TOT_SPESA_ACQ) * 100) / 100,
      perc_spesaACQ: 100,

      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: [],
    },
  ];

  return {
    BENCH_TAB: VAL_OBJ_TEMP,
  };
};

const handleBenchmarkingValutation = async (
  setLoadingBenchmarkingVAL,
  SingleACQ_Downloaded,
  struttureSelected,
  setGareBenchmarking
) => {
  setLoadingBenchmarkingVAL(true);

  const ACQ_LIST_TEMP = [...SingleACQ_Downloaded.acquistato_inserito];

  const ACQ_LIST_SEL_TEMP = await handleCreaFileStruttreSelezionate(
    ACQ_LIST_TEMP,
    struttureSelected
  );

  let updatedACQ_LIST_TEMP = [];

  let GPOSPList = [];

  await ACQ_LIST_SEL_TEMP.forEach((SEL) => {
    if (SEL.codice_gruppo_ospedaliero !== "") {
      GPOSPList.push(SEL.codice_gruppo_ospedaliero);
    }
  });

  const uniqueGPOSPList = [...new Set(GPOSPList)];

  let foundedGPOPS = await garepubService.getGPOSP(uniqueGPOSPList);

  const foundedGPOPSMap = Object.fromEntries(
    foundedGPOPS.map((element) => [
      element.data[0]?.codice_gruppo_ospedaliero,
      element.data,
    ])
  );

  updatedACQ_LIST_TEMP = ACQ_LIST_SEL_TEMP.map((selected) => {
    const matchingData = foundedGPOPSMap[selected.codice_gruppo_ospedaliero];
    if (matchingData) {
      matchingData.sort(sortByPrice);
      selected.garePubList = matchingData;
    }
    return selected;
  });

  const GARE_PUB_BENCHMARKING = await handleGarePubCalcolo(
    updatedACQ_LIST_TEMP
  );

  setGareBenchmarking(GARE_PUB_BENCHMARKING);

  setLoadingBenchmarkingVAL(false);
};

const handleCreateGareDetails = async (ACQ_LIST, setArrayGrupedTEMP) => {
  let ACQ_LIST_TEMP = [...ACQ_LIST];

  await ACQ_LIST_TEMP.sort((a, b) => {
    let x =
      calcoloCosto(a.garePubList[0].prezzo_unitario, a.quantita) -
      calcoloCosto(a.prezzo_unitario, a.quantita);
    let y =
      calcoloCosto(b.garePubList[0].prezzo_unitario, b.quantita) -
      calcoloCosto(b.prezzo_unitario, b.quantita);

    if (x < y) {
      return -1;
    } else if (x > y) {
      return 1;
    }
    return 0;
  });

  const groupedData = ACQ_LIST_TEMP.reduce((acc, current) => {
    const { descrizione_gruppo_ospedaliero } = current;

    if (!acc[descrizione_gruppo_ospedaliero]) {
      acc[descrizione_gruppo_ospedaliero] = [];
    }

    acc[descrizione_gruppo_ospedaliero].push(current);
    return acc;
  }, {});

  let ArrayGruped = [];
  let id_count = 0;

  await Object.values(groupedData).map((SEL) => {
    let SPESA_TOT = 0;
    let Q_TOT = 0;

    SEL.map((SEL_BIS) => {
      SPESA_TOT =
        SPESA_TOT + calcoloCosto(SEL_BIS.prezzo_unitario, SEL_BIS.quantita);
      Q_TOT = Q_TOT + SEL_BIS.quantita;

      return SEL;
    });

    let temp = {
      id_temp: id_count,
      spesa_tot: Math.round(SPESA_TOT * 100) / 100,
      quantita_tot: Q_TOT,
      tipologia: SEL[0]?.descrizione_gruppo_ospedaliero,
      garePubList: SEL[0]?.garePubList,
    };

    ArrayGruped.push(temp);

    id_count = id_count + 1;

    return Object.values(groupedData);
  });

  setArrayGrupedTEMP(ArrayGruped);

  return ACQ_LIST_TEMP;
};

const handleViewDetailsTab = async (
  params,
  setArrayGrupedTEMP,
  setGareSelected,
  setRowNameSEL,
  setDettaglioFlag
) => {
  console.log(
    "params",
    setArrayGrupedTEMP,
    setGareSelected,
    setRowNameSEL,
    setDettaglioFlag
  );

  if (params.row?.title === "PREZZI PIU ALTI DELLE GARE PUBBLICHE") {
    const NEW_OBJ = await handleCreateGareDetails(
      params.row.array_gare,
      setArrayGrupedTEMP
    );
    console.log("NEW_OBJ", NEW_OBJ);
    setGareSelected(NEW_OBJ);
    setRowNameSEL(params.row?.title);
    setDettaglioFlag(true);
  } else if (params.row?.title === "PREZZI PIU BASSI DELLE GARE PUBBLICHE") {
    const NEW_OBJ = await handleCreateGareDetails(
      params.row.array_gare,
      setArrayGrupedTEMP
    );
    console.log("NEW_OBJ", NEW_OBJ);
    setGareSelected(NEW_OBJ);
    setRowNameSEL(params.row?.title);
    setDettaglioFlag(true);
  } else if (params.row?.title === "PREZZI UGUALI ALLE GARE PUBBLICHE") {
    const NEW_OBJ = await handleCreateGareDetails(
      params.row.array_gare,
      setArrayGrupedTEMP
    );
    console.log("NEW_OBJ", NEW_OBJ);
    setGareSelected(NEW_OBJ);
    setRowNameSEL(params.row?.title);
    setDettaglioFlag(true);
  } else if (params.row?.title === "MOLECOLE NON INCROCIATE") {
    console.log("CASO PARTICOLARE DA IMLPEMENTARE");
  } else {
    console.log("CASO DIVERSO");
  }
};

export {
  //   handleGarePubCalcolo,
  handleBenchmarkingValutation,
  handleViewDetailsTab,
};
