import React from "react";
import "../../App.css";
import logo from "../../images/IT_Finanziato_dall_Unione_europea_RGB_POS.png"; // Importa l'immagine

const InfoContributoPage = () => {
  return (
    <>
      <div className="info-container">
        <div className="image-container">
          <img src={logo} alt="Logo" className="info-logo" />
        </div>
        <p>
          La società è beneficiaria del contributo INVITALIA– Fondo Impresa
          Femminile 2022 – Decreto interministeriale 30 settembre 2021
        </p>
      </div>
    </>
  );
};

export default InfoContributoPage;
