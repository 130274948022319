import React from "react";

import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import EXP_ABB_DA_RICHIEDERE from "../../../../exports/ABB/EXP_ABB_DA_RICHIEDERE";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const DaRichiederePage = ({
  handleReturnToResumePage,
  specToWait,
  ABB_TOWAIT_COLUMNS,
  abb_name,
}) => {
  return (
    <>
      <div>
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "25%",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="redtest"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            onClick={handleReturnToResumePage}
          >
            INDIETRO
          </Button>
        </div>
        <div
          style={{
            display: "inline-block",
            width: "50%",
          }}
        ></div>
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "25%",
          }}
        >
          <EXP_ABB_DA_RICHIEDERE data={specToWait} abb_name={abb_name} />
        </div>
      </div>
      <Box
        sx={{
          height: "400px",
          width: "100%",
          paddingTop: "2%",
          paddingLeft: "1%",
          paddingRight: "1%",
          paddingBottom: "1%",

          "& .theme_header": {
            backgroundColor: "#1976d2",
            color: "#fff",
            fontFamily: "Calibri",
            fontSize: "16px",
          },
          "& .theme_cell": {
            fontFamily: "Calibri",
            fontSize: "17px",
            border: "1px solid #000",
          },
        }}
      >
        <DataGridPro
          columns={ABB_TOWAIT_COLUMNS}
          rows={specToWait}
          getRowId={(row) => row._id}
          headerHeight={40}
          rowHeight={33}
          disableColumnMenu
          hideFooter
        />
      </Box>
    </>
  );
};

export default DaRichiederePage;
