import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import offersResumeService from "../../services/offers_resume";

const EXP_CONFRONTO_LISTINI = ({ data, listino }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);
  const [listinoCONF, setListinoCONF] = useState(null);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "CONFRONTO LISTINI.xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const createOffersResumeView = async (offersResumeTEMP) => {
    let finalOBJ = [];
    let id_count = 0;

    // console.log("offersResumeTEMP", offersResumeTEMP);
    await offersResumeTEMP.offerte_inserite.map((OFF_SEL) => {
      OFF_SEL.offerta_obj.map((SINGLE_OFF_SEL) => {
        let temp = {
          id_temp: id_count,

          ragione_sociale: OFF_SEL.ragione_sociale,
          cf_piva: OFF_SEL.cf_piva,
          data_inserimento: OFF_SEL.data_inserimento,
          ordine_min: OFF_SEL.ordine_min,
          pdf_offerta_id: OFF_SEL?.pdf_offerta_id ?? null,

          aic: SINGLE_OFF_SEL.aic,
          atc: SINGLE_OFF_SEL.atc,
          atc_complementare: SINGLE_OFF_SEL.atc_complementare,
          codice_gruppo_ospedaliero: SINGLE_OFF_SEL.codice_gruppo_ospedaliero,
          data_protocollo: SINGLE_OFF_SEL.data_protocollo,
          date: SINGLE_OFF_SEL.date,
          descrizione_atc: SINGLE_OFF_SEL.descrizione_atc,
          descrizione_gruppo_ospedaliero:
            SINGLE_OFF_SEL.descrizione_gruppo_ospedaliero,
          dosaggio: SINGLE_OFF_SEL.dosaggio,
          fascia: SINGLE_OFF_SEL.fascia,
          filef_flag: SINGLE_OFF_SEL.filef_flag,
          forma_farmaceutica: SINGLE_OFF_SEL.forma_farmaceutica,
          is_checked: SINGLE_OFF_SEL.is_checked,
          iva: SINGLE_OFF_SEL.iva,
          nome_commerciale: SINGLE_OFF_SEL.nome_commerciale,
          numero_protocollo: SINGLE_OFF_SEL.numero_protocollo,
          numero_unita_confezione: SINGLE_OFF_SEL.numero_unita_confezione,
          prezzo_netto_exfactory_confezione:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_confezione,
          prezzo_netto_exfactory_unitario:
            SINGLE_OFF_SEL.prezzo_netto_exfactory_unitario,
          prezzo_pub_confezione: SINGLE_OFF_SEL.prezzo_pub_confezione,
          prezzo_pub_unitario: SINGLE_OFF_SEL.prezzo_pub_unitario,
          prezzo_unitario: SINGLE_OFF_SEL.prezzo_unitario,
          principio_attivo: SINGLE_OFF_SEL.principio_attivo,
          sconto_precentuale_prezzo_pub:
            SINGLE_OFF_SEL.sconto_precentuale_prezzo_pub,
        };

        id_count = id_count + 1;

        finalOBJ.push(temp);
        return OFF_SEL.offerta_obj;
      });

      return offersResumeTEMP.offerte_inserite;
    });

    return finalOBJ;
  };

  const confrontaOfferte = async (offerte1, offerte2) => {
    // console.log("offerte1", offerte1);
    // console.log("offerte2", offerte2);

    const inComune = [];
    const soloInOfferte1 = [];
    const soloInOfferte2 = [];

    await offerte1.forEach((offerta1) => {
      if (
        offerte2.some(
          (offerta2) =>
            offerta2.ragione_sociale === offerta1.ragione_sociale &&
            offerta2.aic === offerta1.aic
        )
      ) {
        inComune.push(offerta1);
      } else {
        soloInOfferte1.push(offerta1);
      }
    });

    await offerte2.forEach((offerta2) => {
      if (
        !offerte1.some(
          (offerta1) =>
            offerta1.ragione_sociale === offerta2.ragione_sociale &&
            offerta1.aic === offerta2.aic
        )
      ) {
        soloInOfferte2.push(offerta2);
      }
    });

    await inComune.map((SELInComune) => {
      offerte2.map((Selected2) => {
        if (
          SELInComune.aic === Selected2.aic &&
          SELInComune.ragione_sociale === Selected2.ragione_sociale
        ) {
          SELInComune.matchedAIC = Selected2;
        }
        return offerte2;
      });
      return inComune;
    });

    return { inComune, soloInOfferte1, soloInOfferte2 };
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "FORNITORE",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "PREZZO UNITARIO 2024",
        G: "PREZZO UNITARIO 2023",
        H: "DIFFERENZA",
        I: "N. PROTOCOLLO 2023",
        J: "N. PROTOCOLLO 2024",
        K: "ATC COMP.",
      },
    ];

    let ARR = [];

    await offersResumeService
      .getOffersResumetByOffersResumetId(listino.id)
      .then(async (res) => {
        setListinoCONF(res.data);
        ARR = await createOffersResumeView(res.data);
      });

    let table_TEMP = [];
    let table_TEMP2 = [];

    let arrayForSort = [...data];

    const risultati = await confrontaOfferte(arrayForSort, ARR);

    await risultati.inComune.forEach((row) => {
      if (row.matchedAIC) {
        table_TEMP.push({
          A: row?.principio_attivo,
          B: row?.descrizione_gruppo_ospedaliero,
          C: row?.ragione_sociale,
          D: row?.aic,
          E: row?.nome_commerciale,
          F: row?.prezzo_unitario,
          G: row?.matchedAIC.prezzo_unitario,
          H: row?.prezzo_unitario - row?.matchedAIC.prezzo_unitario,
          I: row?.matchedAIC.numero_protocollo,
          J: row?.numero_protocollo,
          K: row?.atc_complementare,
        });
      }
    });

    await risultati.soloInOfferte1.forEach((row) => {
      table_TEMP.push({
        A: row?.principio_attivo,
        B: row?.descrizione_gruppo_ospedaliero,
        C: row?.ragione_sociale,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.prezzo_unitario,
        G: "",
        H: "",
        I: "",
        J: row?.numero_protocollo,
        K: row?.atc_complementare,
      });
    });

    await risultati.soloInOfferte2.forEach((row) => {
      table_TEMP2.push({
        A: row?.principio_attivo,
        B: row?.descrizione_gruppo_ospedaliero,
        C: row?.ragione_sociale,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: "",
        G: row?.prezzo_unitario,
        H: "",
        I: row?.numero_protocollo,
        J: "",
        K: row?.atc_complementare,
      });
    });

    await table_TEMP.sort((a, b) => {
      let x = a.B;
      let y = b.B;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    let newTABLE = table_TEMP.concat(table_TEMP2);

    var resultGP = newTABLE.reduce((x, y) => {
      (x[y.B] = x[y.B] || []).push(y);
      return x;
    }, {});

    let newOBJ = [];

    await Object.keys(resultGP).map((resultSelected) => {
      let ListTemp = [];

      newTABLE.map((Selected) => {
        if (resultSelected === Selected?.B) {
          ListTemp.push(Selected);
        }

        return newTABLE;
      });

      ListTemp.sort((a, b) => {
        let x = a.F;
        let y = b.F;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      newOBJ.push(ListTemp);

      return resultGP;
    });

    let NO_GP = [];
    let GP_OK = [];

    newOBJ.map((SEL) => {
      if (SEL.length > 0) {
        if (SEL[0].B === "") {
          let NO_PRINC_ATT = [];
          let PRINC_ATT_OK = [];

          SEL.map((elemento) => {
            if (elemento.A === "NESSUN PRINCIPIO ATTIVO") {
              NO_PRINC_ATT.push(elemento);
            } else {
              PRINC_ATT_OK.push(elemento);
            }
            return SEL;
          });

          PRINC_ATT_OK.sort((a, b) => {
            let x = a.A;
            let y = b.A;

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            }
            return 0;
          });

          NO_GP = PRINC_ATT_OK.concat(NO_PRINC_ATT);
        } else {
          let temp = [];
          SEL.map((elemento) => {
            GP_OK.push(elemento);

            return SEL;
          });
        }
      } else {
      }

      return newOBJ;
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1.concat(GP_OK).concat(NO_GP), {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "CONFRONTO LISTINO 2023");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange1: `A1:H1`,
      theadRange2: `I1:K1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(35);
        sheet.column("B").width(45);
        sheet.column("C").width(35);
        sheet.column("D").width(14).style({ horizontalAlignment: "center" });
        sheet.column("E").width(35);
        sheet.column("F").width(22).style({ numberFormat: "#,##0.00000" });
        sheet.column("G").width(22).style({ numberFormat: "#,##0.00000" });
        sheet.column("H").width(30).style({ numberFormat: "#,##0.00000" });
        sheet.column("I").width(25);
        sheet.column("J").width(25);
        sheet.column("K").width(18).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
        if (dataInfo.theadRange2) {
          sheet.range(dataInfo.theadRange2).style({
            fill: "104d89",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP - CONFRONTO {listino.nome_listino}
      </LoadingButton>
    </>
  );
};

export default EXP_CONFRONTO_LISTINI;
