import React, { useState, useRef, useEffect } from "react";
import "../../../App.css";

import fornitoriService from "../../../services/fornitori";
import equivalenzeService from "../../../services/equivalenze";

import * as XLSX from "xlsx";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import EXP_SUPPLIER_LIST from "../../../exports/DB/EXP_SUPPLIER_LIST";
import EXP_OFF_LIST_EMPTY from "../../../exports/EMPTY_FILES/EXP_OFF_LIST_EMPTY";
// import EXP_ACQ_CONFRONTO from "../../exports/ACQ/INSERT/EXP_ACQ_CONFRONTO";

import OFF_LIST_INSTR_POPUP from "../../../popup/INSTRUCTION_POPUP/OFF_LIST_INSTR_POPUP";
import OFF_LIST_SECOND_CONFIRM_POPUP from "../../../popup/OFF_LIST_POPUPS/OFF_LIST_SECOND_CONFIRM_POPUP";

import BeatLoader from "react-spinners/BeatLoader";

import Button from "@mui/material/Button";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import {
  OFF_RESUME_IMPORT_COLUMNS,
  OFF_RESUME_CONFRONTO_COLUMNS,
  OFF_RESUME_ERRORS_COLUMNS,
} from "../../../colums/OFF_LIST_COLUMNS";

const OffersListImport = ({
  handleUpdateFileData,
  insertedFile,
  dates,
  handleChangeDates,
}) => {
  const [supplierDB, setSupplierDB] = useState(null);

  const acceptableFileName = ["xlsx", "xls"];
  const fileRef = useRef();

  const [dataFile, setDataFile] = useState(null);
  const [importErrors, setImportErrors] = useState(null);

  const [confirmedFile, setConfirmedFile] = useState(false);

  const [fileValutation, setFileValutation] = useState([]);

  const [notMatch, setNotMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const [instructionOpen, setInstructionOpen] = useState(false);

  const [secondConfirmOpen, setSecondConfirmOpen] = useState(false);

  const [loadingFarmData, setLoadingFarmData] = useState(false);

  useEffect(() => {
    fornitoriService
      .getFornitori()
      .then((res) => {
        res.data.sort((a, b) => {
          let x = a.ragione_sociale;
          let y = b.ragione_sociale;
          if (x < y) {
            return -1;
          } else if (x > y) {
            return 1;
          }
          return 0;
        });
        setSupplierDB(res.data);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei fornitori dal server:",
          error
        );
      });
  }, []);

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const checkFileName = (name) => {
    return acceptableFileName.includes(name.split(".").pop().toLowerCase());
  };

  const readDataFromFile = (data) => {
    const wb = XLSX.read(data);

    var mySheetData = {};
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);

    mySheetData = jsonData;

    return mySheetData;
  };

  const findCommonElementsWithFlags = (data) => {
    const idMap = {};

    // Popoliamo la mappa degli id temporanei
    data.forEach((item) => {
      if (!idMap[item.id_temp]) {
        idMap[item.id_temp] = { ...item };
      } else {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === "boolean" && key.startsWith("flag_")) {
            idMap[item.id_temp][key] = idMap[item.id_temp][key] || item[key];
          }
        });
      }
    });

    // Converto l'oggetto mappa in un array di oggetti
    const combinedObjects = Object.values(idMap);

    return combinedObjects;
  };

  const handleUploadDataControl = async (fileInserted) => {
    let newFileData = [];

    let id_data_temp = 0;

    await fileInserted.map((Sel) => {
      let temp = {
        id_temp: id_data_temp,
        aic: Sel.AIC !== undefined ? Sel.AIC : "",
        nome_commerciale:
          Sel["NOME COMMERCIALE"] !== undefined ? Sel["NOME COMMERCIALE"] : "",
        fornitore_ragioneSociale_FILE:
          Sel.FORNITORE !== undefined ? Sel.FORNITORE : "",
        fornitore_pivaFILE:
          Sel["P. IVA"] !== undefined ? Sel["P. IVA"].toString() : "",
        prezzo_unitario:
          Sel["PREZZO UNITARIO"] !== undefined ? Sel["PREZZO UNITARIO"] : "",
        numero_protocollo:
          Sel["NUMERO PROTOCOLLO"] !== undefined
            ? Sel["NUMERO PROTOCOLLO"]
            : "",
        principio_attivo:
          Sel["PRINCIPIO ATTIVO"] !== undefined ? Sel["PRINCIPIO ATTIVO"] : "",
        atc: Sel.ATC !== undefined ? Sel.ATC : "",
        atc_complementare:
          Sel["ATC COMPLEMENTARE"] !== undefined
            ? Sel["ATC COMPLEMENTARE"]
            : "",
        descrizione_atc:
          Sel["DESCRIZIONE ATC"] !== undefined ? Sel["DESCRIZIONE ATC"] : "",
      };

      newFileData.push(temp);

      id_data_temp = id_data_temp + 1;

      return fileInserted;
    });

    let errors = {
      aic_error: [],
      name_error: [],
      piva_error: [],
      prezzo_error: [],
      // quantita_error: [],
      flag_error: false,
    };

    await newFileData.map((Sel) => {
      if (Sel.aic === "" || Sel.aic.toString().trim().length > 9) {
        errors.aic_error.push(Sel);
        errors.flag_error = true;
      }
      if (Sel.nome_commerciale === "") {
        errors.name_error.push(Sel);
        errors.flag_error = true;
      }
      if (Sel.fornitore_pivaFILE === "") {
        errors.piva_error.push(Sel);
        errors.flag_error = true;
      }
      if (Sel.prezzo_unitario === "") {
        errors.prezzo_error.push(Sel);
        errors.flag_error = true;
      }
      // if (Sel.quantita === "") {
      //   errors.quantita_error.push(Sel);
      //   errors.flag_error = true;
      // }
      return newFileData;
    });

    if (!errors.flag_error) {
      await newFileData.map((Sel) => {
        if (Sel.aic.toString().length < 9) {
          let tempAIC = Sel.aic.toString();

          for (let i = Sel.aic.toString().length; i < 9; i++) {
            tempAIC = "0" + tempAIC;
          }
          Sel.aic = tempAIC;
        } else {
          Sel.aic = Sel.aic.toString();
        }
        if (Sel.atc) {
          Sel.atc = Sel.atc.toString();
        }
        if (Sel.atc_complementare) {
          Sel.atc_complementare = Sel.atc_complementare.toString();
        }
        return newFileData;
      });

      setDataFile(newFileData);
      setImportErrors(null);
    } else {
      let newErrorsTEMP = [];

      await errors.aic_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          numero_protocollo: ERR_SEL.numero_protocollo,
          principio_attivo: ERR_SEL.principio_attivo,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          descrizione_atc: ERR_SEL.descrizione_atc,
          // quantita: ERR_SEL.quantita,
          flag_aic_error: true,
          flag_name_error: false,
          flag_piva_error: false,
          flag_prezzo_error: false,
          // flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);

        return errors.aic_error;
      });

      await errors.name_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          numero_protocollo: ERR_SEL.numero_protocollo,
          principio_attivo: ERR_SEL.principio_attivo,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          descrizione_atc: ERR_SEL.descrizione_atc,
          flag_aic_error: false,
          flag_name_error: true,
          flag_piva_error: false,
          flag_prezzo_error: false,
          // flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);
        return errors.name_error;
      });

      await errors.piva_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          numero_protocollo: ERR_SEL.numero_protocollo,
          principio_attivo: ERR_SEL.principio_attivo,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          descrizione_atc: ERR_SEL.descrizione_atc,
          flag_aic_error: false,
          flag_name_error: false,
          flag_piva_error: true,
          flag_prezzo_error: false,
          // flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);
        return errors.piva_error;
      });

      await errors.prezzo_error.map((ERR_SEL) => {
        let temp = {
          id_temp: ERR_SEL.id_temp,
          aic: ERR_SEL.aic,
          nome_commerciale: ERR_SEL.nome_commerciale,
          fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
          fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
          prezzo_unitario: ERR_SEL.prezzo_unitario,
          numero_protocollo: ERR_SEL.numero_protocollo,
          principio_attivo: ERR_SEL.principio_attivo,
          atc: ERR_SEL.atc,
          atc_complementare: ERR_SEL.atc_complementare,
          descrizione_atc: ERR_SEL.descrizione_atc,
          flag_aic_error: false,
          flag_name_error: false,
          flag_piva_error: false,
          flag_prezzo_error: true,
          // flag_quantita_error: false,
        };

        newErrorsTEMP.push(temp);
        return errors.prezzo_error;
      });

      // await errors.quantita_error.map((ERR_SEL) => {
      //   let temp = {
      //     id_temp: ERR_SEL.id_temp,
      //     aic: ERR_SEL.aic,
      //     nome_commerciale: ERR_SEL.nome_commerciale,
      //     fornitore_ragioneSociale_FILE: ERR_SEL.fornitore_ragioneSociale_FILE,
      //     fornitore_pivaFILE: ERR_SEL.fornitore_pivaFILE,
      //     atc: ERR_SEL.atc,
      //     atc_complementare: ERR_SEL.atc_complementare,
      //     prezzo_unitario: ERR_SEL.prezzo_unitario,
      //     quantita: ERR_SEL.quantita,
      //     flag_aic_error: false,
      //     flag_name_error: false,
      //     flag_piva_error: false,
      //     flag_prezzo_error: false,
      //     flag_quantita_error: true,
      //   };

      //   newErrorsTEMP.push(temp);
      //   return errors.quantita_error;
      // });

      const commonElements = findCommonElementsWithFlags(newErrorsTEMP);

      setImportErrors(commonElements);
    }
  };

  const handleFile = async (e) => {
    setDataFile(null);
    const myfile = e.target.files[0];
    if (!myfile) return;

    if (!checkFileName(myfile.name)) {
      alert("Invalid file type!");
      return;
    }

    const data = await myfile.arrayBuffer();
    const mySheetData = readDataFromFile(data);

    readDataFromFile(data);

    handleUploadDataControl(mySheetData);
  };

  const handleRemove = () => {
    setDataFile(null);
    setFileValutation([]);
    setNotMatch([]);
    setConfirmedFile(false);
    handleChangeDates();
    setImportErrors(null);
  };

  const handleNoMatchFlag = () => {
    setNoMatchFlag(!noMatchFlag);
  };

  const handleFirstConfirm = async () => {
    setLoadingFarmData(true);

    let dataFileTEMP = [...dataFile];
    let foundedAIC = [];
    let matchFarmadati = [];

    let AICList = [];

    await dataFileTEMP.map((Selected) => {
      let flag_sup = false;
      let supp_TEMP = {};

      supplierDB.map((SEL) => {
        if (Selected.fornitore_pivaFILE === SEL.cf_piva) {
          flag_sup = true;
          supp_TEMP = SEL;
        }

        return supplierDB;
      });

      if (flag_sup) {
        Selected.fornitore = supp_TEMP;
      } else {
        // console.log("PIVA NON TROVATA", Selected.fornitore_pivaFILE);
        Selected.fornitore = {
          ragione_sociale: "NON TROVATO",
          cf_piva: Selected.fornitore_pivaFILE,
        };
      }

      AICList.push(Selected.aic);
      return dataFileTEMP;
    });

    equivalenzeService.getAIC(AICList).then((res) => {
      foundedAIC = res;

      let matchList = [];
      dataFileTEMP.map((Selected) => {
        let tempFound = [];
        foundedAIC.map((element) => {
          if (element.data.length > 0 && element.data[0].aic === Selected.aic) {
            tempFound = element.data[0];
          } else {
          }
          return foundedAIC;
        });

        let matchListTemp = {
          inserted_excell: Selected,
          found_farmadati: tempFound,
        };

        matchList.push(matchListTemp);
        return dataFileTEMP;
      });

      let id_acq_temp = 0;

      matchList.map((Selected) => {
        let temp = [];
        if (
          Selected.found_farmadati.length === 0 ||
          Selected.inserted_excell.aic === undefined
        ) {
          temp = {
            id_acquistato_temp: id_acq_temp,
            fornitore: {
              ragione_sociale:
                Selected?.inserted_excell.fornitore.ragione_sociale,
              cf_piva: Selected?.inserted_excell.fornitore.cf_piva,
            },
            aic: Selected?.inserted_excell.aic.toString(),
            nome_commerciale: Selected?.inserted_excell.nome_commerciale,
            atc: Selected?.inserted_excell?.atc,
            atc_complementare: Selected?.inserted_excell.atc_complementare,
            prezzo_unitario:
              Math.round(Selected?.inserted_excell.prezzo_unitario * 100000) /
              100000,
            numero_protocollo: Selected?.inserted_excell.numero_protocollo,
            aic_farmadati: "NO MATCH",
            nome_commerciale_farmadati: "NO MATCH",
            atc_farmadati: "NO MATCH",
            atc_complementare_farmadati: "NO MATCH",
            codice_gruppo_ospedaliero: "NO MATCH",
            descrizione_gruppo_ospedaliero: "NO MATCH",
            descrizione_atc_farmadati: "NO MATCH",
            principio_attivo_farmadati: "NO MATCH",
            forma_farmaceutica: "NO MATCH",
            dosaggio: "NO MATCH",
            fascia: "NO MATCH",
            iva: 0,
          };
        } else {
          temp = {
            id_acquistato_temp: id_acq_temp,
            fornitore: {
              ragione_sociale:
                Selected?.inserted_excell.fornitore.ragione_sociale,
              cf_piva: Selected?.inserted_excell.fornitore.cf_piva,
            },
            aic: Selected?.inserted_excell.aic.toString(),
            nome_commerciale: Selected?.inserted_excell.nome_commerciale,
            atc: Selected?.inserted_excell?.atc,
            atc_complementare: Selected?.inserted_excell.atc_complementare,
            prezzo_unitario:
              Math.round(Selected?.inserted_excell.prezzo_unitario * 100000) /
              100000,
            numero_protocollo: Selected?.inserted_excell.numero_protocollo,
            aic_farmadati: Selected?.found_farmadati.aic,
            nome_commerciale_farmadati:
              Selected?.found_farmadati.descrizione_aic,
            atc_farmadati: Selected?.found_farmadati.atc,
            atc_complementare_farmadati:
              Selected?.found_farmadati.atc_complementare,
            codice_gruppo_ospedaliero:
              Selected?.found_farmadati.codice_gruppo_ospedaliero,
            descrizione_gruppo_ospedaliero:
              Selected?.found_farmadati.descrizione_gruppo_ospedaliero,
            descrizione_atc_farmadati:
              Selected?.found_farmadati.descrizione_atc,
            principio_attivo_farmadati:
              Selected?.found_farmadati.principio_attivo,
            forma_farmaceutica: Selected?.found_farmadati.forma_farmaceutica,
            dosaggio: Selected?.found_farmadati.dosaggio,
            fascia: Selected?.found_farmadati.fascia,
            iva: Selected?.found_farmadati.iva,
          };
        }
        matchFarmadati.push(temp);
        id_acq_temp = id_acq_temp + 1;
        return matchList;
      });

      let noMatch = [];
      let completeOBJ = [];

      matchFarmadati.map((Selected) => {
        if (Selected.aic_farmadati === "NO MATCH") {
          noMatch.push(Selected);
        } else {
          completeOBJ.push(Selected);
        }

        return matchFarmadati;
      });

      const finalOBJ = noMatch.concat(completeOBJ);

      if (noMatch.length > 0) {
        setNoMatchFlag(true);
      }

      setFileValutation(finalOBJ);
      setNotMatch(noMatch);
      setConfirmedFile(true);
      setLoadingFarmData(false);
    });
  };

  const handleSecondConfirm = async () => {
    let finalFile = [];
    await fileValutation.map((Selected) => {
      if (Selected.aic_farmadati !== "NO MATCH") {
        let temp = {
          id_acquistato_temp: Selected.id_acquistato_temp,
          fornitore: Selected.fornitore,
          aic: Selected.aic_farmadati,
          nome_commerciale: Selected.nome_commerciale_farmadati,
          principio_attivo: Selected.principio_attivo_farmadati || "",
          atc: Selected.atc_farmadati,
          atc_complementare: Selected.atc_complementare_farmadati || "",
          codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
          descrizione_gruppo_ospedaliero:
            Selected.descrizione_gruppo_ospedaliero || "",
          descrizione_atc: Selected.descrizione_atc_farmadati || "",
          forma_farmaceutica: Selected.forma_farmaceutica || "",
          dosaggio: Selected.dosaggio || "",
          fascia: Selected.fascia || "",
          iva: Selected.iva || "",
          prezzo_unitario:
            Math.round(Selected.prezzo_unitario * 100000) / 100000,
          numero_protocollo: Selected.numero_protocollo,
          // quantita: Selected.quantita,
          // filef_flag: false,
          // is_acquistato: true,
        };
        finalFile.push(temp);
      }

      return fileValutation;
    });

    finalFile.sort((a, b) => {
      let x = a.principio_attivo;
      let y = b.principio_attivo;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var finalFile_COD_OSP = finalFile.reduce((x, y) => {
      (x[y.descrizione_gruppo_ospedaliero] =
        x[y.descrizione_gruppo_ospedaliero] || []).push(y);
      return x;
    }, {});

    let finalOBJ = [];

    Object.keys(finalFile_COD_OSP).map((resultSelected) => {
      let COD_OSPListTemp = [];

      finalFile.map((SelectedBIS) => {
        if (resultSelected === SelectedBIS?.descrizione_gruppo_ospedaliero) {
          COD_OSPListTemp.push(SelectedBIS);
        }

        return finalFile;
      });

      COD_OSPListTemp.sort((a, b) => {
        let x = a.prezzo_unitario;
        let y = b.prezzo_unitario;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      finalOBJ.push(COD_OSPListTemp);

      return finalFile_COD_OSP;
    });

    let OBJtoExp = [];

    finalOBJ.map((Sel) => {
      Sel.map((SelBIS) => {
        OBJtoExp.push(SelBIS);

        return Sel;
      });

      return finalOBJ;
    });

    handleUpdateFileData(OBJtoExp);
    insertedFile(true);
  };

  return (
    <>
      {!confirmedFile && importErrors === null && (
        <>
          <div
            style={{
              display: "inline-block",
              width: "15%",
              textAlign: "left",
              verticalAlign: "top",
            }}
          >
            <Button
              variant="contained"
              color="redtest"
              startIcon={<ArrowBackIosNewIcon />}
              onClick={() => handleChangeDates()}
            >
              CAMBIA PERIODO
            </Button>
          </div>
          <div
            style={{
              display: "inline-block",
              width: "25%",
              textAlign: "left",
            }}
          >
            <Button
              variant="contained"
              color="bluetest"
              startIcon={<InfoOutlinedIcon />}
              onClick={() => setInstructionOpen(true)}
            >
              ISTRUZIONI
            </Button>
          </div>

          <div
            style={{
              display: "inline-block",
              width: "35%",
              textAlign: "right",
            }}
          >
            <EXP_SUPPLIER_LIST data={supplierDB} />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "15%",
              textAlign: "right",
            }}
          >
            <EXP_OFF_LIST_EMPTY />
          </div>
          {!dataFile && !confirmedFile ? (
            <div
              style={{
                display: "inline-block",
                width: "10%",
                textAlign: "right",
              }}
            >
              <Button
                variant="contained"
                color="bluetest"
                component="label"
                startIcon={<BackupOutlinedIcon />}
              >
                UPLOAD
                <input
                  type="file"
                  hidden
                  accept="xlsx, xls"
                  multiple={false}
                  onChange={(e) => handleFile(e)}
                  ref={fileRef}
                />
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "inline-block",
                width: "10%",
              }}
            ></div>
          )}

          {dataFile && !confirmedFile ? (
            <>
              {loadingFarmData ? (
                <div style={{ textAlign: "center", paddingTop: "3%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingFarmData}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6
                    style={{
                      fontFamily: "Calibri",
                      color: "#1976d2",
                    }}
                  >
                    CARICAMENTO...
                  </h6>
                </div>
              ) : (
                <>
                  <div>
                    <Box
                      sx={{
                        height: "385px",
                        width: "100%",
                        paddingLeft: "8%",
                        paddingRight: "8%",
                        paddingTop: "1%",
                        "& .theme_header": {
                          backgroundColor: "#1976d2",
                          color: "#fff",
                          fontFamily: "Calibri",
                          fontSize: "16px",
                        },
                        "& .theme_cell": {
                          fontFamily: "Calibri",
                          fontSize: "17px",
                          border: "1px solid",
                        },
                      }}
                    >
                      <div
                        style={{
                          textAlign: "left",
                          display: "inline-block",
                          width: "50%",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "left",
                            color: "#1976d2",
                            fontFamily: "Calibri",
                            fontSize: "18px",
                          }}
                        >
                          PERIODO SELEZIONATO:{" "}
                          <b>
                            {visualizzaData(dates[0].split("T")[0])} -{" "}
                            {visualizzaData(dates[1].split("T")[0])}{" "}
                          </b>
                        </p>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          display: "inline-block",
                          width: "50%",
                        }}
                      >
                        <h3
                          style={{
                            textAlign: "right",
                            fontFamily: "Calibri",
                            color: "#1976d2",
                          }}
                        >
                          TOT ELEMENTI: {dataFile.length}
                        </h3>
                      </div>

                      <DataGridPro
                        columns={OFF_RESUME_IMPORT_COLUMNS}
                        rows={dataFile}
                        getRowId={(row) => row.id_temp}
                        headerHeight={40}
                        rowHeight={33}
                        disableColumnMenu
                        hideFooter
                      />
                    </Box>
                  </div>
                  <div
                    style={{
                      paddingTop: "3%",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        width: "58%",
                      }}
                    ></div>
                    <div
                      style={{
                        textAlign: "right",
                        display: "inline-block",
                        width: "13%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="redtest"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={handleRemove}
                      >
                        RIMUOVI IL FILE
                      </Button>
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        display: "inline-block",
                        width: "21%",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<ManageSearchOutlinedIcon />}
                        onClick={handleFirstConfirm}
                      >
                        CONFRONTA CON FARMADATI
                      </Button>
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        display: "inline-block",
                        width: "8%",
                      }}
                    ></div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "34%",
              }}
            ></div>
          )}
        </>
      )}

      {!confirmedFile && importErrors !== null && (
        <>
          <div
            style={{
              textAlign: "left",
              display: "inline-block",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="redtest"
              startIcon={<DeleteOutlinedIcon />}
              onClick={handleRemove}
            >
              RIMUOVI IL FILE
            </Button>
          </div>
          <h2
            style={{
              textAlign: "center",
              color: "#f00",
              fontFamily: "Calibri",
              paddingTop: "1%",
            }}
          >
            INDIVIDUATI ERRORI NEL FILE INSERITO:
          </h2>

          <div>
            <Box
              sx={{
                height: "500px",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "1%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "17px",
                },
                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "18px",
                  border: "1px solid",
                },
                "& .theme_cell_error": {
                  fontFamily: "Calibri",
                  backgroundColor: "#f00",
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  border: "1px solid #000",
                },
              }}
            >
              <DataGridPro
                columns={OFF_RESUME_ERRORS_COLUMNS}
                rows={importErrors}
                getRowId={(row) => row.id_temp}
                headerHeight={40}
                rowHeight={33}
                disableColumnMenu
                hideFooter
              />
            </Box>
          </div>
        </>
      )}

      {confirmedFile && importErrors === null && (
        <>
          <div
            style={{
              textAlign: "left",

              display: "inline-block",
              width: "28%",
            }}
          >
            <p
              style={{
                textAlign: "left",
                color: "#1976d2",
                fontFamily: "Calibri",
                fontSize: "18px",
              }}
            >
              PERIODO SELEZIONATO:{" "}
              <b>
                {visualizzaData(dates[0].split("T")[0])} -{" "}
                {visualizzaData(dates[1].split("T")[0])}{" "}
              </b>
            </p>
          </div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "20%",
            }}
          ></div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "18%",
            }}
          >
            {/* <EXP_ACQ_CONFRONTO data={fileValutation} /> */}
          </div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "10%",
            }}
          ></div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "13%",
            }}
          >
            <Button
              variant="contained"
              color="redtest"
              startIcon={<DeleteOutlinedIcon />}
              onClick={handleRemove}
            >
              RIMUOVI IL FILE
            </Button>
          </div>
          <div
            style={{
              textAlign: "right",
              display: "inline-block",
              width: "11%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckOutlinedIcon />}
              onClick={() => setSecondConfirmOpen(true)}
            >
              CONFERMA
            </Button>
          </div>
          <Box
            sx={{
              height: "418px",
              width: "100%",
              paddingLeft: "5%",
              paddingRight: "5%",
              paddingTop: "1%",
              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "17px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "18px",
                border: "1px solid",
              },
              "& .theme_cell_nomatch": {
                fontFamily: "Calibri",
                fontSize: "18px",
                color: "#ff1a00",
                border: "1px solid",
              },
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "50%",
                textAlign: "left",
              }}
            >
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Switch
                        checked={noMatchFlag}
                        onChange={handleNoMatchFlag}
                      />
                    }
                    label={
                      <h4
                        style={{
                          fontFamily: "Calibri",
                          color: noMatchFlag ? "#1976d2" : "#ccc",
                        }}
                      >
                        FILTRO NO MATCH
                      </h4>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>

            {!noMatchFlag && (
              <>
                <h3
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  TOT Elementi: {fileValutation.length}
                </h3>

                <DataGridPro
                  columns={OFF_RESUME_CONFRONTO_COLUMNS}
                  rows={fileValutation}
                  getRowId={(row) => row.id_acquistato_temp}
                  headerHeight={40}
                  rowHeight={33}
                  disableColumnMenu
                  hideFooter
                />
              </>
            )}
            {noMatchFlag && (
              <>
                <h3
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "right",
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  TOT Elementi: {notMatch.length}
                </h3>
                <DataGridPro
                  columns={OFF_RESUME_CONFRONTO_COLUMNS}
                  rows={notMatch}
                  getRowId={(row) => row.id_acquistato_temp}
                  headerHeight={40}
                  rowHeight={33}
                  disableColumnMenu
                  hideFooter
                />
              </>
            )}
          </Box>
        </>
      )}

      <OFF_LIST_INSTR_POPUP
        instructionOpen={instructionOpen}
        setInstructionOpen={setInstructionOpen}
      />

      <OFF_LIST_SECOND_CONFIRM_POPUP
        secondConfirmOpen={secondConfirmOpen}
        setSecondConfirmOpen={setSecondConfirmOpen}
        notMatch={notMatch}
        handleSecondConfirm={handleSecondConfirm}
      />
    </>
  );
};

export default OffersListImport;
