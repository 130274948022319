import { calcoloCosto } from "../FUN_CALCOLI";

const handleCreateTabACQ_RESUME = async (
  ACQ_SEL,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList
) => {
  const GARE_SEL_TEMP = [...fileData];

  let GARE_LIST_TEMP = [...ACQ_SEL?.garePubList];

  let ARRAY_TEMP = [];

  let Q_TOT = 0;
  let SPESA_TOT = 0;

  await GARE_SEL_TEMP.map((SEL) => {
    if (
      ACQ_SEL?.descrizione_gruppo_ospedaliero ===
      SEL?.descrizione_gruppo_ospedaliero
    ) {
      console.log("SEL", SEL);

      Q_TOT = Q_TOT + SEL.quantita;
      SPESA_TOT = SPESA_TOT + calcoloCosto(SEL.prezzo_unitario, SEL.quantita);

      ARRAY_TEMP.push(SEL);
    }

    return GARE_SEL_TEMP;
  });

  await GARE_LIST_TEMP.map((SEL) => {
    SEL.quantita_totale = Q_TOT;
    SEL.spesa_totale = Math.round(SPESA_TOT * 100) / 100;

    SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT);

    return GARE_LIST_TEMP;
  });

  setQuantitaTOT(Q_TOT);
  setSpesaTOT(Math.round(SPESA_TOT * 100) / 100);

  setAcqSelGareList(GARE_LIST_TEMP);

  return ARRAY_TEMP;
};

const handleCreateTabACQ_RESUME_BIS = async (
  ACQ_SEL,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList
) => {
  const GARE_SEL_TEMP = [...fileData];
  let GARE_LIST_TEMP = [...ACQ_SEL?.garePubList];

  console.log("GARE_SEL_TEMP", GARE_SEL_TEMP);
  console.log("GARE_LIST_TEMP", GARE_LIST_TEMP);

  let ARRAY_TEMP = [];

  let Q_TOT = 0;
  let SPESA_TOT = 0;

  await GARE_SEL_TEMP.map((SEL) => {
    if (ACQ_SEL?.tipologia === SEL?.descrizione_gruppo_ospedaliero) {
      console.log("SEL", SEL);

      Q_TOT = Q_TOT + SEL.quantita;
      SPESA_TOT = SPESA_TOT + calcoloCosto(SEL.prezzo_unitario, SEL.quantita);

      ARRAY_TEMP.push(SEL);
    }

    return GARE_SEL_TEMP;
  });

  await GARE_LIST_TEMP.map((SEL) => {
    SEL.quantita_totale = Q_TOT;
    SEL.spesa_totale = Math.round(SPESA_TOT * 100) / 100;

    SEL.spesa_temp = calcoloCosto(SEL.prezzo_unitario, Q_TOT);

    return GARE_LIST_TEMP;
  });

  setQuantitaTOT(Q_TOT);
  setSpesaTOT(Math.round(SPESA_TOT * 100) / 100);
  setAcqSelGareList(GARE_LIST_TEMP);

  return ARRAY_TEMP;
};

const handleSingleDetail = async (
  params,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList,
  setGareSingleDetail
) => {
  const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME(
    params.row,
    fileData,
    setQuantitaTOT,
    setSpesaTOT,
    setAcqSelGareList
  );

  setGareSingleDetail(ACQ_TAB_TEMP);
};

const handleSingleDetailGrouped = async (
  params,
  fileData,
  setQuantitaTOT,
  setSpesaTOT,
  setAcqSelGareList,
  setGareSingleDetail
) => {
  const ACQ_TAB_TEMP = await handleCreateTabACQ_RESUME_BIS(
    params.row,
    fileData,
    setQuantitaTOT,
    setSpesaTOT,
    setAcqSelGareList
  );

  setGareSingleDetail(ACQ_TAB_TEMP);
};

export { handleSingleDetail, handleSingleDetailGrouped };
