import React, { useState } from "react";

import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FABB_EXP_POPUP = (props) => {
  const {
    expPopupOpen,
    setExpPopupOpen,
    listaStrutture,
    EXP_FABB_MAIN,
    fileData,
  } = props;

  const [struttureSelected, setStruttureSelected] = useState(null);

  const handleStruttureChange = (e, newValue) => {
    const tutteLeStruttureSelezionato = newValue.some(
      (option) => option.id === 0
    );

    if (tutteLeStruttureSelezionato) {
      const tutteLeStrutture = listaStrutture.filter(
        (option) => option.id !== 0
      );
      setStruttureSelected(tutteLeStrutture);
    } else {
      setStruttureSelected(newValue);
    }
  };

  return (
    <>
      <Dialog
        open={expPopupOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={() => setExpPopupOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#f4f4f4",
            border: "1px solid #ccc",
            borderRadius: "10px",
            minHeight: "300px",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{
            fontFamily: "Calibri",
            fontSize: 25,
            fontWeight: "bold",
            color: "#1976d2",
            textAlign: "center",
            marginTop: "1%",
          }}
        >
          SELEZIONA UNA O PIU STRUTTURE PER ESPOSRTARE IL FABBISOGNO COMPLESSIVO
        </DialogContentText>
        <DialogContent>
          <>
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-multiple"
              options={listaStrutture ?? []}
              getOptionLabel={(option) => option?.nome_struttura || ""}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              sx={{
                width: "100%",
                marginTop: "2%",
                paddingLeft: "30%",
                paddingRight: "30%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="STRUTTURE" />
              )}
              value={struttureSelected || []}
              onChange={handleStruttureChange}
            />

            <div
              style={{
                textAlign: "center",
                marginTop: "1%",
              }}
            >
              {!(
                struttureSelected === null || struttureSelected.length === 0
              ) && (
                <EXP_FABB_MAIN
                  data={fileData}
                  struttureSelected={struttureSelected}
                />
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FABB_EXP_POPUP;
