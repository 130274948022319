import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_LIST_INSTR_POPUP = (props) => {
  const { instructionOpen, setInstructionOpen } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={instructionOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => setInstructionOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 36,
                textAlign: "center",
              }}
            >
              ISTRUZIONI D'INSERIMENTO:
            </DialogTitle>
            <DialogContent
              sx={{
                padding: "20px",
                width: "90%",
                margin: "auto",
              }}
            >
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Calibri",
                  fontSize: 25,
                }}
              >
                <ol
                  style={{
                    paddingLeft: "2%",
                  }}
                >
                  <li style={{ color: "#1976d2", fontSize: "28px" }}>
                    ESPORTARE IL FILE VUOTO DA COMPILARE PER L'INSERIMENTO
                  </li>
                  <li style={{ color: "#1976d2", fontSize: "28px" }}>
                    I CAMPI NECESSARI SONO:
                    <br /> <b>AIC, NOME COMMERCIALE, PREZZO UNITARIO E P.IVA</b>
                  </li>
                  <li style={{ color: "#1976d2", fontSize: "28px" }}>
                    VERIFICARE L'ASSENZA DI ERRORI NEL FILE INSERITO (ES: NUMERO
                    DI RIGHE TOT.)
                  </li>
                  <li style={{ color: "#1976d2", fontSize: "28px" }}>
                    CONFRONTARE IL FILE INSERITO CON IL DATABASE
                  </li>
                </ol>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setInstructionOpen(false)}
                sx={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  ":hover": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_LIST_INSTR_POPUP;
