import axios from "axios";

//se sviluppo
// const urlOffersGroupChange = "http://localhost:3001/api/offersgroupchange";
//se produzione
const urlOffersGroupChange = "/api/offersgroupchange";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

//Ricezione
const getOffersGroupChangeByUserId = (userId) => {
  let newUrl = urlOffersGroupChange + "/user/" + userId;
  return axios.get(newUrl);
};

const getOffersGroupChangeByOffersGroupId = (offersGroupChangeId) => {
  let newUrl =
    urlOffersGroupChange + "/singleoffersgroupchange/id/" + offersGroupChangeId;
  return axios.get(newUrl);
};

const getOffersGroupChangeByOffersGroupYear = (offersGroupChangeYear) => {
  let newUrl =
    urlOffersGroupChange +
    "/singleoffersgroupchange/year/" +
    offersGroupChangeYear;
  return axios.get(newUrl);
};

//Invio
const salvaOffersGroupChange = async (newOffersGroupChange) => {
  const config = {
    headers: { Authorization: token },
  };

  try {
    const res = await axios.post(
      urlOffersGroupChange,
      newOffersGroupChange,
      config
    );
    return res;
  } catch (error) {
    return "error";
  }
};

const aggiornaOffersGroupChange = async (
  newOffersGroupChange,
  OffersGroupChangeId
) => {
  const config = {
    headers: { Authorization: token },
  };

  let newUrl =
    urlOffersGroupChange + "/updateoffersgroupchange/" + OffersGroupChangeId;

  const res = await axios.patch(newUrl, newOffersGroupChange, config);
  return res;
};

const offersGroupChangeService = {
  getOffersGroupChangeByUserId,
  getOffersGroupChangeByOffersGroupId,
  getOffersGroupChangeByOffersGroupYear,
  salvaOffersGroupChange,
  aggiornaOffersGroupChange,
  setToken,
};

export default offersGroupChangeService;
