import React from "react";
import "../../../../App.css";

// SERVICES
// COMPONENTS
// FUNCTIONS
import { RapprPrezzo } from "../../../../functions/FUN_CALCOLI";

// COLUMNS
import { ABB_RESUME_COLUMNS } from "../../../../colums/ABB_COLUMNS";

import { BENCH_RESUME_COLUMNS } from "../../../../colums/BENCHMARKING_COLUMNS";

// EXPS
// POPUPS
// MUI ELEMENTS
import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import { ResponsivePie } from "@nivo/pie";

// ICONS
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

// LOADERS
// OPTIONS

const ResumeSelection = ({
  NEW_ABB_FLAG,
  handleBackToGenerateMain,
  handleSelectionListPage,
  abbinatoResume,
  garePubBench,
  setPopUpSaveABBOpen,
}) => {
  return (
    <>
      <div
        style={{
          marginBottom: "3%",
        }}
      >
        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "25%",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="redtest"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            onClick={() => handleBackToGenerateMain()}
          >
            INDIETRO
          </Button>
        </div>

        <div
          style={{
            textAlign: "left",
            display: "inline-block",
            width: "25%",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="bluetest"
            startIcon={<InventoryIcon />}
            disabled
            // onClick={() => handlePopUpSuppResumeOpen()}
          >
            SINTESI FORNITORI
          </Button>
        </div>
        {/* <div
                    style={{
                      textAlign: "center",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <EXP_ABB_CONFRONTO data={abbinatoDownloaded} />
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <EXP_ABB_SELECTED_TAB
                      data={specMatchedTOT}
                      abb_name={abbinatoDownloaded?.nome_abbinato}
                    />
                  </div> */}
        <div
          style={{
            textAlign: "CENTER",
            display: "inline-block",
            width: "25%",
          }}
        >
          <Button
            variant="contained"
            color="bluetest"
            startIcon={<FileDownloadOutlinedIcon />}
            disabled

            // onClick={() => setExpsVariPopUpOpen(true)}
          >
            EXPORT
          </Button>
        </div>

        <div
          style={{
            textAlign: "right",
            display: "inline-block",
            width: "25%",
          }}
        >
          {NEW_ABB_FLAG ? (
            <>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => setPopUpSaveABBOpen(true)}
              >
                PROSEGUI E SALVA
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="bluetest"
                startIcon={<SaveOutlinedIcon />}
                disabled
                // onClick={() => setPopUpUpdateABBOpen(true)}
              >
                SALVA MODIFICHE
              </Button>
            </>
          )}
        </div>
      </div>
      <div>
        <Box
          sx={{
            width: "100%",
            marginBottom: "1%",

            "& .theme_header": {
              backgroundColor: "#1976d2",
              color: "#fff",
              fontFamily: "Calibri",
              fontSize: "16px",
            },
            "& .theme_cell": {
              fontFamily: "Calibri",
              fontSize: "17px",
              border: "1px solid #000",
            },
            "& .theme_cell_lost": {
              fontFamily: "Calibri",
              backgroundColor: "#FFC7CE",
              color: "#9C0006",
              fontSize: "17px",
              border: "1px solid",
            },
            "& .theme_cell_gain": {
              fontFamily: "Calibri",
              backgroundColor: "#C6EFCE",
              color: "#006100",
              fontSize: "17px",
              border: "1px solid",
            },
          }}
        >
          <DataGridPro
            columns={ABB_RESUME_COLUMNS}
            rows={abbinatoResume}
            getRowId={(row) => row.title}
            headerHeight={40}
            rowHeight={33}
            autoHeight
            {...abbinatoResume}
            onRowDoubleClick={handleSelectionListPage}
            disableColumnMenu
            hideFooter
          />
        </Box>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "inline-block",
            width: "55%",
            marginBottom: "1%",

            "& .theme_header": {
              backgroundColor: "#1976d2",
              color: "#fff",
              fontFamily: "Calibri",
              fontSize: "16px",
            },
            "& .theme_cell": {
              fontFamily: "Calibri",
              fontSize: "17px",
              border: "0.5px solid rgba(0, 0, 0, 0.5)",
            },
            "& .theme_cell_lost": {
              fontFamily: "Calibri",
              backgroundColor: "#FFC7CE",
              color: "#9C0006",
              fontSize: "17px",
              border: "0.5px solid rgba(0, 0, 0, 0.5)",
            },
            "& .theme_cell_gain": {
              fontFamily: "Calibri",
              backgroundColor: "#C6EFCE",
              color: "#006100",
              fontSize: "17px",
              border: "0.5px solid rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DataGridPro
            columns={BENCH_RESUME_COLUMNS}
            rows={garePubBench.BENCH_TAB}
            getRowId={(row) => row.title}
            headerHeight={40}
            rowHeight={33}
            autoHeight
            {...garePubBench.BENCH_TAB}
            // onRowDoubleClick={handleViewDetailsTab}
            disableColumnMenu
            hideFooter
          />
        </Box>
        <Box
          sx={{
            display: "inline-block",
            height: "300px",
            width: "45%",
            textAlign: "center",
          }}
        >
          <ResponsivePie
            data={garePubBench.BENCH_PIE}
            margin={{
              top: 30,
              right: 40,
              bottom: 60,
              left: 40,
            }}
            colors={["#104d89", "#1976d2", "#76b3ef", "#e8f2fc"]}
            sortByValue={false}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={2}
            activeInnerRadiusOffset={5}
            activeOuterRadiusOffset={5}
            arcLinkLabel={(e) => RapprPrezzo(e.data.value)}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#000"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabel={(e) => e.data.perc_spesaABB.toString() + "%"}
            arcLabelsSkipAngle={30}
            arcLabelsTextColor="#fff"
            animate={true}
            tooltip={(e) => (
              <div
                style={{
                  padding: "5%",
                  fontFamily: "Calibri",
                  background: "#fff",
                  borderRadius: "5px",
                  border: "2px solid #1976d2",
                }}
              >
                <b>{e.datum.data.label}</b>
                <br /> <b>SPECIALITA':</b> {e.datum.data.n_specialita}
                <br />
                <b> SPESA:</b> {RapprPrezzo(e.datum.data.value)}
              </div>
            )}
          />
        </Box>
      </div>
    </>
  );
};

export default ResumeSelection;
