import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

// import EXP_ABB_MULTIPLI from "../../exports/ABB/EXP_ABB_MULTIPLI";

import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";

import BeatLoader from "react-spinners/BeatLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SAVE_UPDATE_POPUP = ({
  popUpUpdateABBOpen,
  setPopUpUpdateABBOpen,
  loadingUpdateABB,
  handleUpdateNewABB,
  // abb_name,
}) => {
  return (
    <>
      <Dialog
        open={popUpUpdateABBOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={() => setPopUpUpdateABBOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              fontFamily: "Calibri",
              fontSize: 25,
              textAlign: "center",
            }}
          >
            CONFERMI DI VOLER SALVARE L'ABBINATO?
          </DialogContentText>
          <div
            style={{
              textAlign: "right",
              paddingTop: "1%",
              paddingRight: "0.5%",
              display: "inline-block",
              width: "50%",
            }}
          >
            <Button
              variant="contained"
              color="redtest"
              onClick={() => setPopUpUpdateABBOpen(false)}
            >
              INDIETRO
            </Button>
          </div>
          <div
            style={{
              textAlign: "left",
              paddingTop: "1%",
              display: "inline-block",
              width: "50%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleUpdateNewABB}
            >
              CONFERMA
            </Button>
          </div>

          {loadingUpdateABB && (
            <div style={{ paddingTop: "1%", textAlign: "center" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingUpdateABB}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6
                style={{
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                CARICAMENTO...
              </h6>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SAVE_UPDATE_POPUP;
