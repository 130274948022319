import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CheckIcon from "@mui/icons-material/Check";

import BeatLoader from "react-spinners/BeatLoader";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_CREATE_NEW_GROUP_POPUP = (props) => {
  const {
    saveNewGroupOpen,
    setSaveNewGroupOpen,
    handleCreateNewOfferList,
    yearSel,
    loadingCreateListSave,
    salvaOffersGroup_ERR,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={saveNewGroupOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => setSaveNewGroupOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: "#fff",
                textAlign: "center",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Calibri",
                  fontSize: 25,
                }}
              >
                <div
                  style={{
                    color: "#1976d2",
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                  }}
                >
                  NON RISULTANO ANCORA OFFERTE INSERITE NELL'ANNO {yearSel.anno}
                  <br />
                  VUOI INIZIARE AD INSERIRE LE OFFERTE {yearSel.anno}?
                </div>
              </DialogContentText>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  component="label"
                  startIcon={<ArrowBackIosNewIcon />}
                  onClick={() => setSaveNewGroupOpen(false)}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "6%",
                }}
              ></div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  component="label"
                  startIcon={<CheckIcon />}
                  onClick={() => handleCreateNewOfferList()}
                >
                  CONFERMA
                </Button>
              </div>
              {loadingCreateListSave && (
                <div style={{ textAlign: "center", paddingTop: "3%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingCreateListSave}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_CREATE_NEW_GROUP_POPUP;
