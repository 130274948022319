import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const ExpExcelTabResumeEnrico = (props) => {
  const { data } = props;

  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "ENRICO 2023.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "ABBINAMENTI",
        B: "N.",
        C: "COSTO 22",
        D: "PREVISIONE SPESA 2023",
        E: "DIFFERENZA",
        F: "DIFF. %",
        G: "PREVISIONE SPESA SEL.",
        H: "DIFFERENZA",
        I: "DIFF. %",
        J: "PREVISIONE GARE PUB",
        K: "DIFFERENZA",
        L: "DIFF. %",
      },
    ];

    data.forEach((row) => {
      table1.push({
        A: row?.title,
        B: row?.n_molecole,
        C: row?.costo_acq,
        D: row?.costo_minimo,
        E: row?.diff_costo_acq_minimo,
        F: row?.diff_perc_minimo,
        G: row?.costo_selezionato,
        H: row?.diff_costo_acq_selezionato,
        I: row?.diff_perc_selezionato,
        J: row?.costo_garepub,
        K: row?.diff_costo_acq_garepub,
        L: row?.diff_perc_garepub,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "ENRICO 2023");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:L1`,
      rispRange1: `E2:F4`,
      rispRange2: `E8:F8`,
      rispRange3: `H2:I4`,
      rispRange4: `H8:I8`,
      rispRange5: `K2:L4`,
      rispRange6: `K8:L8`,
      tabRange: `A1:L8`,
      bold1: `A4:L4`,
      bold2: `A7:L7`,
      bold3: `A8:L8`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          verticalAlignment: "left",
          horizontalAlignment: "left",
        });
        sheet.column("A").width(34).style({});
        sheet.column("B").width(18).style({
          horizontalAlignment: "right",
        });
        sheet.column("C").width(17).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("D").width(25).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("E").width(20).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("F").width(13).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("G").width(25).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("H").width(20).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("I").width(13).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("J").width(25).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("K").width(20).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });
        sheet.column("L").width(13).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00",
        });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
            border: true,
            borderColor: "000",
          });
        }
        if (dataInfo.rispRange1) {
          sheet.range(dataInfo.rispRange1).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.rispRange2) {
          sheet.range(dataInfo.rispRange2).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.rispRange3) {
          sheet.range(dataInfo.rispRange3).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.rispRange4) {
          sheet.range(dataInfo.rispRange4).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.rispRange5) {
          sheet.range(dataInfo.rispRange5).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.rispRange6) {
          sheet.range(dataInfo.rispRange6).style({
            fill: "c6efce",
            fontColor: "006100",
          });
        }
        if (dataInfo.tabRange) {
          sheet.range(dataInfo.tabRange).style({
            bold: false,
            border: true,
            borderColor: "000",
          });
        }
        if (dataInfo.bold1) {
          sheet.range(dataInfo.bold1).style({
            bold: true,
          });
        }
        if (dataInfo.bold2) {
          sheet.range(dataInfo.bold2).style({
            bold: true,
          });
        }
        if (dataInfo.bold3) {
          sheet.range(dataInfo.bold3).style({
            bold: true,
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP TAB
      </Button>
    </>
  );
};

export default ExpExcelTabResumeEnrico;
