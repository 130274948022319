import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_GRUPPO_OSP_MIN = ({ data, nome_listino }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "MINIMI PER TIPOLOGIA " + nome_listino + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "ATC COMP",
        D: "FORNITORE",
        E: "AIC",
        F: "NOME COMMERCIALE",
        G: "PREZZO UNITARIO",
        H: "NUMERO UNITA CONFEZIONE",
        I: "PREZZO CONFEZIONE",
        J: "N PROTOCOLLO",
        K: "DATA INIZIO VALIDITA'",
        L: "DATA FINE VALIDITA'",
        M: "ORDINE MIN",
      },
    ];

    let offerListTEMP = [...data];

    offerListTEMP.sort((a, b) => {
      let x = a.codice_gruppo_ospedaliero;
      let y = b.codice_gruppo_ospedaliero;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    let tempWithGruppoOsp = [];
    let tempWithOutGruppoOsp = [];

    offerListTEMP.map((Selected) => {
      if (
        Selected.codice_gruppo_ospedaliero === "" ||
        !Selected.codice_gruppo_ospedaliero
      ) {
        tempWithOutGruppoOsp.push(Selected);
      } else {
        tempWithGruppoOsp.push(Selected);
      }

      return offerListTEMP;
    });

    let resultWithGruppoOsp = tempWithGruppoOsp.reduce((x, y) => {
      (x[y.codice_gruppo_ospedaliero] =
        x[y.codice_gruppo_ospedaliero] || []).push(y);
      return x;
    }, {});

    let resultWithOutGruppoOsp = tempWithOutGruppoOsp.reduce((x, y) => {
      (x[y.atc] = x[y.atc] || []).push(y);
      return x;
    }, {});

    let GruppoOspListTemp = [];
    let ATCListTemp = [];

    Object.keys(resultWithGruppoOsp).map((resultSelected) => {
      GruppoOspListTemp.push(resultWithGruppoOsp[resultSelected]);
      return resultWithGruppoOsp;
    });

    Object.keys(resultWithOutGruppoOsp).map((resultSelected) => {
      ATCListTemp.push(resultWithOutGruppoOsp[resultSelected]);
      return resultWithOutGruppoOsp;
    });

    let GruppoOspListTempMIN = [];
    let ATCListTempMIN = [];

    GruppoOspListTemp.map((Selected) => {
      let orderByPrezzoUnitario = Selected;

      orderByPrezzoUnitario.sort((a, b) => {
        let x = a.prezzo_unitario;
        let y = b.prezzo_unitario;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      GruppoOspListTempMIN.push(orderByPrezzoUnitario[0]);
      return GruppoOspListTemp;
    });

    ATCListTemp.map((Selected) => {
      let orderByPrezzoUnitario = Selected;

      orderByPrezzoUnitario.sort((a, b) => {
        let x = a.prezzo_unitario;
        let y = b.prezzo_unitario;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      ATCListTempMIN.push(orderByPrezzoUnitario[0]);
      return ATCListTemp;
    });

    let newListMIN = GruppoOspListTempMIN.concat(ATCListTempMIN);

    newListMIN.forEach((row) => {
      table1.push({
        A: row?.principio_attivo,
        B: row?.descrizione_gruppo_ospedaliero,
        C: row?.atc_complementare,
        D: row?.ragione_sociale,
        E: row?.aic,
        F: row?.nome_commerciale,
        G: row?.prezzo_unitario,
        H: row?.numero_unita_confezione,
        I: row?.prezzo_pub_confezione,
        J: row?.numero_protocollo,
        K: visualizzaData(row?.date[0].split("T")[0]),
        L: visualizzaData(row?.date[1].split("T")[0]),
        M: row?.ordine_min,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "MINIMI PER TIPOLOGIA");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:M1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({});

        sheet.column("A").width(20);
        sheet.column("B").width(35);
        sheet.column("C").width(13).style({ horizontalAlignment: "center" });
        sheet.column("D").width(25);
        sheet.column("E").width(14).style({ horizontalAlignment: "center" });
        sheet.column("F").width(30);
        sheet
          .column("G")
          .width(16)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });
        sheet.column("H").width(25);
        sheet.column("I").width(20);
        sheet.column("J").width(18).style({ horizontalAlignment: "center" });
        sheet.column("K").width(20).style({ horizontalAlignment: "center" });
        sheet.column("L").width(20).style({ horizontalAlignment: "center" });
        sheet.column("M").width(15).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP - MINIMI PER TIPOLOGIA
      </LoadingButton>
    </>
  );
};

export default EXP_GRUPPO_OSP_MIN;
