const handleResetSearchDTL = (
  setFilteredResults,
  setSearchedFlag,
  setNotFoundFlag,
  setSearchDtlPopupOpen
) => {
  setFilteredResults(null);
  setSearchedFlag(false);
  setNotFoundFlag(false);
  setSearchDtlPopupOpen(false);
};

const handleSearchDTL = (
  searchValue,
  acqSelGareList, //file principale
  selectedField,
  setFilteredResults,
  setNotFoundFlag,
  setSearchDtlPopupOpen,
  setSearchedFlag,
  handleResetSearchDTL
) => {
  if (searchValue === null) {
    handleResetSearchDTL();
  } else {
    const ArrayToSearch = [...acqSelGareList];
    let filtered = [];

    if (selectedField.name !== "FORNITORE") {
      filtered = ArrayToSearch.filter((item) => {
        return item[selectedField.field]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    } else {
      filtered = ArrayToSearch.filter((item) => {
        return item.fornitore.ragione_sociale
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }

    if (filtered.length > 0) {
      setFilteredResults(filtered);
      setNotFoundFlag(false);
      setSearchDtlPopupOpen(false);
      setSearchedFlag(true);
    } else {
      setFilteredResults(filtered);
      setNotFoundFlag(true);
    }
  }
};

const createSuggestionsListDTL = (newValue, gareData) => {
  const arrayToSearch = [...gareData];
  const suggestions = arrayToSearch.map((Selected) =>
    newValue.name !== "FORNITORE"
      ? Selected[newValue.field]
      : Selected.fornitore.ragione_sociale
  );
  return [...new Set(suggestions)].sort();
};

const handleFieldChangeDTL = async (
  newValue,
  acqSelGareList,
  setSelectedField,
  setFieldSuggestions,
  createSuggestionsListDTL
) => {
  if (newValue !== null) {
    setSelectedField(newValue);
    const SUGGESTIONS_LIST_TEMP = await createSuggestionsListDTL(
      newValue,
      acqSelGareList
    );
    setFieldSuggestions(SUGGESTIONS_LIST_TEMP);
  }
};

export {
  handleResetSearchDTL,
  handleSearchDTL,
  createSuggestionsListDTL,
  handleFieldChangeDTL,
};
