import React from "react";
import "../../App.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import COMPLETO_HOME_700 from "../../images/COMPLETO_HOME_700.jpg";

const HomePage = () => {
  const handleProva = () => {
    // console.log("handleProva", handleProva);
  };

  const theme = createTheme({
    palette: {
      redtest: {
        main: "#c00000",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
      },
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      greentest: {
        main: "#c00000",
        light: "#E9DB5D",
        dark: "#A29415",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <div className="homePage">
        <div
          style={{
            textAlign: "center",
          }}
        >
          <>
            <ThemeProvider theme={theme}>
              <img
                // style={{ width: "100%", height: "auto" }}
                src={COMPLETO_HOME_700}
                alt="Logo"
              />
            </ThemeProvider>
          </>
        </div>
      </div>
    </>
  );
};

export default HomePage;
