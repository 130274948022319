import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_ANALYSIS_OFF_COMPLETE = ({ data, list_name, VAL_Flag }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
    },
  });

  const CalcolaDifferenzaUnitaria = (PrezzoNuovo, PrezzoVecchio) => {
    return PrezzoNuovo - PrezzoVecchio;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "VALUTAZIONE COMPLETEZZA OFFERTA - " + data.FORNITORE + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "FORNITORE",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "PREZZO OFFERTA",
        H: "PREZZO " + list_name,
        I: "DIFFERENZA",
      },
    ];

    let table2 = [
      {
        A: "FORNITORE",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "PREZZO " + list_name,
      },
    ];

    let table3 = [
      {
        A: "FORNITORE",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "PREZZO OFFERTA",
      },
    ];

    let val_PREZZO_AUMENTATO = [];
    let val_PREZZO_DIMINUITO = [];

    let rowNum = 0;

    data.TROVATI.forEach((row) => {
      if (
        CalcolaDifferenzaUnitaria(
          row?.prezzo_offerta,
          row?.prezzo_offertaList
        ) > 0
      ) {
        val_PREZZO_AUMENTATO.push(rowNum + 2);
      }
      if (
        CalcolaDifferenzaUnitaria(
          row?.prezzo_offerta,
          row?.prezzo_offertaList
        ) < 0
      ) {
        val_PREZZO_DIMINUITO.push(rowNum + 2);
      }
      table1.push({
        A: data?.FORNITORE,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.prezzo_offerta,
        H: row?.prezzo_offertaList,
        I: CalcolaDifferenzaUnitaria(
          row?.prezzo_offerta,
          row?.prezzo_offertaList
        ),
      });

      rowNum = rowNum + 1;
    });

    data.NON_TROVATI.forEach((row) => {
      table2.push({
        A: data?.FORNITORE,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.prezzo_unitario,
      });
    });

    data.NUOVI.forEach((row) => {
      table3.push({
        A: data?.FORNITORE,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.prezzo_unitario,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    const sheet3 = XLSX.utils.json_to_sheet(table3, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "RI-OFFERTE");
    XLSX.utils.book_append_sheet(wb, sheet2, "NON OFFERTE");
    XLSX.utils.book_append_sheet(wb, sheet3, "OFFERTE NUOVE");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange1: `A1:I1`,
      theadRange2: `A1:G1`,
      theadRange3: `A1:G1`,
      val_PREZZO_AUMENTATO: val_PREZZO_AUMENTATO,
      val_PREZZO_DIMINUITO: val_PREZZO_DIMINUITO,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;
      workbook.sheets("RI-OFFERTE").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(30);
          sheet.column("B").width(35);
          sheet.column("C").width(40);
          sheet.column("D").width(15).style({ horizontalAlignment: "center" });
          sheet.column("E").width(35);
          sheet.column("F").width(22).style({ horizontalAlignment: "center" });
          sheet.column("G").width(25).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet.column("H").width(25).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet.column("I").width(25).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });

          dataInfo.val_PREZZO_AUMENTATO.forEach((VAL) => {
            sheet.cell(VAL, 9).style({
              fontColor: "ff0000",
              bold: false,
            });
          });

          dataInfo.val_PREZZO_DIMINUITO.forEach((VAL) => {
            sheet.cell(VAL, 9).style({
              fontColor: "009933",
              bold: false,
            });
          });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              bold: false,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(30);
          sheet.column("B").width(35);
          sheet.column("C").width(40);
          sheet.column("D").width(14).style({ horizontalAlignment: "center" });
          sheet.column("E").width(40);
          sheet.column("F").width(20).style({ horizontalAlignment: "center" });
          sheet.column("G").width(23).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              bold: false,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        } else if (sheetsCounter === 3) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(30);
          sheet.column("B").width(35);
          sheet.column("C").width(40);
          sheet.column("D").width(14).style({ horizontalAlignment: "center" });
          sheet.column("E").width(40);
          sheet.column("F").width(20).style({ horizontalAlignment: "center" });
          sheet.column("G").width(23).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });

          if (dataInfo.theadRange3) {
            sheet.range(dataInfo.theadRange3).style({
              fill: "1976d2",
              bold: false,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        }

        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingButton
          variant="contained"
          color="bluetest"
          loading={loadingEXPData}
          loadingPosition="start"
          disabled={!VAL_Flag}
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => createDownLoadData()}
        >
          EXP CONFRONTO
        </LoadingButton>
      </ThemeProvider>
    </>
  );
};

export default EXP_ANALYSIS_OFF_COMPLETE;
