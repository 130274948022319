import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_ACQ_MAIN_COMPLETE = ({ data, periodo }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "ACQUISTATO INSERITO " + periodo[1].substring(0, 4) + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "STRUTTURA",
        B: "PRINCIPIO ATTIVO",
        C: "TIPOLOGIA",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "ATC COMPLEMENTARE",
        G: "ATC",
        H: "FORNITORE",
        I: "P. IVA",
        J: "PREZZO UNITARIO",
        K: "QUANTITA' " + periodo[1].substring(0, 4),
        L: "SPESA " + periodo[1].substring(0, 4),
      },
    ];

    let valZERO = [];
    let rowNum = 0;

    data.forEach((row) => {
      if (row?.quantita === 0) {
        valZERO.push(rowNum + 2);
      }
      table1.push({
        A: row?.struttura,
        B: row?.principio_attivo,
        C: row?.descrizione_gruppo_ospedaliero,
        D: row?.aic,
        E: row?.nome_commerciale,
        F: row?.atc_complementare,
        G: row?.atc,
        H: row?.fornitore.ragione_sociale,
        I: row?.fornitore.cf_piva,
        J: row?.prezzo_unitario,
        K: row?.quantita,
        L: calcoloCosto(row?.prezzo_unitario, row?.quantita),
      });

      rowNum = rowNum + 1;
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "ACQUISTATO INSERITO");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:L1`,
      valZERO: valZERO,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(35);
        sheet.column("B").width(35);
        sheet.column("C").width(40);
        sheet.column("D").width(14).style({ horizontalAlignment: "center" });
        sheet.column("E").width(30);
        sheet.column("F").width(21).style({ horizontalAlignment: "center" });
        sheet.column("G").width(12).style({ horizontalAlignment: "center" });
        sheet.column("H").width(35);
        sheet.column("I").width(18).style({ horizontalAlignment: "center" });
        sheet
          .column("J")
          .width(22)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });
        sheet.column("K").width(16).style({ horizontalAlignment: "right" });
        sheet
          .column("L")
          .width(14)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });

        dataInfo.valZERO.forEach((VAL) => {
          sheet.cell(VAL, 11).style({
            fontColor: "ff0000",
            bold: false,
          });
          sheet.cell(VAL, 12).style({
            fontColor: "ff0000",
            bold: false,
          });
        });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP ACQUISTATO INSERITO
      </LoadingButton>
    </>
  );
};

export default EXP_ACQ_MAIN_COMPLETE;
