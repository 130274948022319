import equivalenzeService from "../../services/equivalenze";

const handleFirstConfirm = (
  setLoadingFarmData,
  dataFile,
  setNoMatchFlag,
  setFileValutation,
  setNotMatch,
  setConfirmedFile
) => {
  setLoadingFarmData(true);

  let dataFileTEMP = [...dataFile];
  let AICList = dataFileTEMP.map((Selected) => Selected.aic);

  equivalenzeService.getAIC(AICList).then((res) => {
    let matchList = createMatchList(dataFileTEMP, res);
    let { finalOBJ, noMatch } = processMatchList(matchList);

    if (noMatch.length > 0) setNoMatchFlag(true);

    setFileValutation(finalOBJ);
    setNotMatch(noMatch);
    setConfirmedFile(true);
    setLoadingFarmData(false);
  });
};

const createMatchList = (dataFileTEMP, foundedAIC) => {
  return dataFileTEMP.map((Selected) => {
    let tempFound =
      foundedAIC.find(
        (element) =>
          element.data.length > 0 && element.data[0].aic === Selected.aic
      )?.data[0] || [];
    return { inserted_excell: Selected, found_farmadati: tempFound };
  });
};

const processMatchList = (matchList) => {
  let matchFarmadati = [];
  let id_acq_temp = 0;

  matchList.forEach((Selected) => {
    let temp = createMatchObject(Selected, id_acq_temp);
    matchFarmadati.push(temp);
    id_acq_temp++;
  });

  let noMatch = matchFarmadati.filter(
    (Selected) => Selected.aic_farmadati === "NO MATCH"
  );
  let completeOBJ = matchFarmadati.filter(
    (Selected) => Selected.aic_farmadati !== "NO MATCH"
  );
  const finalOBJ = noMatch.concat(completeOBJ);

  return { finalOBJ, noMatch };
};

const createMatchObject = (Selected, id_acq_temp) => {
  if (
    Selected.found_farmadati.length === 0 ||
    Selected.inserted_excell.aic === undefined
  ) {
    return {
      id_acquistato_temp: id_acq_temp,
      struttura: Selected?.inserted_excell.struttura,
      aic: Selected?.inserted_excell.aic.toString(),
      nome_commerciale: Selected?.inserted_excell.nome_commerciale,
      quantita: Selected?.inserted_excell.quantita,
      aic_farmadati: "NO MATCH",
      nome_commerciale_farmadati: "NO MATCH",
      atc_farmadati: "NO MATCH",
      atc_complementare_farmadati: "NO MATCH",
      codice_gruppo_ospedaliero: "NO MATCH",
      descrizione_gruppo_ospedaliero: "NO MATCH",
      descrizione_atc_farmadati: "NO MATCH",
      principio_attivo_farmadati: "NO MATCH",
      forma_farmaceutica: "NO MATCH",
      dosaggio: "NO MATCH",
      fascia: "NO MATCH",
      iva: 0,
    };
  } else {
    return {
      id_acquistato_temp: id_acq_temp,
      struttura: Selected?.inserted_excell.struttura,
      aic: Selected?.inserted_excell.aic.toString(),
      nome_commerciale: Selected?.inserted_excell.nome_commerciale,
      quantita: Selected?.inserted_excell.quantita,
      aic_farmadati: Selected?.found_farmadati.aic,
      nome_commerciale_farmadati: Selected?.found_farmadati.descrizione_aic,
      atc_farmadati: Selected?.found_farmadati.atc,
      atc_complementare_farmadati: Selected?.found_farmadati.atc_complementare,
      codice_gruppo_ospedaliero:
        Selected?.found_farmadati.codice_gruppo_ospedaliero,
      descrizione_gruppo_ospedaliero:
        Selected?.found_farmadati.descrizione_gruppo_ospedaliero,
      descrizione_atc_farmadati: Selected?.found_farmadati.descrizione_atc,
      principio_attivo_farmadati: Selected?.found_farmadati.principio_attivo,
      forma_farmaceutica: Selected?.found_farmadati.forma_farmaceutica,
      dosaggio: Selected?.found_farmadati.dosaggio,
      fascia: Selected?.found_farmadati.fascia,
      iva: Selected?.found_farmadati.iva,
    };
  }
};

const handleSecondConfirm = async (
  updateFileData,
  insertedFile,
  fileValutation
) => {
  let finalFile = filterAndMapFinalFile(fileValutation);

  finalFile.sort((a, b) =>
    a.principio_attivo.localeCompare(b.principio_attivo)
  );

  let resultPAList = groupByPrincipioAttivo(finalFile);
  let newOBJ = sortAndGroupByCodiceOspedaliero(resultPAList);
  let finalOBJ_TEMP = sortAndGroupByPrezzoUnitario(newOBJ);
  let OBJtoExp = finalOBJ_TEMP.flat();

  let { farmaci, dispositivi } = separateFarmaciDispositivi(OBJtoExp);
  let finalFarmaci = processFarmaci(farmaci);
  let finalDispositivi = processDispositivi(dispositivi);

  const FabbToExp = { DISPOSITIVI: finalDispositivi, FARMACI: finalFarmaci };
  updateFileData(OBJtoExp, FabbToExp);
  insertedFile(true);
};

const filterAndMapFinalFile = (fileValutation) => {
  return fileValutation
    .filter((Selected) => Selected.aic_farmadati !== "NO MATCH")
    .map((Selected) => ({
      id_acquistato_temp: Selected.id_acquistato_temp,
      struttura: Selected?.struttura,
      aic: Selected.aic_farmadati,
      nome_commerciale: Selected.nome_commerciale_farmadati,
      principio_attivo: Selected.principio_attivo_farmadati || "",
      atc: Selected.atc_farmadati,
      atc_complementare: Selected.atc_complementare_farmadati || "",
      codice_gruppo_ospedaliero: Selected.codice_gruppo_ospedaliero || "",
      descrizione_gruppo_ospedaliero:
        Selected.descrizione_gruppo_ospedaliero || "",
      descrizione_atc: Selected.descrizione_atc_farmadati || "",
      forma_farmaceutica: Selected.forma_farmaceutica || "",
      dosaggio: Selected.dosaggio || "",
      fascia: Selected.fascia || "",
      iva: Selected.iva || "",
      quantita: Selected.quantita,
      filef_flag: false,
      is_acquistato: true,
    }));
};

const groupByPrincipioAttivo = (finalFile) => {
  return finalFile.reduce((x, y) => {
    (x[y.principio_attivo] = x[y.principio_attivo] || []).push(y);
    return x;
  }, {});
};

const sortAndGroupByCodiceOspedaliero = (resultPAList) => {
  let newOBJ = [];
  Object.keys(resultPAList).forEach((resultSelected) => {
    let PAListTemp = resultPAList[resultSelected];
    PAListTemp.sort((a, b) =>
      a.codice_gruppo_ospedaliero.localeCompare(b.codice_gruppo_ospedaliero)
    );
    newOBJ.push(PAListTemp);
  });
  return newOBJ;
};

const sortAndGroupByPrezzoUnitario = (newOBJ) => {
  let finalOBJ_TEMP = [];
  newOBJ.forEach((Selected) => {
    var resultCOD_OSPList = Selected.reduce((x, y) => {
      (x[y.codice_gruppo_ospedaliero] =
        x[y.codice_gruppo_ospedaliero] || []).push(y);
      return x;
    }, {});

    Object.keys(resultCOD_OSPList).forEach((resultSelected) => {
      let COD_OSPListTemp = resultCOD_OSPList[resultSelected];
      COD_OSPListTemp.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
      finalOBJ_TEMP.push(COD_OSPListTemp);
    });
  });
  return finalOBJ_TEMP;
};

const separateFarmaciDispositivi = (OBJtoExp) => {
  let farmaci = [];
  let dispositivi = [];
  OBJtoExp.forEach((row) => {
    if (row?.aic.charAt(0) === "9") {
      dispositivi.push(row);
    } else {
      farmaci.push(row);
    }
  });
  return { farmaci, dispositivi };
};

const processFarmaci = (farmaci) => {
  farmaci.sort((a, b) =>
    a.descrizione_gruppo_ospedaliero.localeCompare(
      b.descrizione_gruppo_ospedaliero
    )
  );

  var result = farmaci.reduce((x, y) => {
    (x[y.descrizione_gruppo_ospedaliero] =
      x[y.descrizione_gruppo_ospedaliero] || []).push(y);
    return x;
  }, {});

  let DescrOspList = Object.keys(result).map((resultSelected) => ({
    tipologia_descr: resultSelected,
  }));

  let withGPOSP = [];
  let withOutGPOSP = [];
  DescrOspList.forEach((Selected) => {
    if (Selected.tipologia_descr !== "") {
      let TOTQuantita = result[Selected.tipologia_descr].reduce(
        (sum, SelSingoli) => sum + SelSingoli.quantita,
        0
      );
      withGPOSP.push({
        tipologia_descr: Selected.tipologia_descr,
        principio_attivo: result[Selected.tipologia_descr][0].principio_attivo,
        tot_quantita: TOTQuantita,
      });
    } else {
      withOutGPOSP.push(
        ...result[Selected.tipologia_descr].map((SelSingoli) => ({
          tipologia_descr: Selected.tipologia_descr,
          principio_attivo: SelSingoli.principio_attivo,
          tot_quantita: SelSingoli.quantita,
        }))
      );
    }
  });

  return withGPOSP
    .concat(withOutGPOSP)
    .sort((a, b) => a.principio_attivo.localeCompare(b.principio_attivo));
};

const processDispositivi = (dispositivi) => {
  dispositivi.sort((a, b) =>
    a.descrizione_atc.localeCompare(b.descrizione_atc)
  );

  var result2 = dispositivi.reduce((x, y) => {
    (x[y.aic] = x[y.aic] || []).push(y);
    return x;
  }, {});

  let AICList = Object.keys(result2).map((resultSelected) => ({
    aic_descr: resultSelected,
  }));

  return AICList.map((Selected) => {
    let TOTQuantita = result2[Selected.aic_descr].reduce(
      (sum, SelSingoli) => sum + SelSingoli.quantita,
      0
    );
    return {
      aic_descr: Selected.aic_descr,
      nome_commerciale: result2[Selected.aic_descr][0].nome_commerciale,
      atc: result2[Selected.aic_descr][0].atc,
      descrizione_atc: result2[Selected.aic_descr][0].descrizione_atc,
      tot_quantita: TOTQuantita,
    };
  });
};

export { handleFirstConfirm, handleSecondConfirm };
