import React, { useEffect, useState } from "react";
import "../../App.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LoginService from "../../services/login";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const { user, changeUserState } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const formErrors = ValidateLoginForm();

    try {
      const userLog = await LoginService.login({
        email,
        password,
      });
      localStorage.setItem("loggedUser", JSON.stringify(userLog));
      setTimeout(() => {
        localStorage.removeItem("loggedUser");
        changeUserState(null);
      }, 1000 * 60 * 60 * 12);

      changeUserState(userLog);
      setEmail("");
      setPassword("");
      setErrors({});
      setErrorMessage(false);
      navigate("/");

      // changePath();
    } catch (exeption) {
      console.log("Log Failed! Wrong credential");
      setErrors(formErrors);
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    const loggedUserJSON = localStorage.getItem("loggedUser");
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      changeUserState(user);
      //Settare il token
    }
  }, []);

  const ValidateLoginForm = () => {
    const emailCheck = email;
    const passwordCheck = password;

    const newErrors = {};

    if (!emailCheck || emailCheck === "") {
      newErrors.email = "Email sbagliata";
    }

    if (!passwordCheck || passwordCheck === "") {
      newErrors.password = "Password Sbagliata";
    }

    return newErrors;
  };

  return (
    <>
      <div className="login">
        <Box component="form" className="formContainer">
          <p className="loginHeader">LOG IN</p>
          <div className="inputField">
            <TextField
              id="email"
              label="Email"
              placeholder="Inserisci Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          </div>
          <div className="inputField">
            <TextField
              id="password"
              label="Password"
              placeholder="Inserisci Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!errors.password}
              helperText={errors.password}
            />
          </div>
          <div className="submitButton">
            <Button
              variant="contained"
              color="success"
              type="submit"
              onClick={handleLogin}
            >
              Conferma
            </Button>
          </div>
        </Box>

        {errorMessage && (
          <>
            <p
              style={{
                paddingTop: "2%",
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "red",
                fontSize: 23,
                textAlign: "center",
              }}
            >
              Email/Password Scorretta
              <br />
              Controllare dati inseriti
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default Login;
