import React from "react";

// COLUMNS
import { ABB_TOWAIT_ATC_COLUMNS } from "../../../../colums/ABB_COLUMNS";

// EXPS
import EXP_ABB_DISP_MEDICI from "../../../../exports/ABB/EXP_ABB_DISP_MEDICI";

// MUI ELEMENTS
import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

// ICONS
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const DispMedici = ({ handleReturnToResumePage, specToWaitATC }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "25%", textAlign: "left" }}>
          <Button
            variant="contained"
            component="label"
            color="redtest"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            onClick={handleReturnToResumePage}
          >
            INDIETRO
          </Button>
        </div>
        <div style={{ width: "25%", textAlign: "left" }}>
          <EXP_ABB_DISP_MEDICI data={specToWaitATC} />
        </div>
        <div style={{ width: "25%" }}></div>
      </div>

      <Box
        sx={{
          height: 400,
          width: "100%",
          mt: 2, // shorthand for marginTop
          "& .theme_header": {
            backgroundColor: "#1976d2",
            color: "white",
            fontFamily: "Calibri",
            fontSize: 16,
          },
          "& .theme_cell": {
            fontFamily: "Calibri",
            fontSize: 17,
            border: 1, // shorthand for "1px solid #000"
            borderColor: "black",
          },
        }}
      >
        <DataGridPro
          columns={ABB_TOWAIT_ATC_COLUMNS}
          rows={specToWaitATC}
          getRowId={(row) => row._id}
          headerHeight={40}
          rowHeight={33}
          disableColumnMenu
          hideFooter
        />
      </Box>
    </>
  );
};

export default DispMedici;
