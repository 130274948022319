import { menuItems_OBJ } from "../../menuItems_OBJ";
import MenuItemsBar from "./MenuItemsBar";
const Navbar = () => {
  return (
    <nav>
      <ul className="menus">
        {menuItems_OBJ.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItemsBar items={menu} key={index} depthLevel={depthLevel} />
          );
        })}
      </ul>
    </nav>
  );
};

export default Navbar;
