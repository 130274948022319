const handleResetSearch = (
  setFilteredResults,
  setSearchedFlag,
  setNotFoundFlag,
  setSearchPopupOpen
) => {
  setFilteredResults(null);
  setSearchedFlag(false);
  setNotFoundFlag(false);
  setSearchPopupOpen(false);
};

const handleSearch = (
  searchValue,
  fileData,
  selectedField,
  setFilteredResults,
  setNotFoundFlag,
  setSearchPopupOpen,
  setSearchedFlag,
  handleResetSearch
) => {
  if (searchValue === null) {
    handleResetSearch();
  } else {
    const ArrayToSearch = [...fileData];
    let filtered = [];

    if (selectedField.name !== "FORNITORE") {
      filtered = ArrayToSearch.filter((item) => {
        return item[selectedField.field]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    } else {
      filtered = ArrayToSearch.filter((item) => {
        return item.fornitore.ragione_sociale
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }

    if (filtered.length > 0) {
      setFilteredResults(filtered);
      setNotFoundFlag(false);
      setSearchPopupOpen(false);
      setSearchedFlag(true);
    } else {
      setFilteredResults(filtered);
      setNotFoundFlag(true);
    }
  }
};

const createSuggestionsList = (newValue, acqData) => {
  const arrayToSearch = [...acqData];
  const suggestions = arrayToSearch.map((Selected) =>
    newValue.name !== "FORNITORE"
      ? Selected[newValue.field]
      : Selected.fornitore.ragione_sociale
  );
  return [...new Set(suggestions)].sort();
};

const handleFieldChange = async (
  newValue,
  fileData,
  setSelectedField,
  setFieldSuggestions,
  createSuggestionsList
) => {
  if (newValue !== null) {
    setSelectedField(newValue);
    const SUGGESTIONS_LIST_TEMP = await createSuggestionsList(
      newValue,
      fileData
    );
    setFieldSuggestions(SUGGESTIONS_LIST_TEMP);
  }
};

export {
  handleResetSearch,
  handleSearch,
  createSuggestionsList,
  handleFieldChange,
};
