import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import EXP_DETTAGLIO_GP from "../../exports/ABB/EXP_DETTAGLIO_GP";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BENCHMARKING_DETAILS_POPUP = (props) => {
  const { garaDetailsOpen, data, handleGaraDetailsClose } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          {data !== null && (
            <>
              <Dialog
                open={garaDetailsOpen}
                TransitionComponent={Transition}
                fullWidth={true}
                maxWidth="md"
                keepMounted
                onClose={handleGaraDetailsClose}
                PaperProps={{
                  style: {
                    backgroundColor: "#f4f4f4",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    textAlign: "center",
                  },
                }}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <EXP_DETTAGLIO_GP data={data} />
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default BENCHMARKING_DETAILS_POPUP;
