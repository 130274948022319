import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_FABB_MAIN = (props) => {
  const { data, struttureSelected } = props;

  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);
    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "SINTESI FABBISOGNO.xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleCreaFileStruttreSelezionate = () => {
    let FILE_TO_FILTER = [...data];

    const nomiStrutture = new Set(
      struttureSelected.map((item) => item.nome_struttura)
    );

    const filteredFILE = FILE_TO_FILTER.filter((obj) =>
      nomiStrutture.has(obj.struttura)
    );

    return filteredFILE;
  };

  const createFabbToExp = async () => {
    const filteredFILE = handleCreaFileStruttreSelezionate();
    // console.log("filteredFILE", filteredFILE);

    filteredFILE.sort((a, b) => {
      let x = a.principio_attivo;
      let y = b.principio_attivo;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var resultPAList = filteredFILE.reduce((x, y) => {
      (x[y.principio_attivo] = x[y.principio_attivo] || []).push(y);
      return x;
    }, {});

    let newOBJ = [];

    Object.keys(resultPAList).map((resultSelected) => {
      let PAListTemp = [];

      filteredFILE.map((Selected) => {
        if (resultSelected === Selected?.principio_attivo) {
          PAListTemp.push(Selected);
        }

        return filteredFILE;
      });

      PAListTemp.sort((a, b) => {
        let x = a.codice_gruppo_ospedaliero;
        let y = b.codice_gruppo_ospedaliero;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      newOBJ.push(PAListTemp);

      return resultPAList;
    });

    let finalOBJ_TEMP = [];

    newOBJ.map((Selected) => {
      var resultCOD_OSPList = Selected.reduce((x, y) => {
        (x[y.codice_gruppo_ospedaliero] =
          x[y.codice_gruppo_ospedaliero] || []).push(y);
        return x;
      }, {});

      Object.keys(resultCOD_OSPList).map((resultSelected) => {
        let COD_OSPListTemp = [];

        Selected.map((SelectedBIS) => {
          if (resultSelected === SelectedBIS?.codice_gruppo_ospedaliero) {
            COD_OSPListTemp.push(SelectedBIS);
          }

          return Selected;
        });

        COD_OSPListTemp.sort((a, b) => {
          let x = a.prezzo_unitario;
          let y = b.prezzo_unitario;

          if (x < y) {
            return -1;
          } else if (x > y) {
            return 1;
          }
          return 0;
        });

        finalOBJ_TEMP.push(COD_OSPListTemp);

        return resultCOD_OSPList;
      });

      return finalOBJ_TEMP;
    });

    let OBJtoExp = [];

    await finalOBJ_TEMP.map((Sel) => {
      Sel.map((SelBIS) => {
        OBJtoExp.push(SelBIS);

        return Sel;
      });

      return finalOBJ_TEMP;
    });

    let farmaci = [];
    let dispositivi = [];

    OBJtoExp.forEach((row) => {
      if (row?.aic.charAt(0) === "9") {
        dispositivi.push(row);
      } else {
        farmaci.push(row);
      }
    });

    farmaci.sort((a, b) => {
      let x = a.descrizione_gruppo_ospedaliero;
      let y = b.descrizione_gruppo_ospedaliero;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var result = farmaci.reduce((x, y) => {
      (x[y.descrizione_gruppo_ospedaliero] =
        x[y.descrizione_gruppo_ospedaliero] || []).push(y);

      return x;
    }, {});

    let DescrOspList = [];

    Object.keys(result).map((resultSelected) => {
      let temp = {
        tipologia_descr: resultSelected,
      };

      DescrOspList.push(temp);

      return result;
    });

    let withGPOSP = [];
    let withOutGPOSP = [];

    DescrOspList.map((Selected) => {
      if (Selected.tipologia_descr !== "") {
        let TOTQuantita = 0;

        result[Selected.tipologia_descr].map((SelSingoli) => {
          TOTQuantita = TOTQuantita + SelSingoli.quantita;

          return result[Selected.tipologia_descr];
        });

        let temp = {
          tipologia_descr: Selected.tipologia_descr,
          principio_attivo:
            result[Selected.tipologia_descr][0].principio_attivo,
          tot_quantita: TOTQuantita,
        };
        withGPOSP.push(temp);
      } else {
        result[Selected.tipologia_descr].map((SelSingoli) => {
          let temp = {
            tipologia_descr: Selected.tipologia_descr,
            principio_attivo: SelSingoli.principio_attivo,
            tot_quantita: SelSingoli.quantita,
          };
          withOutGPOSP.push(temp);
        });
      }

      return DescrOspList;
    });

    let finalOBJ = withGPOSP.concat(withOutGPOSP);

    finalOBJ.sort((a, b) => {
      let x = a.principio_attivo;
      let y = b.principio_attivo;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    dispositivi.sort((a, b) => {
      let x = a.descrizione_atc;
      let y = b.descrizione_atc;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var result2 = dispositivi.reduce((x, y) => {
      (x[y.aic] = x[y.aic] || []).push(y);

      return x;
    }, {});

    let AICList = [];

    Object.keys(result2).map((resultSelected) => {
      let temp = {
        aic_descr: resultSelected,
      };

      AICList.push(temp);

      return result2;
    });

    let finalOBJ2 = [];

    AICList.map((Selected) => {
      let TOTQuantita = 0;

      result2[Selected.aic_descr].map((SelSingoli) => {
        TOTQuantita = TOTQuantita + SelSingoli.quantita;

        return result[Selected.aic_descr];
      });

      let temp = {
        aic_descr: Selected.aic_descr,
        nome_commerciale: result2[Selected.aic_descr][0].nome_commerciale,
        atc: result2[Selected.aic_descr][0].atc,
        descrizione_atc: result2[Selected.aic_descr][0].descrizione_atc,
        tot_quantita: TOTQuantita,
      };
      finalOBJ2.push(temp);

      return AICList;
    });

    const FabbToExp = { DISPOSITIVI: finalOBJ2, FARMACI: finalOBJ };
    return FabbToExp;
  };

  const handleExport = async () => {
    const fabbToEXP = await createFabbToExp();
    // console.log("fabbToEXP", fabbToEXP);

    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "QUANTITA'",
      },
    ];

    let table2 = [
      {
        A: "DESCRIZIONE ATC",
        B: "AIC",
        C: "NOME COMMERCIALE",
        D: "QUANTITA'",
        E: "ATC",
      },
    ];

    let farmaci = [...fabbToEXP.FARMACI];
    let dispositivi = [...fabbToEXP.DISPOSITIVI];

    farmaci.forEach((row) => {
      table1.push({
        A: row.principio_attivo,
        B: row.tipologia_descr,
        C: row.tot_quantita,
      });
    });

    dispositivi.forEach((row) => {
      table2.push({
        A: row.descrizione_atc,
        B: row.aic_descr,
        C: row.nome_commerciale,
        D: row.tot_quantita,
        E: row.atc,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "FARMACI");
    XLSX.utils.book_append_sheet(wb, sheet2, "PARAF. E ALTRO");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange1: `A1:C1`,
      theadRange2: `A1:E1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;

      workbook.sheets("FABBISOGNO SINTESI").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(50);
          sheet.column("B").width(65);
          sheet.column("C").width(16).style({ horizontalAlignment: "right" });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(45);
          sheet.column("B").width(16).style({ horizontalAlignment: "center" });
          sheet.column("C").width(45);
          sheet.column("D").width(14).style({ horizontalAlignment: "right" });
          sheet.column("E").width(16).style({ horizontalAlignment: "center" });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        }

        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP FABBISOGNO COMPLETO
      </LoadingButton>
    </>
  );
};

export default EXP_FABB_MAIN;
