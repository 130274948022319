import React, { useEffect, useState, useRef } from "react";
import "../../../App.css";

// SERVICES
import filefService from "../../../services/filef";

// COMPONENTS
import {
  boxStyles,
  errorBoxStyles,
} from "../../../boxStyles/ACQUISTATO/FILEF_BOXES";

// FUNCTIONS
import { handleFile } from "../../../functions/IMPORT/FILEF_IMPORT";

// COLUMNS
import {
  FILEF_INSERTED_COLUMNS,
  FILEF_ERRORS_COLUMNS,
} from "../../../colums/FILEF_COLUMNS";

// EXPS
import EXP_FILEF_EMPTY from "../../../exports/EMPTY_FILES/EXP_FILEF_EMPTY";

// POPUPS
import { FILEF_INSTR_POPUP } from "../../../popup/INSTRUCTION_POPUP/FILEF_INSTR_POPUP";
import { FILEF_SAVE_CONFIRM_POPUP } from "../../../popup/ACQ_POPUP/FILEF_SAVE_CONFIRM_POPUP";

// MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

// ICONS
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

// OPTIONS
import filef_years from "../../../utils/filef_years.json";

const PurchasedFileF = ({ user }) => {
  const fileRef = useRef();

  const [confirmedFile, setConfirmedFile] = useState(false);

  const [noMatch, setNoMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const [fileValutation, setFileValutation] = useState([]);

  const [yearSel, setYearSel] = useState(null);

  const [instructionOpen, setInstructionOpen] = useState(false);
  const [savePopUpOpen, setSavePopUpOpen] = useState(false);

  const [loadingFileUpload, setLoadingFileUpload] = useState(false);
  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const [importErrors, setImportErrors] = useState(null);

  useEffect(() => {}, []);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
  };

  const handleConfirmSave = async () => {
    setLoadingFinalSave(true);

    const FILE_VAL_TEMP = [...fileValutation];

    let noMatchRemoved = FILE_VAL_TEMP.filter(
      (SEL) => SEL.nome_commerciale !== "NO MATCH"
    );

    let finalToSave = {
      anno: yearSel.nome,
      obj_file: noMatchRemoved,
    };

    filefService.setToken(user.token);

    await filefService.salvaFileF(finalToSave).then((res) => {
      setFileValutation([]);
      setNoMatch([]);
      setConfirmedFile(false);
      handleSavePopUpClose();
      setYearSel(null);
    });
  };

  const handleRemoveFile = () => {
    setYearSel(null);
    setFileValutation([]);
    setNoMatch([]);
    setConfirmedFile(false);
    setImportErrors(null);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          {!confirmedFile && importErrors === null && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "73%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={() => setInstructionOpen(true)}
                  startIcon={<InfoOutlinedIcon />}
                >
                  ISTRUZIONI INSERIMENTO
                </Button>
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "18%",
                }}
              >
                <EXP_FILEF_EMPTY />
              </div>
              <div
                style={{
                  display: "inline-block",
                  width: "9%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  component="label"
                  startIcon={<BackupOutlinedIcon />}
                  disabled={yearSel === null}
                >
                  UPLOAD
                  <input
                    type="file"
                    hidden
                    accept="xlsx, xls"
                    multiple={false}
                    onChange={(e) =>
                      handleFile(
                        e,
                        setImportErrors,
                        setFileValutation,
                        setNoMatchFlag,
                        setNoMatch,
                        setConfirmedFile,
                        setLoadingFileUpload
                      )
                    }
                    ref={fileRef}
                  />
                </Button>
              </div>

              <Autocomplete
                disablePortal
                id="region"
                options={filef_years}
                getOptionLabel={(option) => option.nome}
                sx={{
                  mx: "auto",
                  width: "100%",
                  paddingTop: "10%",
                  paddingRight: "40%",
                  paddingLeft: "40%",
                }}
                renderOption={(props, option, index) => {
                  const key = `listItem-${index}-${option.id}`;
                  return (
                    <li {...props} key={key}>
                      {option.nome}
                    </li>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="ANNO" />}
                value={yearSel}
                onChange={(e, newValue) => {
                  setYearSel(newValue);
                }}
              />
              {loadingFileUpload && (
                <div style={{ textAlign: "center", paddingTop: "3%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingFileUpload}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </>
          )}
          {!confirmedFile && importErrors !== null && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  onClick={handleRemoveFile}
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                >
                  INDIETRO
                </Button>
              </div>
              <h2 className="error-title">
                INDIVIDUATI ERRORI NEL FILE INSERITO:
              </h2>

              <Box sx={errorBoxStyles}>
                <DataGridPro
                  columns={FILEF_ERRORS_COLUMNS}
                  rows={importErrors}
                  getRowId={(row) => row.id_temp}
                  headerHeight={40}
                  rowHeight={33}
                  disableColumnMenu
                  hideFooter
                />
              </Box>
            </>
          )}
          {confirmedFile && importErrors === null && (
            <>
              <div
                style={{
                  display: "inline-block",
                  width: "20%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  onClick={handleRemoveFile}
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                >
                  INDIETRO
                </Button>
              </div>

              <div
                style={{
                  display: "inline-block",
                  width: "60%",
                  textAlign: "left",
                }}
              ></div>

              <div
                style={{
                  display: "inline-block",
                  width: "20%",
                  textAlign: "right",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={() => setSavePopUpOpen(true)}
                  startIcon={<SaveOutlinedIcon />}
                >
                  SALVA
                </Button>
              </div>

              <Box sx={boxStyles}>
                {noMatch.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "left",
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormGroup aria-label="position" row>
                          <FormControlLabel
                            value="bottom"
                            control={
                              <Switch
                                checked={noMatchFlag}
                                onChange={() => setNoMatchFlag(!noMatchFlag)}
                              />
                            }
                            label={
                              <h4
                                style={{
                                  fontFamily: "Calibri",
                                  color: noMatchFlag ? "#1976d2" : "#ccc",
                                }}
                              >
                                FILTRO NO MATCH
                              </h4>
                            }
                            labelPlacement="end"
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                      }}
                    ></div>
                  </>
                )}

                {!noMatchFlag ? (
                  <>
                    <h3
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                        fontFamily: "Calibri",
                        color: "#1976d2",
                      }}
                    >
                      TOT Elementi: {fileValutation.length}
                    </h3>

                    <DataGridPro
                      columns={FILEF_INSERTED_COLUMNS}
                      rows={fileValutation}
                      getRowId={(row) => row.id_temp}
                      headerHeight={40}
                      rowHeight={33}
                      disableColumnMenu
                      hideFooter
                    />
                  </>
                ) : (
                  <>
                    <h3
                      style={{
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                        fontFamily: "Calibri",
                        color: "#1976d2",
                      }}
                    >
                      TOT Elementi: {noMatch.length}
                    </h3>
                    <DataGridPro
                      columns={FILEF_INSERTED_COLUMNS}
                      rows={noMatch}
                      getRowId={(row) => row.id_temp}
                      headerHeight={40}
                      rowHeight={33}
                      disableColumnMenu
                      hideFooter
                    />
                  </>
                )}
              </Box>
            </>
          )}
        </div>

        <FILEF_INSTR_POPUP
          instructionOpen={instructionOpen}
          setInstructionOpen={setInstructionOpen}
        />

        <FILEF_SAVE_CONFIRM_POPUP
          savePopUpOpen={savePopUpOpen}
          handleSavePopUpClose={handleSavePopUpClose}
          loadingFinalSave={loadingFinalSave}
          handleConfirmSave={handleConfirmSave}
        />
      </ThemeProvider>
    </>
  );
};

export default PurchasedFileF;
