import axios from "axios";

//se sviluppo
// const urlFileF = "http://localhost:3001/api/filef";
//se produzione
const urlFileF = "/api/filef";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

//Ricezione
const getFileFByUserId = (userId) => {
  let newUrl = urlFileF + "/user/" + userId;
  return axios.get(newUrl);
};

const getFileFByFileFId = (filefId) => {
  let newUrl = urlFileF + "/singlefilef/" + filefId;
  return axios.get(newUrl);
};

//Invio
const salvaFileF = async (newFileF) => {
  const config = {
    headers: { Authorization: token },
  };
  const res = await axios.post(urlFileF, newFileF, config);
  return res;
};

const filefService = {
  getFileFByUserId,
  getFileFByFileFId,
  salvaFileF,
  setToken,
};

export default filefService;
