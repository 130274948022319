import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_ATC_ORDERED = ({ data, nome_listino }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "EXP - ORDINATO PER ATC " + nome_listino + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "DESCRIZIONE ATC",
        B: "ATC",
        C: "ATC COMP",
        D: "FORNITORE",
        E: "TIPOLOGIA",
        F: "PRINCIPIO ATTIVO",
        G: "AIC",
        H: "NOME COMMERCIALE",
        I: "PREZZO UNITARIO",
        J: "NUMERO UNITA CONFEZIONE",
        K: "PREZZO CONFEZIONE",
        L: "N PROTOCOLLO",
        M: "DATA INIZIO VALIDITA'",
        N: "DATA FINE VALIDITA'",
        O: "ORDINE MIN",
      },
    ];

    let arrayForSort = [...data];

    arrayForSort.sort((a, b) => {
      let x = a.atc;
      let y = b.atc;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var resultATC = arrayForSort.reduce((x, y) => {
      (x[y.atc] = x[y.atc] || []).push(y);
      return x;
    }, {});

    let newOBJ = [];

    Object.keys(resultATC).map((resultSelected) => {
      let ATCListTemp = [];

      arrayForSort.map((Selected) => {
        if (resultSelected === Selected?.atc) {
          ATCListTemp.push(Selected);
        }

        return arrayForSort;
      });

      ATCListTemp.sort((a, b) => {
        let x = a.codice_gruppo_ospedaliero;
        let y = b.codice_gruppo_ospedaliero;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      newOBJ.push(ATCListTemp);

      return resultATC;
    });

    let finalOBJ = [];
    let finalOBJ2 = [];

    newOBJ.map((Selected) => {
      var resultATCComp = Selected.reduce((x, y) => {
        (x[y.codice_gruppo_ospedaliero] =
          x[y.codice_gruppo_ospedaliero] || []).push(y);
        return x;
      }, {});

      Object.keys(resultATCComp).map((resultSelected) => {
        let ATCListTemp = [];

        Selected.map((SelectedBIS) => {
          if (resultSelected === SelectedBIS?.codice_gruppo_ospedaliero) {
            ATCListTemp.push(SelectedBIS);
          }

          return Selected;
        });

        ATCListTemp.sort((a, b) => {
          let x = a.prezzo_unitario;
          let y = b.prezzo_unitario;

          if (x < y) {
            return -1;
          } else if (x > y) {
            return 1;
          }
          return 0;
        });

        finalOBJ.push(ATCListTemp);

        return resultATCComp;
      });

      return finalOBJ;
    });

    let OBJtoExp = [];

    finalOBJ.map((Sel) => {
      Sel.map((SelBIS) => {
        OBJtoExp.push(SelBIS);

        return Sel;
      });

      return finalOBJ;
    });

    OBJtoExp.forEach((row) => {
      table1.push({
        A: row?.descrizione_atc,
        B: row?.atc,
        C: row?.atc_complementare,
        D: row?.ragione_sociale,
        E: row?.descrizione_gruppo_ospedaliero,
        F: row?.principio_attivo,
        G: row?.aic,
        H: row?.nome_commerciale,
        I: row?.prezzo_unitario,
        J: row?.numero_unita_confezione,
        K: row?.prezzo_pub_confezione,
        L: row?.numero_protocollo,
        M: visualizzaData(row?.date[0].split("T")[0]),
        N: visualizzaData(row?.date[1].split("T")[0]),
        O: row?.ordine_min,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "ORDINATO PER ATC");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:O1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({});

        sheet.column("A").width(18);
        sheet.column("B").width(13).style({ horizontalAlignment: "center" });
        sheet.column("C").width(13).style({ horizontalAlignment: "center" });
        sheet.column("D").width(25);
        sheet.column("E").width(35);
        sheet.column("F").width(18);
        sheet.column("G").width(14).style({ horizontalAlignment: "center" });
        sheet.column("H").width(30);
        sheet.column("I").width(16);
        sheet.column("J").width(25);
        sheet.column("K").width(20);
        sheet.column("L").width(18).style({ horizontalAlignment: "center" });
        sheet.column("M").width(20).style({ horizontalAlignment: "center" });
        sheet.column("N").width(20).style({ horizontalAlignment: "center" });
        sheet.column("O").width(15).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP - ORDINATO PER ATC
      </LoadingButton>
    </>
  );
};

export default EXP_ATC_ORDERED;
