import React, { useState } from "react";
import "../App.css";
import { Routes, Route, useNavigate } from "react-router-dom";

import LayoutUser from "./Users/LayoutUser";
import LayoutLogin from "./Login/LayoutLogin";

//Componenti pagine
import Login from "./MainPages/Login";
import HomePageLogin from "./MainPages/HomePageLogin";
import HomePage from "./MainPages/HomePage";

//*Per ora link al contributo
import InfoPage from "./MainPages/InfoPage";
import InfoContributoPage from "./MainPages/InfoContributoPage";

//***ACQUISTATO***//
//*GESTIONE ACQUISTATI
import PurchasedMain from "../components/ACQUISTATO_NEW/GESTIONE_ACQUISTATI/PurchasedMain";

//*CALCOLO FABBISOGNO
import FabbMain from "../components/ACQUISTATO_NEW/CALCOLO_FABBISOGNO/FabbMain";

//*BECNHMARKING ACQUISTATO
import PurchasedBenchmarking from "../components/ACQUISTATO_NEW/BENCHMARKING_ACQ/PurchasedBenchmarking";

//*FILE F INSERIMENTO
import PurchasedFileF from "../components/ACQUISTATO_NEW/FILE_F_INSERIMENTO/PurchasedFileF";

//OFFER
import OfferInsertMain from "../components/OFFERTE/OfferInsertMain";
import OffersListMain from "../components/LISTINO/OffersListMain";
import OffersAnalysis from "../components/OFFERTE/OffersAnalysis";

//ABB
import AbbinatoMain from "../components/ENRICO/ABBINATO/AbbinatoMain";

import IpotesiRisp from "../components/ENRICO/IpotesiRisp";
import EnricoFinal from "../components/ENRICO/EnricoFinal";
import FILEF_TEMP from "../components/ENRICO/FILEF_TEMP";

//*ABB NEW
import AbbinatoMainNEW from "../components/ENRICO_NEW/ABBINAMENTO_NEW/AbbinamentoMainNEW";

//SCADENZE
// import ScadenzeBrevettualiImpatto from "../components/SCADENZE_BREV/ScadenzeBrevettualiImpatto";
// import ScadenzeBrevettualiLista from "../components/SCADENZE_BREV/ScadenzeBrevettualiLista";

// import GPImport from "../components/GARE_PUB/GPImport";

const MenuBar = () => {
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  const changeUserState = (newState) => {
    setUser(newState);
  };

  const handleLogout = async () => {
    localStorage.removeItem("loggedUser");
    setUser(null);
    navigate("/");
  };
  return (
    <>
      <Routes>
        {user !== null ? (
          <>
            <Route
              path="/"
              element={<LayoutUser user={user} handleLogout={handleLogout} />}
            >
              <Route index element={<HomePage user={user} />} />

              <Route path="info" element={<InfoPage user={user} />} />

              {/* ACQUISTATO */}
              <Route
                path="acquistato"
                element={<PurchasedMain user={user} />}
              />
              {/* FABBISOGNO */}
              <Route
                path="acquistato-fabbisogno"
                element={<FabbMain user={user} />}
              />
              <Route
                path="acquistato-benchmarking"
                element={<PurchasedBenchmarking user={user} />}
              />

              <Route
                path="acquistato-file-f"
                element={<PurchasedFileF user={user} />}
              />
              {/* OFFERTE */}
              <Route
                path="offerta-inserimento-nuovo"
                element={<OfferInsertMain user={user} />}
              />
              <Route
                path="offerte-visualizza-nuovo"
                element={<OffersListMain user={user} />}
              />
              <Route
                path="offerte-controllo-nuovo"
                element={<OffersAnalysis user={user} />}
              />

              {/* ABBINATO */}
              <Route
                path="abbinamento-main"
                element={<AbbinatoMain user={user} />}
              />
              {/* ENRICO */}
              <Route
                path="ipotesi-risparmio"
                element={<IpotesiRisp user={user} />}
              />
              <Route path="enrico" element={<EnricoFinal user={user} />} />

              <Route path="filef" element={<FILEF_TEMP user={user} />} />

              <Route
                path="abbinamento-main-new"
                element={<AbbinatoMainNEW user={user} />}
              />
              {/* SCADENZE BREVETTUALI */}
              {/* <Route
                path="lista-scadenze-brevettuali"
                element={<ScadenzeBrevettualiLista user={user} />}
              />
              <Route
                path="impatto-scadenze-brevettuali"
                element={<ScadenzeBrevettualiImpatto user={user} />}
              /> */}
              {/* <Route path="gare-pubbliche" element={<GPImport />} /> */}

              <Route path="*" element={<p>Not found!</p>} />
            </Route>
          </>
        ) : (
          <>
            <Route path="/" element={<LayoutLogin />}>
              <Route index element={<HomePageLogin user={user} />} />

              <Route path="info" element={<InfoContributoPage user={user} />} />

              <Route
                path="login"
                element={
                  <Login user={user} changeUserState={changeUserState} />
                }
              />

              <Route path="*" element={<p>Not found!</p>} />
            </Route>
          </>
        )}
      </Routes>
    </>
  );
};

export default MenuBar;
