import axios from "axios";

//se sviluppo
// const urlLogin = "http://localhost:3001/api/login";
//se produzione
const urlLogin = "/api/login";

const login = async (credentials) => {
  const res = await axios.post(urlLogin, credentials);
  return res.data;
};

// const changePsw = async (newPsw) => {
//   console.log("newPsw", newPsw);
//   // const res = await axios.post(urlLogin, newPsw);
//   // return res.data;
// };

const changePsw = async (newPsw, UserId) => {
  // console.log("newPsw", newPsw);
  // console.log("UserId", UserId);
  // const config = {
  //   headers: { Authorization: token },
  // };
  // let newUrl = urlEnrico + "/updateenrico/" + UserId;
  // const res = await axios.patch(newUrl, newPsw, config);
  // return res;
};

const LoginService = {
  login,
  changePsw,
};

export default LoginService;
