import React from "react";
import "../../../App.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";

import ApartmentIcon from "@mui/icons-material/Apartment";

import BeatLoader from "react-spinners/BeatLoader";

const AbbinatoSelStrutture = ({
  loadingPurchasedList,
  purchasedList,
  Purchased_List_Selected,
  listaStrutture,
  loadingListaStrutture,
  struttureSelected,
  handlePurchasedSelection,
  handleCreaListaStrutture,
  handleStruttureChange,
  setFlagConfermaStrutture,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          paddingTop: "3%",
          display: "inline-block",
          width: "50%",
        }}
      >
        {loadingPurchasedList ? (
          <div>
            <BeatLoader
              color={"#1976d2"}
              loading={loadingPurchasedList}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <h6
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
              }}
            >
              CARICAMENTO...
            </h6>
          </div>
        ) : (
          <>
            <p
              style={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 25,
              }}
            >
              LISTA ACQUISTATI
            </p>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={purchasedList}
              getOptionLabel={(option) => option.nome_salvataggio}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "2%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="ACQUISTATI" />
              )}
              value={Purchased_List_Selected}
              onChange={(e, newValue) => {
                handlePurchasedSelection(newValue);
              }}
            />
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="bluetest"
                disabled={Purchased_List_Selected === null}
                startIcon={<ApartmentIcon />}
                onClick={() =>
                  handleCreaListaStrutture(Purchased_List_Selected)
                }
              >
                SELEZIONA STRUTTURA
              </Button>
            </div>
            {loadingListaStrutture && (
              <div>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingListaStrutture}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6
                  style={{
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  CARICAMENTO...
                </h6>
              </div>
            )}
            {listaStrutture !== null && (
              <>
                <p
                  style={{
                    fontFamily: "Calibri",
                    fontWeight: "bold",
                    color: "#1976d2",
                    fontSize: 20,
                  }}
                >
                  SELEZIONA UNA O PIU STRTUTTURE
                </p>

                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-multiple"
                  options={listaStrutture ?? []}
                  getOptionLabel={(option) => option?.nome_struttura || ""}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  sx={{
                    width: "100%",
                    paddingTop: "2%",
                    paddingLeft: "20%",
                    paddingRight: "20%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="STRUTTURE" />
                  )}
                  value={struttureSelected || []}
                  onChange={handleStruttureChange}
                />
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "5%",
                    marginBottom: "5%",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    color="success"
                    disabled={
                      struttureSelected === null ||
                      struttureSelected.length === 0
                    }
                    //   startIcon={<CheckOutlinedIcon />}
                    onClick={() => setFlagConfermaStrutture(true)}
                  >
                    CONFERMA STRUTTURE
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AbbinatoSelStrutture;
