import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";

// import { DataGridPro } from "@mui/x-data-grid-pro";
// import Box from "@mui/material/Box";

// import { ABB_SUPP_RESUME_COLUMNS } from "../../colums/ABB_COLUMNS";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SUPP_RESUME_POPUP = (props) => {
  const {
    popUpChangeSelectionOpen,
    handlePopUpChangeSelectionClose,
    handleDoubleClickSpecSelection,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          <Dialog
            open={popUpChangeSelectionOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={handlePopUpChangeSelectionClose}
            PaperProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Calibri",
                  fontSize: 25,
                  textAlign: "center",
                }}
              >
                Confermi di voler cambiare selezione?
              </DialogContentText>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "1%",
                  paddingRight: "0.5%",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  onClick={handlePopUpChangeSelectionClose}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "1%",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleDoubleClickSpecSelection}
                >
                  CONFERMA
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default SUPP_RESUME_POPUP;
