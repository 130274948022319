import React from "react";
import "../../../App.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";

// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import AddchartIcon from "@mui/icons-material/Addchart";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";

import BeatLoader from "react-spinners/BeatLoader";

const AbbinatoViewResume = ({
  loadingAbbinatiList,
  abbinatiList,
  Abb_List_Selected,
  setAbb_List_Selected,
  handleDownloadAbbinato,
  loadingABBValutation,
}) => {
  return (
    <>
      {loadingAbbinatiList ? (
        <div>
          <BeatLoader
            color={"#1976d2"}
            loading={loadingAbbinatiList}
            size={40}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <h6
            style={{
              fontFamily: "Calibri",
              color: "#1976d2",
            }}
          >
            CARICAMENTO...
          </h6>
        </div>
      ) : (
        <>
          <p
            style={{
              fontFamily: "Calibri",
              fontWeight: "bold",
              color: "#1976d2",
              fontSize: 25,
              marginTop: "5%",
            }}
          >
            ABBINATI SALVATI
          </p>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={abbinatiList}
            getOptionLabel={(option) => option.nome_abbinato}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{
              width: "100%",
              marginTop: "2%",
              paddingLeft: "30%",
              paddingRight: "30%",
            }}
            renderInput={(params) => <TextField {...params} label="ABBINATI" />}
            value={Abb_List_Selected}
            onChange={(e, newValue) => {
              setAbb_List_Selected(newValue);
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginTop: "5%",
              marginBottom: "5%",
            }}
          >
            <Button
              variant="contained"
              component="label"
              color="bluetest"
              disabled={Abb_List_Selected === null}
              startIcon={<BrowserUpdatedIcon />}
              onClick={() => handleDownloadAbbinato()}
            >
              CARICA ABBINATO
            </Button>
          </div>
          {loadingABBValutation && (
            <div>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingABBValutation}
                size={40}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6
                style={{
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                CARICAMENTO...
              </h6>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AbbinatoViewResume;
