export const ButtonContainer = ({ children }) => (
  <div style={{ paddingTop: "3%" }}>{children}</div>
);
export const LoaderContainer = ({ children }) => (
  <div style={{ paddingTop: "2%" }}>{children}</div>
);
export const ErrorContainer = ({ children }) => (
  <div style={{ color: "#ff0000", textAlign: "center", paddingTop: "2%" }}>
    <b>{children}</b>
  </div>
);
