import React, { useState, useEffect } from "react";
import "../../../App.css";

import OffersListForm from "./OffersListForm";
import OffersListImport from "./OffersListImport";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import offersResumeService from "../../../services/offers_resume";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { OFF_RESUME_MAIN_COLUMNS } from "../../../colums/OFF_LIST_COLUMNS";

import OFF_LIST_SAVE_CONFIRM_POPUP from "../../../popup/OFF_LIST_POPUPS/OFF_LIST_SAVE_CONFIRM_POPUP";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersListInsert = ({ user, setChangePageFlag }) => {
  const [Dates, setDates] = useState([]);
  const [DatesFlag, setDatesFlag] = useState(false);

  const [errors, setErrors] = useState({});
  const [fileSavedName, setFileSavedName] = useState("");

  const [savePopUpOpen, setSavePopUpOpen] = useState(false);

  const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const [salvaOffersresume_ERR, setSalvaOffersresume_ERR] = useState(false);

  const updateDates = (newFileData) => {
    setDates(newFileData);
  };

  const checkedDatesFlag = (flag) => {
    setDatesFlag(flag);
  };

  const handleChangeDates = () => {
    setDatesFlag(false);
  };

  const [fileData, setFileData] = useState([]);
  const [fileDataSaved, setFileDataSaved] = useState(false);

  useEffect(() => {}, []);

  const handleSavePopUpClose = () => {
    setSavePopUpOpen(false);
    setLoadingFinalSave(false);
    setFileSavedName("");
  };

  const handleUpdateFileData = (newOfferData) => {
    newOfferData.map((Selected) => {
      Selected.date = Dates;
      return newOfferData;
    });

    setFileData(newOfferData);
  };

  const insertedFile = (flag) => {
    setFileDataSaved(flag);
  };

  const raggruppaPerCampo = (oggetti, path) =>
    Object.values(
      oggetti.reduce((acc, oggetto) => {
        // Accedere a un sotto-campo annidato
        const keys = path.split(".");
        const key = keys.reduce((o, k) => (o || {})[k], oggetto);
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(oggetto);
        return acc;
      }, {})
    );

  const handleFinalSavePurchased = async () => {
    const formErrors = ValidateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setLoadingFinalSave(true);
      setErrors({});

      let DATA_TEMP = [...fileData];

      const oggettiRaggruppati = await raggruppaPerCampo(
        DATA_TEMP,
        "fornitore.ragione_sociale"
      );

      let final_OBJ = [];

      await oggettiRaggruppati.map((SEL) => {
        let off_TEMP = [];

        SEL.map((SEL_BIS) => {
          let temp = {
            //Farmadati
            aic: SEL_BIS?.aic,
            nome_commerciale: SEL_BIS?.nome_commerciale,
            principio_attivo: SEL_BIS?.principio_attivo,
            atc: SEL_BIS?.atc,
            atc_complementare: SEL_BIS?.atc_complementare,
            descrizione_atc: SEL_BIS?.descrizione_atc,
            codice_gruppo_ospedaliero: SEL_BIS?.codice_gruppo_ospedaliero,
            descrizione_gruppo_ospedaliero:
              SEL_BIS?.descrizione_gruppo_ospedaliero,
            iva: SEL_BIS?.iva,
            forma_farmaceutica: SEL_BIS?.forma_farmaceutica,
            dosaggio: SEL_BIS?.dosaggio,
            fascia: SEL_BIS?.fascia,
            //Dal file
            prezzo_unitario: SEL_BIS?.prezzo_unitario,
            prezzo_pub_confezione: SEL_BIS?.prezzo_pub_confezione,
            numero_unita_confezione: SEL_BIS?.numero_unita_confezione,
            sconto_precentuale_prezzo_pub: SEL_BIS?.sconto_precentuale,
            prezzo_pub_unitario: SEL_BIS?.prezzo_pub_unitario,
            prezzo_netto_exfactory_confezione:
              SEL_BIS?.prezzo_netto_exfactory_confezione,
            prezzo_netto_exfactory_unitario:
              SEL_BIS?.prezzo_netto_exfactory_unitario,
            date: SEL_BIS?.date,
            numero_protocollo: SEL_BIS?.numero_protocollo,
            data_protocollo: dayjs()
              .utc()
              .local()
              .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            //Flags
            filef_flag: false,
            is_checked: false,
          };

          off_TEMP.push(temp);

          return SEL;
        });

        let temp_off = {
          ragione_sociale: SEL[0].fornitore.ragione_sociale,
          cf_piva: SEL[0].fornitore.cf_piva,
          data_inserimento: dayjs()
            .utc()
            .local()
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
          ordine_min: 0,
          offerta_obj: off_TEMP,
        };

        final_OBJ.push(temp_off);

        return oggettiRaggruppati;
      });

      const annoACQ = Dates[1].substring(0, 4);

      const finalFileData = {
        nome_listino: fileSavedName,
        anno: annoACQ,
        data_creazione: dayjs()
          .utc()
          .local()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        offerte_inserite: final_OBJ,
      };

      await offersResumeService.setToken(user.token);

      await offersResumeService.salvaOffersResume(finalFileData).then((res) => {
        if (res === "error") {
          setSalvaOffersresume_ERR(true);
          setLoadingFinalSave(false);
        } else {
          setSalvaOffersresume_ERR(false);
          handleSavePopUpClose();
          handleRemove();
        }
      });
    }
  };

  const handleRemove = () => {
    setFileData([]);
    insertedFile(false);
    setFileSavedName("");
    setFileDataSaved(false);
  };

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const ValidateForm = () => {
    const fileSavedNameCheck = fileSavedName;
    const newErrors = {};

    if (!fileSavedNameCheck || fileSavedNameCheck === "") {
      newErrors.fileSavedName = "Inserire il nome per il Database";
    }

    return newErrors;
  };

  return (
    <>
      {!fileDataSaved && !DatesFlag && (
        <div style={{ paddingTop: "5%" }}>
          <OffersListForm
            updateDates={updateDates}
            checkedDatesFlag={checkedDatesFlag}
          />
        </div>
      )}
      {!fileDataSaved && DatesFlag && (
        <>
          <OffersListImport
            handleUpdateFileData={handleUpdateFileData}
            insertedFile={insertedFile}
            dates={Dates}
            handleChangeDates={handleChangeDates}
          />
        </>
      )}
      {fileDataSaved && (
        <>
          <ThemeProvider theme={theme}>
            {/* <div className="acqPage"> */}
            <div
              style={{
                textAlign: "left",
                display: "inline-block",
                width: "13%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="redtest"
                startIcon={<DeleteOutlinedIcon />}
                onClick={handleRemove}
              >
                RIMUOVI IL FILE
              </Button>
            </div>
            <div
              style={{
                display: "inline-block",
                width: "52%",
              }}
            ></div>

            <div
              style={{
                textAlign: "right",
                display: "inline-block",
                width: "15%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => setSavePopUpOpen(true)}
              >
                SALVA LISTINO
              </Button>
            </div>

            <Box
              sx={{
                height: "417px",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "3%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                },
                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "17px",
                  border: "1px solid",
                },
              }}
            >
              <div
                style={{
                  textAlign: "left",

                  display: "inline-block",
                  width: "50%",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    color: "#1976d2",
                    fontFamily: "Calibri",
                    fontSize: "18px",
                  }}
                >
                  PERIODO SELEZIONATO:{" "}
                  <b>
                    {visualizzaData(Dates[0].split("T")[0])} -{" "}
                    {visualizzaData(Dates[1].split("T")[0])}{" "}
                  </b>
                </p>
              </div>
              <h3
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                TOT ELEMENTI: {fileData.length}
              </h3>

              <DataGridPro
                columns={OFF_RESUME_MAIN_COLUMNS}
                rows={fileData}
                getRowId={(row) => row.id_acquistato_temp}
                headerHeight={40}
                rowHeight={33}
                disableColumnMenu
                hideFooter
              />
            </Box>
            <OFF_LIST_SAVE_CONFIRM_POPUP
              savePopUpOpen={savePopUpOpen}
              handleSavePopUpClose={handleSavePopUpClose}
              handleFinalSavePurchased={handleFinalSavePurchased}
              errors={errors}
              fileSavedName={fileSavedName}
              setFileSavedName={setFileSavedName}
              loadingFinalSave={loadingFinalSave}
              salvaOffersresume_ERR={salvaOffersresume_ERR}
            />
          </ThemeProvider>
        </>
      )}
    </>
  );
};

export default OffersListInsert;
