import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_RESUME_POPUP = (props) => {
  const {
    formConfirmOpen,
    handleFormConfirmClose,
    offerDataTEMP,
    handleConfirmSubmit,
  } = props;

  const commonStyles = {
    fontFamily: "Calibri",
    textAlign: "center",
  };

  const dataInfoStyle = {
    ...commonStyles,
    color: "#1976d2",
    fontSize: 24,
  };

  const confirmationStyle = {
    ...commonStyles,
    fontWeight: "bold",
    fontSize: 28,
  };

  // console.log("offerDataTEMP.pdfFile", offerDataTEMP?.pdfFile);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={formConfirmOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={handleFormConfirmClose}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 32,
                textAlign: "center",
              }}
            >
              DATI INSERITI:
            </DialogTitle>
            <DialogContent>
              <DialogContentText sx={dataInfoStyle}>
                {offerDataTEMP && (
                  <>
                    FORNITORE:{" "}
                    <b>
                      {offerDataTEMP.supplier.ragione_sociale}{" "}
                      {offerDataTEMP.supplier.cf_piva}
                    </b>
                    <br />
                    DATA PROTOCOLLO:{" "}
                    <b>
                      {visualizzaData(offerDataTEMP.protocolDate.split("T")[0])}
                    </b>
                    <br />
                    PERIODO VALIDITA':{" "}
                    <b>
                      {visualizzaData(offerDataTEMP.firstDate.split("T")[0])} -{" "}
                      {visualizzaData(offerDataTEMP.lastDate.split("T")[0])}
                    </b>
                    <br />
                    NUMERO PROTOCOLLO: <b>{offerDataTEMP.protocolNumber}</b>
                    <br />
                    ORDINE MINIMO: <b>{offerDataTEMP.minOrder.toFixed(2)} €</b>
                    <br />
                    {/* PDF INSERITO:{" "}
                    <b>{offerDataTEMP?.pdfFile !== null ? "SI" : "NO"}</b>
                    <br /> */}
                  </>
                )}

                <br />
                <div style={confirmationStyle}>CONFERMI I DATI?</div>
              </DialogContentText>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="redtest"
                  startIcon={<DeleteOutlinedIcon />}
                  onClick={handleFormConfirmClose}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "6%",
                }}
              ></div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="success"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={handleConfirmSubmit}
                >
                  CONFERMA
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFFER_RESUME_POPUP;
