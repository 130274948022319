import axios from "axios";

//se sviluppo
// const urlOffersResume = "http://localhost:3001/api/offersresume";
//se produzione
const urlOffersResume = "/api/offersresume";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

//Ricezione
const getOfferaResumeByUserId = (userId) => {
  let newUrl = urlOffersResume + "/user/" + userId;
  return axios.get(newUrl);
};

const getOffersResumetByOffersResumetId = (offersResumeId) => {
  let newUrl = urlOffersResume + "/singleoffersresume/" + offersResumeId;
  return axios.get(newUrl);
};

// const getOfferList = () => {
//   return axios.get(urlOffersResume);
// };

//Invio
const salvaOffersResume = async (newOffersResume) => {
  try {
    const config = {
      headers: { Authorization: token },
    };
    const response = await axios.post(urlOffersResume, newOffersResume, config);

    return response;
  } catch (error) {
    return "error";
  }
};

const offersResumeService = {
  getOfferaResumeByUserId,
  getOffersResumetByOffersResumetId,
  salvaOffersResume,
  setToken,
};

export default offersResumeService;
