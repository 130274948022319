export const menuItemsHome_OBJ = [
  {
    title: "HOME",
    url: "/",
  },
  {
    title: "LOGIN",
    url: "/login",
  },
];
