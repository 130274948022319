import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_ABB_CONFRONTO_GP = ({ data }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "TABELLA IMPATTO GARA PUB - " + data.nome_abbinato + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  // const createDownLoadData = () => {
  //   handleExport().then((url) => {
  //     const downloadAnchorNode = document.createElement("a");
  //     downloadAnchorNode.setAttribute("href", url);
  //     downloadAnchorNode.setAttribute(
  //       "download",
  //       "TABELLA IMPATTO GARA PUB - " + data.nome_abbinato + ".xlsx"
  //     );
  //     downloadAnchorNode.click();
  //     downloadAnchorNode.remove();
  //   });
  // };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "PRINC. ATTIVO",
        B: "TIPOLOGIA",
        C: "AIC GARA PUB",
        D: "NOME COMM. PUB",
        E: "FORNITORE PUB",
        F: "REGIONE",
        G: "Q.",
        H: "PREZZO GARA PUB",
        I: "SPESA GARA PUB",
        J: "DIFF. SPESA GARA PUB SU SELEZIONATO",
        K: "AIC  ASSEGN. 24",
        L: "NOME COMM. ASSEGN. 24",
        M: "FORNITORE ASSEGN. 24",
        N: "PREZZO GARA ASSEGN. 24",
        O: "SPESA GARA ASSEGN. 24",
        P: "AIC ACQ. 23",
        Q: "NOME COMM. ACQ. 23",
        R: "FORNITORE ACQ. 23",
        S: "PREZZO ACQ. 23",
        T: "SPESA ACQ. 23",
        U: "QUANTITA'",
      },
    ];

    let table2 = [
      {
        A: "PRINC. ATTIVO",
        B: "TIPOLOGIA",
        C: "AIC ASSEGN. 24",
        D: "NOME COMM. ASSEGN. 24",
        E: "FORNITORE ASSEGN. 24",
        F: "PREZZO GARA ASSEGN. 24",
        G: "SPESA GARA ASSEGN. 24",
        H: "AIC ACQ. 23",
        I: "NOME COMM. ACQ. 23",
        J: "FORNITORE ACQ. 23",
        K: "PREZZO ACQ. 23",
        L: "SPESA ACQ. 23",
        M: "QUANTITA'",
        N: "ATC COMP.",
      },
    ];

    let countRisp = 0;
    let countNeutral = 0;

    let tableTEMP = [];

    console.log("data.obj_file", data.obj_file);

    data.obj_file.forEach((row) => {
      if (row?.flag_match) {
        if (row?.garePubList?.length > 0) {
          let diffCosto = calcoloDifferenzaCosto(
            calcoloCosto(row?.spec_checked.prezzo_unitario, row?.quantita),
            calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita)
          );

          tableTEMP.push({
            A: row?.principio_attivo,
            B: row?.descrizione_gruppo_ospedaliero,
            C: row?.garePubList[0].aic,
            D: row?.garePubList[0].nome_commerciale,
            E: row?.garePubList[0].ragione_sociale,
            F: row?.garePubList[0].regione,
            G: row?.quantita,
            H: row?.garePubList[0].prezzo_unitario,
            I: calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita),
            J: diffCosto,
            K: row?.spec_checked.aic,
            L: row?.spec_checked.nome_commerciale,
            M: row?.spec_checked.ragione_sociale,
            N: row?.spec_checked.prezzo_unitario,
            O: calcoloCosto(row?.spec_checked.prezzo_unitario, row?.quantita),
            P: row?.aic,
            Q: row?.nome_commerciale,
            R: row?.fornitore.ragione_sociale,
            S: row?.prezzo_unitario,
            T: row?.spec_checked.costo_acquistato,
            U: row?.quantita,
          });
        } else {
          table2.push({
            A: row?.principio_attivo,
            B: row?.descrizione_gruppo_ospedaliero,
            C: row?.spec_checked.aic,
            D: row?.spec_checked.nome_commerciale,
            E: row?.spec_checked.ragione_sociale,

            F: row?.spec_checked.prezzo_unitario,
            G: calcoloCosto(
              row?.spec_checked.prezzo_unitario,
              row?.spec_checked.quantita
            ),
            H: row?.aic,
            I: row?.nome_commerciale,
            J: row?.fornitore.ragione_sociale,

            K: row?.prezzo_unitario,
            L: row?.spec_checked.costo_acquistato,
            M: row?.quantita,
            N: row?.atc_complementare,
          });
        }
      }
    });

    await tableTEMP.sort((a, b) => {
      let x = a.J;
      let y = b.J;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    tableTEMP.forEach((row) => {
      if (row.J < 0) {
        countRisp = countRisp + 1;
      } else if (row.J === 0) {
        countNeutral = countNeutral + 1;
      }
    });

    table1 = table1.concat(tableTEMP);
    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "CON GARE PUB");
    XLSX.utils.book_append_sheet(wb, sheet2, "SENZA GARE PUB");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:U1`,
      theadRange2: `A1:N1`,

      rispRange: `J2:J${countRisp + 1}`,
      neutralRange: `J${countRisp + 2}:J${countRisp + countNeutral + 1}`,
      spesaRange: `J${countRisp + countNeutral + 2}:J${tableTEMP.length + 1}`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;
      workbook.sheets("CON GARE PUB").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(35);
          sheet.column("B").width(50);
          sheet.column("C").width(16).style({ horizontalAlignment: "center" });
          sheet.column("D").width(30);
          sheet.column("E").width(28);
          sheet.column("F").width(18);
          sheet.column("G").width(14).style({ horizontalAlignment: "center" });
          sheet.column("H").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("I")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet
            .column("J")
            .width(35)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("K").width(20).style({ horizontalAlignment: "center" });
          sheet.column("L").width(30);
          sheet.column("M").width(28);

          sheet.column("N").width(23).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("O")
            .width(21)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("P").width(17).style({ horizontalAlignment: "center" });
          sheet.column("Q").width(30);
          sheet.column("R").width(28);

          sheet.column("S").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("T")
            .width(21)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("U").width(17).style({ horizontalAlignment: "center" });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }

          if (dataInfo.rispRange) {
            sheet.range(dataInfo.rispRange).style({
              fill: "c6efce",
              fontColor: "006100",
              bold: false,
              border: true,
            });
          }
          if (dataInfo.neutralRange) {
            sheet.range(dataInfo.neutralRange).style({
              bold: false,
              border: true,
            });
          }
          if (dataInfo.spesaRange) {
            sheet.range(dataInfo.spesaRange).style({
              fill: "ffc7ce",
              fontColor: "9c0006",
              bold: false,
              border: true,
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(28);
          sheet.column("B").width(50);
          sheet.column("C").width(20).style({ horizontalAlignment: "center" });
          sheet.column("D").width(28);
          sheet.column("E").width(25);

          sheet.column("F").width(22).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("G")
            .width(20)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("H").width(17).style({ horizontalAlignment: "center" });
          sheet.column("I").width(28);
          sheet.column("J").width(25);

          sheet.column("K").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("L")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("M").width(17).style({ horizontalAlignment: "center" });
          sheet.column("N").width(16).style({ horizontalAlignment: "center" });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        }
        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP CONFRONTO GARE PUB
      </LoadingButton>
    </>
  );
};

export default EXP_ABB_CONFRONTO_GP;
