import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import Button from "@mui/material/Button";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import BeatLoader from "react-spinners/BeatLoader";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FILEF_SAVE_CONFIRM_POPUP = ({
  savePopUpOpen,
  handleSavePopUpClose,
  loadingFinalSave,
  handleConfirmSave,
}) => {
  return (
    <>
      <Dialog
        open={savePopUpOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="lg"
        keepMounted
        onClose={handleSavePopUpClose}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{
              fontFamily: "Calibri",
              fontSize: 20,
              paddingBottom: "2%",
            }}
          >
            <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
              CONFERMI DI VOLER SALVARE IL FILE F INSERITO?
            </div>
          </DialogContentText>
          <Button
            variant="contained"
            component="label"
            color="success"
            startIcon={<CheckOutlinedIcon />}
            onClick={() => handleConfirmSave()}
          >
            CONFERMA
          </Button>
          {loadingFinalSave && (
            <div style={{ textAlign: "center", paddingTop: "1%" }}>
              <BeatLoader
                color={"#1976d2"}
                loading={loadingFinalSave}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                CARICAMENTO...
              </h6>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
