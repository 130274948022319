import garepubService from "../../services/garapub";
import offersResumeService from "../../services/offers_resume";

import {
  roundTwo,
  calcoloCosto,
  calcoloDifferenzaCosto,
  calcoloPercentuale,
  calcoloDifferenzaPerc,
} from "../FUN_CALCOLI";

//*Funzioni EXTRA

//*1
const handleCreaACQStruttureSelezionate = (ACQ_TEMP, struttureSelected) => {
  const nomiStrutture = new Set(
    struttureSelected.map((item) => item.nome_struttura)
  );

  return ACQ_TEMP.acquistato_inserito.filter((obj) =>
    nomiStrutture.has(obj.struttura)
  );
};

//*2
const createOffersResumeView = (offersResumeTEMP) => {
  const finalOBJ = [];
  let id_count = 0;

  offersResumeTEMP.offerte_inserite.forEach((OFF_SEL) => {
    OFF_SEL.offerta_obj.forEach((SINGLE_OFF_SEL) => {
      const temp = {
        id_temp: id_count++,
        is_checked: SINGLE_OFF_SEL?.is_checked ?? null,

        ragione_sociale: OFF_SEL?.ragione_sociale ?? null,
        cf_piva: OFF_SEL?.cf_piva ?? null,
        ordine_min: OFF_SEL?.ordine_min ?? null,
        pdf_offerta_id: OFF_SEL?.pdf_offerta_id ?? null,

        date: SINGLE_OFF_SEL?.date ?? null,
        data_protocollo: SINGLE_OFF_SEL?.data_protocollo ?? null,
        data_inserimento: OFF_SEL?.data_inserimento ?? null,

        aic: SINGLE_OFF_SEL?.aic ?? null,
        nome_commerciale: SINGLE_OFF_SEL?.nome_commerciale ?? null,
        numero_protocollo: SINGLE_OFF_SEL?.numero_protocollo ?? null,
        atc: SINGLE_OFF_SEL?.atc ?? null,
        atc_complementare: SINGLE_OFF_SEL?.atc_complementare ?? null,
        descrizione_atc: SINGLE_OFF_SEL?.descrizione_atc ?? null,
        principio_attivo: SINGLE_OFF_SEL?.principio_attivo ?? null,
        codice_gruppo_ospedaliero:
          SINGLE_OFF_SEL?.codice_gruppo_ospedaliero ?? null,
        descrizione_gruppo_ospedaliero:
          SINGLE_OFF_SEL?.descrizione_gruppo_ospedaliero ?? null,

        prezzo_unitario: SINGLE_OFF_SEL?.prezzo_unitario ?? null,

        dosaggio: SINGLE_OFF_SEL?.dosaggio ?? null,
        fascia: SINGLE_OFF_SEL?.fascia ?? null,
        forma_farmaceutica: SINGLE_OFF_SEL?.forma_farmaceutica ?? null,
        iva: SINGLE_OFF_SEL?.iva ?? null,
        numero_unita_confezione:
          SINGLE_OFF_SEL?.numero_unita_confezione ?? null,
        prezzo_netto_exfactory_confezione:
          SINGLE_OFF_SEL?.prezzo_netto_exfactory_confezione ?? null,
        prezzo_netto_exfactory_unitario:
          SINGLE_OFF_SEL?.prezzo_netto_exfactory_unitario ?? null,
        prezzo_pub_confezione: SINGLE_OFF_SEL?.prezzo_pub_confezione ?? null,
        prezzo_pub_unitario: SINGLE_OFF_SEL?.prezzo_pub_unitario ?? null,
        sconto_precentuale_prezzo_pub:
          SINGLE_OFF_SEL?.sconto_precentuale_prezzo_pub ?? null,
        filef_flag: SINGLE_OFF_SEL?.filef_flag ?? null,
      };

      finalOBJ.push(temp);
    });
  });

  return finalOBJ;
};

//*3.1
const sortByPrice = (a, b) => a.prezzo_unitario - b.prezzo_unitario;

//*3.2
const handleBenchmarkingVAL = (array) => {
  const OFFERS_TEMP = [...array];

  const SEL_GareSI = [];
  const SEL_GareNO = [];

  OFFERS_TEMP.forEach((selected) => {
    if (selected.flag_match) {
      (selected.garePubList && selected.garePubList.length > 0
        ? SEL_GareSI
        : SEL_GareNO
      ).push(selected);
    }
  });

  const GP_MIN_array = [];
  const GP_MAG_array = [];
  const GP_EQ_array = [];

  let GP_MIN_count = 0,
    GP_MAG_count = 0,
    GP_EQ_count = 0;

  let GP_MIN_spesaGP = 0,
    GP_MAG_spesaGP = 0,
    GP_EQ_spesaGP = 0;

  let GP_MIN_spesaABB = 0,
    GP_MAG_spesaABB = 0,
    GP_EQ_spesaABB = 0;

  SEL_GareSI.forEach((sel_si) => {
    const spesaGP_TEMP = calcoloCosto(
      sel_si?.garePubList[0]?.prezzo_unitario,
      sel_si?.quantita
    );

    const spesaABB_TEMP = calcoloCosto(
      sel_si?.spec_checked?.prezzo_unitario,
      sel_si?.quantita
    );

    const diffSpesa_TEMP = calcoloDifferenzaCosto(spesaABB_TEMP, spesaGP_TEMP);

    if (diffSpesa_TEMP > 0) {
      GP_MAG_count++;
      GP_MAG_spesaGP += spesaGP_TEMP;
      GP_MAG_spesaABB += spesaABB_TEMP;
      GP_MAG_array.push(sel_si);
    } else if (diffSpesa_TEMP === 0) {
      GP_EQ_count++;
      GP_EQ_spesaGP += spesaGP_TEMP;
      GP_EQ_spesaABB += spesaABB_TEMP;
      GP_EQ_array.push(sel_si);
    } else {
      GP_MIN_count++;
      GP_MIN_spesaGP += spesaGP_TEMP;
      GP_MIN_spesaABB += spesaABB_TEMP;
      GP_MIN_array.push(sel_si);
    }
  });

  let spesaABB_TEMP = SEL_GareNO.reduce(
    (acc, sel_no) =>
      acc +
      calcoloCosto(sel_no?.spec_checked?.prezzo_unitario, sel_no?.quantita),
    0
  );

  const TOT_SPESA_GP = GP_MIN_spesaGP + GP_MAG_spesaGP + GP_EQ_spesaGP;
  const TOT_SPESA_ABB = GP_MIN_spesaABB + GP_MAG_spesaABB + GP_EQ_spesaABB;

  const VAL_OBJ_TEMP = [
    {
      title: "PREZZI PIU ALTI DELLE GARE PUBBLICHE",
      n_molecole: GP_MIN_count,
      spesaABB: roundTwo(GP_MIN_spesaABB),
      perc_spesaABB: calcoloPercentuale(
        GP_MIN_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      spesaGarePub: roundTwo(GP_MIN_spesaGP),
      diff_spesa: roundTwo(GP_MIN_spesaGP - GP_MIN_spesaABB),
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MIN_spesaABB,
        GP_MIN_spesaGP - GP_MIN_spesaABB
      ),
      array_gare: GP_MIN_array,
    },
    {
      title: "PREZZI PIU BASSI DELLE GARE PUBBLICHE",
      n_molecole: GP_MAG_count,
      spesaABB: roundTwo(GP_MAG_spesaABB),
      perc_spesaABB: calcoloPercentuale(
        GP_MAG_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      spesaGarePub: roundTwo(GP_MAG_spesaGP),
      diff_spesa: roundTwo(GP_MAG_spesaGP - GP_MAG_spesaABB),
      perc_diff_spesa: calcoloDifferenzaPerc(
        GP_MAG_spesaABB,
        GP_MAG_spesaGP - GP_MAG_spesaABB
      ),
      array_gare: GP_MAG_array,
    },
    {
      title: "PREZZI UGUALI ALLE GARE PUBBLICHE",
      n_molecole: GP_EQ_count,
      spesaABB: roundTwo(GP_EQ_spesaABB),
      perc_spesaABB: calcoloPercentuale(
        GP_EQ_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      spesaGarePub: roundTwo(GP_EQ_spesaGP),
      diff_spesa: 0,
      perc_diff_spesa: 0,
      array_gare: GP_EQ_array,
    },
    {
      title: "TOTALE MOLECOLE INCROCIATE",
      n_molecole: SEL_GareSI.length,
      spesaABB: roundTwo(TOT_SPESA_ABB),
      perc_spesaABB: calcoloPercentuale(
        TOT_SPESA_ABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      spesaGarePub: roundTwo(TOT_SPESA_GP),
      diff_spesa: roundTwo(TOT_SPESA_GP - TOT_SPESA_ABB),
      perc_diff_spesa: calcoloDifferenzaPerc(
        TOT_SPESA_ABB,
        TOT_SPESA_GP - TOT_SPESA_ABB
      ),
      array_gare: [],
    },
    {
      title: "MOLECOLE NON INCROCIATE",
      n_molecole: SEL_GareNO.length,
      spesaABB: roundTwo(spesaABB_TEMP),
      perc_spesaABB: calcoloPercentuale(
        spesaABB_TEMP,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: [],
    },
    {
      title: "TOTALE MOLECOLE ANALIZZATE",
      n_molecole: SEL_GareSI.length + SEL_GareNO.length,
      spesaABB: roundTwo(spesaABB_TEMP + TOT_SPESA_ABB),
      perc_spesaABB: 100,
      spesaGarePub: "",
      diff_spesa: "",
      perc_diff_spesa: "",
      array_gare: [],
    },
  ];

  const VAL_PIE_TEMP = [
    {
      id: "gp_minori",
      label: "PREZZI PIU ALTI DELLE GARE PUBBLICHE",
      n_specialita: GP_MIN_count,
      perc_spesaABB: calcoloPercentuale(
        GP_MIN_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      value: roundTwo(GP_MIN_spesaABB),
    },
    {
      id: "gp_maggiori",
      label: "PREZZI PIU BASSI DELLE GARE PUBBLICHE",
      n_specialita: GP_MAG_count,
      perc_spesaABB: calcoloPercentuale(
        GP_MAG_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      value: roundTwo(GP_MAG_spesaABB),
    },
    {
      id: "gp_uguali",
      label: "PREZZI UGUALI ALLE GARE PUBBLICHE",
      n_specialita: GP_EQ_count,
      perc_spesaABB: calcoloPercentuale(
        GP_EQ_spesaABB,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      value: roundTwo(GP_EQ_spesaABB),
    },
    {
      id: "senza_gp",
      label: "MOLECOLE NON INCROCIATE",
      n_specialita: SEL_GareNO.length,
      perc_spesaABB: calcoloPercentuale(
        spesaABB_TEMP,
        spesaABB_TEMP + TOT_SPESA_ABB
      ),
      value: roundTwo(spesaABB_TEMP),
    },
  ];

  return {
    BENCH_TAB: VAL_OBJ_TEMP,
    BENCH_PIE: VAL_PIE_TEMP,
  };
};

//*3.3
const creaSezione = (
  title,
  nMolecole,
  costoAcq,
  costoMin,
  diffPercMin,
  costoSel,
  diffPercSel,
  costoGarePub,
  diffCostoGarePub,
  diffPercGarePub
) => {
  return {
    title: title,
    n_molecole: nMolecole,
    costo_acq: roundTwo(costoAcq),
    costo_minimo: roundTwo(costoMin),
    diff_costo_acq_minimo: roundTwo(costoMin - costoAcq),
    diff_perc_minimo: diffPercMin,
    costo_selezionato: roundTwo(costoSel),
    diff_costo_acq_selezionato: roundTwo(costoSel - costoAcq),
    diff_perc_selezionato: diffPercSel,
    costo_garepub: costoGarePub,
    diff_costo_acq_garepub: diffCostoGarePub,
    diff_perc_garepub: diffPercGarePub,
  };
};

//*3
// Funzione per ottenere la lista unica di codici gruppo ospedaliero
const getUniqueGPOSPList = (OBJ_TO_VALUE) => {
  const GPOSPList = OBJ_TO_VALUE.filter(
    (item) => item.codice_gruppo_ospedaliero !== ""
  ).map((item) => item.codice_gruppo_ospedaliero);
  return [...new Set(GPOSPList)];
};

// Funzione per recuperare i dati di gare pubbliche
const fetchMatchingData = async (uniqueGPOSPList) => {
  const foundedGPOPS = await garepubService.getGPOSP(uniqueGPOSPList);
  return Object.fromEntries(
    foundedGPOPS.map((element) => [
      element.data[0]?.codice_gruppo_ospedaliero,
      element.data,
    ])
  );
};

// Funzione per aggiornare OBJ_TO_VALUE con i dati di gare pubbliche
const updateOBJWithMatchingData = (OBJ_TO_VALUE, foundedGPOPSMap) => {
  return OBJ_TO_VALUE.map((selected) => {
    const matchingData = foundedGPOPSMap[selected.codice_gruppo_ospedaliero];
    if (matchingData) {
      matchingData.sort(sortByPrice);
      selected.garePubList = matchingData;
    }
    return selected;
  });
};

// Funzione per classificare i dati
const classifyData = (updatedOBJ_TO_VALUE) => {
  const toSelectSingle = [];
  const toSelectCollective = [];
  const toWait = [];
  const toWait_atc = [];

  let costoACQ_toSelectSingle = 0;
  let costoACQ_toSelectCollective = 0;
  let costoACQ_toWait = 0;
  let costoACQ_toWait_atc = 0;

  let costoMIN_toSelectSingle = 0;
  let costoMIN_toSelectCollective = 0;

  let costoSEL_toSelectSingle = 0;
  let costoSEL_toSelectCollective = 0;

  updatedOBJ_TO_VALUE.forEach((AbbSelected) => {
    if (AbbSelected.flag_match) {
      if (AbbSelected.flag_prize_collective) {
        costoACQ_toSelectCollective += calcoloCosto(
          AbbSelected.prezzo_unitario,
          AbbSelected.quantita
        );
        costoMIN_toSelectCollective += calcoloCosto(
          AbbSelected.cod_osp_list[0].prezzo_unitario,
          AbbSelected.cod_osp_list[0].quantita
        );
        costoSEL_toSelectCollective += calcoloCosto(
          AbbSelected.spec_checked.prezzo_unitario,
          AbbSelected.spec_checked.quantita
        );
        toSelectCollective.push(AbbSelected);
      } else {
        costoACQ_toSelectSingle += calcoloCosto(
          AbbSelected.prezzo_unitario,
          AbbSelected.quantita
        );
        costoMIN_toSelectSingle += calcoloCosto(
          AbbSelected.cod_osp_list[0].prezzo_unitario,
          AbbSelected.cod_osp_list[0].quantita
        );
        costoSEL_toSelectSingle += calcoloCosto(
          AbbSelected.spec_checked.prezzo_unitario,
          AbbSelected.spec_checked.quantita
        );
        toSelectSingle.push(AbbSelected);
      }
    } else if (AbbSelected.flag_gp_osp) {
      costoACQ_toWait += calcoloCosto(
        AbbSelected.prezzo_unitario,
        AbbSelected.quantita
      );
      toWait.push(AbbSelected);
    } else {
      costoACQ_toWait_atc += calcoloCosto(
        AbbSelected.prezzo_unitario,
        AbbSelected.quantita
      );
      toWait_atc.push(AbbSelected);
    }
  });

  return {
    toSelectSingle,
    toSelectCollective,
    toWait,
    toWait_atc,
    costoACQ_toSelectSingle,
    costoACQ_toSelectCollective,
    costoACQ_toWait,
    costoACQ_toWait_atc,
    costoMIN_toSelectSingle,
    costoMIN_toSelectCollective,
    costoSEL_toSelectSingle,
    costoSEL_toSelectCollective,
  };
};

// Funzione per creare le sezioni di riepilogo con `creaSezione`
const createResumeSections = (
  classifications,
  GARE_PUB_BENCHMARKING,
  updatedOBJ_TO_VALUE
) => {
  const {
    toSelectSingle,
    toSelectCollective,
    toWait,
    toWait_atc,
    costoACQ_toSelectSingle,
    costoACQ_toSelectCollective,
    costoACQ_toWait,
    costoACQ_toWait_atc,
    costoMIN_toSelectSingle,
    costoMIN_toSelectCollective,
    costoSEL_toSelectSingle,
    costoSEL_toSelectCollective,
  } = classifications;

  const SPEC_TO_SELECT_SINGLE = [
    creaSezione(
      "Abbinati (unico prezzo minimo)",
      toSelectSingle.length,
      costoACQ_toSelectSingle,
      costoMIN_toSelectSingle,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle,
        costoMIN_toSelectSingle - costoACQ_toSelectSingle
      ),
      costoSEL_toSelectSingle,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle,
        costoSEL_toSelectSingle - costoACQ_toSelectSingle
      ),
      "",
      "",
      ""
    ),
  ];

  const SPEC_TO_SELECT_COLLECTIVE = [
    creaSezione(
      "Abbinati (prezzo minimo multiplo)",
      toSelectCollective.length,
      costoACQ_toSelectCollective,
      costoMIN_toSelectCollective,
      calcoloDifferenzaPerc(
        costoACQ_toSelectCollective,
        costoMIN_toSelectCollective - costoACQ_toSelectCollective
      ),
      costoSEL_toSelectCollective,
      calcoloDifferenzaPerc(
        costoACQ_toSelectCollective,
        costoSEL_toSelectCollective - costoACQ_toSelectCollective
      ),
      "",
      "",
      ""
    ),
  ];

  const TOTALE_ABBINAMENTI = [
    creaSezione(
      "TOTALE ABBINAMENTI",
      toSelectSingle.length + toSelectCollective.length,
      costoACQ_toSelectSingle + costoACQ_toSelectCollective,
      costoMIN_toSelectSingle + costoMIN_toSelectCollective,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle + costoACQ_toSelectCollective,
        costoMIN_toSelectSingle +
          costoMIN_toSelectCollective -
          (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
      ),
      costoSEL_toSelectSingle + costoSEL_toSelectCollective,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle + costoACQ_toSelectCollective,
        costoSEL_toSelectSingle +
          costoSEL_toSelectCollective -
          (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
      ),
      GARE_PUB_BENCHMARKING.BENCH_TAB[3].spesaGarePub,
      GARE_PUB_BENCHMARKING.BENCH_TAB[3].diff_spesa,
      calcoloDifferenzaPerc(
        GARE_PUB_BENCHMARKING.BENCH_TAB[3].spesaABB,
        GARE_PUB_BENCHMARKING.BENCH_TAB[3].diff_spesa
      )
    ),
  ];

  const SPEC_TO_WAIT = [
    creaSezione(
      "Molecole non pervenute",
      toWait.length,
      costoACQ_toWait,
      costoACQ_toWait,
      "",
      costoACQ_toWait,
      "",
      "",
      "",
      ""
    ),
  ];
  const SPEC_TO_WAIT_ATC = [
    creaSezione(
      "Dispositivi medici",
      toWait_atc.length,
      costoACQ_toWait_atc,
      costoACQ_toWait_atc,
      "",
      costoACQ_toWait_atc,
      "",
      "",
      "",
      ""
    ),
  ];

  const TOTALE_NON_ABBINATI = [
    creaSezione(
      "TOTALE NON ABBINATI",
      toWait.length + toWait_atc.length,
      costoACQ_toWait + costoACQ_toWait_atc,
      costoACQ_toWait + costoACQ_toWait_atc,
      "",
      costoACQ_toWait + costoACQ_toWait_atc,
      "",
      "",
      "",
      ""
    ),
  ];
  const TOTALE_GENERALE = [
    creaSezione(
      "TOTALE GENERALE",
      updatedOBJ_TO_VALUE.length,
      costoACQ_toSelectSingle +
        costoACQ_toSelectCollective +
        costoACQ_toWait +
        costoACQ_toWait_atc,
      costoMIN_toSelectSingle +
        costoMIN_toSelectCollective +
        costoACQ_toWait +
        costoACQ_toWait_atc,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle +
          costoACQ_toSelectCollective +
          costoACQ_toWait +
          costoACQ_toWait_atc,
        costoMIN_toSelectSingle +
          costoMIN_toSelectCollective -
          (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
      ),
      costoSEL_toSelectSingle +
        costoSEL_toSelectCollective +
        costoACQ_toWait +
        costoACQ_toWait_atc,
      calcoloDifferenzaPerc(
        costoACQ_toSelectSingle +
          costoACQ_toSelectCollective +
          costoACQ_toWait +
          costoACQ_toWait_atc,
        costoSEL_toSelectSingle +
          costoSEL_toSelectCollective -
          (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
      ),
      "",
      "",
      ""
    ),
  ];

  return SPEC_TO_SELECT_SINGLE.concat(SPEC_TO_SELECT_COLLECTIVE)
    .concat(TOTALE_ABBINAMENTI)
    .concat(SPEC_TO_WAIT)
    .concat(SPEC_TO_WAIT_ATC)
    .concat(TOTALE_NON_ABBINATI)
    .concat(TOTALE_GENERALE);
};

// Funzione principale
export const handleAbbinatoValutation = async (OBJTEMP) => {
  const OBJ_TO_VALUE = [...OBJTEMP];
  const uniqueGPOSPList = getUniqueGPOSPList(OBJ_TO_VALUE);
  const foundedGPOPSMap = await fetchMatchingData(uniqueGPOSPList);
  const updatedOBJ_TO_VALUE = updateOBJWithMatchingData(
    OBJ_TO_VALUE,
    foundedGPOPSMap
  );

  const classifications = classifyData(updatedOBJ_TO_VALUE);
  const GARE_PUB_BENCHMARKING = await handleBenchmarkingVAL(
    updatedOBJ_TO_VALUE
  );
  const RESUME_TAB = createResumeSections(
    classifications,
    GARE_PUB_BENCHMARKING,
    updatedOBJ_TO_VALUE
  );

  return {
    RESUME_TAB,
    GARE_PUB: GARE_PUB_BENCHMARKING,
    SPEC_TO_CHECK: classifications.toSelectSingle.concat(
      classifications.toSelectCollective
    ),
    OBJ_COMPLETE: updatedOBJ_TO_VALUE,
    TO_WAIT: classifications.toWait,
    TO_WAIT_ATC: classifications.toWait_atc,
  };
};

//*4
export const handleCreateSpecResume = (SPEC_TEMP) => {
  const SPEC_TEMP_TOELAB = [...SPEC_TEMP];

  const groupedData = SPEC_TEMP_TOELAB.reduce((acc, current) => {
    const { descrizione_gruppo_ospedaliero } = current;

    if (!acc[descrizione_gruppo_ospedaliero]) {
      acc[descrizione_gruppo_ospedaliero] = [];
    }

    acc[descrizione_gruppo_ospedaliero].push(current);
    return acc;
  }, {});

  const ArrayGruped = [];
  let id_count = 0;

  Object.values(groupedData).forEach((SEL) => {
    let SPESA_TOT = 0;
    let Q_TOT = 0;

    SEL.forEach((SEL_BIS) => {
      SPESA_TOT += calcoloCosto(SEL_BIS.prezzo_unitario, SEL_BIS.quantita);
      Q_TOT += SEL_BIS.quantita;
    });

    const temp = {
      id_temp: id_count++,
      spesa_tot: roundTwo(SPESA_TOT),
      quantita_tot: Q_TOT,
      tipologia: SEL[0]?.descrizione_gruppo_ospedaliero,
      cod_osp_list: SEL[0]?.cod_osp_list,
    };

    ArrayGruped.push(temp);
  });

  // console.log("ArrayGruped", ArrayGruped);

  return ArrayGruped;
};

const fetchOffersList = async (
  LISTINO_NAME_SEL,
  ACQ_DOWNLOADED,
  struttureSelected
) => {
  const offersListDWN =
    await offersResumeService.getOffersResumetByOffersResumetId(
      LISTINO_NAME_SEL.id
    );
  const offersListTEMP = offersListDWN.data;
  const purchasedTEMP = { ...ACQ_DOWNLOADED };
  const ACQ_SEL = await handleCreaACQStruttureSelezionate(
    purchasedTEMP,
    struttureSelected
  );
  return { offersListTEMP, ACQ_SEL: [...ACQ_SEL] };
};

const populateOffersWithPurchaseData = async (
  ACQ_SEL_TEMP,
  SINGLE_OFFERS_TEMP
) => {
  ACQ_SEL_TEMP.forEach((purchasedSelected) => {
    let id_subTable = 0;
    const COD_OSP_List = [];
    if (purchasedSelected?.codice_gruppo_ospedaliero.length > 0) {
      SINGLE_OFFERS_TEMP.forEach((offerSelected) => {
        if (
          purchasedSelected.codice_gruppo_ospedaliero ===
          offerSelected.codice_gruppo_ospedaliero
        ) {
          const temp = createOfferObject(
            offerSelected,
            purchasedSelected,
            id_subTable++
          );
          COD_OSP_List.push(temp);
        }
      });
      sortCOD_OSP_List(COD_OSP_List);
      purchasedSelected.cod_osp_list = COD_OSP_List;
      purchasedSelected.spec_checked = COD_OSP_List[0];
      purchasedSelected.note = "NOTE:";
    } else {
      purchasedSelected.cod_osp_list = [];
      purchasedSelected.note = "NOTE:";
    }
  });
  return ACQ_SEL_TEMP;
};

const createOfferObject = (offerSelected, purchasedSelected, id_subTable) => {
  return {
    id_subTable,
    flag_is_checked: false,
    ragione_sociale: offerSelected.ragione_sociale,
    cf_piva: offerSelected.cf_piva,
    ordine_min: offerSelected.ordine_min,
    pdf_offerta_id: offerSelected.pdf_offerta_id,
    aic: offerSelected.aic,
    nome_commerciale: offerSelected.nome_commerciale,
    numero_protocollo: offerSelected.numero_protocollo,
    atc: offerSelected.atc,
    atc_complementare: offerSelected.atc_complementare,
    descrizione_atc: offerSelected.descrizione_atc,
    principio_attivo: offerSelected.principio_attivo,
    codice_gruppo_ospedaliero: offerSelected.codice_gruppo_ospedaliero,
    descrizione_gruppo_ospedaliero:
      offerSelected.descrizione_gruppo_ospedaliero,
    prezzo_unitario: offerSelected.prezzo_unitario,
    id_acquistato_control: purchasedSelected._id,
    prezzo_unitario_acq: purchasedSelected.prezzo_unitario,
    quantita: purchasedSelected.quantita,
    costo_acquistato: calcoloCosto(
      purchasedSelected.prezzo_unitario,
      purchasedSelected.quantita
    ),
    data_fine: offerSelected.date[1],
    data_inizio: offerSelected.date[0],
    data_protocollo: offerSelected.data_protocollo,
    data_inserimento: offerSelected.data_inserimento,
    dosaggio: offerSelected.dosaggio,
    fascia: offerSelected.fascia,
    forma_farmaceutica: offerSelected.forma_farmaceutica,
    iva: offerSelected.iva,
    numero_unita_confezione: offerSelected.numero_unita_confezione,
    prezzo_netto_exfactory_confezione:
      offerSelected.prezzo_netto_exfactory_confezione,
    prezzo_netto_exfactory_unitario:
      offerSelected.prezzo_netto_exfactory_unitario,
    prezzo_pub_confezione: offerSelected.prezzo_pub_confezione,
    prezzo_pub_unitario: offerSelected.prezzo_pub_unitario,
    sconto_precentuale_prezzo_pub: offerSelected.sconto_precentuale_prezzo_pub,
    filef_flag: offerSelected.filef_flag,
  };
};

const sortCOD_OSP_List = (COD_OSP_List) => {
  COD_OSP_List.sort((a, b) => a.prezzo_unitario - b.prezzo_unitario);
};

const classifyACQ_SEL = (ACQ_SEL_TEMP) => {
  const ACQ_COD_OSP_MATCHED = [];
  const ACQ_COD_OSP_NOTMATCHED = [];
  ACQ_SEL_TEMP.forEach((purchasedSelected) => {
    if (purchasedSelected.cod_osp_list.length > 0) {
      purchasedSelected.flag_match = true;
      purchasedSelected.flag_assigned = false;
      purchasedSelected.flag_prize_collective = false;
      purchasedSelected.flag_gp_osp = true;
      ACQ_COD_OSP_MATCHED.push(purchasedSelected);
    } else {
      purchasedSelected.flag_match = false;
      ACQ_COD_OSP_NOTMATCHED.push(purchasedSelected);
    }
  });
  return { ACQ_COD_OSP_MATCHED, ACQ_COD_OSP_NOTMATCHED };
};

const matchSingleAndMultipleOffers = (ACQ_COD_OSP_MATCHED) => {
  const MATCH_SINGLE_MIN = [];
  const MATCH_MULTIPLE_MIN = [];
  ACQ_COD_OSP_MATCHED.forEach((Selected) => {
    let samePrize = false;
    if (Selected.cod_osp_list.length === 1) {
      Selected.cod_osp_list[0].flag_is_checked = true;
      MATCH_SINGLE_MIN.push(Selected);
    } else {
      if (
        Selected.cod_osp_list[0].prezzo_unitario ===
        Selected.cod_osp_list[1].prezzo_unitario
      ) {
        samePrize = true;
      } else {
        Selected.cod_osp_list[0].flag_is_checked = true;
        MATCH_SINGLE_MIN.push(Selected);
      }
    }
    if (samePrize) {
      Selected.flag_prize_collective = true;
      MATCH_MULTIPLE_MIN.push(Selected);
    }
  });
  return { MATCH_SINGLE_MIN, MATCH_MULTIPLE_MIN };
};

const classifyUnmatchedOffers = (ACQ_COD_OSP_NOTMATCHED) => {
  const WITHOUT_GP_OSP = [];
  const WITH_GP_OSP_NOMATCH = [];
  ACQ_COD_OSP_NOTMATCHED.forEach((Selected) => {
    if (Selected.codice_gruppo_ospedaliero === "") {
      Selected.flag_gp_osp = false;
      WITHOUT_GP_OSP.push(Selected);
    } else {
      Selected.flag_gp_osp = true;
      WITH_GP_OSP_NOMATCH.push(Selected);
    }
  });
  return { WITHOUT_GP_OSP, WITH_GP_OSP_NOMATCH };
};

const handleFinalCalculations = async (
  MATCH_SINGLE_MIN,
  MATCH_MULTIPLE_MIN,
  WITHOUT_GP_OSP,
  WITH_GP_OSP_NOMATCH
) => {
  const OBJToValue = [
    ...MATCH_SINGLE_MIN,
    ...MATCH_MULTIPLE_MIN,
    ...WITHOUT_GP_OSP,
    ...WITH_GP_OSP_NOMATCH,
  ];
  const finalOBJ = await handleAbbinatoValutation(OBJToValue);
  const resumeSPEC_TEST = await handleCreateSpecResume(finalOBJ.SPEC_TO_CHECK);
  return { finalOBJ, resumeSPEC_TEST };
};

// Funzione principale
export const handleCreaAbbinato = async (
  LISTINO_NAME_SEL,
  ACQ_DOWNLOADED,
  struttureSelected,
  setLoadingABBValutation,
  setFinalAbbinatoToSave,
  setAbbinatoResume,

  setSpecMatchedTOT,
  setSpecMatchedTOT_RESUME,

  setSpecToWait,
  setSpecToWaitATC,

  setGarePubBench,

  setTAB_ABB_FLAG
) => {
  try {
    setLoadingABBValutation(true);

    const { offersListTEMP, ACQ_SEL } = await fetchOffersList(
      LISTINO_NAME_SEL,
      ACQ_DOWNLOADED,
      struttureSelected
    );
    const SINGLE_OFFERS_TEMP = await createOffersResumeView(offersListTEMP);
    const ACQ_SEL_TEMP = await populateOffersWithPurchaseData(
      ACQ_SEL,
      SINGLE_OFFERS_TEMP
    );

    const { ACQ_COD_OSP_MATCHED, ACQ_COD_OSP_NOTMATCHED } =
      classifyACQ_SEL(ACQ_SEL_TEMP);
    const { MATCH_SINGLE_MIN, MATCH_MULTIPLE_MIN } =
      matchSingleAndMultipleOffers(ACQ_COD_OSP_MATCHED);
    const { WITHOUT_GP_OSP, WITH_GP_OSP_NOMATCH } = classifyUnmatchedOffers(
      ACQ_COD_OSP_NOTMATCHED
    );

    const { finalOBJ, resumeSPEC_TEST } = await handleFinalCalculations(
      MATCH_SINGLE_MIN,
      MATCH_MULTIPLE_MIN,
      WITHOUT_GP_OSP,
      WITH_GP_OSP_NOMATCH
    );

    console.log("finalOBJ", finalOBJ);

    setFinalAbbinatoToSave(finalOBJ.OBJ_COMPLETE);
    setAbbinatoResume(finalOBJ.RESUME_TAB);

    setSpecMatchedTOT(finalOBJ.SPEC_TO_CHECK);
    setSpecMatchedTOT_RESUME(resumeSPEC_TEST);

    setSpecToWait(finalOBJ.TO_WAIT);
    setSpecToWaitATC(finalOBJ.TO_WAIT_ATC);

    setGarePubBench(finalOBJ.GARE_PUB);

    setTAB_ABB_FLAG(true);
  } catch (error) {
    console.error("Errore durante il recupero dei dati: ", error);
  } finally {
    setLoadingABBValutation(false);
  }
};
