import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import EXP_ABB_MULTIPLI from "../../exports/ABB/EXP_ABB_MULTIPLI";

// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";

// import BeatLoader from "react-spinners/BeatLoader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EXP_MULTIPLI_POPUP = ({
  popUpExpMultipliOpen,
  setPopUpExpMultipliOpen,
  specMatchedTOT,
  abb_name,
}) => {
  return (
    <>
      <Dialog
        open={popUpExpMultipliOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
        keepMounted
        onClose={() => setPopUpExpMultipliOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#fff",
            textAlign: "center",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div
            style={{
              textAlign: "center",
              display: "inline-block",
              width: "100%",
            }}
          >
            <EXP_ABB_MULTIPLI data={specMatchedTOT} abb_name={abb_name} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EXP_MULTIPLI_POPUP;
