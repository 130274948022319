import React from "react";

// import EXP_FABB_EMPTY from "../../../../../exports/EMPTY_FILES/EXP_FABB_EMPTY";

import Button from "@mui/material/Button";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const TopSection = ({
  handleRemove,
  handleFabbFlag,
  handleCreateStruttureList,
  fabbFlag,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "left",
          display: "inline-block",
          width: "50%",
        }}
      >
        <Button
          variant="contained"
          color="redtest"
          startIcon={<DeleteOutlinedIcon />}
          onClick={handleRemove}
        >
          RIMUOVI IL FILE
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "inline-block",
          width: "25%",
        }}
      >
        <Button variant="contained" color="bluetest" onClick={handleFabbFlag}>
          {fabbFlag ? "MOSTRA FILE INSERITO" : "MOSTRA FABBISOGNO COMPLETO"}
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          display: "inline-block",
          width: "25%",
        }}
      >
        <Button
          variant="contained"
          color="bluetest"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={handleCreateStruttureList}
        >
          EXP FABBISOGNO
        </Button>
      </div>
    </>
  );
};

export default TopSection;
