import MenuUser from "./MenuUser";

const DropdownUser = (props) => {
  let { submenus, dropdown, depthLevel, user, handleLogout } = props;
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <MenuUser
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          user={user}
          handleLogout={handleLogout}
        />
      ))}
    </ul>
  );
};

export default DropdownUser;
