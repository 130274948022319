import React, { useState, useEffect } from "react";
import ".././../App.css";

import IPOTESI_RISPARMIO from "../../utils/IPOTESI_RISPARMIO.json";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import { ABB_RESUME_COLUMNS_IPOTESI } from "../../colums/ABB_COLUMNS";

const IpotesiRisp = (props) => {
  const { user } = props;

  // console.log("ABB_RESUME_COLUMNS_IPOTESI", ABB_RESUME_COLUMNS_IPOTESI);
  // console.log("IPOTESI_RISPARMIO", IPOTESI_RISPARMIO);

  useEffect(() => {}, []);

  return (
    <>
      <div className="abbPage">
        <Box
          sx={{
            width: "100%",
            paddingTop: "2%",
            paddingLeft: "1%",
            paddingRight: "1%",
            paddingBottom: "1%",

            "& .theme_header": {
              backgroundColor: "#1976d2",
              color: "#fff",
              fontFamily: "Calibri",
              fontSize: "16px",
            },
            "& .theme_cell": {
              fontFamily: "Calibri",
              fontSize: "17px",
              border: "1px solid #000",
            },
            "& .theme_cell_lost": {
              fontFamily: "Calibri",
              backgroundColor: "#FFC7CE",
              color: "#9C0006",
              fontSize: "17px",
              border: "1px solid",
            },
            "& .theme_cell_gain": {
              fontFamily: "Calibri",
              backgroundColor: "#C6EFCE",
              color: "#006100",
              fontSize: "17px",
              border: "1px solid",
            },
            // "& .theme_cell_neutral": {
            //   fontFamily: "Calibri",
            //   backgroundColor: "#C6EFCE",
            //   color: "#006100",
            //   fontSize: "17px",
            //   border: "1px solid",
            // },
          }}
        >
          <DataGridPro
            columns={ABB_RESUME_COLUMNS_IPOTESI}
            rows={IPOTESI_RISPARMIO}
            getRowId={(row) => row.title}
            headerHeight={40}
            rowHeight={33}
            autoHeight
            {...IPOTESI_RISPARMIO}
            // onRowDoubleClick={handleSelectionListPage}
            disableColumnMenu
            hideFooter
          />
        </Box>
      </div>
    </>
  );
};

export default IpotesiRisp;
