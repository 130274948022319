import React, { useState } from "react";
import "../../../App.css";

// SERVICES
// COMPONENTS
import AbbinatoGenerateMain from "./CREATE/AbbinatoGenerateMain";
import AbbinatoSelectionMain from "./SELECTION/AbbinatoSelectionMain";
// FUNCTIONS
// COLUMNS
// EXPS
// POPUPS
// MUI ELEMENTS
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ICONS
// LOADERS
// OPTIONS

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const AbbinamentoMainNEW = ({ user }) => {
  //Flag principalI
  const [NEW_ABB_FLAG, setNEW_ABB_FLAG] = useState(false);

  const [TAB_ABB_FLAG, setTAB_ABB_FLAG] = useState(false);

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const [ACQ_DOWNLOADED, setACQ_DOWNLOADED] = useState(null);

  const [ACQ_NAME_SEL, setACQ_NAME_SEL] = useState(null);
  const [LISTINO_NAME_SEL, setLISTINO_NAME_SEL] = useState(null);

  const [listaStrutture, setListaStrutture] = useState(null);
  const [struttureSelected, setStruttureSelected] = useState(null);
  const [STRUTT_SEL_FLAG, setSTRUTT_SEL_FLAG] = useState(false);

  const [finalAbbinatoToSave, setFinalAbbinatoToSave] = useState(null);
  const [abbinatoResume, setAbbinatoResume] = useState(null);
  //   console.log("finalAbbinatoToSave", finalAbbinatoToSave);
  //   console.log("abbinatoResume", abbinatoResume);

  const [specMatchedTOT, setSpecMatchedTOT] = useState(null);
  const [specMatchedTOT_RESUME, setSpecMatchedTOT_RESUME] = useState(null);
  //   console.log("specMatchedTOT", specMatchedTOT);
  //   console.log("specMatchedTOT_RESUME", specMatchedTOT_RESUME);

  const [specToWait, setSpecToWait] = useState(null);
  const [specToWaitATC, setSpecToWaitATC] = useState(null);
  //   console.log("specToWait", specToWait);
  //   console.log("specToWaitATC", specToWaitATC);

  const [garePubBench, setGarePubBench] = useState(null);
  //   console.log("garePubBench", garePubBench);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          {!TAB_ABB_FLAG ? (
            <>
              <AbbinatoGenerateMain
                user={user}
                NEW_ABB_FLAG={NEW_ABB_FLAG}
                setNEW_ABB_FLAG={setNEW_ABB_FLAG}
                setTAB_ABB_FLAG={setTAB_ABB_FLAG}
                ACQ_NAME_SEL={ACQ_NAME_SEL}
                ACQ_DOWNLOADED={ACQ_DOWNLOADED}
                setACQ_DOWNLOADED={setACQ_DOWNLOADED}
                setACQ_NAME_SEL={setACQ_NAME_SEL}
                LISTINO_NAME_SEL={LISTINO_NAME_SEL}
                setLISTINO_NAME_SEL={setLISTINO_NAME_SEL}
                setFinalAbbinatoToSave={setFinalAbbinatoToSave}
                setSpecMatchedTOT={setSpecMatchedTOT}
                setSpecMatchedTOT_RESUME={setSpecMatchedTOT_RESUME}
                setSpecToWait={setSpecToWait}
                setSpecToWaitATC={setSpecToWaitATC}
                setAbbinatoResume={setAbbinatoResume}
                setGarePubBench={setGarePubBench}
                listaStrutture={listaStrutture}
                setListaStrutture={setListaStrutture}
                struttureSelected={struttureSelected}
                setStruttureSelected={setStruttureSelected}
                setSTRUTT_SEL_FLAG={setSTRUTT_SEL_FLAG}
                STRUTT_SEL_FLAG={STRUTT_SEL_FLAG}
                updateTrigger={updateTrigger}
              />
            </>
          ) : (
            <>
              <AbbinatoSelectionMain
                user={user}
                NEW_ABB_FLAG={NEW_ABB_FLAG}
                setNEW_ABB_FLAG={setNEW_ABB_FLAG}
                setUpdateTrigger={setUpdateTrigger}
                setACQ_DOWNLOADED={setACQ_DOWNLOADED}
                setTAB_ABB_FLAG={setTAB_ABB_FLAG}
                ACQ_NAME_SEL={ACQ_NAME_SEL}
                LISTINO_NAME_SEL={LISTINO_NAME_SEL}
                finalAbbinatoToSave={finalAbbinatoToSave}
                setFinalAbbinatoToSave={setFinalAbbinatoToSave}
                abbinatoResume={abbinatoResume}
                setAbbinatoResume={setAbbinatoResume}
                specToWait={specToWait}
                setSpecToWait={setSpecToWait}
                specToWaitATC={specToWaitATC}
                setSpecToWaitATC={setSpecToWaitATC}
                garePubBench={garePubBench}
                setGarePubBench={setGarePubBench}
                setListaStrutture={setListaStrutture}
                setStruttureSelected={setStruttureSelected}
                setSTRUTT_SEL_FLAG={setSTRUTT_SEL_FLAG}
                specMatchedTOT={specMatchedTOT}
              />
            </>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default AbbinamentoMainNEW;
