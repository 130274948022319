export const boxStyles = {
  height: "417px",
  width: "100%",
  paddingLeft: "1%",
  paddingRight: "1%",
  paddingTop: "3%",
  "& .theme_header": {
    backgroundColor: "#1976d2",
    color: "#fff",
    fontFamily: "Calibri",
    fontSize: "16px",
  },
  "& .theme_cell": {
    fontFamily: "Calibri",
    fontSize: "17px",
    border: "1px solid",
  },
};
