import React from "react";

import EXP_FABB_EMPTY from "../../../../../exports/EMPTY_FILES/EXP_FABB_EMPTY";

import Button from "@mui/material/Button";

import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const TopSection = ({ setInstructionOpen, handleFileImport }) => {
  return (
    <>
      <div
        style={{
          display: "inline-block",
          width: "60%",
          textAlign: "left",
        }}
      >
        <Button
          variant="contained"
          color="bluetest"
          onClick={() => setInstructionOpen(true)}
          startIcon={<InfoOutlinedIcon />}
        >
          ISTRUZIONI INSERIMENTO
        </Button>
      </div>
      <div style={{ display: "inline-block", width: "30%" }}>
        <EXP_FABB_EMPTY />
      </div>
      <div style={{ display: "inline-block", width: "10%" }}>
        <Button
          variant="contained"
          color="bluetest"
          component="label"
          startIcon={<BackupOutlinedIcon />}
        >
          UPLOAD
          <input
            type="file"
            hidden
            accept="xlsx, xls"
            multiple={false}
            onChange={handleFileImport}
          />
        </Button>
      </div>
    </>
  );
};

export default TopSection;
