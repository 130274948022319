import acquistatoService from "../../services/acquistato";

const handleCreateStruttureList = async (ACQ_DWN, setListaStrutture) => {
  let listaStruttureTEMP = ["TUTTE LE STRUTTURE"];

  await ACQ_DWN.forEach((SEL) => {
    listaStruttureTEMP.push(SEL?.struttura);
  });

  const listaStrutture_DoppiRimossi = [...new Set(listaStruttureTEMP)];

  let finalListaStruttureTEMP = [];
  let countTEMP = 0;

  await listaStrutture_DoppiRimossi.forEach((SEL) => {
    finalListaStruttureTEMP.push({ id: countTEMP++, nome_struttura: SEL });
  });

  setListaStrutture(finalListaStruttureTEMP);
};

const handleStruttureChange = (
  e,
  newValue,
  listaStrutture,
  setStruttureSelected
) => {
  const tutteLeStruttureSelezionato = newValue.some(
    (option) => option.id === 0
  );

  if (tutteLeStruttureSelezionato) {
    const tutteLeStrutture = listaStrutture.filter((option) => option.id !== 0);
    setStruttureSelected(tutteLeStrutture);
  } else {
    setStruttureSelected(newValue);
  }
};

const handlePurchasedDownload = (
  ACQ_Selected,
  setLoadingSinglePurchased,
  setFlagACQDownloadError,
  setSingleACQ_Downloaded,
  setListaStrutture
) => {
  setLoadingSinglePurchased(true);
  setFlagACQDownloadError(false);

  acquistatoService
    .getAcquistatoByPurchasedId(ACQ_Selected.id)
    .then(async (res) => {
      res.data.acquistato_inserito.sort((a, b) => {
        let x = a.principio_attivo;
        let y = b.principio_attivo;

        if (x < y) {
          return -1;
        } else if (x > y) {
          return 1;
        }
        return 0;
      });

      var resultATC = res.data.acquistato_inserito.reduce((x, y) => {
        (x[y.principio_attivo] = x[y.principio_attivo] || []).push(y);
        return x;
      }, {});

      let newOBJ = [];

      Object.keys(resultATC).map((resultSelected) => {
        let ATCListTemp = [];

        res.data.acquistato_inserito.map((Selected) => {
          if (resultSelected === Selected?.principio_attivo) {
            ATCListTemp.push(Selected);
          }

          return res.data.acquistato_inserito;
        });

        ATCListTemp.sort((a, b) => {
          let x = a.codice_gruppo_ospedaliero;
          let y = b.codice_gruppo_ospedaliero;

          if (x < y) {
            return -1;
          } else if (x > y) {
            return 1;
          }
          return 0;
        });

        newOBJ.push(ATCListTemp);

        return resultATC;
      });

      let finalOBJ = [];

      newOBJ.map((Selected) => {
        var resultATCComp = Selected.reduce((x, y) => {
          (x[y.codice_gruppo_ospedaliero] =
            x[y.codice_gruppo_ospedaliero] || []).push(y);
          return x;
        }, {});

        Object.keys(resultATCComp).map((resultSelected) => {
          let ATCListTemp = [];

          Selected.map((SelectedBIS) => {
            if (resultSelected === SelectedBIS?.codice_gruppo_ospedaliero) {
              ATCListTemp.push(SelectedBIS);
            }

            return Selected;
          });

          ATCListTemp.sort((a, b) => {
            let x = a.prezzo_unitario;
            let y = b.prezzo_unitario;

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            }
            return 0;
          });

          finalOBJ.push(ATCListTemp);

          return resultATCComp;
        });

        return finalOBJ;
      });

      handleCreateStruttureList(
        res.data.acquistato_inserito,
        setListaStrutture
      );

      setSingleACQ_Downloaded(res.data);

      setLoadingSinglePurchased(false);
    })
    .catch((error) => {
      console.error(
        "Errore durante il recupero dell'acquistato dal server:",
        error
      );
      setLoadingSinglePurchased(false);
      setFlagACQDownloadError(true);
    });
};

const handleResetACQDownload = (
  setSingleACQ_Downloaded,
  setACQ_Selected,
  setGareBenchmarking,
  setListaStrutture,
  setStruttureSelected,
  setArrayGrupedTEMP,
  setArrayGrupedFlag
) => {
  setSingleACQ_Downloaded(null);
  setACQ_Selected(null);
  setGareBenchmarking(null);
  setListaStrutture(null);
  setStruttureSelected(null);
  setArrayGrupedTEMP(null);
  setArrayGrupedFlag(false);
};

export {
  handleStruttureChange,
  handlePurchasedDownload,
  handleResetACQDownload,
};
