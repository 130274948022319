import React, { useState, useEffect } from "react";
import "../../App.css";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import fornitoriService from "../../services/fornitori";
import uploadpdf from "../../services/uploadpdf"; // Importa il servizio di upload
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import OFFER_RESUME_POPUP from "../../popup/OFFER_POPUP/OFFER_RESUME_POPUP";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

const theme = createTheme({
  palette: {
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const SingleOfferForm = (props) => {
  const { updateOfferData, suppInsertedList } = props;
  const [supplierDB, setSupplierDB] = useState([]);
  const [supplier, setSupplier] = useState(null);
  const [protocolDate, setProtocolDate] = useState(
    dayjs().utc().local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [firstDate, setFirstDate] = useState(
    dayjs.utc("2024-01-01").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [lastDate, setLastDate] = useState(
    dayjs.utc("2024-12-31").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [protocolNumber, setProtocolNumber] = useState("");
  const [minOrder, setMinOrder] = useState("");
  const [offerDataTEMP, setOfferDataTEMP] = useState(null);
  const [formConfirmOpen, setFormConfirmOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [pdfFile, setPdfFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fornitoriService
      .getFornitori()
      .then((res) => {
        const sortedData = res.data.sort((a, b) =>
          a.ragione_sociale.localeCompare(b.ragione_sociale)
        );
        setSupplierDB(sortedData);
      })
      .catch((error) =>
        console.error(
          "Errore durante il recupero dei fornitori dal server:",
          error
        )
      );
  }, []);

  const handleFormConfirmClose = () => {
    setFormConfirmOpen(false);
    setOfferDataTEMP(null);
  };

  const handlePDFUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
    } else {
      alert("Please upload a PDF file.");
      setPdfFile(null);
    }
  };

  const handleCheckMinOrder = (numToCheck) => {
    if (numToCheck === "") {
      return false;
    }
    const isValidFormat = /^-?\d+(\.\d+)?$|^-?\d+(,\d+)?$/.test(numToCheck);
    return !isValidFormat;
  };

  const validateForm = () => {
    const newErrors = {};

    if (!supplier) {
      newErrors.supplier = "Inserire il fornitore";
    }

    if (!protocolDate) {
      newErrors.protocolDate = "Inserire la data dell'offerta";
    }

    if (!firstDate) {
      newErrors.firstDate = "Inserire la data di inizio validità";
    }

    if (!lastDate) {
      newErrors.lastDate = "Inserire la data di fine validità";
    }

    if (firstDate && lastDate && firstDate >= lastDate) {
      newErrors.firstDate = "Il periodo non è valido";
      newErrors.lastDate = "Il periodo non è valido";
    }

    if (!protocolNumber) {
      newErrors.protocolNumber = "Inserire il numero di protocollo";
    }

    if (handleCheckMinOrder(minOrder)) {
      newErrors.minOrder = "Numero inserito NON valido";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    const finalMinOrder = minOrder ? parseFloat(minOrder.replace(",", ".")) : 0;

    const offerData = {
      supplier,
      protocolDate,
      firstDate,
      lastDate,
      protocolNumber,
      minOrder: Math.round(finalMinOrder * 100) / 100,
      pdfFile,
    };

    setOfferDataTEMP(offerData);
    setFormConfirmOpen(true);
  };

  const handleConfirmSubmit = async () => {
    setLoading(true);

    let pdf_file_ID = "";

    if (pdfFile) {
      try {
        const response = await uploadpdf.uploadFile(pdfFile);
        pdf_file_ID = response.fileId;
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    const newSupplierFlag = await suppInsertedList.some(
      (Selected) =>
        offerDataTEMP.supplier.ragione_sociale === Selected.ragione_sociale
    );

    await updateOfferData(
      { ...offerDataTEMP, pdf_offerta_id: pdf_file_ID },
      newSupplierFlag
    );
    setLoading(false);
    handleFormConfirmClose();
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          className="singleOfferPage"
          sx={{
            pt: 2,
            border: 2,
            borderColor: "#1976d2",
            borderRadius: "10px",
            marginLeft: 45,
            marginRight: 45,
            height: "475px",
            maxHeight: "475px",
          }}
        >
          <h2 style={{ color: "#1976d2" }}>INSERISCI NUOVA OFFERTA</h2>
          <Autocomplete
            disablePortal
            id="supplier"
            options={supplierDB}
            getOptionLabel={(option) =>
              option.ragione_sociale + " " + option.cf_piva
            }
            sx={{
              width: "100%",
              pt: 2,
              px: "25%",
            }}
            renderOption={(props, option, index) => {
              const key = `listItem-${index}-${option.id}`;
              return (
                <li {...props} key={key}>
                  {option.ragione_sociale} - {option.cf_piva}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Fornitore"
                error={errors.supplier ? true : false}
                helperText={errors.supplier}
              />
            )}
            value={supplier}
            onChange={(e, newValue) => {
              setSupplier(newValue);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ pt: 2 }}>
              <DesktopDatePicker
                label="Data Offerta"
                inputFormat="DD/MM/YYYY"
                value={protocolDate}
                onChange={(newValue) =>
                  setProtocolDate(
                    newValue.hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={errors.protocolDate ? true : false}
                    helperText={errors.protocolDate}
                  />
                )}
              />
            </Box>
            <Box
              sx={{ pt: 2, display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ width: "48%", textAlign: "right" }}>
                <DesktopDatePicker
                  label="Inizio Offerta"
                  inputFormat="DD/MM/YYYY"
                  value={firstDate}
                  onChange={(newValue) =>
                    setFirstDate(
                      newValue
                        .hour(12)
                        .local()
                        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.firstDate ? true : false}
                      helperText={errors.firstDate}
                    />
                  )}
                />
              </Box>
              <Box sx={{ width: "48%", textAlign: "left" }}>
                <DesktopDatePicker
                  label="Fine Offerta"
                  inputFormat="DD/MM/YYYY"
                  value={lastDate}
                  onChange={(newValue) =>
                    setLastDate(
                      newValue
                        .hour(12)
                        .local()
                        .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.lastDate ? true : false}
                      helperText={errors.lastDate}
                    />
                  )}
                />
              </Box>
            </Box>
          </LocalizationProvider>
          <Box sx={{ pt: 2 }}>
            <TextField
              id="n-protocollo"
              label="Numero di Protocollo"
              placeholder="Inserisci il Numero di Protocollo"
              sx={{ width: 350 }}
              value={protocolNumber}
              onChange={(e) => setProtocolNumber(e.target.value)}
              error={errors.protocolNumber ? true : false}
              helperText={errors.protocolNumber}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <TextField
              id="min-order"
              label="Ordine Minimo"
              placeholder="Inserisci l'Ordine Minimo"
              sx={{ width: 350 }}
              value={minOrder}
              onChange={(e) => setMinOrder(e.target.value)}
              error={errors.minOrder ? true : false}
              helperText={errors.minOrder}
            />
          </Box>
          <Box sx={{ pt: 2 }}>
            <input
              type="file"
              accept="application/pdf"
              onChange={handlePDFUpload}
            />
          </Box>
        </Box>
        <Box sx={{ pt: 2 }}>
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckOutlinedIcon />}
            onClick={handleSubmit}
          >
            CONFERMA
          </Button>
        </Box>
        <OFFER_RESUME_POPUP
          formConfirmOpen={formConfirmOpen}
          handleFormConfirmClose={handleFormConfirmClose}
          offerDataTEMP={offerDataTEMP}
          handleConfirmSubmit={handleConfirmSubmit}
        />
      </ThemeProvider>
    </>
  );
};

export default SingleOfferForm;
