import React, { useState } from "react";
import "../../../App.css";

import Import from "./Import";
// import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import EXP_FABB_MAIN from "../../../exports/FABB/EXP_FABB_MAIN";
import FABB_EXP_POPUP from "../../../popup/ACQ_POPUP/FABB_EXP_POPUP";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import TopSection from "./FABBISOGNO_COMPONENTS/MAIN/TopSection";

import {
  FABB_MAIN_COLUMNS,
  FABB_MAIN_ELAB_COLUMNS,
} from "../../../colums/FABB_COLUMNS";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const FabbMain = ({ user }) => {
  const [fileData, setFileData] = useState([]);
  const [fileDataFabb, setFileDataFabb] = useState([]);
  const [fileDataSaved, setFileDataSaved] = useState(false);
  const [fabbFlag, setFabbFlag] = useState(true);
  const [expPopupOpen, setExpPopupOpen] = useState(false);
  const [listaStrutture, setListaStrutture] = useState(null);

  const updateFileData = (newOfferData, newFabb) => {
    setFileData(newOfferData);
    setFileDataFabb(newFabb);
  };

  const insertedFile = (flag) => {
    setFileDataSaved(flag);
  };

  const handleRemove = () => {
    setFileData([]);
    setFileDataSaved(false);
  };

  const handleFabbFlag = () => {
    setFabbFlag(!fabbFlag);
  };

  const handleCreateStruttureList = async () => {
    const listaStruttureTEMP = [
      "TUTTE LE STRUTTURE",
      ...fileData.map((SEL) => SEL.struttura),
    ];
    const listaStrutture_DoppiRimossi = [...new Set(listaStruttureTEMP)];

    const finalListaStruttureTEMP = listaStrutture_DoppiRimossi.map(
      (SEL, index) => ({
        id: index,
        nome_struttura: SEL,
      })
    );

    setListaStrutture(finalListaStruttureTEMP);
    setExpPopupOpen(true);
  };

  return (
    <>
      {!fileDataSaved ? (
        <Import updateFileData={updateFileData} insertedFile={insertedFile} />
      ) : (
        <ThemeProvider theme={theme}>
          <div className="acqPage">
            <div>
              <TopSection
                handleRemove={handleRemove}
                handleFabbFlag={handleFabbFlag}
                handleCreateStruttureList={handleCreateStruttureList}
                fabbFlag={fabbFlag}
              />
              {/* <div
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  startIcon={<DeleteOutlinedIcon />}
                  onClick={handleRemove}
                >
                  RIMUOVI IL FILE
                </Button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: "25%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  onClick={handleFabbFlag}
                >
                  {fabbFlag
                    ? "MOSTRA FILE INSERITO"
                    : "MOSTRA FABBISOGNO COMPLETO"}
                </Button>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: "25%",
                }}
              >
                <Button
                  variant="contained"
                  color="bluetest"
                  startIcon={<FileDownloadOutlinedIcon />}
                  onClick={handleCreateStruttureList}
                >
                  EXP FABBISOGNO
                </Button>
              </div> */}
            </div>

            <Box
              sx={{
                height: "417px",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "3%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                },
                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "17px",
                  border: "1px solid",
                },
              }}
            >
              <h3
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                TOT ELEMENTI:{" "}
                {fabbFlag ? fileDataFabb.FARMACI.length : fileData.length}
              </h3>

              {fabbFlag ? (
                <DataGridPro
                  columns={FABB_MAIN_ELAB_COLUMNS}
                  rows={fileDataFabb.FARMACI}
                  getRowId={(row) => row.tipologia_descr}
                  headerHeight={40}
                  rowHeight={33}
                  disableColumnMenu
                  hideFooter
                />
              ) : (
                <DataGridPro
                  columns={FABB_MAIN_COLUMNS}
                  rows={fileData}
                  getRowId={(row) => row.id_acquistato_temp}
                  headerHeight={40}
                  rowHeight={33}
                  disableColumnMenu
                  hideFooter
                />
              )}
            </Box>
            <FABB_EXP_POPUP
              expPopupOpen={expPopupOpen}
              setExpPopupOpen={setExpPopupOpen}
              listaStrutture={listaStrutture}
              EXP_FABB_MAIN={EXP_FABB_MAIN}
              fileData={fileData}
            />
          </div>
        </ThemeProvider>
      )}
    </>
  );
};

export default FabbMain;
