import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const GP_DETAILS_POPUP = (props) => {
  const { popUpGaraPubOpen, dettaglioGaraPub, handlePopUpGaraPubClose } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          <Dialog
            open={popUpGaraPubOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={handlePopUpGaraPubClose}
            PaperProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <h3
                style={{
                  textAlign: "center",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                DETTAGLIO GARA PUBBLICA:
              </h3>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {dettaglioGaraPub &&
                  dettaglioGaraPub.data_delibera_aggiudicazione_gara &&
                  dettaglioGaraPub.data_fine_fornitura && (
                    <div style={{ fontFamily: "Calibri" }}>
                      REGIONE: <b>{dettaglioGaraPub?.regione}</b> <br />
                      PERIODO VALIDITA':{" "}
                      <b>
                        {dettaglioGaraPub?.data_delibera_aggiudicazione_gara}-{" "}
                        {dettaglioGaraPub?.data_fine_fornitura}
                      </b>{" "}
                      <br />
                      ID GARA: <b>{dettaglioGaraPub?.id_gara}</b> <br />
                      AMBITO: <b>{dettaglioGaraPub?.ambito}</b> <br />
                      NOME LOTTO: <b>
                        {dettaglioGaraPub?.denominazione_lotto}
                      </b>{" "}
                      <br />
                      {/* CODICE CONVENZIONE:{" "}
                      <b>{dettaglioGaraPub?.codice_convenzione}</b> <br /> */}
                      N. LOTTO: <b>{dettaglioGaraPub?.numero_lotto}</b> <br />
                      FORNITORE:{" "}
                      <b>
                        {dettaglioGaraPub?.ragione_sociale} -{" "}
                        {dettaglioGaraPub?.cf_piva}
                      </b>{" "}
                      <br />
                      {/* NOME LOTTO: <b>{dettaglioGaraPub?.nome_lotto}</b> <br /> */}
                      CIG LOTTO: <b>{dettaglioGaraPub?.cig_lotto}</b> <br />
                      TIPO GARA: <b>{dettaglioGaraPub?.tipo_gara}</b> <br />
                      ENTE APPALTANTE:{" "}
                      <b>{dettaglioGaraPub?.stazione_appaltante}</b> <br />
                      AIC: <b>{dettaglioGaraPub?.aic}</b> <br />
                      NOME COMMERCIALE:{" "}
                      <b>{dettaglioGaraPub?.nome_commerciale}</b> <br />
                      FORMA FARMACEUTICA:{" "}
                      <b>{dettaglioGaraPub?.forma_farmaceutica}</b> <br />
                      TIPOLOGIA:{" "}
                      <b>
                        {dettaglioGaraPub?.descrizione_gruppo_ospedaliero}
                      </b>{" "}
                      <br />
                      ATC: <b>{dettaglioGaraPub?.atc}</b> <br />
                      ATC COMPLEMENTARE:{" "}
                      <b>{dettaglioGaraPub?.atc_complementare}</b> <br />
                      DESCRIZIONE ATC:{" "}
                      <b>{dettaglioGaraPub?.principio_attivo}</b> <br />
                      DOSAGGIO: <b>{dettaglioGaraPub?.dosaggio}</b> <br />
                      UNITA' PER CONFEZIONE: <br />
                      {/* <b>{dettaglioGaraPub?.unita_per_confezione}</b> <br />
                      PREZZO PUB CONFEZIONE:{" "}
                      <b>{dettaglioGaraPub?.prezzo_pubblico_confezione}</b>{" "}
                      <br />
                      PREZZO CONFEZIONE:{" "}
                      <b>{dettaglioGaraPub?.prezzo_confezione}</b> <br />
                      PREZZO PUB UNITARIO:{" "}
                      <b>{dettaglioGaraPub?.prezzo_pubblico_unitario}</b> <br /> */}
                      PREZZO UNITARIO:{" "}
                      <b>{dettaglioGaraPub?.prezzo_unitario}</b> <br />
                      SCONTO: <b>{dettaglioGaraPub?.sconto}</b> <br />
                      IVA: <b>{dettaglioGaraPub?.iva}</b> <br />
                      {/* ORDINE MIN: <b>{dettaglioGaraPub?.ordine_min}</b> <br />
                      ORDINE MAX: <b>{dettaglioGaraPub?.ordine_max}</b> <br />
                      VALORE MAX: <b>{dettaglioGaraPub?.valore_max}</b> <br />
                      STATUS: <b>{dettaglioGaraPub?.status}</b> <br /> */}
                    </div>
                  )}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default GP_DETAILS_POPUP;
