import axios from "axios";

// Se sviluppo
// const urlUpload = "http://localhost:3001/api/upload";
// const urlFile = "http://localhost:3001/api/file";
// Se produzione
const urlUpload = "/api/upload";
const urlFile = "/api/file";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

// Funzione per l'upload del file
const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "multipart/form-data",
    },
  };

  const res = await axios.post(urlUpload, formData, config);
  return res.data;
};

// Funzione per il download del file
const downloadFile = async (fileId) => {
  const response = await axios.get(`${urlFile}/${fileId}`, {
    responseType: "blob", // Assicurati che la risposta sia un blob
  });
  return response.data;
};

const uploadpdf = {
  uploadFile,
  downloadFile, // Aggiungi questa linea
  setToken,
};

export default uploadpdf;
