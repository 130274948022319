import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import BeatLoader from "react-spinners/BeatLoader";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFFER_NEW_SAVE_POPUP = (props) => {
  const {
    saveNewOfferOpen,
    // setSaveNewOfferOpen,
    handleSaveSingleOfferPopupClose,
    handleSaveSingleOffer,
    loadingSaveSingleOffer,
    //---
    // pdf={pdf}
    // setPdf={}
    pdfUrl,
    // setPdfUrl,
    // setFile,
    onFileUpload,
    onFileChange,
    pdfFlagOK,
    loadingPDF,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <Dialog
          open={saveNewOfferOpen}
          TransitionComponent={Transition}
          fullWidth={true}
          maxWidth="lg"
          keepMounted
          onClose={handleSaveSingleOfferPopupClose}
          PaperProps={{
            style: {
              backgroundColor: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "10px",
              textAlign: "center",
            },
          }}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <div
              style={{
                marginBottom: "1%",
              }}
            >
              {/* <input type="file" onChange={onFileChange} />
              <button onClick={onFileUpload}>Upload!</button> */}
              {loadingPDF && (
                <div style={{ textAlign: "center", paddingTop: "3%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingPDF}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
              {pdfFlagOK && (
                <DialogContentText
                  id="alert-dialog-slide-description"
                  sx={{
                    fontFamily: "Calibri",
                    fontSize: 18,
                    fontWeight: "bold",
                    color: "#1976d2",
                    textAlign: "center",
                  }}
                >
                  SALVATAGGIO PDF CONFERMATO
                </DialogContentText>
              )}
            </div>
            {/* <input type="file" onChange={handlePdfChange} />
            <button onClick={handleUploadPDF}>Upload PDF</button> */}
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                fontFamily: "Calibri",
                fontSize: 25,
                fontWeight: "bold",
                color: "#1976d2",
                textAlign: "center",
              }}
            >
              CONFERMI DI VOLER SALVARE L'OFFERTA?
            </DialogContentText>
            <div
              style={{
                textAlign: "right",
                paddingTop: "2%",
                display: "inline-block",
                width: "47%",
              }}
            >
              <Button
                variant="contained"
                color="redtest"
                component="label"
                onClick={handleSaveSingleOfferPopupClose}
              >
                INDIETRO
              </Button>
            </div>
            <div
              style={{
                paddingTop: "2%",
                display: "inline-block",
                width: "6%",
              }}
            ></div>
            <div
              style={{
                textAlign: "left",
                paddingTop: "2%",
                display: "inline-block",
                width: "47%",
              }}
            >
              <Button
                variant="contained"
                color="success"
                component="label"
                startIcon={<SaveOutlinedIcon />}
                onClick={() => handleSaveSingleOffer(pdfUrl)}
              >
                CONFERMA
              </Button>
            </div>
            {loadingSaveSingleOffer && (
              <div style={{ textAlign: "center", paddingTop: "3%" }}>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingSaveSingleOffer}
                  size={30}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                  CARICAMENTO...
                </h6>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default OFFER_NEW_SAVE_POPUP;
