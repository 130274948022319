import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_ACQ_FABBISOGNO = ({ data }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
    },
  });

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "FABBISOGNO SU " + data.nome_salvataggio + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "QUANTITA'",
      },
    ];

    let table2 = [
      {
        A: "DESCRIZIONE ATC",
        B: "AIC",
        C: "NOME COMMERCIALE",
        D: "ATC",
        E: "FORNITORE",
        F: "PREZZO UNITARIO",
        G: "QUANTITA'",
        H: "SPESA",
      },
    ];

    let dataTEMP = [...data.acquistato_inserito];

    let farmaci = [];
    let dispositivi = [];

    dataTEMP.forEach((row) => {
      if (
        row?.aic.charAt(0) === "9" ||
        row?.aic.descrizione_gruppo_ospedaliero === ""
      ) {
        dispositivi.push(row);
      } else {
        farmaci.push(row);
      }
    });

    farmaci.sort((a, b) => {
      let x = a.descrizione_gruppo_ospedaliero;
      let y = b.descrizione_gruppo_ospedaliero;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    var resultPAList = farmaci.reduce((x, y) => {
      (x[y.descrizione_gruppo_ospedaliero] =
        x[y.descrizione_gruppo_ospedaliero] || []).push(y);
      return x;
    }, {});

    let TIP_OK = [];

    Object.keys(resultPAList).map((resultSelected) => {
      let fabbQuantTOT = 0;
      let princ_TEMP = "";

      if (resultSelected !== "") {
        farmaci.map((Selected) => {
          if (resultSelected === Selected?.descrizione_gruppo_ospedaliero) {
            fabbQuantTOT = fabbQuantTOT + Selected?.quantita;
            princ_TEMP = Selected?.principio_attivo;
          }

          return farmaci;
        });

        let temp = {
          principio_attivo: princ_TEMP,
          tipologia: resultSelected,
          quant_tot: fabbQuantTOT,
        };
        TIP_OK.push(temp);
      } else {
      }

      return resultPAList;
    });

    TIP_OK.forEach((row) => {
      table1.push({
        A: row?.principio_attivo,
        B: row?.tipologia,
        C: row?.quant_tot,
      });
    });

    dispositivi.sort((a, b) => {
      let x = a.descrizione_atc;
      let y = b.descrizione_atc;
      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    dispositivi.forEach((row) => {
      table2.push({
        A: row?.descrizione_atc,
        B: row?.aic,
        C: row?.nome_commerciale,
        D: row?.atc,
        E: row?.fornitore.ragione_sociale,
        F: row?.prezzo_unitario,
        G: row?.quantita,
        H: calcoloCosto(row?.prezzo_unitario, row?.quantita),
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "FARMACI");
    XLSX.utils.book_append_sheet(wb, sheet2, "PARAF. E ALTRO");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange1: `A1:C1`,
      theadRange2: `A1:H1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;

      workbook.sheets("FABBISOGNO SINTESI").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(55);
          sheet.column("B").width(55);
          sheet.column("C").width(14).style({ horizontalAlignment: "right" });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            horizontalAlignment: "left",
            border: true,
          });

          sheet.column("A").width(45);
          sheet.column("B").width(14).style({ horizontalAlignment: "center" });
          sheet.column("C").width(40);
          sheet.column("D").width(14).style({ horizontalAlignment: "center" });
          sheet.column("E").width(35);
          sheet.column("F").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet.column("G").width(20).style({ horizontalAlignment: "right" });
          sheet
            .column("H")
            .width(20)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        }

        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <LoadingButton
          variant="contained"
          color="bluetest"
          loading={loadingEXPData}
          loadingPosition="start"
          startIcon={<FileDownloadOutlinedIcon />}
          onClick={() => createDownLoadData()}
        >
          EXP FABBISOGNO
        </LoadingButton>
      </ThemeProvider>
    </>
  );
};

export default EXP_ACQ_FABBISOGNO;
