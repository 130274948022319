import acquistatoService from "../../services/acquistato";

const handlePurchasedDownload = async (
  ACQ_Selected,
  setLoadingSinglePurchased,
  setFlagACQDownloadError,
  setSingleACQ_Downloaded,
  setFieldSuggestions,
  setChangePageFlag
) => {
  setLoadingSinglePurchased(true);
  setFlagACQDownloadError(false);

  try {
    const res = await acquistatoService.getAcquistatoByPurchasedId(
      ACQ_Selected.id
    );

    const sortedAcq = res.data.acquistato_inserito.sort((a, b) =>
      a.principio_attivo.localeCompare(b.principio_attivo)
    );

    const resultATC = sortedAcq.reduce((acc, item) => {
      (acc[item.principio_attivo] = acc[item.principio_attivo] || []).push(
        item
      );
      return acc;
    }, {});

    let newOBJ = [];

    Object.keys(resultATC).forEach((principioAttivo) => {
      let ATCListTemp = resultATC[principioAttivo].sort((a, b) =>
        a.codice_gruppo_ospedaliero.localeCompare(b.codice_gruppo_ospedaliero)
      );
      newOBJ.push(ATCListTemp);
    });

    let finalOBJ = [];

    newOBJ.forEach((Selected) => {
      let resultATCComp = Selected.reduce((acc, item) => {
        (acc[item.codice_gruppo_ospedaliero] =
          acc[item.codice_gruppo_ospedaliero] || []).push(item);
        return acc;
      }, {});

      Object.keys(resultATCComp).forEach((codiceGruppo) => {
        let ATCListTemp = resultATCComp[codiceGruppo].sort(
          (a, b) => a.prezzo_unitario - b.prezzo_unitario
        );
        finalOBJ.push(ATCListTemp);
      });
    });

    let OBJtoExp = [];
    let firstSuggestionsList = [];

    finalOBJ.forEach((Sel) => {
      Sel.forEach((SelBIS) => {
        firstSuggestionsList.push(SelBIS.nome_commerciale);
        OBJtoExp.push(SelBIS);
      });
    });

    const SUGG_TEMP_UNIQUE = [...new Set(firstSuggestionsList)].sort();

    res.data.acquistato_inserito = OBJtoExp;

    // Aggiorna gli stati nel componente
    setSingleACQ_Downloaded(res.data);
    setFieldSuggestions(SUGG_TEMP_UNIQUE);
    setChangePageFlag(false);
    setLoadingSinglePurchased(false);
  } catch (error) {
    console.error("Errore durante il recupero dell'acquistato:", error);
    setLoadingSinglePurchased(false);
    setFlagACQDownloadError(true);
  }
};

const handleResetACQDownload = (
  setSingleACQ_Downloaded,
  setACQ_Selected,
  setChangePageFlag
) => {
  setSingleACQ_Downloaded(null);
  setACQ_Selected(null);
  setChangePageFlag(true);
};

export { handlePurchasedDownload, handleResetACQDownload };
