import axios from "axios";

//se sviluppo
// const urlEnricoFinal = "http://localhost:3001/api/enricofinal";
//se produzione
const urlEnricoFinal = "/api/enricofinal";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

const getEnricoFinalByUserId = (userId) => {
  let newUrl = urlEnricoFinal + "/user/" + userId;
  return axios.get(newUrl);
};

const getEnricoFinalByEnricoFinalId = (enricoFinalId) => {
  let newUrl = urlEnricoFinal + "/singleenricofinal/" + enricoFinalId;
  return axios.get(newUrl);
};

const getEnricoFinal = () => {
  let newUrl = urlEnricoFinal + "/allenricofinal";
  return axios.get(newUrl);
};

//Invio
const salvaEnricoFinal = async (newEnricoFinal) => {
  const config = {
    headers: { Authorization: token },
  };

  const res = await axios.post(urlEnricoFinal, newEnricoFinal, config);
  return res;
};

const enricoFinalService = {
  getEnricoFinalByUserId,
  getEnricoFinalByEnricoFinalId,
  getEnricoFinal,
  salvaEnricoFinal,
  setToken,
};

export default enricoFinalService;
