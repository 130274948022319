// Footer.js
import React from "react";
import "../App.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div className="footer-left-content">
          <a
            className="footer-link"
            href="https://startup.registroimprese.it/isin/dettaglioStartup?1&id=KXU3bCJQBfS92AJOXqJVKg%2BWN%2B3oCSslxKwmUwzhow%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            Startup.registroimprese.it
          </a>
        </div>
        <div className="footer-right-content">
          <Link to="/info" className="footer-link">
            {" "}
            NextGenerationEU
          </Link>
        </div>
      </div>
      <div>
        <div className="footer-left-content">
          © 2024 FARMA AUDIT & ANALYTICS SRL - P.IVA E CF: 04668740162 - Tutti i
          diritti riservati
        </div>

        <div className="footer-right-content">
          Contatti: info@farmauditanalytics.it
        </div>
      </div>
    </footer>
  );
};

export default Footer;
