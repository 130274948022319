import dayjs from "dayjs";

export const validateForm = (firstDate, lastDate, setFlagWrongPeriod) => {
  const newErrors = {};
  const firstDateObj = dayjs(firstDate);
  const lastDateObj = dayjs(lastDate);

  if (!firstDate || !firstDateObj.isValid()) {
    newErrors.firstDate = "Inserire la data di inizio validità";
  }

  if (!lastDate || !lastDateObj.isValid()) {
    newErrors.lastDate = "Inserire la data di fine validità";
  }

  if (
    firstDateObj.isSame(lastDateObj, "day") ||
    firstDateObj.isAfter(lastDateObj)
  ) {
    newErrors.firstDate = "Il periodo non è valido";
    newErrors.lastDate = "Il periodo non è valido";
    setFlagWrongPeriod(true);
  } else {
    setFlagWrongPeriod(false);
  }

  return newErrors;
};
