import { calcoloCosto } from "../FUN_CALCOLI";

const sortItems = (arr, key, order = "asc") => {
  const newArr = [...arr];
  return newArr.sort((a, b) => {
    let x = a[key],
      y = b[key];
    return order === "asc"
      ? x < y
        ? -1
        : x > y
        ? 1
        : 0
      : x > y
      ? -1
      : x < y
      ? 1
      : 0;
  });
};

const handleSortNEW = (
  fieldSEL,
  searchedFlag,
  filteredResults,
  fileData,
  sortOrder,
  setFilteredResults,
  setFileData,
  setSortOrder
) => {
  if (!fieldSEL) return;

  console.log("fileData", fileData);

  const arrayToSort = searchedFlag ? [...filteredResults] : [...fileData];

  // Logica di ordinamento personalizzata per il campo "fornitore.ragione_sociale"
  if (fieldSEL === "fornitore") {
    arrayToSort.sort((a, b) => {
      const x = a.fornitore.ragione_sociale;
      const y = b.fornitore.ragione_sociale;
      return sortOrder === "asc" ? x.localeCompare(y) : y.localeCompare(x);
    });
  }
  // Logica di ordinamento personalizzata per il campo "costo"
  else if (fieldSEL === "costo") {
    arrayToSort.sort((a, b) => {
      const x = calcoloCosto(a.prezzo_unitario, a.quantita);
      const y = calcoloCosto(b.prezzo_unitario, b.quantita);
      return sortOrder === "asc" ? x - y : y - x;
    });
  }
  // Logica di ordinamento generica per gli altri campi
  else {
    arrayToSort.sort((a, b) => {
      const x = a[fieldSEL];
      const y = b[fieldSEL];
      return sortOrder === "asc"
        ? x < y
          ? -1
          : x > y
          ? 1
          : 0
        : x > y
        ? -1
        : x < y
        ? 1
        : 0;
    });
  }

  // Aggiorna i risultati ordinati
  if (searchedFlag) {
    setFilteredResults(arrayToSort);
  } else {
    setFileData(arrayToSort);
  }

  // Cambia l'ordine di ordinamento per il prossimo click
  setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
};

const handleColumnOrder = (
  columnSEL,
  searchedFlag,
  filteredResults,
  fileData,
  sortOrder,
  setFilteredResults,
  setFileData,
  setSortOrder
) => {
  handleSortNEW(
    columnSEL?.field,
    searchedFlag,
    filteredResults,
    fileData,
    sortOrder,
    setFilteredResults,
    setFileData,
    setSortOrder
  );
};

export { handleColumnOrder, sortItems };
