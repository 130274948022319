import abbinamentoService from "../../services/abbinamento";

import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
dayjs.extend(dayjsPluginUTC);

export const handlePopUpSaveABBClose = (
  setPopUpSaveABBOpen,
  setFileSavedName,
  setLoadingSaveABB
) => {
  setPopUpSaveABBOpen(false);
  setFileSavedName("");
  setLoadingSaveABB(false);
};

const ValidateForm = (fileSavedName) => {
  const fileSavedNameCheck = fileSavedName;
  const newErrors = {};

  if (!fileSavedNameCheck || fileSavedNameCheck === "") {
    newErrors.fileSavedName = "Inserire il nome del file!";
  }

  return newErrors;
};

export const handleSaveNewABB = async (
  user,
  fileSavedName,
  setErrors,
  setLoadingSaveABB,
  finalAbbinatoToSave,
  ACQ_NAME_SEL,
  LISTINO_NAME_SEL,
  setNEW_ABB_FLAG,
  setACQ_DOWNLOADED,
  handleBackToGenerateMain,
  setUpdateTrigger,
  setPopUpSaveABBOpen,
  setFileSavedName,
  setListaStrutture,
  setStruttureSelected,
  setSTRUTT_SEL_FLAG
) => {
  const formErrors = ValidateForm(fileSavedName);
  if (Object.keys(formErrors).length > 0) {
    setErrors(formErrors);
  } else {
    setErrors({});
    setLoadingSaveABB(true);

    const finaleAbbinamentoDate = dayjs()
      .utc()
      .local()
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");

    let finalFileData = {
      nome_abbinato: fileSavedName,
      data: finaleAbbinamentoDate,
      nome_acquistato: ACQ_NAME_SEL.nome_salvataggio,
      nome_listino: LISTINO_NAME_SEL.nome_listino,
      obj_file: finalAbbinatoToSave,
    };

    console.log("finalFileData", finalFileData);

    try {
      abbinamentoService.setToken(user.token);
      await abbinamentoService.salvaAbbinamento(finalFileData);

      setNEW_ABB_FLAG(false);

      setACQ_DOWNLOADED(false);

      setListaStrutture(null);
      setStruttureSelected(null);
      setSTRUTT_SEL_FLAG(false);

      handlePopUpSaveABBClose(
        setPopUpSaveABBOpen,
        setFileSavedName,
        setLoadingSaveABB
      );
      handleBackToGenerateMain();
      setUpdateTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Errore durante il salvataggio:", error);
    } finally {
      setLoadingSaveABB(false);
    }
  }
};
