import React from "react";
import "../../../../App.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";

import BeatLoader from "react-spinners/BeatLoader";

const AbbGenValutation = ({
  setSTRUTT_SEL_FLAG,
  loadingOfferList,
  offerList,
  LISTINO_NAME_SEL,
  setLISTINO_NAME_SEL,
  handleCreaAbbinatoCALL,
  loadingABBValutation,
}) => {
  return (
    <>
      <div
        style={{
          textAlign: "left",
          marginBottom: "1%",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="redtest"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
          onClick={() => setSTRUTT_SEL_FLAG(false)}
        >
          INDIETRO
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          paddingTop: "3%",
          display: "inline-block",
          width: "50%",
        }}
      >
        {loadingOfferList ? (
          <div>
            <BeatLoader
              color={"#1976d2"}
              loading={loadingOfferList}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <h6
              style={{
                fontFamily: "Calibri",
                color: "#1976d2",
              }}
            >
              CARICAMENTO...
            </h6>
          </div>
        ) : (
          <>
            <p
              style={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 25,
              }}
            >
              OFFERTE INSERITE
            </p>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={offerList}
              getOptionLabel={(option) => option.nome_listino}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                width: "100%",
                paddingTop: "2%",
                paddingLeft: "20%",
                paddingRight: "20%",
              }}
              renderInput={(params) => (
                <TextField {...params} label="OFFERTE" />
              )}
              value={LISTINO_NAME_SEL}
              onChange={(e, newValue) => {
                setLISTINO_NAME_SEL(newValue);
              }}
            />
            <div
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="bluetest"
                disabled={LISTINO_NAME_SEL === null}
                startIcon={<AddchartIcon />}
                onClick={() => handleCreaAbbinatoCALL()}
              >
                CREA ABBINATO
              </Button>
            </div>
            {loadingABBValutation && (
              <div>
                <BeatLoader
                  color={"#1976d2"}
                  loading={loadingABBValutation}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                <h6
                  style={{
                    fontFamily: "Calibri",
                    color: "#1976d2",
                  }}
                >
                  CARICAMENTO...
                </h6>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AbbGenValutation;
