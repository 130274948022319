import React, { useState } from "react";
import "../../../../App.css";

// COMPONENTS
import ResumeSelection from "./ResumeSelection";
import SelectionDetails from "./SelectionDetails";
import DaRichiedere from "./DaRichiedere";
import DispMedici from "./DispMedici";

// FUNCTIONS
import {
  handlePopUpSaveABBClose,
  handleSaveNewABB,
} from "../../../../functions/SAVE/ABBINATO_SAVE";

// POPUPS
import SAVE_ABB_POPUP from "../../../../popup/ABB_POPUPS/SAVE_ABB_POPUP";

const AbbinatoSelectionMain = ({
  user,
  NEW_ABB_FLAG,
  setNEW_ABB_FLAG,
  setTAB_ABB_FLAG,
  setUpdateTrigger,
  setACQ_DOWNLOADED,
  ACQ_NAME_SEL,
  LISTINO_NAME_SEL,
  finalAbbinatoToSave,
  setFinalAbbinatoToSave,
  abbinatoResume,
  setAbbinatoResume,
  specToWait,
  setSpecToWait,
  specToWaitATC,
  setSpecToWaitATC,
  garePubBench,
  setGarePubBench,
  setListaStrutture,
  setStruttureSelected,
  setSTRUTT_SEL_FLAG,
  specMatchedTOT,
}) => {
  // Stato singolo per gestire la vista attiva
  const [activeView, setActiveView] = useState("resumeSelection");

  const [popUpSaveABBOpen, setPopUpSaveABBOpen] = useState(false);
  const [loadingSaveABB, setLoadingSaveABB] = useState(false);

  const [fileSavedName, setFileSavedName] = useState("");
  const [errors, setErrors] = useState({});

  const handleBackToGenerateMain = () => {
    setTAB_ABB_FLAG(false);
  };

  const handleReturnToResumePage = () => {
    setActiveView("resumeSelection");
  };

  const handleSelectionListPage = (params) => {
    switch (params.row.title) {
      case "TOTALE ABBINAMENTI":
        setActiveView("selectionDetails");
        break;
      case "Molecole non pervenute":
        setActiveView("daRichiedere");
        break;
      case "Dispositivi medici":
        setActiveView("dispMedici");
        break;
      case "Abbinati (prezzo minimo multiplo)":
        // setPopUpExpMultipliOpen(true);
        break;
      default:
        setActiveView("resumeSelection");
    }
  };

  const handleClose = () => {
    handlePopUpSaveABBClose(
      setPopUpSaveABBOpen,
      setFileSavedName,
      setLoadingSaveABB
    );
  };

  const handleSave = () => {
    handleSaveNewABB(
      user,
      fileSavedName,
      setErrors,
      setLoadingSaveABB,
      finalAbbinatoToSave,
      ACQ_NAME_SEL,
      LISTINO_NAME_SEL,
      setNEW_ABB_FLAG,
      setACQ_DOWNLOADED,
      handleBackToGenerateMain,
      setUpdateTrigger,
      setPopUpSaveABBOpen,
      setFileSavedName,
      setListaStrutture,
      setStruttureSelected,
      setSTRUTT_SEL_FLAG
    );
  };

  // Oggetto mappa per gestire i componenti in base alla vista attiva
  const viewComponents = {
    resumeSelection: (
      <ResumeSelection
        NEW_ABB_FLAG={NEW_ABB_FLAG}
        handleBackToGenerateMain={handleBackToGenerateMain}
        handleSelectionListPage={handleSelectionListPage}
        abbinatoResume={abbinatoResume}
        garePubBench={garePubBench}
        setPopUpSaveABBOpen={setPopUpSaveABBOpen}
      />
    ),
    selectionDetails: (
      <SelectionDetails
        handleReturnToResumePage={handleReturnToResumePage}
        NEW_ABB_FLAG={NEW_ABB_FLAG}
        specMatchedTOT={specMatchedTOT}
      />
    ),
    daRichiedere: (
      <DaRichiedere
        handleReturnToResumePage={handleReturnToResumePage}
        specToWait={specToWait}
      />
    ),
    dispMedici: (
      <DispMedici
        handleReturnToResumePage={handleReturnToResumePage}
        specToWaitATC={specToWaitATC}
      />
    ),
  };

  return (
    <>
      {viewComponents[activeView]}
      <SAVE_ABB_POPUP
        popUpSaveABBOpen={popUpSaveABBOpen}
        loadingSaveABB={loadingSaveABB}
        handleClose={handleClose}
        fileSavedName={fileSavedName}
        setFileSavedName={setFileSavedName}
        errors={errors}
        handleSave={handleSave}
      />
    </>
  );
};

export default AbbinatoSelectionMain;
