import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

const DataGridDisplay = ({
  columns,
  rows,
  getRowId,
  headerHeight,
  rowHeight,
}) => {
  return (
    <Box
      sx={{
        height: "418px",
        width: "100%",
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingTop: "1%",
        "& .theme_header": {
          backgroundColor: "#1976d2",
          color: "#fff",
          fontFamily: "Calibri",
          fontSize: "17px",
        },
        "& .theme_cell": {
          fontFamily: "Calibri",
          fontSize: "18px",
          border: "1px solid",
        },
      }}
    >
      <DataGridPro
        columns={columns}
        rows={rows}
        getRowId={getRowId}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        disableColumnMenu
        hideFooter
      />
    </Box>
  );
};

export default DataGridDisplay;
