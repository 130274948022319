import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_ACQ_CONTROLLO_FORNITORI = ({ data }) => {
  console.log("data", data);
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "CONTROLLO FORNITORI FILE INSERITO.xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "FORNITORE",
        B: "P. IVA DA CONTROLLARE",
        // C: "AIC",
        // D: "AIC FARMADATI",
        // E: "NOME COMMERCIALE",
        // F: "NOME COMMERCIALE FARMADATI",
        // G: "ATC",
        // H: "ATC FARMADATI",
        // I: "ATC COMP.",
        // J: "ATC COMP. FARMADATI",
        // K: "FORNITORE",
        // L: "PREZZO UNITARIO",
        // M: "QUANTITA",
      },
    ];

    // let valNOMATCH = [];
    // let rowNum = 0;

    data.forEach((row) => {
      // if (row?.aic_farmadati === "NO MATCH") {
      //   valNOMATCH.push(rowNum + 2);
      // }

      table1.push({
        A: row?.ragione_sociale,
        B: row?.cf_piva,
        // C: row?.aic,
        // D: row?.aic_farmadati,
        // E: row?.nome_commerciale,
        // F: row?.nome_commerciale_farmadati,
        // G: row?.atc,
        // H: row?.atc_farmadati,
        // I: row?.atc_complementare,
        // J: row?.atc_complementare_farmadati,
        // K: row?.fornitore.ragione_sociale,
        // L: row?.prezzo_unitario,
        // M: row?.quantita,
      });

      // rowNum = rowNum + 1;
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "CONFRONTO FARMADATI ACQ");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:B1`,
      // valNOMATCH: valNOMATCH,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(35);
        sheet.column("B").width(35);
        // sheet.column("C").width(14).style({ horizontalAlignment: "center" });
        // sheet.column("D").width(18).style({ horizontalAlignment: "center" });
        // sheet.column("E").width(35);
        // sheet.column("F").width(35);
        // sheet.column("G").width(14).style({ horizontalAlignment: "center" });
        // sheet.column("H").width(18).style({ horizontalAlignment: "center" });
        // sheet.column("I").width(22).style({ horizontalAlignment: "center" });
        // sheet.column("J").width(22).style({ horizontalAlignment: "center" });
        // sheet.column("K").width(35);
        // sheet
        //   .column("L")
        //   .width(18)
        //   .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });
        // sheet.column("M").width(16).style({ horizontalAlignment: "right" });

        // dataInfo.valNOMATCH.forEach((VAL) => {
        //   sheet.cell(VAL, 2).style({
        //     fontColor: "ff0000",
        //     bold: false,
        //   });
        //   sheet.cell(VAL, 4).style({
        //     fontColor: "ff0000",
        //     bold: false,
        //   });
        //   sheet.cell(VAL, 6).style({
        //     fontColor: "ff0000",
        //     bold: false,
        //   });
        //   sheet.cell(VAL, 8).style({
        //     fontColor: "ff0000",
        //     bold: false,
        //   });
        //   sheet.cell(VAL, 10).style({
        //     fontColor: "ff0000",
        //     bold: false,
        //   });
        // });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        CONTROLLO FORNITORI
      </LoadingButton>
    </>
  );
};

export default EXP_ACQ_CONTROLLO_FORNITORI;
