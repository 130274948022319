import React, { useState } from "react";
import "../../../../App.css";

// SERVICES
// COMPONENTS
// FUNCTIONS
// COLUMNS
import { ABB_SELECTED_LIST_COLUMNS } from "../../../../colums/ABB_COLUMNS";

// EXPS
// POPUPS
// MUI ELEMENTS
import Button from "@mui/material/Button";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

// ICONS
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

// LOADERS
// OPTIONS

const SelectionDetails = ({
  handleReturnToResumePage,
  NEW_ABB_FLAG,
  specMatchedTOT,
}) => {
  const [searchedFlag, setSearchedFlag] = useState(false);
  const [arrayGrupedFlag_SPEC, setArrayGrupedFlag_SPEC] = useState(false);

  const commonCellStyle = {
    fontFamily: "Calibri",
    fontSize: "17px",
    border: "1px solid #000",
  };

  return (
    <>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ width: "25%", textAlign: "left" }}></div>
        <div style={{ width: "25%" }}></div>
        <div style={{ width: "25%" }}></div>
      </div> */}
      <div>
        <div
          style={{
            textAlign: "left",
            paddingLeft: "0.3%",
            display: "inline-block",
            width: "10%",
          }}
        >
          <Button
            variant="contained"
            component="label"
            color="redtest"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
            onClick={handleReturnToResumePage}
          >
            INDIETRO
          </Button>
        </div>
        {!arrayGrupedFlag_SPEC ? (
          <>
            {" "}
            <div
              style={{
                display: "inline-block",
                width: "15%",
                textAlign: "left",
              }}
            >
              <Button
                variant="contained"
                color="bluetest"
                startIcon={<SearchIcon />}
                // onClick={() => setSearchPopupOpen(true)}
                disabled
              >
                CERCA
              </Button>
            </div>
            {!searchedFlag ? (
              <div
                style={{
                  display: "inline-block",
                  width: "25%",
                  textAlign: "left",
                }}
              ></div>
            ) : (
              <div
                style={{
                  display: "inline-block",
                  width: "25%",
                  textAlign: "left",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  startIcon={<RestartAltIcon />}
                  // onClick={handleResetSearch}
                  disabled
                >
                  RESET CERCA
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            {" "}
            <div
              style={{
                display: "inline-block",
                width: "35%",
                textAlign: "left",
              }}
            ></div>
          </>
        )}

        <div
          style={{
            textAlign: "right",
            display: "inline-block",
            width: "10%",
          }}
        >
          dettaglio
          {/* <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Switch
                        checked={arrayGrupedFlag_SPEC}
                        onChange={() =>
                          setArrayGrupedFlag_SPEC(!arrayGrupedFlag_SPEC)
                        }
                      />
                    }
                    label={
                      <h4
                        style={{
                          fontFamily: "Calibri",
                          color: arrayGrupedFlag_SPEC ? "#1976d2" : "#ccc",
                        }}
                      >
                        DETTAGLIO TIPOLOGIA
                      </h4>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl> */}
        </div>
        <div
          style={{
            textAlign: "right",
            display: "inline-block",
            width: "20%",
          }}
        >
          exp
          {/* <EXP_ABB_SELECTED_TAB
                data={specMatchedTOT}
                abb_name={abbinatoDownloaded?.nome_abbinato}
              /> */}
        </div>
        <div
          style={{
            textAlign: "right",
            paddingRight: "0.3%",
            display: "inline-block",
            width: "15%",
          }}
        >
          {NEW_ABB_FLAG ? (
            <>
              <Button
                variant="contained"
                color="success"
                startIcon={<SaveOutlinedIcon />}
                // onClick={() => setPopUpSaveABBOpen(true)}
                disabled
              >
                PROSEGUI E SALVA
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="bluetest"
                startIcon={<SaveOutlinedIcon />}
                disabled
                // onClick={() => setPopUpUpdateABBOpen(true)}
              >
                SALVA MODIFICHE
              </Button>
            </>
          )}
        </div>
      </div>
      <div>
        {!arrayGrupedFlag_SPEC ? (
          <>
            <Box
              sx={{
                height: "550px",
                width: "100%",
                margin: "1% 0.3%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "16px",
                },
                "& .theme_cell": {
                  ...commonCellStyle,
                },
                "& .theme_cell_collectivePrize": {
                  ...commonCellStyle,
                  backgroundColor: "#d9d9d9",
                },
                "& .theme_cell_selected": {
                  ...commonCellStyle,
                  backgroundColor: "#CAFEBA",
                },
                "& .theme_cell_lost": {
                  ...commonCellStyle,
                  backgroundColor: "#FFC7CE",
                  color: "#9C0006",
                },
                "& .theme_cell_gain": {
                  ...commonCellStyle,
                  backgroundColor: "#C6EFCE",
                  color: "#006100",
                },
              }}
            >
              {/* {!searchedFlag ? ( */}
              <DataGridPro
                columns={ABB_SELECTED_LIST_COLUMNS}
                rows={specMatchedTOT}
                getRowId={(row) => row._id}
                headerHeight={40}
                rowHeight={33}
                initialState={{
                  pinnedColumns: {
                    left: [
                      "principio_attivo",
                      "descrizione_gruppo_ospedaliero",
                      "diff_spesa",
                    ],
                  },
                }}
                // onRowDoubleClick={handleSpecSelectionPage}
                disableColumnMenu
                hideFooter
              />
              {/*    ) : (
                  <DataGridPro
                    columns={ABB_SELECTED_LIST_COLUMNS}
                    rows={filteredResults}
                    getRowId={(row) => row._id}
                    headerHeight={40}
                    rowHeight={33}
                    initialState={{
                      pinnedColumns: {
                        left: [
                          "principio_attivo",
                          "descrizione_gruppo_ospedaliero",
                          "diff_spesa",
                        ],
                      },
                    }}
                    onRowDoubleClick={handleSpecSelectionPage}
                    disableColumnMenu
                    hideFooter
                  />
                )}*/}
            </Box>
          </>
        ) : (
          <h2>ftetfe</h2>
          // <>
          //   <Box
          //     sx={{
          //       height: "500px",
          //       width: "100%",
          //       paddingTop: "0.5%",
          //       paddingLeft: "4%",
          //       paddingRight: "4%",

          //       "& .theme_header": {
          //         backgroundColor: "#1976d2",
          //         color: "#fff",
          //         fontFamily: "Calibri",
          //         fontSize: "16px",
          //       },
          //       "& .theme_cell": {
          //         fontFamily: "Calibri",
          //         fontSize: "17px",
          //         border: "1px solid #000",
          //       },
          //       "& .theme_cell_lost": {
          //         fontFamily: "Calibri",
          //         backgroundColor: "#FFC7CE",
          //         color: "#9C0006",
          //         fontSize: "17px",
          //         border: "1px solid",
          //       },
          //       "& .theme_cell_gain": {
          //         fontFamily: "Calibri",
          //         backgroundColor: "#C6EFCE",
          //         color: "#006100",
          //         fontSize: "17px",
          //         border: "1px solid",
          //       },
          //     }}
          //   >
          //     <DataGridPro
          //       columns={GARE_GROUPED_ACQ_COLUMNS_TEST}
          //       rows={specMatchedTOT_RESUME}
          //       getRowId={(row) => row.id_temp}
          //       headerHeight={40}
          //       rowHeight={33}
          //       // onRowDoubleClick={handleSpecSelectionPage}
          //       disableColumnMenu
          //       hideFooter
          //     />
          //   </Box>
          // </>
        )}
      </div>
    </>
  );
};

export default SelectionDetails;
