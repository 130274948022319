import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

const LoadingSpinner = ({ loading }) => {
  return (
    <div style={{ textAlign: "center", paddingTop: "3%" }}>
      <BeatLoader
        color={"#1976d2"}
        loading={loading}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
        CARICAMENTO...
      </h6>
    </div>
  );
};

export default LoadingSpinner;
