import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//Funzioni
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EXP_DETTAGLIO_GP = ({ data, file_name }) => {
  // console.log("data", data);
  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", file_name + ".xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "AIC GARA PUB",
        D: "NOME COMM. PUB",
        E: "FORNITORE PUB",
        F: "REGIONE",
        G: "Q.",
        H: "PREZZO GARA PUB",
        I: "SPESA GARA PUB",
        J: "DIFF. SPESA GARA PUB SU ACQUISTATO",
        K: "AIC ACQ. 23",
        L: "NOME COMM. ACQ. 23",
        M: "FORNITORE ACQ. 23",
        N: "PREZZO ACQ. 23",
        O: "SPESA 2023",
        P: "QUANTITA'",
      },
    ];

    let tableTEMP = [];

    let min = [];
    let mag = [];
    let rowNum = 0;

    data.forEach((row) => {
      let diffCosto = calcoloDifferenzaCosto(
        calcoloCosto(row?.prezzo_unitario, row?.quantita),
        calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita)
      );

      if (diffCosto > 0) {
        mag.push(rowNum + 2);
      } else if (diffCosto < 0) {
        min.push(rowNum + 2);
      }

      tableTEMP.push({
        A: row?.principio_attivo,
        B: row?.descrizione_gruppo_ospedaliero,
        C: row?.garePubList[0].aic,
        D: row?.garePubList[0].nome_commerciale,
        E: row?.garePubList[0].ragione_sociale,
        F: row?.garePubList[0].regione,
        G: row?.quantita,
        H: row?.garePubList[0].prezzo_unitario,
        I: calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita),
        J: diffCosto,
        K: row?.aic,
        L: row?.nome_commerciale,
        M: row?.fornitore.ragione_sociale,
        N: row?.prezzo_unitario,
        O: calcoloCosto(row?.prezzo_unitario, row?.quantita),
        P: row?.quantita,
      });

      rowNum = rowNum + 1;
    });

    await tableTEMP.sort((a, b) => {
      let x = a.J;
      let y = b.J;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    table1 = table1.concat(tableTEMP);
    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "DETTAGLIO GARE PUB");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:P1`,
      // theadRange2: `A1:N1`,

      min: min,
      mag: mag,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets("CON GARE PUB").forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(35);
        sheet.column("B").width(50);
        sheet.column("C").width(16).style({ horizontalAlignment: "center" });
        sheet.column("D").width(30);
        sheet.column("E").width(28);
        sheet.column("F").width(18);
        sheet.column("G").width(14).style({ horizontalAlignment: "center" });
        sheet.column("H").width(20).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00000",
        });
        sheet
          .column("I")
          .width(22)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet
          .column("J")
          .width(35)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet.column("K").width(20).style({ horizontalAlignment: "center" });
        sheet.column("L").width(35);
        sheet.column("M").width(28);

        sheet.column("N").width(23).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00000",
        });
        sheet
          .column("O")
          .width(23)
          .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        sheet.column("P").width(17).style({ horizontalAlignment: "center" });
        // sheet.column("Q").width(30);
        // sheet.column("R").width(28);

        // sheet.column("S").width(20).style({
        //   horizontalAlignment: "right",
        //   numberFormat: "#,##0.00000",
        // });
        // sheet
        //   .column("T")
        //   .width(21)
        //   .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
        // sheet.column("U").width(17).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }

        dataInfo.min.forEach((VAL) => {
          sheet.cell(VAL, 10).style({
            fill: "c6efce",
            fontColor: "006100",
            bold: false,
            border: true,
          });
        });
        dataInfo.mag.forEach((VAL) => {
          sheet.cell(VAL, 10).style({
            fill: "ffc7ce",
            fontColor: "9c0006",
            bold: false,
            border: true,
          });
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP DETTAGLIO GARE PUB
      </Button>
    </>
  );
};

export default EXP_DETTAGLIO_GP;
