//COLUMNS

const FABB_IMPORT_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 1.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANTITA'",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.5,
    align: "right",
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { align: "right", fontSize: 17 }
            : { color: "red", align: "right", fontSize: 17 }
        }
      >
        {params.row.quantita}
      </div>
    ),
  },
];

const FABB_ERRORS_COLUMNS = [
  {
    field: "id_temp",
    headerName: "RIGA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.id_temp + 2}</div>
    ),
  },
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_struttura_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "center",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_aic_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "center",
    flex: 0.6,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_name_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },

  {
    field: "quantita",
    headerName: "QUANTITA'",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_quantita_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "right",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.quantita}</div>
    ),
  },
];

const FABB_CONFRONTO_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 1.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "nome_commerciale_farmadati",
    headerName: "NOME COMMERCIALE FARMADATI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    flex: 1.5,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 17 }
            : { fontSize: 17 }
        }
      >
        {params.row?.nome_commerciale_farmadati}
      </div>
    ),
  },
];

const FABB_MAIN_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
  },
  {
    field: "aic",
    headerName: "AIC",
    align: "center",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    align: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
  },
  {
    field: "quantita",
    headerName: "Q",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { align: "right" }
            : { color: "red", align: "right" }
        }
      >
        {params.row.quantita}
      </div>
    ),
  },
];

const FABB_MAIN_ELAB_COLUMNS = [
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
  },
  {
    field: "tipologia_descr",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
  },

  {
    field: "tot_quantita",
    headerName: "QUANTITA'",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.tot_quantita > 0
            ? { align: "right" }
            : { color: "red", align: "right" }
        }
      >
        {params.row.tot_quantita}
      </div>
    ),
  },
];

export {
  FABB_IMPORT_COLUMNS,
  FABB_ERRORS_COLUMNS,
  FABB_CONFRONTO_COLUMNS,
  FABB_MAIN_COLUMNS,
  FABB_MAIN_ELAB_COLUMNS,
};
