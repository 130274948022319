const calcoloDifferenzaPerc = (Costo, Differenza) => {
  if (Costo === 0) return 0;
  let diff_temp = (Differenza / Costo) * 100;
  return Math.round(diff_temp * 100) / 100;
};

const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
  let diff_temp = CostoOffSel - CostoACQ;
  return Math.round(diff_temp * 100) / 100;
};

const calcoloPercentuale = (NumPerc, Totale) => {
  const PERC_TEMP = (NumPerc / Totale) * 100;
  return Math.round(PERC_TEMP * 100) / 100;
};

export { calcoloDifferenzaPerc, calcoloDifferenzaCosto, calcoloPercentuale };
