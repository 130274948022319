import React, { useState, useEffect } from "react";
import "../../../../App.css";

// SERVICES
import acquistatoService from "../../../../services/acquistato";
import offersResumeService from "../../../../services/offers_resume";
import abbinamentoService from "../../../../services/abbinamento";

// COMPONENTS
import AbbGenStrutture from "./AbbGenStrutture";
import AbbGenValutation from "./AbbGenValutation";

import AbbSelection from "./AbbSelection";

// FUNCTIONS
import {
  handleCreaAbbinato,
  handleAbbinatoValutation,
  handleCreateSpecResume,
} from "../../../../functions/VALUTATION/ABB_GEN_VALUTATION";

// COLUMNS
// EXPS
// POPUPS
// MUI ELEMENTS
import Button from "@mui/material/Button";

// ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

// LOADERS
// OPTIONS

const AbbinatoGenerateMain = ({
  user,
  NEW_ABB_FLAG,
  setNEW_ABB_FLAG,
  setTAB_ABB_FLAG,
  ACQ_NAME_SEL,
  setACQ_NAME_SEL,
  ACQ_DOWNLOADED,
  setACQ_DOWNLOADED,

  LISTINO_NAME_SEL,
  setLISTINO_NAME_SEL,
  setFinalAbbinatoToSave,
  setAbbinatoResume,
  setSpecMatchedTOT,
  setSpecMatchedTOT_RESUME,
  setSpecToWait,
  setSpecToWaitATC,
  setGarePubBench,
  listaStrutture,
  setListaStrutture,
  struttureSelected,
  setStruttureSelected,
  setSTRUTT_SEL_FLAG,
  STRUTT_SEL_FLAG,
  updateTrigger,
}) => {
  const [loadingPurchasedList, setLoadingPurchasedList] = useState(true);

  const [purchasedList, setPurchasedList] = useState([]);

  const [loadingListaStrutture, setLoadingListaStrutture] = useState(false);

  const [loadingOfferList, setLoadingOfferList] = useState(true);

  const [abbinatiList, setAbbinatiList] = useState([]);

  const [ABB_NAME_SEL, setABB_NAME_SEL] = useState(null);
  const [ABB_DOWNLOADED, setABB_DOWNLOADED] = useState(null);

  const [loadingAbbinatiList, setLoadingAbbinatiList] = useState(true);

  const [offerList, setOfferList] = useState([]);

  const [loadingABBValutation, setLoadingABBValutation] = useState(false);

  //?ACQ SELECTION
  const handlePurchasedSelection = (newValue) => {
    if (newValue === null) {
      setACQ_DOWNLOADED(null);
      setListaStrutture(null);
    }

    setACQ_NAME_SEL(newValue);
  };

  const handleCreaListaStrutture = async (AQC_SEL) => {
    if (!AQC_SEL?.id) {
      console.error("Invalid selection ID");
      return;
    }

    setLoadingListaStrutture(true);

    try {
      let purcasedDWN = await acquistatoService.getAcquistatoByPurchasedId(
        AQC_SEL.id
      );
      let purchasedTEMP = purcasedDWN.data;

      let listaStruttureTEMP = ["TUTTE LE STRUTTURE"];

      await purchasedTEMP.acquistato_inserito.forEach((SEL) => {
        listaStruttureTEMP.push(SEL.struttura);
      });

      const listaStrutture_DoppiRimossi = [...new Set(listaStruttureTEMP)];

      let finalListaStruttureTEMP = [];
      let countTEMP = 0;

      await listaStrutture_DoppiRimossi.forEach((SEL) => {
        finalListaStruttureTEMP.push({ id: countTEMP++, nome_struttura: SEL });
      });

      setACQ_DOWNLOADED(purchasedTEMP);
      setListaStrutture(finalListaStruttureTEMP);
    } catch (error) {
      console.error("Errore durante il recupero dei dati acquistati", error);
    } finally {
      setLoadingListaStrutture(false);
    }
  };

  const handleStruttureChange = (e, newValue) => {
    const tutteLeStruttureSelezionato = newValue.some(
      (option) => option.id === 0
    );

    if (tutteLeStruttureSelezionato) {
      const tutteLeStrutture = listaStrutture.filter(
        (option) => option.id !== 0
      );
      setStruttureSelected(tutteLeStrutture);
    } else {
      setStruttureSelected(newValue);
    }
  };

  const handleCreaAbbinatoCALL = () => {
    handleCreaAbbinato(
      LISTINO_NAME_SEL,
      ACQ_DOWNLOADED,
      struttureSelected,
      setLoadingABBValutation,
      setFinalAbbinatoToSave,
      setAbbinatoResume,

      setSpecMatchedTOT,
      setSpecMatchedTOT_RESUME,

      setSpecToWait,
      setSpecToWaitATC,

      setGarePubBench,

      setTAB_ABB_FLAG
    );
  };

  const handleDownloadAbbinato = async () => {
    try {
      setLoadingABBValutation(true);

      const abbinatoDWN =
        await abbinamentoService.getAbbinamentoByAbbinamentoId(ABB_NAME_SEL.id);

      let OBJToValue = [...abbinatoDWN.data.obj_file];

      const finalOBJ = await handleAbbinatoValutation(OBJToValue);

      const resumeSPEC_TEST = await handleCreateSpecResume(
        finalOBJ.SPEC_TO_CHECK
      );

      setABB_DOWNLOADED(abbinatoDWN.data);

      setAbbinatoResume(finalOBJ.RESUME_TAB);
      setSpecMatchedTOT(finalOBJ.SPEC_TO_CHECK);
      setSpecMatchedTOT_RESUME(resumeSPEC_TEST);
      setFinalAbbinatoToSave(finalOBJ.OBJ_COMPLETE);
      setSpecToWait(finalOBJ.TO_WAIT);
      setSpecToWaitATC(finalOBJ.TO_WAIT_ATC);
      setGarePubBench(finalOBJ.GARE_PUB);

      setTAB_ABB_FLAG(true);

      //? ERRORI
    } catch (error) {
      console.error("Errore durante il recupero dei dati: ", error);
      //? FINALE
    } finally {
      setLoadingABBValutation(false);
    }
  };

  useEffect(() => {
    if (!user.id) return;

    acquistatoService.getAcquistatoByUserId(user.id).then((res) => {
      setPurchasedList(res.data);
      setLoadingPurchasedList(false);
    });
    offersResumeService.getOfferaResumeByUserId(user.id).then((res) => {
      setOfferList(res.data);
      setLoadingOfferList(false);
    });
    abbinamentoService.getAbbinamentoByUserId(user.id).then((res) => {
      setAbbinatiList(res.data);
      setLoadingAbbinatiList(false);
    });
  }, [updateTrigger, user.id]);
  return (
    <>
      <div
        style={{
          textAlign: "right",
          marginBottom: "1%",
        }}
      >
        <Button
          variant="contained"
          component="label"
          color="bluetest"
          startIcon={!NEW_ABB_FLAG ? <AddCircleIcon /> : <ListAltIcon />}
          onClick={() => setNEW_ABB_FLAG(!NEW_ABB_FLAG)}
        >
          {!NEW_ABB_FLAG ? "CREA NUOVO ABBINATO" : "VISUALIZZA ABBINATI"}
        </Button>
      </div>
      {!NEW_ABB_FLAG ? (
        <>
          <AbbSelection
            loadingAbbinatiList={loadingAbbinatiList}
            abbinatiList={abbinatiList}
            setABB_NAME_SEL={setABB_NAME_SEL}
            ABB_NAME_SEL={ABB_NAME_SEL}
            handleDownloadAbbinato={handleDownloadAbbinato}
            loadingABBValutation={loadingABBValutation}
          />
        </>
      ) : (
        <>
          {!STRUTT_SEL_FLAG ? (
            <>
              <AbbGenStrutture
                loadingPurchasedList={loadingPurchasedList}
                purchasedList={purchasedList}
                ACQ_NAME_SEL={ACQ_NAME_SEL}
                handlePurchasedSelection={handlePurchasedSelection}
                handleCreaListaStrutture={handleCreaListaStrutture}
                loadingListaStrutture={loadingListaStrutture}
                listaStrutture={listaStrutture}
                struttureSelected={struttureSelected}
                handleStruttureChange={handleStruttureChange}
                setSTRUTT_SEL_FLAG={setSTRUTT_SEL_FLAG}
              />
            </>
          ) : (
            <AbbGenValutation
              setSTRUTT_SEL_FLAG={setSTRUTT_SEL_FLAG}
              loadingOfferList={loadingOfferList}
              offerList={offerList}
              LISTINO_NAME_SEL={LISTINO_NAME_SEL}
              setLISTINO_NAME_SEL={setLISTINO_NAME_SEL}
              handleCreaAbbinatoCALL={handleCreaAbbinatoCALL}
              loadingABBValutation={loadingABBValutation}
            />
          )}
        </>
      )}
    </>
  );
};

export default AbbinatoGenerateMain;
