import React from "react";
import { Outlet } from "react-router-dom";
import HeaderLogin from "./HeaderLogin";
import Footer from "../Footer";
//! Capire come mai non me li importa da qui import "../../App.css";

const LayoutLogin = () => {
  return (
    <div
      // className="root"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <HeaderLogin />
      <div
        // className="contentPage"
        style={{
          // maxWidth: "1600px",
          // margin: "0 auto",
          padding: "0.5%",
          maxHeight: "1000px",
          flex: 1,
        }}
      >
        {/* <div > */}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default LayoutLogin;
