import React, { useState } from "react";
import "../../../App.css";

// COMPONENTS
import PurchasedInsert from "./INSERT/PurchasedInsert";
import PurchasedView from "./VIEW/PurchasedView";

// MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const PurchasedMain = ({ user }) => {
  const [insertPageFlag, setInsertPageFlag] = useState(false);
  const [changePageFlag, setChangePageFlag] = useState(true);

  const toggleInsertPage = () => setInsertPageFlag(!insertPageFlag);

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        {changePageFlag && (
          <div className="button-container-acqChangePage">
            <Button
              variant="contained"
              color="bluetest"
              startIcon={insertPageFlag ? <ListAltIcon /> : <AddCircleIcon />}
              onClick={toggleInsertPage}
            >
              {insertPageFlag
                ? "VISUALIZZA ACQUISTATI"
                : "INSERISCI NUOVO ACQUISTATO"}
            </Button>
          </div>
        )}

        {insertPageFlag ? (
          <PurchasedInsert user={user} setChangePageFlag={setChangePageFlag} />
        ) : (
          <PurchasedView user={user} setChangePageFlag={setChangePageFlag} />
        )}
      </div>
    </ThemeProvider>
  );
};

export default PurchasedMain;
