import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_PRINCIPIO_ATTIVO_ORDERED = ({ data, nome_listino }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const visualizzaData = (DataDaInvertire) => {
    let DataDivisa = DataDaInvertire.split("-");
    let DataInvertita =
      DataDivisa[2] + "/" + DataDivisa[1] + "/" + DataDivisa[0];
    return DataInvertita;
  };

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        `EXP - ORDINATO PER PRINCIPIO ATTIVO ${nome_listino}.xlsx`
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
      setLoadingEXPData(false);
    } catch (error) {
      console.error(
        "Si è verificato un errore durante l'esportazione: ",
        error
      );
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "ATC COMP",
        D: "FORNITORE",
        E: "AIC",
        F: "NOME COMMERCIALE",
        G: "PREZZO UNITARIO",
        H: "NUMERO UNITA CONFEZIONE",
        I: "PREZZO CONFEZIONE",
        J: "N PROTOCOLLO",
        K: "INIZIO VALIDITA'",
        L: "FINE VALIDITA'",
        M: "ORDINE MIN",
      },
    ];

    let arrayForSort = [...data];
    let groupedData = {};

    arrayForSort.forEach((row, index) => {
      const gruppo = row.descrizione_gruppo_ospedaliero;
      if (gruppo) {
        if (!groupedData[gruppo]) {
          groupedData[gruppo] = [];
        }
        groupedData[gruppo].push({ ...row, rowIndex: index + 2 }); // +2 per l'intestazione e 1-based index
      }
      table1.push({
        A: row?.principio_attivo,
        B: row?.descrizione_gruppo_ospedaliero,
        C: row?.atc_complementare,
        D: row?.ragione_sociale,
        E: row?.aic,
        F: row?.nome_commerciale,
        G: row?.prezzo_unitario,
        H: row?.numero_unita_confezione,
        I: row?.prezzo_pub_confezione,
        J: row?.numero_protocollo,
        K: visualizzaData(row?.date[0].split("T")[0]),
        L: visualizzaData(row?.date[1].split("T")[0]),
        M: row?.ordine_min,
      });
    });

    const wb = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(table1, { skipHeader: true });
    XLSX.utils.book_append_sheet(wb, sheet, "ORDINATO PER PRINCIPIO ATTIVO");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: "A1:M1",
      minPriceRows: findMinPriceRows(groupedData),
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const findMinPriceRows = (groupedData) => {
    let minPriceRows = [];
    for (const gruppo in groupedData) {
      if (groupedData.hasOwnProperty(gruppo)) {
        const rows = groupedData[gruppo];
        const minPriceRow = rows.reduce((minRow, currentRow) =>
          currentRow.prezzo_unitario < minRow.prezzo_unitario
            ? currentRow
            : minRow
        );
        minPriceRows.push(minPriceRow.rowIndex);
      }
    }
    return minPriceRows;
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      const sheet = workbook.sheet(0);
      sheet.usedRange().style({});

      sheet.column("A").width(30);
      sheet.column("B").width(35);
      sheet.column("C").width(15).style({ horizontalAlignment: "center" });
      sheet.column("D").width(30);
      sheet.column("E").width(15).style({ horizontalAlignment: "center" });
      sheet.column("F").width(30);
      sheet
        .column("G")
        .width(16)
        .style({ horizontalAlignment: "right", numberFormat: "#,##0.00000" });
      sheet.column("H").width(25);
      sheet.column("I").width(20);
      sheet.column("J").width(25).style({ horizontalAlignment: "center" });
      sheet.column("K").width(20).style({ horizontalAlignment: "center" });
      sheet.column("L").width(20).style({ horizontalAlignment: "center" });
      sheet.column("M").width(15).style({ horizontalAlignment: "center" });

      if (dataInfo.theadRange) {
        sheet.range(dataInfo.theadRange).style({
          fill: "1976d2",
          fontColor: "ffffff",
          bold: true,
          horizontalAlignment: "center",
        });
      }

      dataInfo.minPriceRows.forEach((rowIndex) => {
        // Itera attraverso le colonne dalla A alla M
        [
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
        ].forEach((col) => {
          sheet.cell(`${col}${rowIndex}`).style({ fill: "d1e6fa" });
        });
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <LoadingButton
      variant="contained"
      color="bluetest"
      loading={loadingEXPData}
      loadingPosition="start"
      startIcon={<FileDownloadOutlinedIcon />}
      onClick={createDownLoadData}
    >
      EXP - ORDINATO PER PRINCIPIO ATTIVO
    </LoadingButton>
  );
};

export default EXP_PRINCIPIO_ATTIVO_ORDERED;
