import React, { useState } from "react";
import "../../App.css";

// COMPONENTS
import OffersListView from "./VIEW/OffersListView";
import OffersListInsert from "./INSERT/OffersListInsert";

// MUI ELEMENTS
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// ICONS
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const OffersListMain = ({ user }) => {
  const [insertPageFlag, setInsertPageFlag] = useState(false);
  const [changePageFlag, setChangePageFlag] = useState(true);

  const toggleInsertPage = () => setInsertPageFlag(!insertPageFlag);

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        {changePageFlag && (
          <div className="button-container-acqChangePage">
            <Button
              variant="contained"
              color="bluetest"
              startIcon={insertPageFlag ? <ListAltIcon /> : <AddCircleIcon />}
              onClick={toggleInsertPage}
            >
              {insertPageFlag
                ? "VISUALIZZA LISTINI"
                : "INSERISCI NUOVO LISTINO"}
            </Button>
          </div>
        )}
        <div>
          {insertPageFlag ? (
            <OffersListInsert
              user={user}
              setChangePageFlag={setChangePageFlag}
            />
          ) : (
            <OffersListView user={user} setChangePageFlag={setChangePageFlag} />
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default OffersListMain;
