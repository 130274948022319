import React, { useState, useEffect } from "react";
import "../../App.css";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import ExpExcelTabResumeEnrico from "../EXP_COMPONENTS/EXP_ENRICO/ExpExcelTabResumeEnrico";
import ExpExcelTabResumeEnrico_BIS from "../EXP_COMPONENTS/EXP_ENRICO/ExpExcelTabResumeEnrico_BIS";

import ExpExcelConfrontoGP from "../EXP_COMPONENTS/EXP_ENRICO/ExpExcelConfrontoGP";
import ExpExcelConfrontoGP_BIS from "../EXP_COMPONENTS/EXP_ENRICO/ExpExcelConfrontoGP_BIS";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import enricoFinalService from "../../services/enrico_final";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

import BeatLoader from "react-spinners/BeatLoader";

//FUNZIONI
import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const EnricoFinal = (props) => {
  const { user } = props;

  const [enricosList, setEnricosList] = useState(null);
  const [enricosList_Selected, setEnricosList_Selected] = useState(null);

  const [enricoSelected, setEnricoSelected] = useState(null);
  // console.log("enricoSelected", enricoSelected);

  const [enricoResumeTAB, setEnricoResumeTAB] = useState(null);
  const [enricoValutationFlag, setEnricoValutationFlag] = useState(false);

  const [objToExp, setObjToExp] = useState(null);
  // console.log("objToExp", objToExp);

  const [loadingEnricosList, setLoadingEnricosList] = useState(true);
  const [loadingEnricoValutation, setLoadingEnricoValutation] = useState(false);

  useEffect(() => {
    enricoFinalService.getEnricoFinalByUserId(user.id).then((res) => {
      setEnricosList(res.data);
      setLoadingEnricosList(false);
    });
  }, []);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const RapprPrezzo = (PrezzoDaRappresentare) => {
    if (PrezzoDaRappresentare === "") {
      return PrezzoDaRappresentare;
    } else {
      let IntDec = PrezzoDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal + ",00";
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + " €";
    }
  };

  const RapprPerc = (PercDaRappresentare) => {
    if (PercDaRappresentare === "") {
      return PercDaRappresentare;
    } else {
      let IntDec = PercDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal;
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + "%";
    }
  };

  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const calcoloDifferenzaPerc = (Costo, Differenza) => {
    let diff_temp = (Differenza / Costo) * 100;
    return Math.round(diff_temp * 100) / 100;
  };

  const handleAbbinatoValutation = async (OBJTEMP) => {
    let OBJToValue = OBJTEMP.obj_file;

    // console.log("OBJTEMP", OBJTEMP);

    let toSelectSingle = [];
    let toSelectCollective = [];

    let toWait = [];
    let toWait_atc = [];

    let withGP = [];
    let withOutGP = [];

    let costoACQ_toSelectSingle = 0;
    let costoACQ_toSelectCollective = 0;

    let costoACQ_toWait = 0;
    let costoACQ_toWait_atc = 0;

    let costoMIN_toSelectSingle = 0;
    let costoMIN_toSelectCollective = 0;

    let costoSEL_toSelectSingle = 0;
    let costoSEL_toSelectCollective = 0;

    let costoGAREPUB_toSelectSingle = 0;
    let costoGAREPUB_toSelectCollective = 0;

    await OBJToValue.map((AbbSelected) => {
      if (AbbSelected.flag_match) {
        if (AbbSelected.flag_prize_collective) {
          costoACQ_toSelectCollective =
            costoACQ_toSelectCollective +
            calcoloCosto(AbbSelected.prezzo_unitario, AbbSelected.quantita);

          costoMIN_toSelectCollective =
            costoMIN_toSelectCollective +
            calcoloCosto(
              AbbSelected.cod_osp_list[0].prezzo_unitario,
              AbbSelected.cod_osp_list[0].quantita
            );

          costoSEL_toSelectCollective =
            costoSEL_toSelectCollective +
            calcoloCosto(
              AbbSelected.spec_checked.prezzo_unitario,
              AbbSelected.spec_checked.quantita
            );

          if (AbbSelected.garePubList.length > 0) {
            costoGAREPUB_toSelectCollective =
              costoGAREPUB_toSelectCollective +
              calcoloCosto(
                AbbSelected.garePubList[0].prezzo_unitario,
                AbbSelected.quantita
              );
            withGP.push(AbbSelected);
          } else {
            withOutGP.push(AbbSelected);
          }

          toSelectCollective.push(AbbSelected);
        } else {
          costoACQ_toSelectSingle =
            costoACQ_toSelectSingle +
            calcoloCosto(AbbSelected.prezzo_unitario, AbbSelected.quantita);

          costoMIN_toSelectSingle =
            costoMIN_toSelectSingle +
            calcoloCosto(
              AbbSelected.cod_osp_list[0].prezzo_unitario,
              AbbSelected.cod_osp_list[0].quantita
            );

          costoSEL_toSelectSingle =
            costoSEL_toSelectSingle +
            calcoloCosto(
              AbbSelected.spec_checked.prezzo_unitario,
              AbbSelected.spec_checked.quantita
            );

          if (AbbSelected.garePubList.length > 0) {
            costoGAREPUB_toSelectSingle =
              costoGAREPUB_toSelectSingle +
              calcoloCosto(
                AbbSelected.garePubList[0].prezzo_unitario,
                AbbSelected.quantita
              );
            withGP.push(AbbSelected);
          } else {
            withOutGP.push(AbbSelected);
          }

          toSelectSingle.push(AbbSelected);
        }
      } else if (AbbSelected.flag_gp_osp) {
        costoACQ_toWait =
          costoACQ_toWait +
          calcoloCosto(AbbSelected.prezzo_unitario, AbbSelected.quantita);

        toWait.push(AbbSelected);
      } else {
        costoACQ_toWait_atc =
          costoACQ_toWait_atc +
          calcoloCosto(AbbSelected.prezzo_unitario, AbbSelected.quantita);

        toWait_atc.push(AbbSelected);
      }

      return OBJToValue;
    });

    let toSelectTOT = toSelectSingle.concat(toSelectCollective);

    toSelectTOT.sort((a, b) => {
      let x = calcoloDifferenzaCosto(
        calcoloCosto(a.prezzo_unitario, a.quantita),
        calcoloCosto(a.spec_checked.prezzo_unitario, a.spec_checked.quantita)
      );
      let y = calcoloDifferenzaCosto(
        calcoloCosto(b.prezzo_unitario, b.quantita),
        calcoloCosto(b.spec_checked.prezzo_unitario, b.spec_checked.quantita)
      );

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    let SPEC_TO_SELECT_SINGLE = [];

    let SPEC_TO_SELECT_COLLECTIVE = [];

    let TOTALE_ABBINAMENTI = [];

    let SPEC_TO_WAIT = [];

    let SPEC_TO_WAIT_ATC = [];

    let TOTALE_NON_ABBINATI = [];

    let TOTALE_GENERALE = [];

    if (OBJTEMP.anno === "23") {
      SPEC_TO_SELECT_SINGLE = [
        {
          title: "Abbinati con unico prezzo minimo",
          // object: toSelectSingle,
          n_molecole: toSelectSingle.length,
          costo_acq: Math.round(costoACQ_toSelectSingle * 100) / 100,
          costo_minimo: Math.round(costoMIN_toSelectSingle * 100) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle - costoACQ_toSelectSingle) * 100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle,
            costoMIN_toSelectSingle - costoACQ_toSelectSingle
          ),
          costo_selezionato: Math.round(costoSEL_toSelectSingle * 100) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle - costoACQ_toSelectSingle) * 100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle,
            costoSEL_toSelectSingle - costoACQ_toSelectSingle
          ),

          costo_garepub: Math.round(7402953.04 * 100) / 100,
          diff_costo_acq_garepub:
            Math.round((7402953.04 - costoACQ_toSelectSingle) * 100) / 100,
          diff_perc_garepub: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle,
            7402953.04 - costoACQ_toSelectSingle
          ),
        },
      ];

      SPEC_TO_SELECT_COLLECTIVE = [
        {
          title: "Abbinati con prezzo minimo multiplo",
          // object: toSelectCollective,
          n_molecole: toSelectCollective.length,
          costo_acq: Math.round(costoACQ_toSelectCollective * 100) / 100,
          costo_minimo: Math.round(costoMIN_toSelectCollective * 100) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectCollective - costoACQ_toSelectCollective) * 100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectCollective,
            costoMIN_toSelectCollective - costoACQ_toSelectCollective
          ),
          costo_selezionato:
            Math.round(costoSEL_toSelectCollective * 100) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectCollective - costoACQ_toSelectCollective) * 100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectCollective,
            costoSEL_toSelectCollective - costoACQ_toSelectCollective
          ),

          costo_garepub: Math.round(1760645 * 100) / 100,
          diff_costo_acq_garepub:
            Math.round((1760645 - costoACQ_toSelectCollective) * 100) / 100,
          diff_perc_garepub: calcoloDifferenzaPerc(
            costoACQ_toSelectCollective,
            1760645 - costoACQ_toSelectCollective
          ),
        },
      ];

      TOTALE_ABBINAMENTI = [
        {
          title: "TOTALE ABBINAMENTI",
          // object: AbbinatoDWN.data,
          n_molecole: toSelectSingle.length + toSelectCollective.length,
          costo_acq:
            Math.round(
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective) * 100
            ) / 100,
          costo_minimo:
            Math.round(
              (costoMIN_toSelectSingle + costoMIN_toSelectCollective) * 100
            ) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle +
                costoMIN_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle + costoACQ_toSelectCollective,
            costoMIN_toSelectSingle +
              costoMIN_toSelectCollective -
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
          ),
          costo_selezionato:
            Math.round(
              (costoSEL_toSelectSingle + costoSEL_toSelectCollective) * 100
            ) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle +
                costoSEL_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle + costoACQ_toSelectCollective,
            costoSEL_toSelectSingle +
              costoSEL_toSelectCollective -
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
          ),

          costo_garepub: Math.round((7402953.04 + 1760645) * 100) / 100,
          diff_costo_acq_garepub:
            Math.round(
              (7402953.04 +
                1760645 -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_garepub: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle + costoACQ_toSelectCollective,
            7402953.04 +
              1760645 -
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
          ),
        },
      ];

      SPEC_TO_WAIT = [
        {
          title: "Molecole non pervenute",
          // object: toWait,
          n_molecole: toWait.length,
          costo_acq: Math.round(costoACQ_toWait * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      SPEC_TO_WAIT_ATC = [
        {
          title: "Dispositivi medici",
          // object: toWait_atc,
          n_molecole: toWait_atc.length,
          costo_acq: Math.round(costoACQ_toWait_atc * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      TOTALE_NON_ABBINATI = [
        {
          title: "TOTALE NON ABBINATI",
          // object: AbbinatoDWN.data,
          n_molecole: toWait.length + toWait_atc.length,
          costo_acq:
            Math.round((costoACQ_toWait + costoACQ_toWait_atc) * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      TOTALE_GENERALE = [
        {
          title: "TOTALE GENERALE",
          // object: AbbinatoDWN.data,
          n_molecole: OBJToValue.length,
          costo_acq:
            Math.round(
              (costoACQ_toSelectSingle +
                costoACQ_toSelectCollective +
                costoACQ_toWait +
                costoACQ_toWait_atc) *
                100
            ) / 100,

          costo_minimo: "",
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle +
                costoMIN_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle +
                costoSEL_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub:
            Math.round(
              (7402953.04 +
                1760645 -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_garepub: "",
        },
      ];
    } else {
      SPEC_TO_SELECT_SINGLE = [
        {
          title: "Abbinati con unico prezzo minimo",
          // object: toSelectSingle,
          n_molecole: toSelectSingle.length,
          costo_acq: Math.round(costoACQ_toSelectSingle * 100) / 100,
          costo_minimo: Math.round(costoMIN_toSelectSingle * 100) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle - costoACQ_toSelectSingle) * 100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle,
            costoMIN_toSelectSingle - costoACQ_toSelectSingle
          ),
          costo_selezionato: Math.round(costoSEL_toSelectSingle * 100) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle - costoACQ_toSelectSingle) * 100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle,
            costoSEL_toSelectSingle - costoACQ_toSelectSingle
          ),

          costo_garepub: Math.round(9542507.39 * 100) / 100,
          diff_costo_acq_garepub: Math.round(-373179.79 * 100) / 100,
          diff_perc_garepub: Math.round(-3.76 * 100) / 100,
        },
      ];

      SPEC_TO_SELECT_COLLECTIVE = [
        {
          title: "Abbinati con prezzo minimo multiplo",
          // object: toSelectCollective,
          n_molecole: toSelectCollective.length,
          costo_acq: Math.round(costoACQ_toSelectCollective * 100) / 100,
          costo_minimo: Math.round(costoMIN_toSelectCollective * 100) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectCollective - costoACQ_toSelectCollective) * 100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectCollective,
            costoMIN_toSelectCollective - costoACQ_toSelectCollective
          ),
          costo_selezionato:
            Math.round(costoSEL_toSelectCollective * 100) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectCollective - costoACQ_toSelectCollective) * 100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectCollective,
            costoSEL_toSelectCollective - costoACQ_toSelectCollective
          ),

          costo_garepub: Math.round(2219607.17 * 100) / 100,
          diff_costo_acq_garepub: Math.round(-89208.4 * 100) / 100,
          diff_perc_garepub: Math.round(-3.86 * 100) / 100,
        },
      ];

      TOTALE_ABBINAMENTI = [
        {
          title: "TOTALE ABBINAMENTI",
          // object: AbbinatoDWN.data,
          n_molecole: toSelectSingle.length + toSelectCollective.length,
          costo_acq:
            Math.round(
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective) * 100
            ) / 100,
          costo_minimo:
            Math.round(
              (costoMIN_toSelectSingle + costoMIN_toSelectCollective) * 100
            ) / 100,
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle +
                costoMIN_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_minimo: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle + costoACQ_toSelectCollective,
            costoMIN_toSelectSingle +
              costoMIN_toSelectCollective -
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
          ),
          costo_selezionato:
            Math.round(
              (costoSEL_toSelectSingle + costoSEL_toSelectCollective) * 100
            ) / 100,
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle +
                costoSEL_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_selezionato: calcoloDifferenzaPerc(
            costoACQ_toSelectSingle + costoACQ_toSelectCollective,
            costoSEL_toSelectSingle +
              costoSEL_toSelectCollective -
              (costoACQ_toSelectSingle + costoACQ_toSelectCollective)
          ),

          costo_garepub: Math.round(11762114.56 * 100) / 100,
          diff_costo_acq_garepub: Math.round(-462388.19 * 100) / 100,
          diff_perc_garepub: Math.round(-3.78 * 100) / 100,
        },
      ];

      SPEC_TO_WAIT = [
        {
          title: "Molecole non pervenute",
          // object: toWait,
          n_molecole: toWait.length,
          costo_acq: Math.round(costoACQ_toWait * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      SPEC_TO_WAIT_ATC = [
        {
          title: "Dispositivi medici",
          // object: toWait_atc,
          n_molecole: toWait_atc.length,
          costo_acq: Math.round(costoACQ_toWait_atc * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      TOTALE_NON_ABBINATI = [
        {
          title: "TOTALE NON ABBINATI",
          // object: AbbinatoDWN.data,
          n_molecole: toWait.length + toWait_atc.length,
          costo_acq:
            Math.round((costoACQ_toWait + costoACQ_toWait_atc) * 100) / 100,
          costo_minimo: "",
          diff_costo_acq_minimo: "",
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato: "",
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: "",
          diff_perc_garepub: "",
        },
      ];

      TOTALE_GENERALE = [
        {
          title: "TOTALE GENERALE",
          // object: AbbinatoDWN.data,
          n_molecole: OBJToValue.length,
          costo_acq:
            Math.round(
              (costoACQ_toSelectSingle +
                costoACQ_toSelectCollective +
                costoACQ_toWait +
                costoACQ_toWait_atc) *
                100
            ) / 100,

          costo_minimo: "",
          diff_costo_acq_minimo:
            Math.round(
              (costoMIN_toSelectSingle +
                costoMIN_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_minimo: "",
          costo_selezionato: "",
          diff_costo_acq_selezionato:
            Math.round(
              (costoSEL_toSelectSingle +
                costoSEL_toSelectCollective -
                (costoACQ_toSelectSingle + costoACQ_toSelectCollective)) *
                100
            ) / 100,
          diff_perc_selezionato: "",
          costo_garepub: "",
          diff_costo_acq_garepub: Math.round(-462388.19 * 100) / 100,

          diff_perc_garepub: "",
        },
      ];
    }

    let OBJ_TO_EXP = {
      WITH_GP: withGP,
      WITHOUT_GP: withOutGP,
    };

    setObjToExp(OBJ_TO_EXP);

    let ResumeTab = SPEC_TO_SELECT_SINGLE.concat(SPEC_TO_SELECT_COLLECTIVE)
      .concat(TOTALE_ABBINAMENTI)
      .concat(SPEC_TO_WAIT)
      .concat(SPEC_TO_WAIT_ATC)
      .concat(TOTALE_NON_ABBINATI)
      .concat(TOTALE_GENERALE);

    let OBJToReturn = {
      RESUME_TAB: ResumeTab,
      // SPEC_TO_CHECK: toSelectTOT,
      // OBJ_COMPLETE: OBJToValue,
    };
    return OBJToReturn;
  };

  const handleLoadEnrico = async () => {
    setLoadingEnricoValutation(true);
    let EnricoDWN = await enricoFinalService.getEnricoFinalByEnricoFinalId(
      enricosList_Selected.id
    );
    // console.log("EnricoDWN", EnricoDWN);
    let finalOBJ = await handleAbbinatoValutation(EnricoDWN.data);

    // console.log("finalOBJ", finalOBJ);

    setEnricoSelected(EnricoDWN.data);
    setEnricoResumeTAB(finalOBJ.RESUME_TAB);

    setEnricoValutationFlag(true);
    setLoadingEnricoValutation(false);
  };

  const handleBack = () => {
    setEnricoSelected(null);
    setEnricoResumeTAB(null);
    setEnricosList_Selected(null);
    setEnricoValutationFlag(false);
  };

  //TABS
  const handleCellClassNamePrize = (Num) => {
    if (Num === "") {
      return "theme_cell";
    } else if (Num >= 0) {
      return "theme_cell_lost";
    } else if (Num < 0) {
      return "theme_cell_gain";
    }
  };

  const columnsResume23 = [
    {
      field: "title",
      headerName: "ABBINAMENTI",
      headerAlign: "center",
      align: "left",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 230,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOTALE GENERALE" ||
            params.row.title === "TOTALE ABBINAMENTI" ||
            params.row.title === "TOTALE NON ABBINATI"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.title}
        </div>
      ),
    },
    {
      field: "n_molecole",
      headerName: "N.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOTALE GENERALE" ||
            params.row.title === "TOTALE ABBINAMENTI" ||
            params.row.title === "TOTALE NON ABBINATI"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.n_molecole}
        </div>
      ),
    },

    {
      field: "costo_acq",
      headerName: "COSTO 22",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 110,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.costo_acq) !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_acq)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "costo_minimo",
      headerName: "PREV. SPESA 23",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_minimo)}
            </div>
          );
        }
        return <div>{params.row.costo_minimo}</div>;
      },
    },
    {
      field: "diff_costo_acq_minimo",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_minimo),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_minimo)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_minimo}</div>;
      },
    },
    {
      field: "diff_perc_minimo",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_minimo),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_minimo)}
            </div>
          );
        }
        return <div></div>;
      },
    },
    {
      field: "costo_selezionato",
      headerName: "PREV. SPESA SEL.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_selezionato)}
            </div>
          );
        }
        return <div>{params.row.ccosto_selezionato}</div>;
      },
    },
    {
      field: "diff_costo_acq_selezionato",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_selezionato),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_selezionato)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_selezionato}</div>;
      },
    },
    {
      field: "diff_perc_selezionato",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_selezionato),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_selezionato)}
            </div>
          );
        }
        return <div></div>;
      },
    },
    {
      field: "costo_garepub",
      headerName: "PREV. GARE PUB.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_garepub)}
            </div>
          );
        }
        return <div>{params.row.ccosto_garepub}</div>;
      },
    },
    {
      field: "diff_costo_acq_garepub",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_garepub),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_garepub)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_garepub}</div>;
      },
    },
    {
      field: "diff_perc_garepub",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_garepub),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_garepub)}
            </div>
          );
        }
        return <div></div>;
      },
    },
  ];

  const columnsResume24 = [
    {
      field: "title",
      headerName: "ABBINAMENTI",
      headerAlign: "center",
      align: "left",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 230,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOTALE GENERALE" ||
            params.row.title === "TOTALE ABBINAMENTI" ||
            params.row.title === "TOTALE NON ABBINATI"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.title}
        </div>
      ),
    },
    {
      field: "n_molecole",
      headerName: "N.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOTALE GENERALE" ||
            params.row.title === "TOTALE ABBINAMENTI" ||
            params.row.title === "TOTALE NON ABBINATI"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.n_molecole}
        </div>
      ),
    },

    {
      field: "costo_acq",
      headerName: "COSTO 23",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 110,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.costo_acq) !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_acq)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "costo_minimo",
      headerName: "PREV. SPESA 24",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_minimo)}
            </div>
          );
        }
        return <div>{params.row.costo_minimo}</div>;
      },
    },
    {
      field: "diff_costo_acq_minimo",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_minimo),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_minimo)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_minimo}</div>;
      },
    },
    {
      field: "diff_perc_minimo",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_minimo),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_minimo !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_minimo)}
            </div>
          );
        }
        return <div></div>;
      },
    },
    {
      field: "costo_selezionato",
      headerName: "PREV. SPESA SEL.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_selezionato)}
            </div>
          );
        }
        return <div>{params.row.ccosto_selezionato}</div>;
      },
    },
    {
      field: "diff_costo_acq_selezionato",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_selezionato),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_selezionato)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_selezionato}</div>;
      },
    },
    {
      field: "diff_perc_selezionato",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_selezionato),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_selezionato !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_selezionato)}
            </div>
          );
        }
        return <div></div>;
      },
    },
    {
      field: "costo_garepub",
      headerName: "PREV. GARE PUB.",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      width: 125,
      sortable: false,
      renderCell: (params) => {
        if (params.row.costo_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.costo_garepub)}
            </div>
          );
        }
        return <div>{params.row.ccosto_garepub}</div>;
      },
    },
    {
      field: "diff_costo_acq_garepub",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_costo_acq_garepub),
      width: 100,
      sortable: false,
      renderCell: (params) => {
        if (params.row.diff_costo_acq_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.diff_costo_acq_garepub)}
            </div>
          );
        }
        return <div>{params.row.diff_costo_acq_garepub}</div>;
      },
    },
    {
      field: "diff_perc_garepub",
      headerName: "DIFF. %",
      headerAlign: "center",
      align: "center",
      headerClassName: "theme_header",
      cellClassName: (params) =>
        handleCellClassNamePrize(params.row.diff_perc_garepub),
      width: 70,
      sortable: false,
      renderCell: (params) => {
        if (params.row?.diff_perc_garepub !== "") {
          return (
            <div
              style={
                params.row.title === "TOTALE GENERALE" ||
                params.row.title === "TOTALE ABBINAMENTI" ||
                params.row.title === "TOTALE NON ABBINATI"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(params.row?.diff_perc_garepub)}
            </div>
          );
        }
        return <div></div>;
      },
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="abbPage">
          {enricoValutationFlag && (
            <h1 style={{ color: "#1976d2" }}>{enricoSelected.nome_enrico}</h1>
          )}
          {!enricoValutationFlag ? (
            <>
              {loadingEnricosList ? (
                <div>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingEnricosList}
                    size={40}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6
                    style={{
                      fontFamily: "Calibri",
                      color: "#1976d2",
                    }}
                  >
                    CARICAMENTO...
                  </h6>
                </div>
              ) : (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={enricosList}
                  getOptionLabel={(option) => option.nome_enrico}
                  sx={{
                    width: "100%",
                    paddingTop: "2%",
                    paddingLeft: "38%",
                    paddingRight: "38%",
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="ENRICO SALVATI" />
                  )}
                  value={enricosList_Selected}
                  onChange={(e, newValue) => {
                    setEnricosList_Selected(newValue);
                  }}
                />
              )}
              {enricosList_Selected !== null && (
                <>
                  <div style={{ paddingTop: "2%" }}>
                    <Button
                      variant="contained"
                      color="bluetest"
                      onClick={handleLoadEnrico}
                    >
                      CARICA ENRICO
                    </Button>
                  </div>
                </>
              )}
              {loadingEnricoValutation && (
                <div style={{ paddingTop: "2%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingEnricoValutation}
                    size={40}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6
                    style={{
                      fontFamily: "Calibri",
                      color: "#1976d2",
                    }}
                  >
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "1%",
                  paddingLeft: "1%",
                  display: "inline-block",
                  width: "50%",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                  onClick={handleBack}
                >
                  INDIETRO
                </Button>
              </div>

              {enricoSelected.anno === "23" && (
                <>
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "1%",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <ExpExcelTabResumeEnrico data={enricoResumeTAB} />
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "1%",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <ExpExcelConfrontoGP data={enricoSelected} />
                  </div>
                </>
              )}
              {enricoSelected.anno === "24" && (
                <>
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "1%",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <ExpExcelTabResumeEnrico_BIS data={enricoResumeTAB} />
                  </div>
                  <div
                    style={{
                      textAlign: "left",
                      paddingTop: "1%",
                      display: "inline-block",
                      width: "25%",
                    }}
                  >
                    <ExpExcelConfrontoGP_BIS data={enricoSelected} />
                  </div>
                </>
              )}

              <Box
                sx={{
                  width: "1310px",
                  paddingTop: "2%",
                  paddingLeft: "30px",
                  // paddingRight: "1%",
                  paddingBottom: "1%",

                  "& .theme_header": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontFamily: "Calibri",
                    fontSize: "16px",
                  },
                  "& .theme_cell": {
                    fontFamily: "Calibri",
                    fontSize: "17px",
                    border: "1px solid #000",
                  },
                  "& .theme_cell_lost": {
                    fontFamily: "Calibri",
                    backgroundColor: "#FFC7CE",
                    color: "#9C0006",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                  "& .theme_cell_gain": {
                    fontFamily: "Calibri",
                    backgroundColor: "#C6EFCE",
                    color: "#006100",
                    fontSize: "17px",
                    border: "1px solid",
                  },
                }}
              >
                {enricoSelected.anno === "23" && (
                  <DataGridPro
                    columns={columnsResume23}
                    rows={enricoResumeTAB}
                    getRowId={(row) => row.title}
                    headerHeight={40}
                    rowHeight={33}
                    autoHeight
                    {...enricoResumeTAB}
                    disableColumnMenu
                    hideFooter
                  />
                )}
                {enricoSelected.anno === "24" && (
                  <DataGridPro
                    columns={columnsResume24}
                    rows={enricoResumeTAB}
                    getRowId={(row) => row.title}
                    headerHeight={40}
                    rowHeight={33}
                    autoHeight
                    {...enricoResumeTAB}
                    disableColumnMenu
                    hideFooter
                  />
                )}
              </Box>
            </>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default EnricoFinal;
