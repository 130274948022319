import { Outlet } from "react-router-dom";
import HeaderNEW from "./HeaderNEW";

//! Vedere stili layoutLogin per footer

const LayoutUser = (props) => {
  const { user, handleLogout } = props;
  return (
    <div>
      <HeaderNEW user={user} handleLogout={handleLogout} />
      <div className="contentPage">
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutUser;
