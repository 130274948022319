import React from "react";
import "../../App.css";
import Box from "@mui/material/Box";

const HomePageLogin = () => {
  return (
    <div className="home-page-login">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          fontFamily: "Calibri",
          bgcolor: "#1976d2",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          color: "#fff",
          p: 5,
        }}
      >
        <div
          style={{ fontSize: "55px", marginBottom: "5%", fontWeight: "normal" }}
        >
          FARMA AUDIT & ANALYTICS SRL
        </div>{" "}
        <div style={{ fontSize: "35px", fontWeight: "normal" }}>
          PIATTAFORMA DIGITALE A SUPPORTO DEL FARMACISTA E DELL’UFFICIO ACQUISTI
        </div>
      </Box>
    </div>
  );
};

export default HomePageLogin;
