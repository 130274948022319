import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";

const DateInputField = ({ label, value, onChange, error, helperText }) => (
  <DesktopDatePicker
    label={label}
    inputFormat="DD/MM/YYYY"
    value={value}
    onChange={onChange}
    renderInput={(params) => (
      <TextField {...params} error={!!error} helperText={helperText} />
    )}
  />
);

export default DateInputField;
