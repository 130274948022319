import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import BeatLoader from "react-spinners/BeatLoader";

import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_LIST_SAVE_CONFIRM_POPUP = (props) => {
  const {
    savePopUpOpen,
    handleSavePopUpClose,
    handleFinalSavePurchased,
    errors,
    fileSavedName,
    setFileSavedName,
    loadingFinalSave,
    salvaOffersresume_ERR,
  } = props;

  // const [loadingFinalSave, setLoadingFinalSave] = useState(false);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={savePopUpOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={handleSavePopUpClose}
            PaperProps={{
              style: {
                backgroundColor: "#fff",
                textAlign: "center",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{
                  fontFamily: "Calibri",
                  fontSize: 25,
                }}
              >
                <div style={{ color: "#1976d2", fontFamily: "Calibri" }}>
                  CONFERMI DI VOLER SALVARE L'ACQUISTATO INSERITO?
                </div>
              </DialogContentText>
              <div>
                <TextField
                  id="file_name"
                  label="Nome del Salvataggio"
                  placeholder="Inserisci il Nome del Salvataggio"
                  sx={{ width: 300, paddingBottom: 2 }}
                  value={fileSavedName}
                  onChange={(e) => setFileSavedName(e.target.value)}
                  error={errors.fileSavedName ? true : false}
                  helperText={errors.fileSavedName}
                />
              </div>
              <Button
                variant="contained"
                component="label"
                color="success"
                startIcon={<CheckOutlinedIcon />}
                onClick={() => handleFinalSavePurchased()}
              >
                CONFERMA
              </Button>
              {loadingFinalSave && (
                <div style={{ textAlign: "center", paddingTop: "1%" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingFinalSave}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
              {salvaOffersresume_ERR && (
                <>
                  <div
                    style={{
                      fontFamily: "Calibri",
                      fontSize: 27,
                      color: "red",
                      fontWeight: "bold",
                    }}
                  >
                    ERRORE NEL SALVATAGGIO
                  </div>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSavePopUpClose}>OK</Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_LIST_SAVE_CONFIRM_POPUP;
