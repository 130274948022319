import React from "react";
import ".././../App.css";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";

// import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// import { calcoloCosto } from "../../functions/FUN_CALCOLI";

const FILEF_TEMP = ({ user }) => {
  const DATA_TEST = [
    {
      title: "FILE F",
      n_molecole_2021: 153,
      spesa_2021: 8339527.61,
      perc_spesa_2021: (8339527.61 / 10285800.8) * 100,
      n_molecole_2022: 224,
      spesa_2022: 8194012.8,
      perc_spesa_2022: (8194012.8 / 10539287.2) * 100,
      n_molecole_2023: 240,
      spesa_2023: 7759084.74,
      perc_spesa_2023: (7759084.74 / 10000338.24) * 100,
      n_molecole_2024: 240,
      spesa_2024: 7503948.55,
      perc_spesa_2024: (7503948.55 / 9519923.27) * 100,
    },
    {
      title: "NO FILE F",
      n_molecole_2021: 821,
      spesa_2021: 1946273.19,
      perc_spesa_2021: (1946273.19 / 10285800.8) * 100,
      n_molecole_2022: 930,
      spesa_2022: 2345274.4,
      perc_spesa_2022: (2345274.4 / 10539287.2) * 100,
      n_molecole_2023: 979,
      spesa_2023: 2241253.43,
      perc_spesa_2023: (2241253.43 / 10000338.24) * 100,
      n_molecole_2024: 979,
      spesa_2024: 2015974.54,
      perc_spesa_2024: (2015974.54 / 9519923.27) * 100,
    },
    {
      title: "TOT",
      n_molecole_2021: 974,
      spesa_2021: 10285800.8,
      perc_spesa_2021: "",
      n_molecole_2022: 1154,
      spesa_2022: 10539287.2,
      perc_spesa_2022: "",
      n_molecole_2023: 1225,
      spesa_2023: 10000338.24,
      perc_spesa_2023: "",
      n_molecole_2024: 1225,
      spesa_2024: 9519923.27,
      perc_spesa_2024: "",
    },
  ];

  const DATA_TEST_NEW = [
    {
      title: "FILE F",
      n_molecole_2021: 153,
      spesa_2021: 8339527.61,
      perc_spesa_2021: (8339527.61 / 10285800.8) * 100,
      n_molecole_2022: 224,
      spesa_2022: 8194012.8,
      perc_spesa_2022: (8194012.8 / 10539287.2) * 100,
      n_molecole_2023: 240,
      spesa_2023: 7759084.74,
      perc_spesa_2023: (7759084.74 / 10000338.24) * 100,
      // n_molecole_2023: 240,
      spesa_2024: 7503948.55,
      perc_spesa_2024: (7503948.55 / 9519923.27) * 100,

      differenza: -255136.19,
      differenza_perc: "-3,29%",
    },
    {
      title: "NO FILE F",
      n_molecole_2021: 821,
      spesa_2021: 1946273.19,
      perc_spesa_2021: (1946273.19 / 10285800.8) * 100,
      n_molecole_2022: 930,
      spesa_2022: 2345274.4,
      perc_spesa_2022: (2345274.4 / 10539287.2) * 100,
      n_molecole_2023: 979,
      spesa_2023: 2241253.43,
      perc_spesa_2023: (2241253.43 / 10000338.24) * 100,
      spesa_2024: 2015974.54,
      perc_spesa_2024: (2015974.54 / 9519923.27) * 100,

      differenza: -225278.8,
      differenza_perc: "-10,05%",
    },
    {
      title: "TOT",
      n_molecole_2021: 974,
      spesa_2021: 10285800.8,
      perc_spesa_2021: "",
      n_molecole_2022: 1154,
      spesa_2022: 10539287.2,
      perc_spesa_2022: "",
      n_molecole_2023: 1219,
      spesa_2023: 10000338.24,
      perc_spesa_2023: "",
      spesa_2024: 9519923.27,
      perc_spesa_2024: "",
      differenza: -480814.99,
      differenza_perc: "",
    },
  ];

  const handlePercTEST = (d) => {
    if (d.id === "filef") {
      return d.data.perc_filef;
    } else {
      return d.data.perc_nofilef;
    }
  };

  // console.log("DATATEST", DATA_TEST);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  //PER CALCOLI
  const RapprPrezzo = (PrezzoDaRappresentare) => {
    if (PrezzoDaRappresentare === "") {
      return PrezzoDaRappresentare;
    } else {
      let IntDec = PrezzoDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal + ",00";
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + " €";
    }
  };

  const RapprPerc = (PercDaRappresentare) => {
    if (PercDaRappresentare === "") {
      return PercDaRappresentare;
    } else {
      let IntDec = PercDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal;
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + "%";
    }
  };

  // const calcoloCostoEXP = (Prezzo, Quantità) => {
  //   let costo = 0;
  //   if (Quantità) {
  //     costo = Prezzo * Quantità;
  //     costo = Math.round(costo * 100) / 100;

  //     return costo;
  //   } else {
  //     return costo;
  //   }
  // };

  // const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
  //   let diff_temp = CostoOffSel - CostoACQ;
  //   return Math.round(diff_temp * 100) / 100;
  // };

  // const calcoloDifferenzaPerc = (Costo, Differenza) => {
  //   let diff_temp = (Differenza / Costo) * 100;
  //   return Math.round(diff_temp * 100) / 100;
  // };

  const handleBarTooltip = (id, datas) => {
    if (id === "filef") {
      return (
        <div
          style={{
            padding: "5%",
            fontFamily: "Calibri",
            background: "#fff",
            borderRadius: "5px",
            border: "2px solid #1976d2",
          }}
        >
          <b>FILE F</b>: {RapprPrezzo(datas.filef)}
        </div>
      );
    } else if (id === "nofilef") {
      return (
        <div
          style={{
            padding: "5%",
            fontFamily: "Calibri",
            background: "#fff",
            borderRadius: "5px",
            border: "2px solid #1976d2",
          }}
        >
          <b>NO FILE F</b>: {RapprPrezzo(datas.nofilef)}
        </div>
      );
    }
  };

  const yAxisValues = [0];

  const BAR_SPESA_TEST_UNICO = [
    {
      anno: "2021",
      filef: DATA_TEST[0].spesa_2021,
      nofilef: DATA_TEST[1].spesa_2021,
      perc_filef: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2021 * 100) / 100
      ),
      perc_nofilef: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2021 * 100) / 100
      ),
    },

    {
      anno: "2022",
      filef: DATA_TEST[0].spesa_2022,
      nofilef: DATA_TEST[1].spesa_2022,
      perc_filef: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2022 * 100) / 100
      ),
      perc_nofilef: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2022 * 100) / 100
      ),
    },

    {
      anno: "2023",
      filef: DATA_TEST[0].spesa_2023,
      nofilef: DATA_TEST[1].spesa_2023,
      perc_filef: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2023 * 100) / 100
      ),
      perc_nofilef: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2023 * 100) / 100
      ),
    },

    {
      anno: "2024",
      filef: DATA_TEST[0].spesa_2024,
      nofilef: DATA_TEST[1].spesa_2024,
      perc_filef: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2024 * 100) / 100
      ),
      perc_nofilef: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2024 * 100) / 100
      ),
    },

    // {
    //   anno: "2023 MIN",
    //   filef: DATA_TEST[0].previsione_spesaMIN_2023,
    //   nofilef: DATA_TEST[1].previsione_spesaMIN_2023,
    //   perc_filef: RapprPerc(
    //     Math.round(DATA_TEST[0].perc_spesa_2023_MIN * 100) / 100
    //   ),
    //   perc_nofilef: RapprPerc(
    //     Math.round(DATA_TEST[1].perc_spesa_2023_MIN * 100) / 100
    //   ),
    // },

    // {
    //   anno: "2023 ABB",
    //   filef: DATA_TEST[0].previsione_spesaABB_2023,
    //   nofilef: DATA_TEST[1].previsione_spesaABB_2023,
    //   perc_filef: RapprPerc(
    //     Math.round(DATA_TEST[0].perc_spesa_2023_ABB * 100) / 100
    //   ),
    //   perc_nofilef: RapprPerc(
    //     Math.round(DATA_TEST[1].perc_spesa_2023_ABB * 100) / 100
    //   ),
    // },
  ];

  const PIE_SPESA_2021 = [
    {
      id: "nofilef",
      label: "NO FILE F",
      value: DATA_TEST[1].spesa_2021,
      n_specialita: DATA_TEST[1].n_molecole_2021,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2021 * 100) / 100
      ),
    },
    {
      id: "filef",
      label: "FILE F",
      value: DATA_TEST[0].spesa_2021,
      n_specialita: DATA_TEST[0].n_molecole_2021,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2021 * 100) / 100
      ),
    },
  ];

  const PIE_SPESA_2022 = [
    {
      id: "nofilef",
      label: "NO FILE F",
      value: DATA_TEST[1].spesa_2022,
      n_specialita: DATA_TEST[1].n_molecole_2022,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2022 * 100) / 100
      ),
    },
    {
      id: "filef",
      label: "FILE F",
      value: DATA_TEST[0].spesa_2022,
      n_specialita: DATA_TEST[0].n_molecole_2022,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2022 * 100) / 100
      ),
    },
  ];

  const PIE_SPESA_2023 = [
    {
      id: "nofilef",
      label: "NO FILE F",
      value: DATA_TEST[1].spesa_2023,
      n_specialita: DATA_TEST[1].n_molecole_2023,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2023 * 100) / 100
      ),
    },
    {
      id: "filef",
      label: "FILE F",
      value: DATA_TEST[0].spesa_2023,
      n_specialita: DATA_TEST[0].n_molecole_2023,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2023 * 100) / 100
      ),
    },
  ];

  const PIE_SPESA_2024 = [
    {
      id: "nofilef",
      label: "NO FILE F",
      value: DATA_TEST[1].spesa_2024,
      n_specialita: DATA_TEST[1].n_molecole_2024,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[1].perc_spesa_2024 * 100) / 100
      ),
    },
    {
      id: "filef",
      label: "FILE F",
      value: DATA_TEST[0].spesa_2024,
      n_specialita: DATA_TEST[0].n_molecole_2024,
      perc_spesa: RapprPerc(
        Math.round(DATA_TEST[0].perc_spesa_2024 * 100) / 100
      ),
    },
  ];

  const columnsTESTVER2 = [
    {
      field: "title",
      headerName: "FILE F",
      headerAlign: "center",
      align: "left",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOT"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.title}
        </div>
      ),
    },
    {
      field: "n_molecole_2021",
      headerName: "N. 2021",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOT"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.n_molecole_2021}
        </div>
      ),
    },
    {
      field: "spesa_2021",
      headerName: "SPESA 2021",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.spesa_2021) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.spesa_2021)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "perc_spesa_2021",
      headerName: "%",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.perc_spesa_2021) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(Math.round(params.row.perc_spesa_2021 * 100) / 100)}
            </div>
          );
        } else {
          return <div>{params.row.perc_spesa_2021}</div>;
        }
      },
    },

    {
      field: "n_molecole_2022",
      headerName: "N. 2022",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOT"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.n_molecole_2022}
        </div>
      ),
    },
    {
      field: "spesa_2022",
      headerName: "SPESA 2022",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.spesa_2022) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.spesa_2022)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "perc_spesa_2022",
      headerName: "%",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.perc_spesa_2022) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(Math.round(params.row.perc_spesa_2022 * 100) / 100)}
            </div>
          );
        } else {
          return <div>{params.row.perc_spesa_2022}</div>;
        }
      },
    },

    {
      field: "n_molecole_2023",
      headerName: "N. 2023",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => (
        <div
          style={
            params.row.title === "TOT"
              ? { fontWeight: "bold", fontSize: 14 }
              : { fontSize: 14 }
          }
        >
          {params.row.n_molecole_2023}
        </div>
      ),
    },
    {
      field: "spesa_2023",
      headerName: "SPESA 2023",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.spesa_2023) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.spesa_2023)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "perc_spesa_2023",
      headerName: "%",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.perc_spesa_2023) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(Math.round(params.row.perc_spesa_2023 * 100) / 100)}
            </div>
          );
        } else {
          return <div>{params.row.perc_spesa_2023}</div>;
        }
      },
    },

    // {
    //   field: "n_molecole_2024",
    //   headerName: "N. 2024",
    //   headerAlign: "center",
    //   align: "right",
    //   headerClassName: "theme_header",
    //   cellClassName: "theme_cell",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <div
    //       style={
    //         params.row.title === "TOT"
    //           ? { fontWeight: "bold", fontSize: 14 }
    //           : { fontSize: 14 }
    //       }
    //     >
    //       {params.row.n_molecole_2023}
    //     </div>
    //   ),
    // },
    {
      field: "spesa_2024",
      headerName: "SPESA MIN 2024",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.spesa_2024) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.spesa_2024)}
            </div>
          );
        }
        return <div>{params.row.costo_acq}</div>;
      },
    },
    {
      field: "perc_spesa_2024",
      headerName: "%",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.perc_spesa_2024) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPerc(Math.round(params.row.perc_spesa_2024 * 100) / 100)}
            </div>
          );
        } else {
          return <div>{params.row.perc_spesa_2024}</div>;
        }
      },
    },

    {
      field: "differenza",
      headerName: "DIFFERENZA",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (RapprPrezzo(params.row.differenza) !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {RapprPrezzo(params.row.differenza)}
            </div>
          );
        }
        return <div>{params.row.differenza}</div>;
      },
    },
    {
      field: "differenza_perc",
      headerName: "DIFF %",
      headerAlign: "center",
      align: "right",
      headerClassName: "theme_header",
      cellClassName: "theme_cell",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        if (params.row.differenza_perc !== "") {
          return (
            <div
              style={
                params.row.title === "TOT"
                  ? { fontWeight: "bold", fontSize: 14 }
                  : { fontSize: 14 }
              }
            >
              {params.row.differenza_perc}
            </div>
          );
        }
        return <div>{params.row.differenza_perc}</div>;
      },
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <div className="abbPage">
        {" "}
        <>
          <div
            style={{
              textAlign: "left",
              display: "inline-block",
              width: "100%",
            }}
          >
            {/* <Button
              variant="contained"
              color="redtest"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
              onClick={handleBackToResumeBIS}
            >
              INDIETRO
            </Button> */}
          </div>
          <Box
            sx={{
              width: "100%",
              paddingLeft: "2%",
              paddingRight: "2%",
              paddingTop: "1%",
              "& .theme_header": {
                backgroundColor: "#1976d2",
                color: "#fff",
                fontFamily: "Calibri",
                fontSize: "17px",
              },
              "& .theme_cell": {
                fontFamily: "Calibri",
                fontSize: "18px",
                border: "1px solid",
              },
              "& .theme_cell_gain": {
                fontFamily: "Calibri",
                backgroundColor: "#C6EFCE",
                color: "#006100",
                fontSize: "17px",
                border: "1px solid",
              },
            }}
          >
            <DataGridPro
              columns={columnsTESTVER2}
              rows={DATA_TEST_NEW}
              getRowId={(row) => row.title}
              headerHeight={35}
              rowHeight={28}
              autoHeight
              {...DATA_TEST_NEW}
              disableColumnMenu
              hideFooter
            />
          </Box>
          <h2
            style={{
              paddingTop: "2%",
              fontFamily: "Calibri",
              color: "#1976d2",
            }}
          >
            ANALISI ANDAMENTO SPESA FILE F:
          </h2>
          <div style={{ paddingTop: "2%" }}>
            <Box
              sx={{
                display: "inline-block",
                height: "250px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            >
              <h3
                style={{
                  paddingBottom: "1%",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                ANNO: 2021
              </h3>
              <ResponsivePie
                data={PIE_SPESA_2021}
                margin={{ top: 30, right: 40, bottom: 60, left: 40 }}
                colors={["#104d89", "#1976d2"]}
                sortByValue={false}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={2}
                activeInnerRadiusOffset={5}
                activeOuterRadiusOffset={5}
                arcLinkLabel={(e) => RapprPrezzo(e.data.value)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#000"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabel={(e) => e.data.perc_spesa}
                arcLabelsSkipAngle={30}
                arcLabelsTextColor="#fff"
                animate={true}
                tooltip={(e) => (
                  <div
                    style={{
                      padding: "5%",
                      fontFamily: "Calibri",
                      background: "#fff",
                      borderRadius: "5px",
                      border: "2px solid #1976d2",
                    }}
                  >
                    <b>{e.datum.data.label}</b>
                    <br /> <b>SPECIALITA':</b> {e.datum.data.n_specialita}
                    <br />
                    <b> SPESA:</b> {RapprPrezzo(e.datum.data.value)}
                  </div>
                )}
                legends={[
                  {
                    anchor: "bottom-left",
                    direction: "row",
                    justify: false,
                    translateX: -25,
                    translateY: 56,
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: "#999",
                    itemDirection: "left-to-right",
                    itemOpacity: 1,
                    symbolSize: 20,
                    symbolShape: "circle",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemTextColor: "#000",
                        },
                      },
                    ],
                  },
                ]}
              />
            </Box>
            <Box
              sx={{
                display: "inline-block",
                height: "250px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            >
              <h3
                style={{
                  paddingBottom: "1%",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                ANNO: 2022
              </h3>
              <ResponsivePie
                data={PIE_SPESA_2022}
                margin={{ top: 30, right: 40, bottom: 60, left: 40 }}
                colors={["#104d89", "#1976d2"]}
                sortByValue={false}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={2}
                activeInnerRadiusOffset={5}
                activeOuterRadiusOffset={5}
                arcLinkLabel={(e) => RapprPrezzo(e.data.value)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#000"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabel={(e) => e.data.perc_spesa}
                arcLabelsSkipAngle={30}
                arcLabelsTextColor="#fff"
                animate={true}
                tooltip={(e) => (
                  <div
                    style={{
                      padding: "5%",
                      fontFamily: "Calibri",
                      background: "#fff",
                      borderRadius: "5px",
                      border: "2px solid #1976d2",
                    }}
                  >
                    <b>{e.datum.data.label}</b>
                    <br /> <b>SPECIALITA':</b> {e.datum.data.n_specialita}
                    <br />
                    <b> SPESA:</b> {RapprPrezzo(e.datum.data.value)}
                  </div>
                )}
                legends={[]}
              />
            </Box>
            <Box
              sx={{
                display: "inline-block",
                height: "250px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            >
              <h3
                style={{
                  paddingBottom: "1%",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                ANNO: 2023
              </h3>
              <ResponsivePie
                data={PIE_SPESA_2023}
                margin={{ top: 30, right: 40, bottom: 60, left: 40 }}
                colors={["#104d89", "#1976d2"]}
                sortByValue={false}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={2}
                activeInnerRadiusOffset={5}
                activeOuterRadiusOffset={5}
                arcLinkLabel={(e) => RapprPrezzo(e.data.value)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#000"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabel={(e) => e.data.perc_spesa}
                arcLabelsSkipAngle={30}
                arcLabelsTextColor="#fff"
                animate={true}
                tooltip={(e) => (
                  <div
                    style={{
                      padding: "5%",
                      fontFamily: "Calibri",
                      background: "#fff",
                      borderRadius: "5px",
                      border: "2px solid #1976d2",
                    }}
                  >
                    <b>{e.datum.data.label}</b>
                    <br /> <b>SPECIALITA':</b> {e.datum.data.n_specialita}
                    <br />
                    <b> SPESA:</b> {RapprPrezzo(e.datum.data.value)}
                  </div>
                )}
                legends={[]}
              />
            </Box>

            <Box
              sx={{
                display: "inline-block",
                height: "250px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            >
              <h3
                style={{
                  paddingBottom: "1%",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                ANNO: 2024 MIN
              </h3>
              <ResponsivePie
                data={PIE_SPESA_2024}
                margin={{ top: 30, right: 40, bottom: 60, left: 40 }}
                colors={["#104d89", "#1976d2"]}
                sortByValue={false}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={2}
                activeInnerRadiusOffset={5}
                activeOuterRadiusOffset={5}
                arcLinkLabel={(e) => RapprPrezzo(e.data.value)}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#000"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: "color" }}
                arcLabel={(e) => e.data.perc_spesa}
                arcLabelsSkipAngle={30}
                arcLabelsTextColor="#fff"
                animate={true}
                tooltip={(e) => (
                  <div
                    style={{
                      padding: "5%",
                      fontFamily: "Calibri",
                      background: "#fff",
                      borderRadius: "5px",
                      border: "2px solid #1976d2",
                    }}
                  >
                    <b>{e.datum.data.label}</b>
                    <br /> <b>SPECIALITA':</b> {e.datum.data.n_specialita}
                    <br />
                    <b> SPESA:</b> {RapprPrezzo(e.datum.data.value)}
                  </div>
                )}
                legends={[]}
              />
            </Box>

            {/* BAR ESEMPI */}

            <div style={{ paddingTop: "2%" }}>
              <Box
                sx={{
                  display: "inline-block",
                  height: "300px",
                  width: "25%",
                  textAlign: "center",
                  paddingTop: "1%",
                }}
              >
                <h4 style={{ color: "#1976d2", marginLeft: "15%" }}>
                  SPESA TOTALE
                </h4>
                <ResponsiveBar
                  data={BAR_SPESA_TEST_UNICO}
                  keys={["filef", "nofilef"]}
                  indexBy="anno"
                  margin={{ top: 25, right: 40, bottom: 50, left: 90 }}
                  padding={0.15}
                  valueScale={{ type: "linear" }}
                  indexScale={{ type: "band", round: true }}
                  colors={["#1976d2", "#104d89"]}
                  tooltip={(e) => handleBarTooltip(e.id, e.data)}
                  //             <div
                  //   style={{
                  //     padding: "5%",
                  //     fontFamily: "Calibri",
                  //     background: "#fff",
                  //     borderRadius: "5px",
                  //     border: "2px solid #1976d2",
                  //   }}
                  // >
                  //   <b>FILE F</b>: {RapprPrezzo(datas.filef)}
                  // </div>
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    legend: "ANNO",
                    legendPosition: "middle",
                    legendOffset: 45,
                    style: {
                      stroke: "#000",
                      strokeWidth: 1,
                    },
                  }}
                  axisLeft={{
                    tickValues: yAxisValues,
                    legend: "SPESA",
                    legendPosition: "middle",
                    legendOffset: -20,
                    style: {
                      stroke: "#000",
                      strokeWidth: 1,
                    },
                  }}
                  enableGridY={false}
                  theme={{
                    axis: {
                      ticks: {
                        line: {
                          stroke: "#000",
                        },
                        text: {
                          fill: "#000",
                        },
                      },
                    },
                    // grid: {
                    //   line: {
                    //     stroke: "#000",
                    //     strokeWidth: 0.7,
                    //   },
                    // },
                  }}
                  label={(d) => handlePercTEST(d)}
                  labelTextColor="white"
                  legends={[]}
                  // isInteractive={false}

                  role="application"
                  ariaLabel="Nivo bar chart demo"
                />
              </Box>
            </div>

            <Box
              sx={{
                display: "inline-block",
                height: "300px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            ></Box>
            <Box
              sx={{
                display: "inline-block",
                height: "300px",
                width: "25%",
                textAlign: "center",
                paddingTop: "1%",
              }}
            ></Box>
          </div>
        </>
      </div>
    </ThemeProvider>
  );
};

export default FILEF_TEMP;
