import React from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

//FUNZIONI
import { calcoloCosto } from "../../../functions/FUN_CALCOLI";

const ExpExcelConfrontoGP_BIS = ({ data }) => {
  const calcoloDifferenzaCosto = (CostoACQ, CostoOffSel) => {
    let diff_temp = CostoOffSel - CostoACQ;
    return Math.round(diff_temp * 100) / 100;
  };

  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "TABELLA IMPATTO GARA PUB.xlsx"
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "PRINC. ATTIVO",
        B: "TIPOLOGIA",
        C: "ATC COMP.",
        D: "AIC GARA PUB",
        E: "NOME COMM. PUB",
        F: "FORNITORE PUB",
        G: "REGIONE",
        H: "PREZZO GARA PUB",
        I: "SPESA GARA PUB",
        J: "DIFF. SPESA GARA PUB SU SELEZIONATO",
        K: "AIC  ASSEGN. 24",
        L: "NOME COMM. ASSEGN. 24",
        M: "FORNITORE ASSEGN. 24",
        N: "PREZZO GARA ASSEGN. 24",
        O: "SPESA GARA ASSEGN. 24",
        P: "AIC ACQ. 23",
        Q: "NOME COMM. ACQ. 23",
        R: "FORNITORE ACQ. 23",
        S: "PREZZO ACQ. 23",
        T: "SPESA 23",
        U: "QUANTITA'",
      },
    ];

    let table2 = [
      {
        A: "PRINC. ATTIVO",
        B: "TIPOLOGIA",
        C: "ATC COMP.",
        D: "AIC ASSEGN. 24",
        E: "NOME COMM. ASSEGN. 24",
        F: "FORNITORE ASSEGN. 24",
        G: "PREZZO GARA ASSEGN. 24",
        H: "SPESA GARA ASSEGN. 24",
        I: "AIC ACQ. 23",
        J: "NOME COMM. ACQ. 23",
        K: "FORNITORE ACQ. 23",
        L: "PREZZO ACQ. 23",
        M: "SPESA 23",
        N: "QUANTITA'",
      },
    ];

    let countRisp = 0;
    let tot_spec = 0;
    let tableTEMP = [];

    data.obj_file.forEach((row) => {
      if (row?.flag_match) {
        if (row.garePubList.length > 0) {
          let diffCosto = calcoloDifferenzaCosto(
            calcoloCosto(
              row?.spec_checked.prezzo_unitario,
              row?.spec_checked.quantita
            ),
            calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita)
          );

          tableTEMP.push({
            A: row?.principio_attivo,
            B: row?.descrizione_gruppo_ospedaliero,
            C: row?.atc_complementare,
            D: row?.garePubList[0].aic,
            E: row?.garePubList[0].nome_farmadati,
            F: row?.garePubList[0].fornitore,
            G: row?.garePubList[0].regione,
            H: row?.garePubList[0].prezzo_unitario,
            I: calcoloCosto(row?.garePubList[0].prezzo_unitario, row?.quantita),
            J: diffCosto,
            K: row?.spec_checked.aic,
            L: row?.spec_checked.nome_commerciale,
            M: row?.spec_checked.fornitore.ragione_sociale,
            N: row?.spec_checked.prezzo_unitario,
            O: calcoloCosto(
              row?.spec_checked.prezzo_unitario,
              row?.spec_checked.quantita
            ),
            P: row?.aic,
            Q: row?.nome_commerciale,
            R: row?.fornitore.ragione_sociale,
            S: row?.prezzo_unitario,
            T: row?.spec_checked.costo_acquistato,
            U: row?.quantita,
          });
          if (diffCosto < 0) {
            countRisp = countRisp + 1;
          }
          tot_spec = tot_spec + 1;
        } else {
          table2.push({
            A: row?.principio_attivo,
            B: row?.descrizione_gruppo_ospedaliero,
            C: row?.atc_complementare,
            D: row?.spec_checked.aic,
            E: row?.spec_checked.nome_commerciale,
            F: row?.spec_checked.fornitore.ragione_sociale,

            G: row?.spec_checked.prezzo_unitario,
            H: calcoloCosto(
              row?.spec_checked.prezzo_unitario,
              row?.spec_checked.quantita
            ),
            I: row?.aic,
            J: row?.nome_commerciale,
            K: row?.fornitore.ragione_sociale,

            L: row?.prezzo_unitario,
            M: row?.spec_checked.costo_acquistato,
            N: row?.quantita,
          });
        }
      }
    });

    tableTEMP.sort((a, b) => {
      let x = a.J;
      let y = b.J;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    table1 = table1.concat(tableTEMP);
    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    const sheet2 = XLSX.utils.json_to_sheet(table2, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "CON GARE PUB");
    XLSX.utils.book_append_sheet(wb, sheet2, "SENZA GARE PUB");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:U1`,
      theadRange2: `A1:N1`,

      rispRange: `J2:J${countRisp}`,
      spesaRange: `J${countRisp}:J${tot_spec + 1}`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      let sheetsCounter = 1;
      workbook.sheets("CON GARE PUB").forEach((sheet) => {
        if (sheetsCounter === 1) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            verticalAlignment: "left",
            horizontalAlignment: "left",
          });

          sheet.column("A").width(28);
          sheet.column("B").width(50);
          sheet.column("C").width(16).style({ horizontalAlignment: "center" });
          sheet.column("D").width(16).style({ horizontalAlignment: "center" });
          sheet.column("E").width(28);
          sheet.column("F").width(25);

          sheet.column("G").width(18);
          sheet.column("H").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("I")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet
            .column("J")
            .width(35)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("K").width(20).style({ horizontalAlignment: "center" });
          sheet.column("L").width(28);
          sheet.column("M").width(25);

          sheet.column("N").width(23).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("O")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("P").width(17).style({ horizontalAlignment: "center" });
          sheet.column("Q").width(28);
          sheet.column("R").width(25);

          sheet.column("S").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("T")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("U").width(17).style({ horizontalAlignment: "center" });

          if (dataInfo.theadRange1) {
            sheet.range(dataInfo.theadRange1).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }

          if (dataInfo.rispRange) {
            sheet.range(dataInfo.rispRange).style({
              fill: "c6efce",
              fontColor: "006100",
              bold: false,
              border: true,
              borderColor: "cccccc",
            });
          }
          if (dataInfo.spesaRange) {
            sheet.range(dataInfo.spesaRange).style({
              fill: "ffc7ce",
              fontColor: "9c0006",
              bold: false,
              border: true,
              borderColor: "cccccc",
            });
          }
        } else if (sheetsCounter === 2) {
          sheet.usedRange().style({
            fontFamily: "Calibri",
            verticalAlignment: "left",
            horizontalAlignment: "left",
          });

          sheet.column("A").width(28);
          sheet.column("B").width(50);
          sheet.column("C").width(16).style({ horizontalAlignment: "center" });

          sheet.column("D").width(20).style({ horizontalAlignment: "center" });
          sheet.column("E").width(28);
          sheet.column("F").width(25);

          sheet.column("G").width(22).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("H")
            .width(20)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("I").width(17).style({ horizontalAlignment: "center" });
          sheet.column("J").width(28);
          sheet.column("K").width(25);

          sheet.column("L").width(20).style({
            horizontalAlignment: "right",
            numberFormat: "#,##0.00000",
          });
          sheet
            .column("M")
            .width(22)
            .style({ horizontalAlignment: "right", numberFormat: "#,##0.00" });
          sheet.column("N").width(17).style({ horizontalAlignment: "center" });

          if (dataInfo.theadRange2) {
            sheet.range(dataInfo.theadRange2).style({
              fill: "1976d2",
              fontColor: "ffffff",
              bold: false,
              horizontalAlignment: "center",
            });
          }
        }
        sheetsCounter = sheetsCounter + 1;
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP CONFRONTO GARE PUB
      </Button>
    </>
  );
};

export default ExpExcelConfrontoGP_BIS;
