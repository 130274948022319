import axios from "axios";

//se sviluppo
// const urlFornitori = "http://localhost:3001/api/suppliers";
//se produzione
const urlFornitori = "/api/suppliers";

//Ricezione
const getFornitori = () => {
  return axios.get(urlFornitori);
};

const fornitoriService = {
  getFornitori,
};

export default fornitoriService;
