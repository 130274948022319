//FUNZIONI
import { calcoloCosto } from "../functions/FUN_CALCOLI";

const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (
    PrezzoDaRappresentare === "" ||
    PrezzoDaRappresentare === null ||
    PrezzoDaRappresentare === undefined
  ) {
    return "";
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

const handleCellClassNamePrize = (Num) => {
  if (Num === "" || Num === 0) {
    return "theme_cell";
  } else if (Num > 0) {
    return "theme_cell_lost";
  } else if (Num < 0) {
    return "theme_cell_gain";
  }
};

const OFF_MAIN_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.aic !== null ? params.row?.aic : ""}
      </div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.nome_commerciale !== null
          ? params.row?.nome_commerciale
          : ""}
      </div>
    ),
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.principio_attivo !== null
          ? params.row?.principio_attivo
          : ""}
      </div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.descrizione_gruppo_ospedaliero !== null
          ? params.row?.descrizione_gruppo_ospedaliero
          : ""}
      </div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario !== null
          ? RapprPrezzo(
              Math.round(params.row?.prezzo_unitario * 100000) / 100000
            )
          : ""}
      </div>
    ),
  },
  {
    field: "costo",
    headerName: "COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.costo !== null
          ? RapprPrezzo(
              calcoloCosto(
                params.row?.prezzo_unitario,
                params.row?.quantita_tot
              )
            )
          : ""}
      </div>
    ),
  },
];

const OFF_SUB_ACQ_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.aic !== null ? params.row?.aic : ""}
      </div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.nome_commerciale !== null
          ? params.row?.nome_commerciale
          : ""}
      </div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.fornitore !== null
          ? params.row?.fornitore.ragione_sociale
          : ""}
      </div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario !== null
          ? RapprPrezzo(
              Math.round(params.row?.prezzo_unitario * 100000) / 100000
            )
          : ""}
      </div>
    ),
  },
  {
    field: "diff_UNITARIA",
    headerName: "DIFFERENZA UNIT.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row?.diff_UNITARIA),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_UNITARIA !== null
          ? RapprPrezzo(params.row?.diff_UNITARIA)
          : ""}
      </div>
    ),
  },

  {
    field: "costo_TEMP",
    headerName: "COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.costo_TEMP !== null
          ? RapprPrezzo(Math.round(params.row?.costo_TEMP * 100) / 100)
          : ""}
      </div>
    ),
  },
  {
    field: "diff_TEMP",
    headerName: "DIFFERENZA COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => handleCellClassNamePrize(params.row?.diff_TEMP),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_TEMP !== null
          ? RapprPrezzo(params.row?.diff_TEMP)
          : ""}
      </div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANT.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.quantita !== null ? params.row?.quantita : ""}
      </div>
    ),
  },
];

const OFF_SUB_LIST_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic ?? ""}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale ?? ""}</div>
    ),
  },
  {
    field: "ragione_sociale",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.ragione_sociale ?? ""}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario !== null
          ? RapprPrezzo(
              Math.round(params.row?.prezzo_unitario * 100000) / 100000
            )
          : ""}
      </div>
    ),
  },
  {
    field: "diff_UNITARIA",
    headerName: "DIFFERENZA UNIT.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row?.diff_UNITARIA ?? null),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_UNITARIA != null
          ? RapprPrezzo(params.row?.diff_UNITARIA)
          : ""}
      </div>
    ),
  },

  {
    field: "costo_TEMP",
    headerName: "COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => {
      const costoTemp = params.row?.costo_TEMP;
      return (
        <div style={{ fontSize: 17 }}>
          {costoTemp != null ? RapprPrezzo(costoTemp) : ""}
        </div>
      );
    },
  },

  {
    field: "diff_TEMP",
    headerName: "DIFFERENZA COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row?.diff_TEMP ?? null),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_TEMP != null
          ? RapprPrezzo(params.row?.diff_TEMP)
          : ""}
      </div>
    ),
  },
];

const OFF_SUB_GARE_COLUMNS = [
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic || ""}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale || ""}</div>
    ),
  },
  {
    field: "ragione_sociale",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.ragione_sociale || ""}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario != null
          ? RapprPrezzo(
              Math.round(params.row?.prezzo_unitario * 100000) / 100000
            )
          : ""}
      </div>
    ),
  },
  {
    field: "diff_UNITARIA",
    headerName: "DIFFERENZA UNIT.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) =>
      handleCellClassNamePrize(params.row?.diff_UNITARIA),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_UNITARIA != null
          ? RapprPrezzo(params.row?.diff_UNITARIA)
          : ""}
      </div>
    ),
  },
  {
    field: "costo_TEMP",
    headerName: "COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "right",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.costo_TEMP != null
          ? RapprPrezzo(params.row?.costo_TEMP)
          : ""}
      </div>
    ),
  },
  {
    field: "diff_TEMP",
    headerName: "DIFFERENZA COSTO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => handleCellClassNamePrize(params.row?.diff_TEMP),
    align: "right",
    sortable: false,
    flex: 0.6,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.diff_TEMP != null
          ? RapprPrezzo(params.row?.diff_TEMP)
          : ""}
      </div>
    ),
  },
  {
    field: "regione",
    headerName: "REGIONE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.regione || ""}</div>
    ),
  },
];

export {
  OFF_MAIN_COLUMNS,
  OFF_SUB_ACQ_COLUMNS,
  OFF_SUB_LIST_COLUMNS,
  OFF_SUB_GARE_COLUMNS,
};
