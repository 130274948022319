import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ACQ_SECOND_CONFIRM_POPUP = (props) => {
  const {
    secondConfirmOpen,
    setSecondConfirmOpen,
    notMatch,
    totSingoleStrtuture,
    handleSecondConfirm,
    fileValutation,
    updateFileData,
    insertedFile,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  const RapprPrezzo = (PrezzoDaRappresentare) => {
    if (PrezzoDaRappresentare === "") {
      return PrezzoDaRappresentare;
    } else {
      let IntDec = PrezzoDaRappresentare.toString();
      IntDec = IntDec.split(".");

      let nuovoVal = "";
      let vecchioVal = IntDec[0];

      while (vecchioVal?.length > 3) {
        nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
        vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
      }
      if (IntDec[1] !== undefined) {
        if (IntDec[1].length === 1) {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
        } else {
          nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
        }
      } else {
        nuovoVal = vecchioVal + nuovoVal + ",00";
      }

      let finalVal = nuovoVal;

      if (finalVal.startsWith("-.")) {
        finalVal = "- " + finalVal.substr(2);
      } else if (finalVal.startsWith(".")) {
        finalVal = finalVal.substr(1);
      }

      return finalVal + " €";
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={secondConfirmOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => setSecondConfirmOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: "#fff",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                fontSize: 25,
                textAlign: "center",
              }}
            >
              {totSingoleStrtuture.length > 0 && (
                <span style={{ color: "#1976d2" }}>
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                    }}
                  >
                    {totSingoleStrtuture.map(([struttura, totale]) => (
                      <li key={struttura}>
                        {struttura}: {RapprPrezzo(totale.toFixed(2))}
                      </li>
                    ))}
                  </ul>
                </span>
              )}
              {notMatch.length > 0 && (
                <span style={{ color: "red" }}>
                  PROSEGUENDO VERRANNO RIMOSSE LE SPECIALITA' SENZA MATCH!
                </span>
              )}
              <br />
              <span style={{ color: "#1976d2" }}>
                CONFERMI DI VOLER PROSEGUIRE?
              </span>
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  variant="contained"
                  color="redtest"
                  startIcon={<DeleteOutlinedIcon />}
                  onClick={() => setSecondConfirmOpen(false)}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "6%",
                }}
              ></div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={() =>
                    handleSecondConfirm(
                      fileValutation,
                      updateFileData,
                      insertedFile
                    )
                  }
                >
                  CONFERMA
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ACQ_SECOND_CONFIRM_POPUP;
