import React from "react";
import "../../App.css";

const InfoPage = () => {
  return (
    <>
      <div className="info-container">
        <div className="info-box">
          <h2>Chi Siamo</h2>
          <p>
            Fondata nel 2023, la nostra azienda si dedica a offrire soluzioni
            innovative
          </p>

          {/* <h2>Servizi</h2>
          <p>...</p> */}

          <h2>Contattaci</h2>
          <p>Email: info@farmauditanalytics.it</p>
        </div>
      </div>
    </>
  );
};

export default InfoPage;
