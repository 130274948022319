import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EXPS_RESUME_POPUP = ({
  expsVariPopUpOpen,
  setExpsVariPopUpOpen,
  abbinatoDownloaded,
  specMatchedTOT,
  VAL_OBJ,
  EXP_ABB_SELECTED_TAB,
  EXP_ABB_CONFRONTO,
  EXP_ABB_STRUTTURE_RESUME,
  EXP_BENCHMARKING_RESUME,
}) => {
  return (
    <>
      <Dialog
        open={expsVariPopUpOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
        keepMounted
        onClose={() => setExpsVariPopUpOpen(false)}
        PaperProps={{
          style: {
            backgroundColor: "#f4f4f4",
            border: "1px solid #ccc",
            borderRadius: "10px",
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <EXP_ABB_SELECTED_TAB
              data={specMatchedTOT}
              abb_name={abbinatoDownloaded?.nome_abbinato}
            />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            <EXP_ABB_CONFRONTO data={abbinatoDownloaded} />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            <EXP_ABB_STRUTTURE_RESUME data={abbinatoDownloaded} />
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "3%",
            }}
          >
            <EXP_BENCHMARKING_RESUME
              data={VAL_OBJ}
              fileName={"BENCHMARKING SU " + abbinatoDownloaded?.nome_abbinato}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EXPS_RESUME_POPUP;
