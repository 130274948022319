import React from "react";
import "../../App.css";

import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import {
  OFF_SUB_ACQ_COLUMNS,
  OFF_SUB_LIST_COLUMNS,
  OFF_SUB_GARE_COLUMNS,
} from "../../colums/OFF_ANALYSIS";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_DETAILS_VALUTATION_POPUP = (props) => {
  const {
    subTabOpen,
    handleSubTabClose,
    listinoName,
    acquistatoName,
    subTabMAIN,
    subTabACQ,
    subTabLIST,
    subTabGARE,
  } = props;

  // console.log("subTabMAIN", subTabMAIN);
  // console.log("subTabACQ", subTabACQ);
  // console.log("subTabLIST", subTabLIST);
  // console.log("subTabGARE", subTabGARE);

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={subTabOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="fullWidth"
            keepMounted
            onClose={handleSubTabClose}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              {subTabACQ !== null && (
                <>
                  <h3
                    style={{
                      paddingTop: "3%",
                      color: "#1976d2",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {acquistatoName}
                  </h3>
                  <Box
                    sx={{
                      // height: "180px",
                      width: "100%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      "& .theme_header": {
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontFamily: "Calibri",
                        fontSize: "16px",
                      },
                      "& .theme_cell": {
                        fontFamily: "Calibri",
                        border: "1px solid",
                      },
                      "& .theme_cell_lost": {
                        fontFamily: "Calibri",
                        backgroundColor: "#FFC7CE",
                        color: "#9C0006",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                      "& .theme_cell_gain": {
                        fontFamily: "Calibri",
                        backgroundColor: "#C6EFCE",
                        color: "#006100",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                    }}
                  >
                    <DataGridPro
                      columns={OFF_SUB_ACQ_COLUMNS}
                      rows={subTabACQ}
                      getRowId={(row) => row._id}
                      headerHeight={40}
                      rowHeight={33}
                      autoHeight
                      {...subTabACQ}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                </>
              )}
              {subTabLIST !== null && (
                <>
                  <h3
                    style={{
                      paddingTop: "3%",
                      color: "#1976d2",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {listinoName}
                  </h3>
                  <Box
                    sx={{
                      // height: "250px",
                      width: "100%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      "& .theme_header": {
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontFamily: "Calibri",
                        fontSize: "16px",
                      },
                      "& .theme_cell": {
                        fontFamily: "Calibri",
                        border: "1px solid",
                      },
                      "& .theme_cell_lost": {
                        fontFamily: "Calibri",
                        backgroundColor: "#FFC7CE",
                        color: "#9C0006",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                      "& .theme_cell_gain": {
                        fontFamily: "Calibri",
                        backgroundColor: "#C6EFCE",
                        color: "#006100",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                    }}
                  >
                    <DataGridPro
                      columns={OFF_SUB_LIST_COLUMNS}
                      rows={subTabLIST}
                      getRowId={(row) => row.id_temp}
                      headerHeight={40}
                      rowHeight={33}
                      autoHeight
                      {...subTabLIST}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                </>
              )}
              {subTabGARE !== null && (
                <>
                  <h3
                    style={{
                      paddingTop: "3%",
                      color: "#1976d2",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    GARE PUBBLICHE
                  </h3>
                  <Box
                    sx={{
                      // height: "350px",
                      width: "100%",
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      "& .theme_header": {
                        backgroundColor: "#1976d2",
                        color: "#fff",
                        fontFamily: "Calibri",
                        fontSize: "16px",
                      },
                      "& .theme_cell": {
                        fontFamily: "Calibri",
                        border: "1px solid",
                      },
                      "& .theme_cell_lost": {
                        fontFamily: "Calibri",
                        backgroundColor: "#FFC7CE",
                        color: "#9C0006",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                      "& .theme_cell_gain": {
                        fontFamily: "Calibri",
                        backgroundColor: "#C6EFCE",
                        color: "#006100",
                        fontSize: "17px",
                        border: "1px solid",
                      },
                    }}
                  >
                    <DataGridPro
                      columns={OFF_SUB_GARE_COLUMNS}
                      rows={subTabGARE}
                      getRowId={(row) => row.id}
                      headerHeight={40}
                      rowHeight={33}
                      autoHeight
                      {...subTabGARE}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleSubTabClose}
                sx={{
                  color: "#1976d2",
                  fontWeight: "bold",
                  ":hover": {
                    backgroundColor: "#1976d2",
                    color: "#fff",
                    fontWeight: "bold",
                  },
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_DETAILS_VALUTATION_POPUP;
