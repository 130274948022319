import { Link } from "react-router-dom";

import Navbar from "./Navbar";

const HeaderLogin = () => {
  return (
    <header>
      <div className="nav-area">
        {/* <Link to="/" className="logo">
          HOME
        </Link> */}
        <Navbar />
        {/* <User
        items={userLog}
        key={0}
        depthLevel={0}
        user={user}
        handleLogout={handleLogout}
      /> */}
      </div>
    </header>
  );
};

export default HeaderLogin;
