import React, { useState } from "react";
import "../../../../App.css";
import "../../../../styles/ACQUISTATO/Insert.css";

// COMPONENTS
import DateInputField from "../../../../functions/DATE/DATE_COMP_ACQ_IMPORT";

// FUNCTIONS
import { validateForm } from "../../../../functions/VALIDATE/VALIDATE_FORM_ACQ_IMPORT";

// MUI ELEMENTS
import Button from "@mui/material/Button";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

dayjs.extend(dayjsPluginUTC);

const PurchasedForm = ({
  updateDates,
  checkedDatesFlag,
  setChangePageFlag,
}) => {
  const [firstDate, setFirstDate] = useState(
    dayjs.utc("2024-01-01").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [lastDate, setLastDate] = useState(
    dayjs.utc("2024-12-31").hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
  );
  const [errors, setErrors] = useState({});
  const [flagWrongPeriod, setFlagWrongPeriod] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm(firstDate, lastDate, setFlagWrongPeriod);

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      checkedDatesFlag(false);
    } else {
      setErrors({});
      setFlagWrongPeriod(false);
      updateDates([firstDate, lastDate]);
      checkedDatesFlag(true);
      setChangePageFlag(false);
    }
  };

  return (
    <>
      <h1 className="form-title">INSERIMENTO NUOVO ACQUISTATO</h1>
      <h3 className="form-subtitle">INSERISCI IL PERIODO:</h3>
      <div className="form-date-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="form-date-field">
            <DateInputField
              value={firstDate}
              onChange={(newValue) =>
                setFirstDate(
                  newValue.hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                )
              }
              error={errors.firstDate}
              helperText={errors.firstDate}
            />
          </div>
          <div className="form-date-spacing"></div>
          <div className="form-date-field">
            <DateInputField
              value={lastDate}
              onChange={(newValue) =>
                setLastDate(
                  newValue.hour(12).local().format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                )
              }
              error={errors.lastDate}
              helperText={errors.lastDate}
            />
          </div>
        </LocalizationProvider>
      </div>
      {flagWrongPeriod && (
        <div className="form-error-message">
          HAI SCELTO UN INTERVALLO DI DATE NON CORRETTO
        </div>
      )}
      <div className="form-submit-button">
        <Button
          variant="contained"
          color="success"
          type="submit"
          startIcon={<CheckOutlinedIcon />}
          onClick={handleFormSubmit}
        >
          CONFERMA
        </Button>
      </div>
    </>
  );
};

export default PurchasedForm;
