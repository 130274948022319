import axios from "axios";

//se sviluppo
// const urlAcquistato = "http://localhost:3001/api/purchased";
//se produzione
const urlAcquistato = "/api/purchased";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

//Ricezione
const getAcquistatoByUserId = (userId) => {
  let newUrl = urlAcquistato + "/user/" + userId;
  return axios.get(newUrl);
};

const getAcquistatoByPurchasedId = (purchasedId) => {
  let newUrl = urlAcquistato + "/singlepurchased/" + purchasedId;
  return axios.get(newUrl);
};

// const getAcquistatoComplete = () => {
//   let newUrl = urlAcquistato + "/allpurchased";
//   return axios.get(newUrl);
// };

//Invio
const salvaAcquistato = async (newACQ) => {
  const config = {
    headers: { Authorization: token },
  };
  const res = await axios.post(urlAcquistato, newACQ, config);
  return res;
};

const acquistatoService = {
  getAcquistatoByUserId,
  getAcquistatoByPurchasedId,
  salvaAcquistato,
  // getAcquistatoComplete,
  setToken,
};

export default acquistatoService;
