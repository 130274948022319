import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_SUPPLIER_LIST = (props) => {
  const { data } = props;

  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);
    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "LISTA FORNITORI.xlsx");
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "RAGIONE SOCIALE",
        B: "CF - P. IVA",
      },
    ];

    data.forEach((row) => {
      table1.push({
        A: row.ragione_sociale,
        B: row.cf_piva,
      });
    });

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "LISTA FORNITORI");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:B1`,
    };

    return await addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(75);
        sheet.column("B").width(25).style({ horizontalAlignment: "center" });

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }

        const usedRange = sheet.usedRange();
        const startRow = usedRange.startCell().rowNumber();
        const endRow = usedRange.endCell().rowNumber();
        const startCol = usedRange.startCell().columnNumber();
        const endCol = usedRange.endCell().columnNumber();

        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          for (let colIndex = startCol; colIndex <= endCol; colIndex++) {
            const cell = sheet.cell(rowIndex, colIndex);

            const currentValue = cell.value();
            cell.value(currentValue && currentValue.toString());
            cell.style("numberFormat", "@");
          }
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        disabled={data === null}
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP LISTA FORNITORI
      </LoadingButton>
    </>
  );
};

export default EXP_SUPPLIER_LIST;
