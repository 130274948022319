import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import BeatLoader from "react-spinners/BeatLoader";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_ERRORS_CONFIRM_POPUP = (props) => {
  const {
    errorConfirm,
    setErrorConfirm,
    handleFirstConfirmWithErrors,
    loadingFarmData,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={errorConfirm}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => setErrorConfirm(false)}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#F00",
                fontSize: 25,
                textAlign: "center",
              }}
            >
              PROSEGUENDO LE RIGHE EVIDENZIATE A CAUSA DI ERRORI VERRANNO
              RIMOSSE DAL FILE
              <br />
              CONFERMI DI VOLER PROSEGUIRE?
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="redtest"
                  startIcon={<ArrowBackIosNewOutlinedIcon />}
                  onClick={() => setErrorConfirm(false)}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "6%",
                }}
              ></div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="success"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={handleFirstConfirmWithErrors}
                >
                  CONFERMA
                </Button>
              </div>
              {loadingFarmData && (
                <div style={{ paddingTop: "3%", textAlign: "center" }}>
                  <BeatLoader
                    color={"#1976d2"}
                    loading={loadingFarmData}
                    size={30}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                  <h6 style={{ fontFamily: "Calibri", color: "#1976d2" }}>
                    CARICAMENTO...
                  </h6>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_ERRORS_CONFIRM_POPUP;
