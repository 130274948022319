import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_FABB_EMPTY = () => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);
    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "FABBISOGNO DA INSERIRE.xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      // console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    let table1 = [
      {
        A: "STRUTTURA",
        B: "AIC",
        C: "NOME COMMERCIALE",
        D: "QUANTITA",
      },
    ];

    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet, "FABBISOGNO DA INSERIRE");

    const workbookBlob = workbook2blob(wb);

    const dataInfo = {
      theadRange: `A1:D1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "center",
          border: true,
        });

        sheet.column("A").width(25);
        sheet.column("B").width(16);
        sheet.column("C").width(35);
        sheet.column("D").width(20);

        if (dataInfo.theadRange) {
          sheet.range(dataInfo.theadRange).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };
  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP FILE VUOTO
      </LoadingButton>
    </>
  );
};

export default EXP_FABB_EMPTY;
