import React, { useState } from "react";
import "../../../App.css";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import {
  FABB_IMPORT_COLUMNS,
  FABB_CONFRONTO_COLUMNS,
} from "../../../colums/FABB_COLUMNS";
import EXP_FABB_CONFRONTO from "../../../exports/FABB/EXP_FABB_CONFRONTO";

import { handleFile } from "../../../functions/IMPORT/FABBISOGNO_IMPORT";

import {
  handleFirstConfirm,
  handleSecondConfirm,
} from "../../../functions/CONFIRM/FABBISOGNO_CONFIRM";

import ErrorDisplay from "./FABBISOGNO_COMPONENTS/IMPORT/ErrorDisplay";
import DataGridDisplay from "./FABBISOGNO_COMPONENTS/IMPORT/DataGridDisplay";
import LoadingSpinner from "./FABBISOGNO_COMPONENTS/IMPORT/LoadingSpinner";
import ConfirmButtons from "./FABBISOGNO_COMPONENTS/IMPORT/ConfirmButtons";
import Popups from "./FABBISOGNO_COMPONENTS/IMPORT/Popups";

import TopSection from "./FABBISOGNO_COMPONENTS/IMPORT/TopSection";

const theme = createTheme({
  palette: {
    bluetest: {
      main: "#1976d2",
      light: "#328ce7",
      dark: "#1566b7",
      contrastText: "#fff",
    },
    redtest: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#cc0000",
      contrastText: "#fff",
    },
  },
});

const PurchasedFabbisognoImport = ({ insertedFile, updateFileData }) => {
  const [dataFile, setDataFile] = useState(null);
  const [importErrors, setImportErrors] = useState(null);
  const [confirmedFile, setConfirmedFile] = useState(false);
  const [fileValutation, setFileValutation] = useState([]);
  const [notMatch, setNotMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [secondConfirmOpen, setSecondConfirmOpen] = useState(false);
  const [loadingFarmData, setLoadingFarmData] = useState(false);

  const handleFileImport = (e) => {
    handleFile(e, setDataFile, setImportErrors);
  };

  const handleRemoveFile = () => {
    setDataFile(null);
    setFileValutation([]);
    setNotMatch([]);
    setConfirmedFile(false);
    setImportErrors(null);
  };

  const firstConfirm = () => {
    handleFirstConfirm(
      setLoadingFarmData,
      dataFile,
      setNoMatchFlag,
      setFileValutation,
      setNotMatch,
      setConfirmedFile
    );
  };

  const secondConfirm = () => {
    handleSecondConfirm(updateFileData, insertedFile, fileValutation);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="acqPage">
        {!confirmedFile && importErrors === null && (
          <>
            {!dataFile && (
              <TopSection
                setInstructionOpen={setInstructionOpen}
                handleFileImport={handleFileImport}
              />
            )}
            {dataFile && (
              <>
                {loadingFarmData ? (
                  <LoadingSpinner loading={loadingFarmData} />
                ) : (
                  <>
                    <div>
                      <DataGridDisplay
                        columns={FABB_IMPORT_COLUMNS}
                        rows={dataFile}
                        getRowId={(row) => row.id_temp}
                        headerHeight={40}
                        rowHeight={33}
                      />
                    </div>
                    <ConfirmButtons
                      handleRemoveFile={handleRemoveFile}
                      firstConfirm={firstConfirm}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
        {!confirmedFile && importErrors !== null && (
          <ErrorDisplay
            importErrors={importErrors}
            handleRemoveFile={handleRemoveFile}
          />
        )}
        {confirmedFile && importErrors === null && (
          <>
            <div
              style={{
                textAlign: "left",
                display: "inline-block",
                width: "50%",
              }}
            >
              <Button
                className="Button"
                variant="contained"
                component="label"
                color="redtest"
                startIcon={<DeleteOutlinedIcon />}
                onClick={handleRemoveFile}
              >
                RIMUOVI IL FILE
              </Button>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "inline-block",
                width: "50%",
              }}
            >
              <EXP_FABB_CONFRONTO data={fileValutation} />
            </div>
            <Box
              sx={{
                height: "418px",
                width: "100%",
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: "1%",
                "& .theme_header": {
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  fontFamily: "Calibri",
                  fontSize: "17px",
                },
                "& .theme_cell": {
                  fontFamily: "Calibri",
                  fontSize: "18px",
                  border: "1px solid",
                },
                "& .theme_cell_nomatch": {
                  fontFamily: "Calibri",
                  fontSize: "18px",
                  color: "#ff1a00",
                  border: "1px solid",
                },
              }}
            >
              {notMatch.length > 0 && (
                <div
                  style={{
                    display: "inline-block",
                    width: "50%",
                    textAlign: "left",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="bottom"
                        control={
                          <Switch
                            checked={noMatchFlag}
                            onChange={() => setNoMatchFlag(!noMatchFlag)}
                          />
                        }
                        label={
                          <h4
                            style={{
                              fontFamily: "Calibri",
                              color: noMatchFlag ? "#1976d2" : "#ccc",
                            }}
                          >
                            FILTRO NO MATCH
                          </h4>
                        }
                        labelPlacement="end"
                      />
                    </FormGroup>
                  </FormControl>
                </div>
              )}
              <h3
                style={{
                  display: "inline-block",
                  width: "50%",
                  textAlign: "right",
                  fontFamily: "Calibri",
                  color: "#1976d2",
                }}
              >
                TOT Elementi:{" "}
                {noMatchFlag ? notMatch.length : fileValutation.length}
              </h3>
              <DataGridDisplay
                columns={FABB_CONFRONTO_COLUMNS}
                rows={noMatchFlag ? notMatch : fileValutation}
                getRowId={(row) => row.id_acquistato_temp}
                headerHeight={40}
                rowHeight={33}
              />
            </Box>
            <div style={{ textAlign: "right", margin: "5%" }}>
              <Button
                className="Button"
                variant="contained"
                component="label"
                color="success"
                startIcon={<CheckOutlinedIcon />}
                onClick={() => setSecondConfirmOpen(true)}
              >
                CONFERMA
              </Button>
            </div>
          </>
        )}
        <Popups
          instructionOpen={instructionOpen}
          setInstructionOpen={setInstructionOpen}
          secondConfirmOpen={secondConfirmOpen}
          setSecondConfirmOpen={setSecondConfirmOpen}
          notMatch={notMatch}
          secondConfirm={secondConfirm}
        />
      </div>
    </ThemeProvider>
  );
};

export default PurchasedFabbisognoImport;
