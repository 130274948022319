import React, { useState, useRef, useEffect } from "react";
import "../../../../App.css";
import "../../../../styles/ACQUISTATO/Insert.css";

// SERVICES
import fornitoriService from "../../../../services/fornitori";

// COLUMNS
import {
  ACQ_IMPORT_COLUMNS,
  ACQ_CONFRONTO_COLUMNS,
  ACQ_ERRORS_COLUMNS,
} from "../../../../colums/ACQ_COLUMNS";

// EXPS
import EXP_SUPPLIER_LIST from "../../../../exports/DB/EXP_SUPPLIER_LIST";
import EXP_ACQ_EMPTY from "../../../../exports/EMPTY_FILES/EXP_ACQ_EMPTY";
import EXP_ACQ_CONFRONTO from "../../../../exports/ACQ/EXP_ACQ_CONFRONTO";
import EXP_ACQ_CONTROLLO_FORNITORI from "../../../../exports/ACQ/EXP_ACQ_CONTROLLO_FORNITORI";

// POPUPS
import PURCHASED_INSTR_POPUP from "../../../../popup/INSTRUCTION_POPUP/PURCHASED_INSTR_POPUP";
import ACQ_SECOND_CONFIRM_POPUP from "../../../../popup/ACQ_POPUP/ACQ_SECOND_CONFIRM_POPUP";

// MUI ELEMENTS
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// ICONS
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// LOADERS
import BeatLoader from "react-spinners/BeatLoader";

// FUNZIONI
import { calcoloCosto } from "../../../../functions/FUN_CALCOLI";
import { visualizzaData } from "../../../../functions/DATE/VISUALIZZA_DATA";
import { handleFile } from "../../../../functions/IMPORT/ACQUISTATO_IMPORT";
import {
  handleFirstConfirm,
  handleSecondConfirm,
} from "../../../../functions/VALUTATION/ACQ_INSERT_CONFIRM";

import {
  boxStyles,
  boxStylesValutation,
  errorBoxStyles,
} from "../../../../boxStyles/ACQUISTATO/IMPORT_BOXES";

// Funzione per rendere il DataGridPro
const renderDataGrid = (rows, rowIdAccessor) => (
  <DataGridPro
    columns={ACQ_CONFRONTO_COLUMNS}
    rows={rows}
    getRowId={(row) => row[rowIdAccessor]}
    headerHeight={40}
    rowHeight={33}
    disableColumnMenu
    hideFooter
  />
);

const PurchasedImport = ({
  updateFileData,
  insertedFile,
  dates,
  handleChangeDates,
}) => {
  const [supplierDB, setSupplierDB] = useState(null);
  const fileRef = useRef();
  const [dataFile, setDataFile] = useState(null);
  const [importErrors, setImportErrors] = useState(null);
  const [confirmedFile, setConfirmedFile] = useState(false);
  const [controlloFornitori, setControlloFornitori] = useState([]);
  const [fileValutation, setFileValutation] = useState([]);
  const [totSingoleStrtuture, setTotSingoleStrtuture] = useState([]);
  const [notMatch, setNotMatch] = useState([]);
  const [noMatchFlag, setNoMatchFlag] = useState(false);
  const [instructionOpen, setInstructionOpen] = useState(false);
  const [secondConfirmOpen, setSecondConfirmOpen] = useState(false);
  const [loadingFarmData, setLoadingFarmData] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    fornitoriService
      .getFornitori()
      .then((res) => {
        res.data.sort((a, b) =>
          a.ragione_sociale.localeCompare(b.ragione_sociale)
        );
        setSupplierDB(res.data);
      })
      .catch((error) => {
        console.error(
          "Errore durante il recupero dei fornitori dal server:",
          error
        );
      });
    if (loadingFarmData) {
      loadData();
    }
  }, [loadingFarmData]);

  const loadData = () => {
    setLoadingPercentage(0);
    handleFirstConfirm(
      setLoadingFarmData,
      dataFile,
      supplierDB,
      setLoadingPercentage,
      setNoMatchFlag,
      setControlloFornitori,
      setFileValutation,
      setNotMatch,
      setConfirmedFile
    );
  };

  const handleRemove = () => {
    setDataFile(null);
    setControlloFornitori([]);
    setFileValutation([]);
    setNotMatch([]);
    setConfirmedFile(false);
    handleChangeDates();
    setImportErrors(null);
    setTotSingoleStrtuture([]);
  };

  const handleFirstConfirmClick = () => {
    handleFirstConfirm(
      setLoadingFarmData,
      dataFile,
      supplierDB,
      setLoadingPercentage,
      setNoMatchFlag,
      setControlloFornitori,
      setFileValutation,
      setNotMatch,
      setConfirmedFile
    );
  };

  const handleCalocloTOTStrutture = (ACQ_TEMP) => {
    const result = ACQ_TEMP.reduce(
      (acc, curr) => {
        const struttura = curr.struttura;

        if (!acc[struttura]) {
          acc[struttura] = 0;
        }

        acc[struttura] += calcoloCosto(curr.prezzo_unitario, curr.quantita);
        acc.TOTALE += calcoloCosto(curr.prezzo_unitario, curr.quantita);

        return acc;
      },
      { TOTALE: 0 }
    );

    const entries = Object.entries(result);

    entries.sort(([key1], [key2]) => {
      if (key1 === "total") return 1;
      if (key2 === "total") return -1;
      return 0;
    });

    return entries;
  };

  const handleSecondConfirmOpen = async () => {
    const fileValutationTEMP = [...fileValutation];

    const TOT_STRUTTURE = await handleCalocloTOTStrutture(fileValutationTEMP);

    setTotSingoleStrtuture(TOT_STRUTTURE);
    setSecondConfirmOpen(true);
  };

  const ButtonGroupImport = ({
    handleChangeDates,
    setInstructionOpen,
    supplierDB,
    dataFile,
    confirmedFile,
    setDataFile,
    setImportErrors,
    setLoadingPercentage,
    fileRef,
  }) => (
    <div className="button-group-import">
      <div
        className="inline-block text-left"
        style={{ width: "15%", verticalAlign: "top" }}
      >
        <Button
          variant="contained"
          color="redtest"
          startIcon={<ArrowBackIosNewIcon />}
          onClick={() => handleChangeDates()}
        >
          CAMBIA PERIODO
        </Button>
      </div>
      <div className="inline-block text-left" style={{ width: "25%" }}>
        <Button
          variant="contained"
          color="bluetest"
          startIcon={<InfoOutlinedIcon />}
          onClick={() => setInstructionOpen(true)}
        >
          ISTRUZIONI
        </Button>
      </div>

      <div className="inline-block text-right" style={{ width: "35%" }}>
        <EXP_SUPPLIER_LIST data={supplierDB} />
      </div>
      <div className="inline-block text-right" style={{ width: "15%" }}>
        <EXP_ACQ_EMPTY />
      </div>
      {!dataFile && !confirmedFile ? (
        <div className="inline-block text-right" style={{ width: "10%" }}>
          <Button
            variant="contained"
            color="bluetest"
            component="label"
            startIcon={<BackupOutlinedIcon />}
          >
            UPLOAD
            <input
              type="file"
              hidden
              accept="xlsx, xls"
              multiple={false}
              onChange={(e) =>
                handleFile(
                  e,
                  setDataFile,
                  setImportErrors,
                  setLoadingPercentage
                )
              }
              ref={fileRef}
            />
          </Button>
        </div>
      ) : (
        <div className="inline-block" style={{ width: "10%" }}></div>
      )}
    </div>
  );

  const ButtonGroupConfirm = ({
    fileValutation,
    controlloFornitori,
    handleRemove,
    handleSecondConfirm,
  }) => (
    <div>
      <div className="inline-block text-center" style={{ width: "33%" }}>
        <EXP_ACQ_CONFRONTO data={fileValutation} />
      </div>
      <div className="inline-block text-right" style={{ width: "33%" }}>
        <EXP_ACQ_CONTROLLO_FORNITORI data={controlloFornitori} />
      </div>
      <div className="inline-block text-right" style={{ width: "10%" }}></div>
      <div className="inline-block text-right" style={{ width: "13%" }}>
        <Button
          variant="contained"
          color="redtest"
          startIcon={<DeleteOutlinedIcon />}
          onClick={handleRemove}
        >
          RIMUOVI IL FILE
        </Button>
      </div>
      <div className="inline-block text-right" style={{ width: "11%" }}>
        <Button
          variant="contained"
          color="success"
          startIcon={<CheckOutlinedIcon />}
          onClick={handleSecondConfirmOpen}
        >
          CONFERMA
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {!confirmedFile && importErrors === null && (
        <>
          <ButtonGroupImport
            handleChangeDates={handleChangeDates}
            setInstructionOpen={setInstructionOpen}
            supplierDB={supplierDB}
            dataFile={dataFile}
            confirmedFile={confirmedFile}
            setDataFile={setDataFile}
            setImportErrors={setImportErrors}
            setLoadingPercentage={setLoadingPercentage}
            fileRef={fileRef}
          />

          {dataFile && !confirmedFile && (
            <>
              {loadingFarmData ? (
                <div className="text-center" style={{ paddingTop: "3%" }}>
                  <Box className="box box-large">
                    <LinearProgress
                      variant="determinate"
                      value={loadingPercentage}
                    />
                    <h3 className="text-primary" style={{ marginTop: "10px" }}>
                      CARICAMENTO... {loadingPercentage}%
                    </h3>
                    <BeatLoader
                      color={"#1976d2"}
                      loading={loadingFarmData}
                      size={30}
                    />
                  </Box>
                </div>
              ) : (
                <>
                  <Box sx={boxStyles}>
                    <div
                      className="inline-block text-left"
                      style={{ width: "50%" }}
                    >
                      <p className="text-primary" style={{ fontSize: "22px" }}>
                        PERIODO SELEZIONATO:{" "}
                        <b>
                          {visualizzaData(dates[0].split("T")[0])} -{" "}
                          {visualizzaData(dates[1].split("T")[0])}
                        </b>
                      </p>
                    </div>
                    <div
                      className="inline-block text-right"
                      style={{ width: "50%" }}
                    >
                      <p className="text-primary" style={{ fontSize: "22px" }}>
                        <b>TOT ELEMENTI: {dataFile.length}</b>
                      </p>
                    </div>

                    <DataGridPro
                      columns={ACQ_IMPORT_COLUMNS}
                      rows={dataFile}
                      getRowId={(row) => row.id_temp}
                      headerHeight={40}
                      rowHeight={33}
                      disableColumnMenu
                      hideFooter
                    />
                  </Box>
                  <div style={{ paddingTop: "3%" }}>
                    <div
                      className="inline-block text-right"
                      style={{ width: "13%" }}
                    >
                      <Button
                        variant="contained"
                        color="redtest"
                        startIcon={<DeleteOutlinedIcon />}
                        onClick={handleRemove}
                      >
                        RIMUOVI IL FILE
                      </Button>
                    </div>
                    <div
                      className="inline-block text-right"
                      style={{ width: "21%" }}
                    >
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<ManageSearchOutlinedIcon />}
                        onClick={handleFirstConfirmClick}
                      >
                        CONFRONTA CON FARMADATI
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}

      {!confirmedFile && importErrors !== null && (
        <>
          <div className="button-container">
            <Button
              variant="contained"
              color="redtest"
              startIcon={<DeleteOutlinedIcon />}
              onClick={handleRemove}
            >
              RIMUOVI IL FILE
            </Button>
          </div>
          <h2 className="error-title">INDIVIDUATI ERRORI NEL FILE INSERITO:</h2>

          <Box sx={errorBoxStyles}>
            <DataGridPro
              columns={ACQ_ERRORS_COLUMNS}
              rows={importErrors}
              getRowId={(row) => row.id_temp}
              headerHeight={40}
              rowHeight={33}
              disableColumnMenu
              hideFooter
            />
          </Box>
        </>
      )}

      {confirmedFile && importErrors === null && (
        <>
          <ButtonGroupConfirm
            fileValutation={fileValutation}
            controlloFornitori={controlloFornitori}
            handleRemove={handleRemove}
            handleSecondConfirm={handleSecondConfirm}
          />

          <Box sx={boxStylesValutation}>
            <div className="inline-block text-left" style={{ width: "50%" }}>
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Switch
                        checked={noMatchFlag}
                        onChange={() => setNoMatchFlag(!noMatchFlag)}
                      />
                    }
                    label={
                      <h4
                        className={
                          noMatchFlag ? "label-checked" : "label-unchecked"
                        }
                      >
                        FILTRO NO MATCH
                      </h4>
                    }
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </div>

            {!noMatchFlag ? (
              <>
                <div className="text-right" style={{ width: "50%" }}>
                  <p className="text-primary" style={{ fontSize: "22px" }}>
                    <b>TOT ELEMENTI: {dataFile.length}</b>
                  </p>
                </div>
                {renderDataGrid(fileValutation, "id_acquistato_temp")}
              </>
            ) : (
              <>
                <h3 className="text-right" style={{ width: "50%" }}>
                  TOT Elementi: {notMatch.length}
                </h3>
                {renderDataGrid(notMatch, "id_acquistato_temp")}
              </>
            )}
          </Box>
        </>
      )}

      <PURCHASED_INSTR_POPUP
        instructionOpen={instructionOpen}
        setInstructionOpen={setInstructionOpen}
      />

      <ACQ_SECOND_CONFIRM_POPUP
        secondConfirmOpen={secondConfirmOpen}
        setSecondConfirmOpen={setSecondConfirmOpen}
        notMatch={notMatch}
        totSingoleStrtuture={totSingoleStrtuture}
        handleSecondConfirm={handleSecondConfirm}
        fileValutation={fileValutation}
        updateFileData={updateFileData}
        insertedFile={insertedFile}
      />
    </>
  );
};

export default PurchasedImport;
