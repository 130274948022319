import axios from "axios";
// import codOspListService from "./codOspListService";
// import garePubListService from "./garePubListService";

// se sviluppo
// const urlAbbinamento = "http://localhost:3001/api/abbinamento";
// se produzione
const urlAbbinamento = "/api/abbinamento";

let token = null;

const setToken = (newToken) => {
  token = "bearer " + newToken;
};

const getAbbinamentoByUserId = (userId) => {
  let newUrl = urlAbbinamento + "/user/" + userId;
  return axios.get(newUrl);
};

const getAbbinamentoByAbbinamentoId = (abbinamentoId) => {
  let newUrl = urlAbbinamento + "/singleabbinamento/" + abbinamentoId;
  return axios.get(newUrl);
};

// Ricezione
const getAbbinamento = () => {
  let newUrl = urlAbbinamento + "/allabbinamento";
  return axios.get(newUrl);
};

// Invio
const salvaAbbinamento = async (newAbbinamento) => {
  const config = {
    headers: { Authorization: token },
  };

  console.log("newAbbinamento", newAbbinamento);

  const res = await axios.post(urlAbbinamento, newAbbinamento, config);
  return res;
};

const aggiornaAbbinamento = async (newAbbinamento, AbbinamentoId) => {
  const config = {
    headers: { Authorization: token },
  };

  let newUrl = urlAbbinamento + "/updateabbinamento/" + AbbinamentoId;

  const res = await axios.patch(newUrl, newAbbinamento, config);
  return res;
};

const abbinamentoService = {
  getAbbinamentoByUserId,
  getAbbinamentoByAbbinamentoId,
  getAbbinamento,
  salvaAbbinamento,
  aggiornaAbbinamento,
  setToken,
};

export default abbinamentoService;
