import React from "react";
import FABB_INSTR_POPUP from "../../../../../popup/INSTRUCTION_POPUP/FABB_INSTR_POPUP";
import FABB_SECOND_CONFIRM_POPUP from "../../../../../popup/ACQ_POPUP/FABB_SECOND_CONFIRM_POPUP";

const Popups = ({
  instructionOpen,
  setInstructionOpen,
  secondConfirmOpen,
  setSecondConfirmOpen,
  notMatch,
  secondConfirm,
}) => {
  return (
    <>
      <FABB_INSTR_POPUP
        instructionOpen={instructionOpen}
        setInstructionOpen={setInstructionOpen}
      />
      <FABB_SECOND_CONFIRM_POPUP
        secondConfirmOpen={secondConfirmOpen}
        setSecondConfirmOpen={setSecondConfirmOpen}
        notMatch={notMatch}
        secondConfirm={secondConfirm}
      />
    </>
  );
};

export default Popups;
