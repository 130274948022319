import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EXP_ABB_MULTIPLI = ({ data, abb_name }) => {
  const [loadingEXPData, setLoadingEXPData] = useState(false);

  const createDownLoadData = async () => {
    setLoadingEXPData(true);

    try {
      const url = await handleExport();
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        "OFFERTE PREZZI MULTIPLI - " + abb_name + ".xlsx"
      );
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

      setLoadingEXPData(false);
    } catch (error) {
      console.log("Si è verificato un errore durante l'esportazione: ", error);
      alert("Errore nell'export! Riprova");
      setLoadingEXPData(false);
    }
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);

    const view = new Uint8Array(buf);

    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = async () => {
    let table1 = [
      {
        A: "PRINCIPIO ATTIVO",
        B: "TIPOLOGIA",
        C: "ATC COMP.",
        D: "AIC",
        E: "NOME COMMERCIALE",
        F: "FORNITORE",
        G: "PREZZO UNITARIO",
      },
    ];

    let prizeCollectiveSpec = [];

    await data.forEach((row) => {
      if (row.flag_prize_collective) {
        prizeCollectiveSpec.push(row);
      }
    });

    let offersCompleteList = [];

    await prizeCollectiveSpec.map((SEL) => {
      SEL.cod_osp_list.map((specSel) => {
        offersCompleteList.push({
          A: specSel?.principio_attivo,
          B: specSel?.descrizione_gruppo_ospedaliero,
          C: specSel?.atc_complementare,
          D: specSel?.aic,
          E: specSel?.nome_commerciale,
          F: specSel?.ragione_sociale,
          G: specSel?.prezzo_unitario,
        });

        return SEL.cod_osp_list;
      });

      return prizeCollectiveSpec;
    });

    await offersCompleteList.sort((a, b) => {
      let x = a.B;
      let y = b.B;

      if (x < y) {
        return -1;
      } else if (x > y) {
        return 1;
      }
      return 0;
    });

    table1 = table1.concat(offersCompleteList);

    const wb = XLSX.utils.book_new();

    const sheet1 = XLSX.utils.json_to_sheet(table1, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, sheet1, "OFFERTE MINIMI MULTIPLI");

    const workbookBlob = workbook2blob(wb);
    const dataInfo = {
      theadRange1: `A1:G1`,
    };

    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets("OFFERTE MINIMI MULTIPLI").forEach((sheet) => {
        sheet.usedRange().style({
          fontFamily: "Calibri",
          horizontalAlignment: "left",
          border: true,
        });

        sheet.column("A").width(35);
        sheet.column("B").width(35);
        sheet.column("C").width(17).style({ horizontalAlignment: "center" });
        sheet.column("D").width(14).style({ horizontalAlignment: "center" });
        sheet.column("E").width(30);
        sheet.column("F").width(35);

        sheet.column("G").width(18).style({
          horizontalAlignment: "right",
          numberFormat: "#,##0.00000",
        });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "1976d2",
            fontColor: "ffffff",
            bold: false,
            horizontalAlignment: "center",
          });
        }
      });

      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="bluetest"
        loading={loadingEXPData}
        loadingPosition="start"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => createDownLoadData()}
      >
        EXP OFFERTE PREZZI MULTIPLI
      </LoadingButton>
    </>
  );
};

export default EXP_ABB_MULTIPLI;
