import React from "react";
import Button from "@mui/material/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";

const ConfirmButtons = ({ handleRemoveFile, firstConfirm }) => {
  return (
    <div style={{ paddingTop: "3%" }}>
      <div
        style={{ textAlign: "left", display: "inline-block", width: "48%" }}
      ></div>
      <div
        style={{ textAlign: "right", display: "inline-block", width: "15%" }}
      >
        <Button
          className="Button"
          variant="contained"
          component="label"
          color="redtest"
          startIcon={<DeleteOutlinedIcon />}
          onClick={handleRemoveFile}
        >
          RIMUOVI IL FILE
        </Button>
      </div>
      <div
        style={{ textAlign: "right", display: "inline-block", width: "22%" }}
      >
        <Button
          className="Button"
          variant="contained"
          component="label"
          color="success"
          startIcon={<ManageSearchOutlinedIcon />}
          onClick={firstConfirm}
        >
          CONFRONTA CON FARMADATI
        </Button>
      </div>
      <div
        style={{ textAlign: "left", display: "inline-block", width: "15%" }}
      ></div>
    </div>
  );
};

export default ConfirmButtons;
