const handleResetSearchSEL = (
  setFilteredResults,
  setSearchedFlag,
  setNotFoundFlag,
  setSearchSelPopupOpen
) => {
  setFilteredResults(null);
  setSearchedFlag(false);
  setNotFoundFlag(false);
  setSearchSelPopupOpen(false);
};

const handleSearchSEL = (
  searchValue,
  gareSelected, //file principale
  selectedField,
  setFilteredResults,
  setNotFoundFlag,
  setSearchSelPopupOpen,
  setSearchedFlag,
  handleResetSearchSEL
) => {
  if (searchValue === null) {
    handleResetSearchSEL();
  } else {
    const ArrayToSearch = [...gareSelected];
    let filtered = [];

    if (selectedField.name !== "FORNITORE") {
      filtered = ArrayToSearch.filter((item) => {
        return item[selectedField.field]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    } else {
      filtered = ArrayToSearch.filter((item) => {
        return item.fornitore.ragione_sociale
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }

    if (filtered.length > 0) {
      setFilteredResults(filtered);
      setNotFoundFlag(false);
      setSearchSelPopupOpen(false);
      setSearchedFlag(true);
    } else {
      setFilteredResults(filtered);
      setNotFoundFlag(true);
    }
  }
};

const createSuggestionsListSEL = (newValue, gareData) => {
  const arrayToSearch = [...gareData];
  const suggestions = arrayToSearch.map((Selected) =>
    newValue.name !== "FORNITORE"
      ? Selected[newValue.field]
      : Selected.fornitore.ragione_sociale
  );
  return [...new Set(suggestions)].sort();
};

const handleFieldChangeSEL = async (
  newValue,
  gareSelected,
  setSelectedField,
  setFieldSuggestions,
  createSuggestionsListSEL
) => {
  if (newValue !== null) {
    setSelectedField(newValue);
    const SUGGESTIONS_LIST_TEMP = await createSuggestionsListSEL(
      newValue,
      gareSelected
    );
    setFieldSuggestions(SUGGESTIONS_LIST_TEMP);
  }
};

export {
  handleResetSearchSEL,
  handleSearchSEL,
  createSuggestionsListSEL,
  handleFieldChangeSEL,
};
