//FUNZIONI
import { calcoloCosto } from "../functions/FUN_CALCOLI";

const RapprPrezzo = (PrezzoDaRappresentare) => {
  if (PrezzoDaRappresentare === "") {
    return PrezzoDaRappresentare;
  } else {
    let IntDec = PrezzoDaRappresentare.toString();
    IntDec = IntDec.split(".");

    let nuovoVal = "";
    let vecchioVal = IntDec[0];

    while (vecchioVal?.length > 3) {
      nuovoVal = "." + vecchioVal.substr(vecchioVal?.length - 3) + nuovoVal;
      vecchioVal = vecchioVal.substr(0, vecchioVal?.length - 3);
    }
    if (IntDec[1] !== undefined) {
      if (IntDec[1].length === 1) {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1] + "0";
      } else {
        nuovoVal = vecchioVal + nuovoVal + "," + IntDec[1];
      }
    } else {
      nuovoVal = vecchioVal + nuovoVal + ",00";
    }

    let finalVal = nuovoVal;

    if (finalVal.startsWith("-.")) {
      finalVal = "- " + finalVal.substr(2);
    } else if (finalVal.startsWith(".")) {
      finalVal = finalVal.substr(1);
    }

    return finalVal + " €";
  }
};

//COLUMNS

const ACQ_ERRORS_COLUMNS = [
  {
    field: "id_temp",
    headerName: "RIGA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.id_temp + 2}</div>
    ),
  },
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_struttura_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "left",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_aic_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "center",
    flex: 0.6,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_name_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore_pivaFILE",
    headerName: "P. IVA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_piva_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "center",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.fornitore_pivaFILE}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_prezzo_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "right",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>
        {RapprPrezzo(params.row?.prezzo_unitario)}
      </div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANTITA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: (params) => {
      if (params.row?.flag_quantita_error) {
        return "theme_cell_error";
      } else {
        return "theme_cell";
      }
    },
    align: "right",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 14 }}>{params.row?.quantita}</div>
    ),
  },
];

const ACQ_IMPORT_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 0.7,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.8,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.fornitore_ragioneSociale_FILE}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "center",
    sortable: false,
    flex: 0.3,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.6,
    align: "right",
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>
        {params.row?.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
  {
    field: "quantita",
    headerName: "QUANTITA'",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    flex: 0.3,
    align: "right",
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { align: "right", fontSize: 17 }
            : { color: "red", align: "right", fontSize: 17 }
        }
      >
        {params.row.quantita}
      </div>
    ),
  },
  {
    field: "Costo",
    headerName: "SPESA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "right",
    flex: 0.5,
    renderCell: (params) => (
      <div
        style={
          params.row?.quantita > 0
            ? { fontSize: 17 }
            : { color: "red", fontSize: 17 }
        }
      >
        {RapprPrezzo(
          calcoloCosto(params.row?.prezzo_unitario, params.row?.quantita)
        )}
      </div>
    ),
  },
];

const ACQ_CONFRONTO_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 0.7,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.struttura}</div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.4,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "left",
    flex: 1,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "nome_commerciale_farmadati",
    headerName: "NOME FARMADATI",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    align: "left",
    flex: 1,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 17 }
            : { fontSize: 17 }
        }
      >
        {params.row?.nome_commerciale_farmadati}
      </div>
    ),
  },
  {
    field: "atc",
    headerName: "ATC",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.3,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.atc}</div>
    ),
  },
  {
    field: "atc_farmadati",
    headerName: "ATC FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.4,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH"
            ? { color: "red", fontSize: 17 }
            : { fontSize: 17 }
        }
      >
        {params.row?.atc_farmadati}
      </div>
    ),
  },
  {
    field: "atc_complementare",
    headerName: "ATC COMP.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.5,
    renderCell: (params) => (
      <div style={{ fontSize: 17 }}>{params.row?.atc_complementare}</div>
    ),
  },
  {
    field: "atc_complementare_farmadati",
    headerName: "ATC COMP. FARM.",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    sortable: false,
    align: "center",
    flex: 0.6,
    renderCell: (params) => (
      <div
        style={
          params.row.aic_farmadati === "NO MATCH" ||
          (params.row.atc_complementare !== undefined &&
            params.row.atc_complementare_farmadati !==
              params.row.atc_complementare)
            ? { color: "red", fontSize: 17 }
            : { fontSize: 17 }
        }
      >
        {params.row.atc_complementare_farmadati}
      </div>
    ),
  },
];

const ACQ_MAIN_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row.struttura}</div>
    ),
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.3,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.principio_attivo}</div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.7,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    align: "center",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.7,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.3,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row.fornitore.ragione_sociale}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.9,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
  {
    field: "quantita",
    headerName: "Q",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { fontSize: 16, align: "right" }
            : { fontSize: 16, color: "red", align: "right" }
        }
      >
        {params.row.quantita}
      </div>
    ),
  },
  {
    field: "costo",
    headerName: "SPESA",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.9,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { fontSize: 16 }
            : { fontSize: 16, color: "red" }
        }
      >
        {RapprPrezzo(
          calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
        )}
      </div>
    ),
  },
];

const ACQ_VIEW_COLUMNS = [
  {
    field: "struttura",
    headerName: "STRUTTURA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row.struttura}</div>
    ),
  },
  {
    field: "principio_attivo",
    headerName: "PRINCIPIO ATTIVO",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.principio_attivo}</div>
    ),
  },
  {
    field: "descrizione_gruppo_ospedaliero",
    headerName: "TIPOLOGIA",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row?.descrizione_gruppo_ospedaliero}
      </div>
    ),
  },
  {
    field: "aic",
    headerName: "AIC",
    align: "center",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.5,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.aic}</div>
    ),
  },
  {
    field: "nome_commerciale",
    headerName: "NOME COMMERCIALE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 1.2,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row?.nome_commerciale}</div>
    ),
  },
  {
    field: "fornitore",
    headerName: "FORNITORE",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.8,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>{params.row.fornitore.ragione_sociale}</div>
    ),
  },
  {
    field: "prezzo_unitario",
    headerName: "PREZZO UNITARIO",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.7,
    sortable: false,
    renderCell: (params) => (
      <div style={{ fontSize: 16 }}>
        {params.row.prezzo_unitario.toFixed(5)}
        {""}€
      </div>
    ),
  },
  {
    field: "quantita",
    headerName: "Q",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.3,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { align: "right", fontSize: 16 }
            : { color: "red", align: "right", fontSize: 16 }
        }
      >
        {params.row.quantita}
      </div>
    ),
  },
  {
    field: "costo",
    headerName: "SPESA",
    align: "right",
    headerAlign: "center",
    headerClassName: "theme_header",
    cellClassName: "theme_cell",
    flex: 0.6,
    sortable: false,
    renderCell: (params) => (
      <div
        style={
          params.row.quantita > 0
            ? { fontSize: 16 }
            : { color: "red", fontSize: 16 }
        }
      >
        {RapprPrezzo(
          calcoloCosto(params.row.prezzo_unitario, params.row.quantita)
        )}
      </div>
    ),
  },
];

export {
  ACQ_ERRORS_COLUMNS,
  ACQ_IMPORT_COLUMNS,
  ACQ_CONFRONTO_COLUMNS,
  ACQ_MAIN_COLUMNS,
  ACQ_VIEW_COLUMNS,
};
