import React from "react";
import "../../App.css";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OFF_SECOND_CONFIRM_POPUP = (props) => {
  const {
    secondConfirmOpen,
    setSecondConfirmOpen,
    notMatch,
    handleSecondConfirm,
  } = props;

  const theme = createTheme({
    palette: {
      bluetest: {
        main: "#1976d2",
        light: "#328ce7",
        dark: "#1566b7",
        contrastText: "#fff",
      },
      redtest: {
        main: "#ff0000",
        light: "#ff3333",
        dark: "#cc0000",
        contrastText: "#fff",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="acqPage">
          <Dialog
            open={secondConfirmOpen}
            TransitionComponent={Transition}
            fullWidth={true}
            maxWidth="lg"
            keepMounted
            onClose={() => setSecondConfirmOpen(false)}
            PaperProps={{
              style: {
                backgroundColor: "#f4f4f4",
                border: "1px solid #ccc",
                borderRadius: "10px",
              },
            }}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle
              sx={{
                fontFamily: "Calibri",
                fontWeight: "bold",
                color: "#1976d2",
                fontSize: 25,
                textAlign: "center",
              }}
            >
              {notMatch.length > 0 && (
                <span style={{ color: "red" }}>
                  PROSEGUENDO VERRANNO RIMOSSE LE SPECIALITA' SENZA MATCH!
                </span>
              )}
              <br />
              CONFERMI DI VOLER PROSEGUIRE?
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="redtest"
                  startIcon={<DeleteOutlinedIcon />}
                  onClick={() => setSecondConfirmOpen(false)}
                >
                  INDIETRO
                </Button>
              </div>
              <div
                style={{
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "6%",
                }}
              ></div>
              <div
                style={{
                  textAlign: "left",
                  paddingTop: "2%",
                  display: "inline-block",
                  width: "47%",
                }}
              >
                <Button
                  className="Button"
                  variant="contained"
                  component="label"
                  color="success"
                  startIcon={<CheckOutlinedIcon />}
                  onClick={handleSecondConfirm}
                >
                  CONFERMA
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  );
};

export default OFF_SECOND_CONFIRM_POPUP;
