import * as XLSX from "xlsx";

const acceptableFileName = ["xlsx", "xls"];

const checkFileName = (name) => {
  return acceptableFileName.includes(name.split(".").pop().toLowerCase());
};

const readDataFromFile = (data) => {
  const wb = XLSX.read(data, { type: "array" });
  const worksheet = wb.Sheets[wb.SheetNames[0]];
  const jsonData = XLSX.utils.sheet_to_json(worksheet);
  return jsonData;
};

const createFileData = (e) => {
  let newFileData = [];
  let id_data_temp = 0;

  e.forEach((Sel) => {
    newFileData.push({
      id_temp: id_data_temp++,
      struttura: Sel["STRUTTURA"] || "",
      aic: Sel.AIC || "",
      nome_commerciale: Sel["NOME COMMERCIALE"] || "",
      quantita: Sel["QUANTITA"] || "",
    });
  });

  return newFileData;
};

const validateFileData = (newFileData) => {
  let errors = {
    struttura_error: [],
    aic_error: [],
    name_error: [],
    quantita_error: [],
    flag_error: false,
  };

  newFileData.forEach((Sel) => {
    if (Sel.struttura === "") errors.struttura_error.push(Sel);
    if (Sel.aic === "" || Sel.aic.toString().trim().length > 9)
      errors.aic_error.push(Sel);
    if (Sel.nome_commerciale === "") errors.name_error.push(Sel);
    if (Sel.quantita === "") errors.quantita_error.push(Sel);
  });

  errors.flag_error =
    errors.struttura_error.length > 0 ||
    errors.aic_error.length > 0 ||
    errors.name_error.length > 0 ||
    errors.quantita_error.length > 0;

  return errors;
};

const formatCorrectData = (newFileData) => {
  newFileData.forEach((Sel) => {
    if (Sel.aic.toString().length < 9) {
      Sel.aic = Sel.aic.toString().padStart(9, "0");
    } else {
      Sel.aic = Sel.aic.toString();
    }
  });

  return newFileData;
};

const handleErrors = (errors, setImportErrors) => {
  let newErrorsTEMP = [];
  const errorTypes = [
    "aic_error",
    "name_error",
    "quantita_error",
    "struttura_error",
  ];

  errorTypes.forEach((type) => {
    errors[type].forEach((ERR_SEL) => {
      let temp = {
        id_temp: ERR_SEL.id_temp,
        aic: ERR_SEL.aic,
        nome_commerciale: ERR_SEL.nome_commerciale,
        quantita: ERR_SEL.quantita,
        struttura: ERR_SEL.struttura,
      };
      temp[`flag_${type}`] = true;
      newErrorsTEMP.push(temp);
    });
  });

  const commonElements = findCommonElementsWithFlags(newErrorsTEMP);
  setImportErrors(commonElements);
};

const findCommonElementsWithFlags = (data) => {
  const idMap = {};
  data.forEach((item) => {
    if (!idMap[item.id_temp]) {
      idMap[item.id_temp] = { ...item };
    } else {
      Object.keys(item).forEach((key) => {
        if (typeof item[key] === "boolean" && key.startsWith("flag_")) {
          idMap[item.id_temp][key] = idMap[item.id_temp][key] || item[key];
        }
      });
    }
  });
  return Object.values(idMap);
};

const handleUploadDataControl = async (e, setDataFile, setImportErrors) => {
  let newFileData = createFileData(e);
  let errors = validateFileData(newFileData);

  if (!errors.flag_error) {
    newFileData = formatCorrectData(newFileData);
    setDataFile(newFileData);
    setImportErrors(null);
  } else {
    handleErrors(errors, setImportErrors);
  }

  return newFileData;
};

const handleFile = async (e, setDataFile, setImportErrors) => {
  setDataFile(null);
  const myfile = e.target.files[0];
  if (!myfile || !checkFileName(myfile.name)) {
    alert("Invalid file type!");
    return;
  }

  const data = await myfile.arrayBuffer();
  const mySheetData = readDataFromFile(data);
  handleUploadDataControl(mySheetData, setDataFile, setImportErrors);
};

export { handleFile };
